import {ModalTitle, ModalWithPresence} from "../../../../../components/modal";
import {AssignLicenses} from "./assignLicensesModal";
import {useSelector} from "react-redux";
import {RootState} from "../../../../store";
import {selectDiscunaAppById} from "./discunaAppSlice";
import {Alert, Button, CircularProgress, Typography} from "@mui/material";
import {Center, Row} from "../../../../../components/motion_mui";
import {useCallback, useState} from "react";
import {removeAppFromCommunity} from "../../../../../firebase/discunaApi";
import {circularProgressSize} from "../../../../../constants/constants";

export function RemoveAppFromCommunityModal({show, onClose, appId, communityId}: {
    show: boolean,
    onClose: () => void,
    appId: string | null,
    communityId: string,
}) {

    return (
        <ModalWithPresence show={show} onCancel={onClose}>
            {
                appId &&
                <RemoveAppFromCommunity appId={appId} communityId={communityId} onClose={onClose}/>
            }
        </ModalWithPresence>
    )
}

function RemoveAppFromCommunity({communityId, appId, onClose}: {
    appId: string,
    communityId: string,
    onClose: () => void,
}) {
    const app = useSelector((state: RootState) => selectDiscunaAppById(state, appId))

    const [status, setStatus] = useState<null | "processing" | "success" | "error">(null)

    const onRemoveAppFromCommunity = useCallback(async (appId: string, communityId: string) => {
        setStatus("processing")
        removeAppFromCommunity({
            communityId,
            appId
        }).then(() => {
            setStatus("success")
        }).catch((e) => {
            console.error("failed to remove app from community", e)
            setStatus("error")
        })
    }, [])

    if (status === "processing") {
        return (
            <>
                <ModalTitle title={`Remove ${app?.details?.config?.name}`} onCancel={onClose}/>
                <Center>
                    <CircularProgress size={circularProgressSize}/>
                </Center>
            </>
        )
    }

    if (status === "success") {
        return (
            <>
                <ModalTitle title={`Remove ${app?.details?.config?.name} app`} onCancel={onClose}/>
                <Alert severity={"success"}>
                    App has been removed successfully!
                </Alert>
            </>
        )
    }

    if (status === "error") {
        return (
            <>
                <ModalTitle title={`Remove ${app?.details?.config?.name} app`} onCancel={onClose}/>
                <Alert severity={"error"}>
                    We are very sorry, an error has occurred. Please contact support@discuna.com or try again.
                </Alert>
            </>
        )
    }

    return (
        <>
            <ModalTitle title={`Remove ${app?.details?.config?.name} app`} onCancel={onClose}/>

            <Typography variant={"body1"}>
                All channels and licenses associated with this app will be removed / cancelled.
            </Typography>
            <Row mainAxisAlignment={"spaceBetween"} crossAxisAlignment={"center"} sx={{mt: 2}}>
                <Button onClick={onClose}>
                    cancel
                </Button>
                <Button variant={"contained"} color={"primary"} onClick={() => {
                    onRemoveAppFromCommunity(appId, communityId)
                }}>
                    remove
                </Button>
            </Row>
        </>
    )
}


