import {useCommunityBookmarks} from "./useCommunityBookmarks";
import {useSelector} from "react-redux";
import {RootState} from "../../../store";
import {useMemo} from "react";


export function CommunityBookmarkLoader() {
    const userSecretsData = useSelector((state: RootState) => state.userSecrets.data)
    const communityIds = useMemo(() => {
        if(!userSecretsData) return []
        return Object.keys(userSecretsData.details.memberships)
    }, [userSecretsData])
    return (
        <>
            {
                communityIds.map((communityId) => (
                    <CommunityBookmarkLoaderItem key={communityId} communityId={communityId}/>
                ))
            }
        </>
    )
}


function CommunityBookmarkLoaderItem(props: {communityId: string}) {
    useCommunityBookmarks(props.communityId)
    return null
}
