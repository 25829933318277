import {useDispatch} from "react-redux";
import useMemoizedState from "../../../../../hooks/useMemoizedState";
import {useEffect} from "react";
import {firestore} from "../../../../../firebase/firebase";
import {firestoreManager} from "../../../../../firebase/queryManager";
import {AppLicenseFirestoreData} from "shared";
import {AppLicense} from "./discunaAppInterface";
import {removeDiscunaAppLicense, upsertDiscunaAppLicense} from "./discunaAppLicenseSlice";


export function getLicenseReduxId(appId: string, licenseId: string) {
    return `${appId}_${licenseId}`
}

export function useAppLicenses(appId: string) {
    const dispatch = useDispatch()
    const [isLoading, setIsLoading] = useMemoizedState(true)
    useEffect(() => {
        setIsLoading(true)
        const licenseColRef = firestore
            .collection("apps")
            .doc(appId)
            .collection("licenses")
        const {isNew, cancel} = firestoreManager.queryCollectionSnapshots(licenseColRef,
            (snap) => {
                setIsLoading(false)
                snap.docChanges().forEach((change) => {
                    const licenseId = change.doc.id
                    const reduxId = getLicenseReduxId(appId, licenseId)
                    if (change.type === "removed") {
                        dispatch(removeDiscunaAppLicense(reduxId))
                    } else {
                        const discunaAppLicense = {
                            id: reduxId,
                            isSynced: true,
                            appId,
                            licenseId,
                            ...(change.doc.data() as AppLicenseFirestoreData)
                        } as AppLicense
                        dispatch(upsertDiscunaAppLicense(discunaAppLicense))
                    }
                })
            })
        setIsLoading(isNew)
        return cancel
    }, [appId, dispatch, setIsLoading])

    return isLoading
}
