import {createEntityAdapter, createSlice} from '@reduxjs/toolkit'
import {RootState} from "../../../../store";
import {MemberInsights} from "./memberInsightsInterfaces";

const memberInsightsEntityAdapter = createEntityAdapter<MemberInsights>()

const memberInsightsSlice = createSlice({
    name: "memberInsights",
    initialState: memberInsightsEntityAdapter.getInitialState({}),
    reducers: {
        addMemberInsights: memberInsightsEntityAdapter.addOne,
        upsertMemberInsights: memberInsightsEntityAdapter.upsertOne,
        updateMemberInsights: memberInsightsEntityAdapter.updateOne,
        removeMemberInsights: memberInsightsEntityAdapter.removeOne,
        removeAllMemberInsights: memberInsightsEntityAdapter.removeAll,
        setAllMemberInsights: memberInsightsEntityAdapter.setAll
    },
    extraReducers: builder => {
    }
})

export const {
    addMemberInsights,
    upsertMemberInsights,
    updateMemberInsights,
    removeMemberInsights,
    removeAllMemberInsights,
    setAllMemberInsights
} = memberInsightsSlice.actions

export const memberInsightsReducer = memberInsightsSlice.reducer

export const {
    selectAll: selectAllMemberInsights,
    selectById: selectMemberInsightsById,
    selectIds: selectMemberInsightsIds,
} = memberInsightsEntityAdapter.getSelectors<RootState>(state => state.memberInsights)

