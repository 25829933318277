import {useSelector} from "react-redux";
import {RootState} from "../../../../../store";
import {useMemo} from "react";
import {selectAllBookmarks, selectBookmarksForCommunity} from "../../../bookmarks/bookmarksSlice";
import {LibraryItemWithLevel} from "./communityLibraryUtils";

// TODO get rid of bookmark types
const bookmarkTypes = ["todo", "interesting", "solved", "unsolved"] as const

export function useBadgeCountBookmarkCommunityList(communityIds: string[]) {

    const allBookmarks = useSelector((state: RootState) => selectAllBookmarks(state))

    const bookmarksCount = useMemo(() => {
        const bookmarksCount = {} as Record<string, Record<string, number>>
        for (const communityId of communityIds) {
            bookmarksCount[communityId] = {} as Record<string, number>
            for (const type of bookmarkTypes) {
                bookmarksCount[communityId][type] = 0
            }
        }
        allBookmarks.forEach((bookmark) => {
            const communityId = bookmark.details.communityId
            const bookmarkType = bookmark.type
            if (!communityIds.includes(communityId)) {
                return
            }
            bookmarksCount[communityId][bookmarkType] =
                (bookmarksCount[communityId][bookmarkType] ?? 0) + 1
        })
        return bookmarksCount
    }, [allBookmarks, communityIds])

    return bookmarksCount
}


export function useBadgeCountBookmarksCommunityLibrary(
    communityId: string,
    libraryList: LibraryItemWithLevel[],
    allChildChannelsMap: Map<string, string[]>
) {
    // compute num unread channel messages
    const selectBookmarksForCommunityMemo = useMemo(selectBookmarksForCommunity, [])
    const bookmarks = useSelector(
        (state: RootState) => selectBookmarksForCommunityMemo(state, communityId)
    )
    const channelBookmarkCountMap = useMemo(() => {
        // bookmarksCount[channelId][type] = count
        const bookmarksCount = {} as Record<string, Record<string, number>>
        bookmarks.forEach((bookmark) => {
            const channelId = bookmark.details.channelId
            if (!(channelId in bookmarksCount)) {
                bookmarksCount[channelId] = {} as Record<string, number>
            }
            if (!(bookmark.type in bookmarksCount[channelId])) {
                bookmarksCount[channelId][bookmark.type] = 0
            }
            bookmarksCount[channelId][bookmark.type] += 1
        })
        return bookmarksCount
    }, [bookmarks])

    const bookmarkCountMap = useMemo(() => {
            // bookmarksCount[channelId][type] = count including children
            const bookmarkCountMap = {} as Record<string, Record<string, number>>
            // TODO why do we need to copy the map?
            for (const channelId in channelBookmarkCountMap) {
                bookmarkCountMap[channelId] = {...(channelBookmarkCountMap[channelId]??{})}
            }
            // TODO this has quadratic complexity => create virtual tree and compute counts in one pass
            allChildChannelsMap.forEach((childChannels, folderId) => {
                bookmarkCountMap[folderId] = {}
                for (const type of bookmarkTypes) {
                    bookmarkCountMap[folderId][type] = 0
                }
                childChannels.forEach(id => {
                    for (const type of bookmarkTypes) {
                        if (!bookmarkCountMap[id]) {
                            continue
                        }
                        bookmarkCountMap[folderId][type] += bookmarkCountMap[id][type] ?? 0
                    }
                })
            })
            return bookmarkCountMap
        },
        [allChildChannelsMap, channelBookmarkCountMap]
    )

    return bookmarkCountMap
}
