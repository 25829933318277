import {useNavigate, useSearchParams} from "react-router-dom";
import {useCallback, useEffect, useMemo, useState} from "react";
import {denyConsent, getConsentData, grantConsent} from "../../firebase/discunaApi";
import {LoadingScreen} from "../../utils/loadingScreen";
import {Box, Button} from "@mui/material";


export function SkipConsent() {

    const [params] = useSearchParams()
    useEffect(() => {
        const consentChallenge = params.get("consent_challenge")
        if(consentChallenge) {
            grantConsent({
                consent_challenge: consentChallenge,
                grant_scope: ["openId"]
            }).then(({data}) => {
                window.location.href = data.redirectTo
            })
        }
    }, [params])

    return null
}

export function Consent() {

    // TODO keeps causing updates
    const [params] = useSearchParams()
    const consentChallenge = useMemo(
        () => params.get("consent_challenge"),
        [params]
    )

    const [consentData, setConsentData] = useState<any | null>(null)
    const [errMsg, setErrMsg] = useState<null | string>(null)


    // fetch consent data
    useEffect(() => {
        if(consentChallenge) {
            getConsentData({
                consent_challenge: consentChallenge
            }).then((res) => {
                // console.log(res.data)
                setConsentData(res.data)
            }).catch((e) => {
                setErrMsg(e.message??"unknown")
            })
        }
    }, [consentChallenge])


    const grantConsentCallback = useCallback(async () => {
        if(consentChallenge) {
            const {data} = await grantConsent({
                consent_challenge: consentChallenge,
                grant_scope: ["openId"]
            })
            window.location.href = data.redirectTo
        }
    }, [consentChallenge])

    const denyConsentCallback = useCallback(async () => {
        if(consentChallenge) {
            const {data} = await denyConsent({
                consent_challenge: consentChallenge,
            })
            window.location.href = data.redirectTo
        }
    }, [consentChallenge])

    // return <p>
    //     test
    // </p>

    if(errMsg) return (
        <p>
            Error: {errMsg}
        </p>
    )

    if(!consentData) return (
        <p>loading</p>
    )

    return (
        <Box sx={{
            overflowY: "auto",
            maxHeight: "100%"
        }}>
            <p>
                {JSON.stringify(consentData)}
            </p>
            <Button onClick={denyConsentCallback}>
                Deny Access
            </Button>
            <Button onClick={grantConsentCallback}>
                Grant Access
            </Button>
        </Box>
    )
}
