import {MessageClient} from "./messageClient";

export class DiscunaAppFrontendApiClient {
    messageClient: MessageClient

    constructor() {
        this.messageClient = new MessageClient({
            meta: {
                api: "discuna-app-frontend-api",
                version: "0.0.1"
            }
        })
        this.messageClient.start()
    }

    async createChannelClick(targetOrigin: string, targetWindow: Window) {
        await this.messageClient.post({cmd: "createChannelClick"}, targetOrigin, targetWindow)
    }

    async notificationClick(notificationId: string, data: any, targetOrigin: string, targetWindow: Window) {
        await this.messageClient.post({cmd: "notificationClick", payload: {notificationId, data}}, targetOrigin, targetWindow)
    }
}

export const discunaAppFrontendApiClient = new DiscunaAppFrontendApiClient()
