import {useNavigate, Outlet} from "react-router-dom";
import {Row} from "../../components/motion_mui";
import React, {useCallback, useEffect} from "react";
import {NavigationBar} from "./features/navbar/navigationBar";
import {useUserSecrets} from "../userSecrets/userSecretsUtils";
import {parseFrom, useUnreadInboxMessages} from "./features/inbox/inboxUtils";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../store";
import {setSelectedCommunityId} from "./dashboardSlice";
import {useAppsOnce} from "./features/communities/apps/useAppsOnce";
import {useBookmarks} from "./features/bookmarks/useBookmarks";
import {Button, IconButton, Snackbar} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import {useUpdateSW} from "../../hooks/useUpdateSW";
import {CommunityBookmarkLoader} from "./features/bookmarks/communityBookmarkLoader";


export function Dashboard() {

    // useGlobalHoverFix()

    const navigate = useNavigate()
    const navTo = (navId: string) => {
        navigate(`./${navId}`)
    }

    // Message handler between discuna and the discuna apps
    // useDiscunaAppBridge()

    useUserSecrets()
    useUnreadInboxMessages()
    useBookmarks()
    useAppsOnce()

    // handle clicks on desktop notifications
    useEffect(() => {
        if (window.discunaDesktopAPI) {
            const messageClickHandler = ((event: CustomEvent) => {
                // TODO navigate to issue
                // why
                navigate("./account")
            }) as EventListener
            window.addEventListener('desktopMessageClicked', messageClickHandler)
            return () => {
                window.removeEventListener('desktopMessageClicked', messageClickHandler)
            }
        }
    }, [navigate])

    // navigate to inbox message origin
    const dispatch = useDispatch()
    const selectedInboxMessagePath = useSelector((state: RootState) => state.dashboard.selectedInboxMessagePath)
    useEffect(() => {
            if (selectedInboxMessagePath) {
                const parsed = parseFrom(selectedInboxMessagePath)
                const {communityId, channelId} = parsed.params
                dispatch(setSelectedCommunityId(communityId))

                const sendInboxMessageClickEvent = () => {
                    const inboxMessageClickEvent = new CustomEvent(
                        'inboxMessageClick',
                        {
                            detail: selectedInboxMessagePath
                        }
                    )
                    window.dispatchEvent(inboxMessageClickEvent)
                    // dispatch(setSelectedInboxMessagePath(null))
                }
                if (window.location.href.includes(channelId)) {
                    sendInboxMessageClickEvent()
                } else {
                    // navigate to channel
                    navigate(`communities/${communityId}/channels/${channelId}`)

                    // send inbox message click event once channel is ready
                    const channelReadyEventHandler = ((e: CustomEvent) => {
                        if (e.detail === channelId) {
                            sendInboxMessageClickEvent()
                        }
                    }) as EventListener
                    window.addEventListener("channelIsReady", channelReadyEventHandler, {once: true})
                    return () => {
                        window.removeEventListener("channelIsReady", channelReadyEventHandler)
                    }
                }

            }
        },
        // NOTE we do not include navigate, because it would trigger an update before
        // channelIsReady event is fired
        [dispatch, selectedInboxMessagePath]
    )

    // check for updates
    const updateSwSnackbar = useUpdateSW()

    return (
        <Row
            mainAxisAlignment={"start"}
            crossAxisAlignment={"stretch"}
            sx={{
                minWidth: "100%",
                maxWidth: "100%",
                minHeight: "100%",
                maxHeight: "100%",
                height: "100%"
            }}
        >
            <NavigationBar navTo={navTo}/>
            <CommunityBookmarkLoader/>
            <Outlet/>
            {updateSwSnackbar}
        </Row>
    )
}



