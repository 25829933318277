// types for qandaDesktopAPI
import {channelOfflineDataSliceStateType} from "./dashboard/features/communities/channels/channelOfflineDataSlice";

export const discunaDesktopAppProtocol = window.origin.includes("qanda") ? "qanda-link" : "discuna"

declare global {
    interface Window {
        version: string,
        qandaDesktopAPI?: {}, // for dashboard
        discunaDesktopAPI?: {
            sendWebAppIsReady: () => void,
            setChannelOfflineData: (channelOfflineData: channelOfflineDataSliceStateType) => void,
            readChannelOfflineDataSync: () => {channelOfflineData: channelOfflineDataSliceStateType | undefined},
            // file operations
            openFileDialog: () => void,
            readFile: (filePath: string) => void,
            readFileSync: (filePath: string) => {
                success: boolean,
                error: null | unknown,
                fileBuffer: null | Uint8Array
            },
            readFileSyncFromAppData: (filePath: string) => {
                success: boolean,
                error: null | unknown,
                fileBuffer: null | Uint8Array
            },
            checkIfFileExistsSync: (filePath: string) => {fileExists: boolean},
            writeFileSync: (relFilePath: string, buffer: Uint8Array) => {
                success: boolean,
                error: null | unknown,
            },
            deleteFileSync: (relFilePath: string) => {
                success: boolean,
                error: null | unknown,
            },
            // webview operations
            webviewPreloadFilepath: string,
            webviewCom: (webviewMessageCallback: (data: any) => void) => {
                sender: (data: any) => void,
                removeListener: () => void
            }
            getUrlHeaderTypeSync: (url: string) => Promise<{
                success: boolean,
                urlType?: "application/pdf" | "text/html" | "text/html; charset=utf-8",
                error?: any
            }>
            getUrlHeaderType: (url: string) => void,
            getRegistrationToken: () => void,
            platform: () => "aix" | "darwin" | "freebsd" | "linux" | "openbsd" | "sunos" | "win32"
        }
    }
}
