import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../../store";
import {useEffect} from "react";
import {FieldPath, firestore} from "../../../../../firebase/firebase";
import {firestoreManager} from "../../../../../firebase/queryManager";
import {getMemberId, removeMember, upsertMember} from "./memberSlice";
import {Member} from "./memberInterfaces";
import {MemberFirestoreData, memberRoles} from "shared/communities/members/member";
import useMemoizedState from "../../../../../hooks/useMemoizedState";
import {sleep} from "../../../../../utils/sleep";
import {InboxMessageFirestoreData} from "shared/user/inbox";
import {InboxMessage} from "../../inbox/inboxInterfaces";
import {getCommunityRef} from "../communityUtils";

export function getMemberRef({userId, communityId}: {
    userId: string,
    communityId: string
}) {
    return getCommunityRef(communityId).collection("members").doc(userId)
}

// --------------------------
// loaders
// --------------------------

// export async function loadMoreMembers(
//     communityId: string,
//     startAfterLastActive: string,
//     limit: number
// ) {
//     const memberQuery = getCommunityRef(communityId).collection("members")
//         .orderBy("lastActive", "desc")
//         .startAfter(startAfterLastActive).limit(limit)
//     const docs = await firestoreManager.query(memberQuery)
//     return [docs.map((doc) => ({
//         id: doc.id,
//         ...(doc.data() as MemberFirestoreData)
//     } as Member)), limit] as [Member[], number]
// }

export async function loadMember(args: {
    communityId: string,
    userId: string
}) {
    const memberRef = getMemberRef(args)
    return await firestoreManager.queryDocument(memberRef)
}

export function useMembersWithRole(communityId: string, role: memberRoles) {
    const dispatch = useDispatch()
    const [isLoading, setIsLoading] = useMemoizedState(true)
    const auth = useSelector((state: RootState) => state.auth)


    // fetch community and community member data
    useEffect(() => {
        if (auth.isSignedIn) {
            const query = getCommunityRef(communityId)
                .collection("members").where("role", "==", role)
            const {isNew, cancel} = firestoreManager.querySnapshots(
                `${communityId}_${role}`,
                query,
                (snapshot) => {
                    setIsLoading(false)
                    snapshot.docChanges().forEach((change) => {
                        if (change.type === "added" || change.type === "modified") {
                            const doc = change.doc
                            const member = {
                                id: getMemberId(communityId, doc.id),
                                isSynced: true,
                                communityId,
                                userId: doc.id,
                                ...(doc.data() as MemberFirestoreData)
                            } as Member
                            dispatch(upsertMember(member))
                        } else {
                            dispatch(removeMember(getMemberId(communityId, change.doc.id)))
                        }
                    })
                }
            )
            setIsLoading(isNew)
            return cancel
        }
    }, [role, communityId, dispatch, setIsLoading, auth])
    return isLoading
}

export function useMembers(communityId: string) {
    const dispatch = useDispatch()
    const [isLoading, setIsLoading] = useMemoizedState(true)
    const auth = useSelector((state: RootState) => state.auth)

    // fetch community and community member data
    useEffect(() => {
        if (auth.isSignedIn) {
            const membersQuery = getCommunityRef(communityId)
                .collection("members")
                .where(FieldPath.documentId(), "!=", "insights")
            const {isNew, cancel} = firestoreManager.querySnapshots(
                `${communityId}_members`,
                membersQuery,
                (snapshot) => {
                    setIsLoading(false)
                    snapshot.docChanges().forEach((change) => {
                        if (change.type === "added" || change.type === "modified") {
                            const doc = change.doc
                            const member = {
                                id: getMemberId(communityId, doc.id),
                                isSynced: true,
                                communityId,
                                userId: doc.id,
                                ...(doc.data() as MemberFirestoreData)
                            } as Member
                            dispatch(upsertMember(member))
                        } else {
                            dispatch(removeMember(getMemberId(communityId, change.doc.id)))
                        }
                    })
                }
            )
            setIsLoading(isNew)
            return cancel
        }
    }, [communityId, dispatch, setIsLoading, auth])
    return isLoading
}


export function useMembershipsOnce(communityIds: string[]) {
    const dispatch = useDispatch()
    const [isLoading, setIsLoading] = useMemoizedState(true)
    const auth = useSelector((state: RootState) => state.auth)

    useEffect(() => {
        if (auth.isSignedIn) {
            const userId = auth.uid
            const promises = []
            for (const communityId of communityIds) {
                const memberRef = getMemberRef({communityId, userId})
                promises.push(memberRef.get().then(doc => {
                    return {
                        id: getMemberId(communityId),
                        isSynced: true,
                        communityId,
                        userId: doc.id,
                        ...(doc.data() as MemberFirestoreData)
                    } as Member
                }))
                Promise.allSettled(promises)
                    .then(res => {
                        res.forEach(val => {
                            if (val.status === "fulfilled")
                                dispatch(upsertMember(val.value))
                        })
                    })
                    .finally(() => {
                        setIsLoading(false)
                    })
            }
        }
    }, [auth, communityIds, dispatch, setIsLoading])

    return isLoading
}


export function useMember({userId, communityId}: {
    userId: string,
    communityId: string
}) {
    const dispatch = useDispatch()
    const [isLoading, setIsLoading] = useMemoizedState(true)
    const auth = useSelector((state: RootState) => state.auth)


    // fetch community and community member data
    useEffect(() => {
        if (auth.isSignedIn) {
            const memberRef = getMemberRef({communityId, userId})
            const {isNew, cancel} = firestoreManager.queryDocumentSnapshots(memberRef, (doc) => {
                setIsLoading(false)
                if (doc.exists) {
                    const member = {
                        id: getMemberId(communityId),
                        isSynced: true,
                        communityId,
                        userId: doc.id,
                        ...(doc.data() as MemberFirestoreData)
                    } as Member
                    dispatch(upsertMember(member))
                } else {
                    dispatch(removeMember(getMemberId(communityId)))
                }
            })
            setIsLoading(isNew)
            return cancel
        }
    }, [userId, communityId, dispatch, setIsLoading, auth])
    return isLoading
}
