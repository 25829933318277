import {Column} from "components/motion_mui";
import {
    Typography
} from "@mui/material";
import React from "react";
import {useDiscunaAppsFromPublisher} from "../communities/apps/discunaAppUtils";
import {useSelector} from "react-redux";
import {RootState} from "../../../store";
import {DevApps} from "./devApps";
import {DevSubscriptions} from "./devSubscriptions";

export function DevBoard() {

    // fetch discuna apps developed by user
    const auth = useSelector((state: RootState) => state.auth)
    if(!auth.isSignedIn) throw Error("unknown")
    useDiscunaAppsFromPublisher(auth.uid)

    return (
        <Column
            sx={{
                width: "100%",
                height: "100%",
                overflow: "auto",
                pb: 4,
            }}
            mainAxisAlignment={"start"}
            crossAxisAlignment={"start"}
        >
            <Typography variant={"h1"} sx={{
                ml: 4,
                mt: 4,
                mb: 4,
            }}>
                Development Board
            </Typography>

            <DevApps/>

            {/*<DevSubscriptions/>*/}

        </Column>
    )
}
