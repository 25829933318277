import {createSlice, PayloadAction} from "@reduxjs/toolkit";


export interface DashboardState{
    selectedListItemKey: null | string,
    selectedCommunityId: null | string,
    selectedInboxMessagePath: null | string
}

const initialState = {
    selectedListItemKey: null,
    selectedCommunityId: null,
    // selectedInboxMessagePath: null
} as DashboardState

const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState: initialState,
    reducers: {
        setSelectedListItemKey(state, action: PayloadAction<string>){
            state.selectedListItemKey = action.payload
        },
        setSelectedCommunityId(state, action: PayloadAction<string | null>) {
            state.selectedCommunityId = action.payload
        },
        // setSelectedInboxMessagePath(state, action: PayloadAction<string | null>) {
        //     state.selectedInboxMessagePath = action.payload
        // }
    }
})

export const { setSelectedListItemKey, setSelectedCommunityId } = dashboardSlice.actions
export const dashboardReducer = dashboardSlice.reducer
