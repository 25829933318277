import {useCallback, useEffect, useMemo, useState} from "react";
import {useNavigate, useSearchParams} from "react-router-dom";
import {login} from "../../firebase/discunaApi";
import {LoadingScreen} from "../../utils/loadingScreen";

export function Login() {

    const [params] = useSearchParams()
    const loginChallenge = useMemo(
        () => params.get("login_challenge"),
        [params]
    )

    const [errMsg, setErrMsg] = useState<null | string>(null)

    const loginCallback = useCallback(async () => {
        if(!loginChallenge) throw Error("login challenge not provided")
        const {data} = await login({
            challenge: loginChallenge
        })
        window.location.href = data.redirectTo
    }, [loginChallenge, ])

    useEffect(() => {
        loginCallback().catch((e) => {
            setErrMsg(e.message??"unknown")
        })
    })

    // TODO
    if(errMsg) return <div>
        {errMsg}
    </div>

    return (
        <LoadingScreen/>
    )
}
