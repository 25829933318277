import {useParams} from "react-router-dom";
import React, {useMemo, useState} from "react";
import {NestedListItem} from "./nestedListItem";
import {Icon as IconifyIcon} from "@iconify/react";
import {Center} from "../../../../../../components/motion_mui";
import {Menu, MenuItem} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {EditChannelModal} from "./contextMenu/editChannelModal";
import {DeleteChannel} from "./contextMenu/deleteChannel";
import {toggleChannelMute} from "../../../../../../firebase/discunaApi";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import NotificationsOffOutlinedIcon from "@mui/icons-material/NotificationsOffOutlined";
import {RestrictAccess} from "../../../communities/accessWrappers";
import {primaryColor} from "../../../../../../constants/constants";
import {LibraryItemWithLevel} from "./communityLibraryUtils";
import {RootState, useAppDispatch} from "../../../../../store";
import {selectCommunityById} from "../../../communities/communitySlice";
import {selectAllDiscunaApps} from "../../../communities/apps/discunaAppSlice";
import {AppIcon} from "../../../communities/apps/appIcon";
import {DiscunaApp} from "../../../communities/apps/discunaAppInterface";

export function FileItem(
    {
        muted,
        enableMuteMenu,
        dragId,
        setDragId,
        item,
        communityId,
        numUnreadChannelMessages,
        numUnreadPersonalMessages,
        bookmarksCount,
        isSynced,
        navTo,
        isDraggable = true
    }: {
        item: LibraryItemWithLevel,
        communityId: string,
        numUnreadChannelMessages: number,
        numUnreadPersonalMessages: number,
        bookmarksCount: Record<string, number>,
        isSynced: boolean,
        navTo: (navId: string) => void

        // mute
        muted: boolean,
        enableMuteMenu: boolean,

        // drag and drop
        dragId: string | null,
        setDragId: (id: string | null) => void,
        isDraggable?: boolean
    }
) {
    const apps = useSelector((state: RootState) => selectAllDiscunaApps(state))
    const appMap = useMemo(() => {
        const appMap = new Map<string, DiscunaApp>()
        for(const app of apps) {
            appMap.set(app.id, app)
        }
        return appMap
    }, [apps])

    const {channelId} = useParams<{ channelId: string | undefined }>()
    const [showEditForm, setShowEditForm] = useState(false)
    const [showDeleteForm, setShowDeleteForm] = useState(false)

    const [contextMenu, setContextMenu] = useState<{
        mouseX: number;
        mouseY: number;
    } | null>(null);

    const handleContextMenu = (event: React.MouseEvent) => {
        event.preventDefault();
        setContextMenu(
            contextMenu === null
                ? {
                    mouseX: event.clientX - 2,
                    mouseY: event.clientY - 4,
                }
                : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
                  // Other native context menus might behave different.
                  // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
                null,
        );
    };

    const handleClose = () => {
        setContextMenu(null);
    };

    const dispatch = useAppDispatch()

    return (
        <>
            <NestedListItem
                id={item.id}
                communityId={communityId}
                channelId={item.id}
                lowCount={numUnreadChannelMessages}
                highCount={numUnreadPersonalMessages}
                bookmarksCount={bookmarksCount}
                level={item.level}
                name={item.name}
                muted={muted}
                isSynced={isSynced}
                selected={item.id === channelId || contextMenu !== null}
                dragId={dragId}
                setDragId={setDragId}
                isDroppable={false}
                isDraggable={isDraggable}
                icon={<AppIcon sx={{minWidth: 16, minHeight: 16, maxWidth: 16, maxHeight: 16}} url={appMap.get(item.appId)?.details.config.icons.channel??""}/>}
                onContextMenu={handleContextMenu}
                onClick={() => {
                    navTo(item.id)
                }}
            />
            <Menu
                id="qanda-more-library-item-menu"
                anchorReference="anchorPosition"
                anchorPosition={
                    contextMenu !== null
                        ? {top: contextMenu.mouseY, left: contextMenu.mouseX}
                        : undefined
                }
                open={contextMenu !== null}
                onClose={handleClose}
            >
                <MenuItem
                    disabled={!enableMuteMenu}
                    onClick={() => {
                        dispatch(toggleChannelMute({
                            value: !muted,
                            communityId,
                            channelId: item.id
                        }))
                        handleClose()
                    }}>
                    {
                        muted ?
                            <NotificationsActiveIcon sx={{mr: 1}}/> :
                            <NotificationsOffOutlinedIcon sx={{mr: 1}}/>
                    }
                    {
                        muted ?
                            "Unmute" :
                            "Mute"
                    }
                </MenuItem>
                <RestrictAccess communityId={communityId} role={"moderator"}>
                    <MenuItem onClick={() => {
                        handleClose()
                        setShowEditForm(true)
                    }}>
                        Edit
                    </MenuItem>
                    <MenuItem onClick={() => {
                        handleClose()
                        setShowDeleteForm(true)
                    }}>
                        Delete
                    </MenuItem>
                </RestrictAccess>
            </Menu>

            <EditChannelModal
                show={showEditForm}
                communityId={communityId}
                item={item}
                onClose={() => setShowEditForm(false)}
            />
            <DeleteChannel
                show={showDeleteForm}
                communityId={communityId}
                item={item}
                onClose={() => setShowDeleteForm(false)}
            />
        </>
    )
}
