import {createEntityAdapter, createSelector, createSlice, PayloadAction} from '@reduxjs/toolkit'
import {Community} from "./communityInterfaces";
import {RootState} from "../../../store";
import {auth} from '../../../../firebase/firebase';
import {
    createCommunity,
    createInvitationLink,
    deleteCommunity,
    deleteInvitationLink,
    getUser, updateCommunity
} from "../../../../firebase/discunaApi";
import {createCommunityFromArgs} from 'shared/communities/community';
import {memberRoles} from "shared/communities/members/member";
import {
    arraySaveDelete,
    arraySavePush,
    removeArrayItemWithId,
    updateArrayItemWithId
} from "../../../../utils/arrayOperations";
import {isInDevMode} from "../navbar/communities/contextMenu/algolia";

function addMinutes(date: Date, minutes: number) {
    return new Date(date.getTime() + minutes*60000);
}

const communityEntityAdapter = createEntityAdapter<Community>()

const communitySlice = createSlice({
    name: "communities",
    initialState: communityEntityAdapter.getInitialState({
        notSynced: [] as string[]
    }),
    reducers: {
        addCommunity: communityEntityAdapter.addOne,
        upsertCommunity: communityEntityAdapter.upsertOne,
        updateOneCommunity: communityEntityAdapter.updateOne,
        removeCommunity: communityEntityAdapter.removeOne,
        removeAllCommunities: communityEntityAdapter.removeAll,
        setAllCommunities: communityEntityAdapter.setAll,
    },
    extraReducers: builder => {
        builder.addCase(createCommunity.fulfilled,
            (state, action) => {
                const community = state.entities[action.meta.arg.communityId]
                state.notSynced = arraySaveDelete(state.notSynced,action.meta.arg.communityId)
                if (community) community.isSynced = true
            })
            .addCase(createCommunity.pending,
                (state, action) => {
                    const args = action.meta.arg
                    const communityEnhanced = {
                        // redux
                        id: args.communityId,
                        isSynced: false,
                        // firestore
                        ...createCommunityFromArgs(args, isInDevMode)
                    } as Community
                    state.notSynced = arraySavePush(state.notSynced, args.communityId)
                    communityEntityAdapter.upsertOne(state, communityEnhanced)
                })
            .addCase(deleteCommunity.pending,
                (state, action) => {
                    const args = action.meta.arg
                    communityEntityAdapter.removeOne(state, args.communityId)
                })
            .addCase(updateCommunity.pending,
                (state, action) => {
                    const args = action.meta.arg
                    const community = state.entities[args.communityId]
                    if(community) {
                        community.details = {
                            ...community.details,
                            ...args.details
                        }
                        if(args.accessibility) {
                            community.accessibility = args.accessibility
                        }
                    }
                })

    }
})

export const {
    addCommunity,
    upsertCommunity,
    updateOneCommunity,
    removeCommunity,
    removeAllCommunities,
    setAllCommunities,
} = communitySlice.actions

export const communityReducer = communitySlice.reducer

export const {
    selectAll: selectAllCommunities,
    selectById: selectCommunityById,
    selectIds: selectCommunityIds,
} = communityEntityAdapter.getSelectors<RootState>(state => state.communities)

export const selectCommunitiesByCommunityIds = () => createSelector(
    [(state: RootState) => selectAllCommunities(state), (state: RootState, communityIds: string[]) => communityIds], //
    (communities, communityIds) => communities.filter(community => communityIds.includes(community.id))
)
