import {createEntityAdapter, createSlice} from '@reduxjs/toolkit'
import {RootState} from "../../../../../store";
import {RewardInsights} from "./rewardInsightsInterfaces";

const rewardInsightsEntityAdapter = createEntityAdapter<RewardInsights>()

const rewardInsightsSlice = createSlice({
    name: "rewardInsights",
    initialState: rewardInsightsEntityAdapter.getInitialState({}),
    reducers: {
        addRewardInsights: rewardInsightsEntityAdapter.addOne,
        upsertRewardInsights: rewardInsightsEntityAdapter.upsertOne,
        updateRewardInsights: rewardInsightsEntityAdapter.updateOne,
        removeRewardInsights: rewardInsightsEntityAdapter.removeOne,
        removeAllRewardInsights: rewardInsightsEntityAdapter.removeAll,
        setAllRewardInsights: rewardInsightsEntityAdapter.setAll
    },
    extraReducers: builder => {
    }
})

export const {
    addRewardInsights,
    upsertRewardInsights,
    updateRewardInsights,
    removeRewardInsights,
    removeAllRewardInsights,
    setAllRewardInsights
} = rewardInsightsSlice.actions

export const rewardInsightsReducer = rewardInsightsSlice.reducer

export const {
    selectAll: selectAllRewardInsights,
    selectById: selectRewardInsightsById,
    selectIds: selectRewardInsightsIds,
} = rewardInsightsEntityAdapter.getSelectors<RootState>(state => state.rewardInsights)
