import {createTheme, responsiveFontSizes} from "@mui/material";
import {primaryColor, secondaryColor} from "../constants/constants";

// TODO put these function into utils
export function ptToRem(pt: number){
    const rem = pt / 12
    return rem + "rem"
}

export function pxToPt(px: number){
    // 12 / 16 = 3 / 4 = 0.75
    return px * 0.75
}

export function pxToRem(px: number){
    const rem = px / 16;
    return rem + "rem"
}

export const theme = responsiveFontSizes(createTheme({
    components: {
        MuiListItemIcon: {
            styleOverrides: {
                // Name of the slot
                root: {
                    // Some CSS
                    color: "black",
                },
            },
        },
        MuiIconButton: {
            styleOverrides: {
                // Name of the slot
                root: {
                    // Some CSS
                    color: "black",
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                // Name of the slot
                root: {
                    // Some CSS
                    // TODO where needed??
                    // color: "black",
                    // '&:hover': {
                    //     backgroundColor: '#F0F2F5'
                    // }
                },
            },
        },
    },
    palette: {
        primary: {
            main: primaryColor
        },
        secondary: {
            main: secondaryColor
        },
        // info: {
        //     main: deepOrange[500]
        // }
    },
    typography: {
        // fontSize: 100,
        // htmlFontSize: 20,
        // 56 - 22, 44, 32 - 18,
        // 42 - 16.5, 33, 24 - 13.5
        h1: {
            fontSize: pxToRem(48), // 64
            fontWeight: 400,
            color: "#0063dc"
        },
        h2: {
            fontSize: pxToRem(36), // 56
            fontWeight: 400,
            color: "#0063dc"
        },
        h3: {
            fontSize: pxToRem(24), // 48
            fontWeight: 400,
        },
        h4: {
            fontSize: pxToRem(20),
            fontWeight: 500,
            // color: "#0063dc"
        },
        h5: {
            fontSize: pxToRem(18),
            fontWeight: 500,
            color: "#0063dc"
        },
        h6: {
            fontSize: pxToRem(18),
        },
        subtitle1: {
            fontSize: pxToRem(18),
            color: "#606060",
            // lineHeight: "1.2"
        },
        body1: {
            fontSize: pxToRem(16),
            // lineHeight: "1.2"
        }
    }
}));
