import {
    Card, IconButton,
    List, ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText, Menu
} from "@mui/material";
import React, {useState} from "react";
import {AppIcon} from "./appIcon";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";

export function AppCard({iconUrl, name, description, onClick, contextMenuItems, secondaryAction}: {
    // TODO maybe change back to app
    // appId: string,
    name: string,
    description: React.ReactNode,
    iconUrl: string,
    onClick?: () => void,
    contextMenuItems?: React.ReactNode[],
    secondaryAction?: React.ReactNode,
}) {

    const [contextMenuAnchorEl, setContextMenuAnchorEl] = useState<null | EventTarget & HTMLElement>(null);

    const listItemContent = [
        <ListItemIcon key={"appCardIcon"}>
            <AppIcon url={iconUrl} sx={{
                height: "40px",
                minHeight: "40px",
                width: "40px",
                minWidth: "40px",
            }}/>
        </ListItemIcon>,
        <ListItemText key={"appCardText"} primary={name} secondary={description}/>
    ]

    return (
        <Card
            sx={{
                p: 0
            }}
            variant={"outlined"}
        >
            {/* TODO Remove List dependency and use card only? */}
            <List disablePadding={true} dense={true}>
                <ListItem disablePadding={!!onClick} secondaryAction={
                    secondaryAction ? secondaryAction :
                        contextMenuItems &&
                        <IconButton aria-label="settings" onClick={(e) => {
                            setContextMenuAnchorEl(e.currentTarget)
                        }}>
                            <MoreVertOutlinedIcon/>
                        </IconButton>
                }>
                    {
                        onClick ?
                            <ListItemButton role={undefined} onClick={onClick} dense={true}>
                                {listItemContent}
                            </ListItemButton> :
                            listItemContent
                    }
                </ListItem>
            </List>
            {
                contextMenuItems &&
                <Menu
                    id="app-card-menu"
                    anchorEl={contextMenuAnchorEl}
                    open={Boolean(contextMenuAnchorEl)}
                    onClose={() => setContextMenuAnchorEl(null)}
                    onClick={() => setContextMenuAnchorEl(null)}
                >
                    {contextMenuItems}
                </Menu>
            }
        </Card>
    )
}
