import React, {useCallback, useState} from "react";
import {ModalTitle, ModalWithPresence} from "../../../../components/modal";
import {Alert, Box, Button, Typography} from "@mui/material";
import {Row} from "../../../../components/motion_mui";
import {CustomLoadingButton} from "../communities/accessWrappers";
import {getAuth} from "firebase/auth";

export function DeleteCommunityModal({show, onClose}: {
    onClose: () => void,
    show: boolean,
}) {
    const [alertMessage, setAlertMessage] = useState<null | string>(null)

    const [progress, setProgress] = useState<null | "processing" | "error">(null)

    const setError = useCallback((msg: string) => {
        setProgress("error")
        setAlertMessage(`Error occurred: ${msg}`)
    }, [])

    const onConfirm = useCallback(async () => {
        setProgress("processing")
        const currentUser = getAuth().currentUser
        if(!currentUser) return setError("auth required")
        try {
            await currentUser.delete()
            return onClose()
        } catch (e) {
            // user must re-authenticate
            setError("Re-authentication required. Please sign out and then sign in again.")
        }
    }, [onClose, setError])

    return (
        <ModalWithPresence onCancel={onClose}
                           show={show}>
            <ModalTitle title={`Delete Account`} onCancel={onClose}/>
            {
                progress === "error" &&
                <Alert severity={"error"} onClose={() => {
                    setProgress(null)
                    setAlertMessage(null)
                }}>
                    {alertMessage}
                </Alert>
            }
            {
                progress !== "error" &&
                <>
                    <Typography variant={"body1"}>
                        Are you sure you want to delete your account? This step cannot be undone.
                    </Typography>
                    <Row sx={{mt: 4}} mainAxisAlignment={"end"} crossAxisAlignment={"center"}>
                        <Button onClick={onClose} disabled={progress === "processing"}>
                            Cancel
                        </Button>
                        <Box width={12}/>
                        <CustomLoadingButton
                            variant={"contained"}
                            disabled={progress === "processing"}
                            color={"primary"}
                            onClick={onConfirm}
                            isLoading={progress === "processing"}
                        >
                            Confirm
                        </CustomLoadingButton>
                    </Row>
                </>
            }
        </ModalWithPresence>
    )
}
