import {SxProps} from "@mui/material";
import {getDownloadURL, getStorage, ref} from "firebase/storage";
import React, {useEffect, useState} from "react";
import {AppIcon} from "./appIcon";

export function AppStoreImage(props: {
    imageId: string,
    appId: string,
    sx?: SxProps
}) {
    const storage = getStorage()
    const imagePath = `appStore/${props.appId}/${props.imageId}`
    const storageRef = ref(storage, imagePath)
    const [imgUrl, setImgUrl] = useState<null | string>(null)
    useEffect(() => {
        getDownloadURL(storageRef).then(setImgUrl).catch(console.error)
    }, [storageRef])

    const pixelPerUnit = 32

    return (
        <AppIcon url={imgUrl ?? ""} sx={{
            height: 10 * pixelPerUnit + "px",
            minHeight: 10 * pixelPerUnit + "px",
            width: 16 * pixelPerUnit + "px",
            minWidth: 16 * pixelPerUnit + "px",
            borderRadius: 4,
            overflow: "hidden",
            ...props.sx,
        }}/>
    )
}
