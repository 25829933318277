import React, {useEffect} from "react";
import {Navigate, Route, Routes, Outlet, useLocation, useNavigate, useParams} from "react-router-dom";
import {Dashboard} from "./dashboard/dashboard";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "./store";
import {useAuth} from "./auth/authUtils";
// import { upsertNotification } from "./dashboard/features/notifications/notificationsSlice";
// import {Notification} from "./dashboard/features/notifications/notificationInterfaces";
import "./discunaDesktopApi"
import {theme} from "./theme";
import {ThemeProvider} from "@mui/material";
import {
    ChannelContainerWrapper,
} from "./dashboard/features/communities/channels/channelContainer";
import {LoadingScreen} from "../utils/loadingScreen";
import {Membership} from "./dashboard/features/communities/membership/membership";
import {CommunityInfo} from "./dashboard/features/communities/communityInfo/communityInfo";
import {Account} from "./dashboard/features/account/account";
import {DevBoard} from "./dashboard/features/devBoard/devBoard";
// import {AppState, Auth0Provider, useAuth0, User, withAuthenticationRequired} from "@auth0/auth0-react";
// import {getAuth, signInWithCustomToken, updateEmail, updateProfile} from "firebase/auth";
// import {Auth0ProviderOptions} from "@auth0/auth0-react/src/auth0-provider";
import {WithAuth} from "./auth/protectedRoutes/withAuth";
import {Invitation} from "./invitation/invitation";
import {AuthFlow} from "./auth/steps/authFlow";
import {RegistrationForm} from "./auth/steps/registrationForm";
import {WithConsent} from "./auth/protectedRoutes/withConsent";
import {OAuthFlow} from "./oauth/oAuthFlow";
import {CommunityAppsPage} from "./dashboard/features/communities/apps/communityAppsPage";
import {AdminPanel} from "./dashboard/features/adminPanel/adminPanel";
import {DashboardMobile} from "./dashboard/mobile/dashboardMobile";
import {ExplorerMobile} from "./dashboard/mobile/explorerMobile";

export function RequireSignedOut(props: { element?: JSX.Element }) {
    const signedIn = useSelector((state: RootState) => state.auth.isSignedIn)
    const location = useLocation()
    if (signedIn) return <Navigate replace to={"/dashboard/account"} state={{from: location}}/>
    else return props.element ?? <Outlet/>
}


export function App() {
    window.version = "0.1.6"
    useAuth()
    // TODO why are we loading user secrets and unread inbox messages not in dashboard?
    // useAuth0FirebaseAuthSync()

    const auth = useSelector((state: RootState) => state.auth)
    const dispatch = useDispatch()


    // --------------------------
    // Electron Protocol URLs
    // --------------------------

    const navigate = useNavigate()
    useEffect(() => {
        if (window.discunaDesktopAPI) {
            const urlHandle = ((event: CustomEvent) => {
                // TODO what to do with notification data?
                //  We have a live listener on notifications anyway so that we still receive notifications
                //  even when the user did not choose to receive notifications.
                const url = event.detail.url as string
                if (url.includes("//auth/completeSignIn")) {
                    navigate("/" + url.split("://")[1])
                }
                if (url.includes("//auth/completeUpdateSignInMethod")) {
                    navigate("/" + url.split("://")[1])
                }
                if (url.includes("//auth/reauthenticate")) {
                    navigate("/" + url.split("://")[1])
                }
            }) as EventListener
            window.addEventListener('openUrl', urlHandle)
            return () => {
                window.removeEventListener('openUrl', urlHandle)
            }
        }
    }, [navigate])


    // --------------------------
    // Electron RPC Messages
    // --------------------------

    // check that selectedCommunity exists (will be deleted when we leave / delete community)
    // listen to rpc messages from electron
    useEffect(() => {
        if (window.discunaDesktopAPI) {
            const rpcMessageHandler = ((event: CustomEvent) => {
                console.log("rpc message received", event)
                const msg = event.detail
                // NOTE these command is never issued
                // if (msg.cmd === "OPEN_INVITATION") {
                //     const invitationId = msg.args.invitationId
                //     previewCommunity({
                //         via: "invitation",
                //         invitationId,
                //     }).then((res) => {
                //         dispatch(setSelectedCommunityId(res.data))
                //     }).catch((e) => {
                //         console.error("could not preview community", e)
                //     })
                // }
            }) as EventListener
            window.addEventListener('rpcMessage', rpcMessageHandler)
            // NOTE it is important that we call sendQandaIsActive after we appended the rpcMessage event handler
            // if we are in desktop app => let electron know that we are ready to receive messages
            window.discunaDesktopAPI.sendWebAppIsReady()
            return () => {
                window.removeEventListener('rpcMessage', rpcMessageHandler)
            }
        }
    }, [dispatch])


    if (!auth.hasLoaded) return <LoadingScreen/>
    return (
        <ThemeProvider theme={theme}>
            <Routes>

                <Route path={"invite/:invitationId"} element={<WithConsent protectedComponent={<Invitation/>}/>}/>

                <Route path={"auth/*"} element={<AuthFlow/>}/>
                <Route path={"oauth2/*"} element={
                    <WithAuth protectedComponent={<OAuthFlow/>}/>
                }/>

                <Route path={"welcome"} element={<RegistrationForm/>}/>

                <Route path={"mobile"}>
                    <Route path={"dashboard"} element={
                        <WithAuth protectedComponent={
                            <WithConsent protectedComponent={<DashboardMobile/>}/>
                        }/>
                    }>
                        <Route path={"dev"} element={<DevBoard/>}/>
                        <Route path={"admin"} element={<AdminPanel/>}/>
                        {/*<Route path={"inbox/*"}*/}
                        {/*       element={<Inbox/>}/>*/}
                        <Route path={"communities/:communityId"}
                               element={<ExplorerMobile/>}>
                            <Route path={"info"}
                                   element={<CommunityInfo/>}/>
                            <Route path={"membership"}
                                   element={<Membership/>}/>
                            <Route path={"apps"}
                                   element={<CommunityAppsPage/>}/>
                            <Route path={"channels/:channelId"}
                                   element={<ChannelContainerWrapper/>}/>
                        </Route>
                        <Route path={"account"} element={<Account/>}/>
                        <Route path={""} element={<Navigate to={"account"}/>}/>
                    </Route>
                </Route>

                <Route path={"dashboard"} element={
                    <WithAuth protectedComponent={
                        <WithConsent protectedComponent={<Dashboard/>}/>
                    }/>
                }>
                    <Route path={"account"} element={<Account/>}/>
                    <Route path={"dev"} element={<DevBoard/>}/>
                    <Route path={"admin"} element={<AdminPanel/>}/>
                    {/*<Route path={"inbox/*"}*/}
                    {/*       element={<Inbox/>}/>*/}
                    <Route path={"communities/:communityId/info"}
                           element={<CommunityInfo/>}>
                    </Route>
                    <Route path={"communities/:communityId/membership"}
                           element={<Membership/>}/>
                    <Route path={"communities/:communityId/apps"}
                           element={<CommunityAppsPage/>}/>
                    <Route path={"communities/:communityId/channels/:channelId"}
                           element={<ChannelContainerWrapper/>}/>
                </Route>

                <Route path={"/"} element={<Navigate to={"dashboard"}/>}/>
                <Route path={":invitationId"} element={<RedirectOldInvitationLink/>}/>
            </Routes>
        </ThemeProvider>
    )
}

// delete this with next version
function RedirectOldInvitationLink() {
    const {invitationId} = useParams<{ invitationId: string }>()
    const navigate = useNavigate()
    useEffect(() => {
        navigate(`/invite/${invitationId}`)
    }, [invitationId, navigate])
    return <></>
}
