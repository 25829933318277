import {AuthCredentialsViaPassword} from "./authCredentialsViaPassword";
import {AuthCredentialsViaEmail} from "./authCredentialsViaEmail";
import {Navigate, useNavigate, useSearchParams} from "react-router-dom";
import React, {useCallback, useMemo} from "react";
import {getAuth} from "firebase/auth";

export function AuthCredentials() {
    const email = useMemo(() => window.localStorage.getItem("auth/email"), [])
    const [params] = useSearchParams()
    const via = useMemo(() => params.get("via"), [params])

    const navigate = useNavigate()

    const onGoBack = useCallback(() => {
        navigate("/auth/identity", {replace: true})
    }, [])

    const onAuthSuccess = useCallback(() => {
        const targetURL = window.localStorage.getItem("auth/targetURL")
        let targetRelativePath = "/"
        if (targetURL) {
            try {
                const targetURLObj = new URL(targetURL)
                targetRelativePath = targetURLObj.pathname + targetURLObj.search
            } catch (e) {
                console.error("target URL is invalid")
            }
        }

        // clean storage
        window.localStorage.removeItem("auth/targetURL")
        window.localStorage.removeItem("auth/email")

        // navigate to target URL
        navigate(targetRelativePath)
    }, [])

    const isSignedIn = useMemo(() => {
        const auth = getAuth()
        return !!auth.currentUser
    }, [])

    if (isSignedIn) {
        return <Navigate to={"/"} replace={true}/>
    }

    if (!via) {
        return <Navigate to={"/auth/identity"} replace={true}/>
    }

    return (
        via === "password" ?
            <AuthCredentialsViaPassword email={email} onGoBack={onGoBack} onAuthSuccess={onAuthSuccess}/> :
            via === "email" ?
                <AuthCredentialsViaEmail email={email} onGoBack={onGoBack} onAuthSuccess={onAuthSuccess}/> :
                null
    )
}
