import {useEffect, useState} from "react";

export function useIsResizing() {
    const [windowIsResizing, setWindowIsResizing] = useState(false);

    let timeout: ReturnType<typeof setTimeout> | undefined = undefined;
    useEffect(() => {

        const handleResize = () => {
            if(timeout) clearTimeout(timeout);

            setWindowIsResizing(true);

            timeout = setTimeout(() => {
                setWindowIsResizing(false);
            }, 200);
        }
        window.addEventListener("resize", handleResize);

        return () => window.removeEventListener("resize", handleResize);
    }, [setWindowIsResizing]);

    return windowIsResizing
}