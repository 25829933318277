import React, {useCallback, useMemo} from "react";
import {InboxMessage} from "../inbox/inboxInterfaces";
import {RootState, useAppDispatch} from "../../../store";
import {Column} from "../../../../components/motion_mui";
import {markInboxMessageAsRead} from "../../../../firebase/discunaApi";
import {setQueuedNotification} from "../inbox/inboxSlice";
import {useSelector} from "react-redux";
import {
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText, Typography
} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {selectDiscunaAppById} from "../communities/apps/discunaAppSlice";
import {discunaAppFrontendApiClient} from "../../../discunaCoreFrontendCom/discunaAppFrontendApiClient";
import {setSelectedCommunityId} from "../../dashboardSlice";
import {Icon} from "@iconify/react";
import {selectBookmarkById, selectBookmarks} from "./bookmarksSlice";

export function Bookmarks({type, communityId, channelId, allChildChannels, maxHeight, onClose}: {
    communityId: string,
    channelId?: string,
    allChildChannels?: string[],
    maxHeight: number,
    onClose: () => void,
    type: string
}) {

    const dispatch = useAppDispatch()

    return (
        <Column
            mainAxisAlignment={"start"}
            crossAxisAlignment={"stretch"}
            sx={{
                width: 540,
                maxWidth: "80vw",
                maxHeight,
                // maxHeight: "calc(100vh - 32px)",
                // minHeight: "calc(100vh - 32px)",
                overflow: "auto",
                borderRadius: 1,
                position: "relative"
            }}
        >
            <BookmarkMessageList
                type={type}
                onClose={onClose}
                communityId={communityId}
                channelId={channelId}
                allChildChannels={allChildChannels}
            />
        </Column>
    )
}

function BookmarkMessageList({communityId, channelId, allChildChannels, onClose, type}: {
    communityId: string,
    channelId?: string,
    allChildChannels?: string[],
    onClose: () => void,
    type: string
}) {
    const selectBookmarksMemo = useMemo(selectBookmarks, [])
    const bookmarks = useSelector((state: RootState) => selectBookmarksMemo(
        state,
        communityId,
        channelId ? (allChildChannels ? allChildChannels : [channelId]) : null,
        type
    ))

    return (
        <>
            <Typography variant={"h6"} sx={{px: 2, pt: 2, pb: 1}}>
                {type.charAt(0).toUpperCase() + type.slice(1).toLowerCase()}
            </Typography>
            <List dense sx={{
                minWidth: 340
            }}>
                {
                    bookmarks.map((bookmark) => (
                        <BookmarkItem
                            key={bookmark.id}
                            bookmarkId={bookmark.id}
                            onClose={onClose}
                        />
                    ))
                }
            </List>
        </>
    )
}


function BookmarkItem({bookmarkId, onClose}: {
    bookmarkId: string,
    onClose: () => void
}) {

    const bookmark = useSelector((state: RootState) => selectBookmarkById(state, bookmarkId))
    if(!bookmark) throw Error("bookmark not found")
    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    const app = useSelector((state: RootState) => selectDiscunaAppById(state, bookmark.details.appId))

    const bookmarkClickCallback = useCallback(async () => {
        if (app) {
            const {communityId, channelId} = bookmark.details
            const iframe = document.getElementById(`iframe-${channelId}`) as HTMLIFrameElement | null
            if (iframe?.contentWindow) {
                discunaAppFrontendApiClient.notificationClick(
                    bookmark.id,
                    bookmark.details.data,
                    app.details.config.pages.origin,
                    iframe.contentWindow
                ).catch((err) => {console.error(err)})
            } else {
                // TODO this is a hack. Does this work at all?
                // TODO we need definitely a better way to handle this
                dispatch(setQueuedNotification({
                    id: bookmark.id,
                    details: bookmark.details,
                    // sort
                    sentAt: bookmark.createdAt,
                    // filter
                    hasRead: true,
                    priority: "high"
                }))
                navigate(`communities/${communityId}/channels/${channelId}`)
            }
            // navigate to community
            dispatch(setSelectedCommunityId(communityId))
            onClose()
        }
    }, [onClose, app, dispatch, bookmark, navigate])

    return (
        <ListItem
            disablePadding
            sx={{
                position: "relative",
                // "&:hover": {
                //     bgcolor: "lightgray"
                // }
            }}

        >
            <ListItemButton
                dense
                sx={{
                    pr: "92px !important",
                    // bgcolor: !bookmark.hasRead ? primaryLightBackground : "initial",
                }}
                onClick={() => {
                    // NOTE
                    // dispatch(setSelectedInboxMessagePath(bookmark.details.from))
                    // if (!selectedCommunityId) onClose()

                    bookmarkClickCallback()
                    // dispatch(setSelectedInboxMessagePath(bookmark.details.from))
                    // navigateToInboxMessageOrigin(bookmark.details.from)
                }}
            >

                <ListItemIcon sx={{
                    // pl: 2,
                    // pt: 1
                }}>
                    {
                        bookmark.details.icon.split(":")[0] === "ic" &&
                        <Icon height={24} width={24} icon={bookmark.details.icon}/>
                    }
                </ListItemIcon>
                <ListItemText
                    primary={bookmark.details.title}
                    secondary={bookmark.details.body}
                />

            </ListItemButton>
        </ListItem>
    )
}
