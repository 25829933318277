import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {UserSecrets} from "./userSecretsInterfaces";
import {createCommunity, deleteCommunity, leaveCommunity, updateCommunity} from "../../firebase/discunaApi";
import {arraySaveDelete, arraySavePush, insertArrayItemWithId} from "../../utils/arrayOperations";

const userSecretsSlice = createSlice({
        name: 'userSecrets',
        initialState: {
            data: null as UserSecrets | null,
            membershipsNotSynced: [] as string[]
        },
        reducers: {
            setUserSecret: (state, action: PayloadAction<UserSecrets>) => {
                state.data = action.payload
            }
        },
        extraReducers: builder => {
            builder
                .addCase(createCommunity.fulfilled,
                    (state, action) => {
                        const {name, communityId} = action.meta.arg
                        state.membershipsNotSynced = arraySaveDelete(state.membershipsNotSynced, communityId)
                    })
                .addCase(createCommunity.pending,
                    (state, action) => {
                        const {name, communityId} = action.meta.arg
                        if (state.data) {
                            state.membershipsNotSynced = arraySavePush(state.membershipsNotSynced, communityId)
                            state.data.details.memberships = {
                                ...state.data.details.memberships,
                                [communityId]: {name}
                            }
                        }
                    })
                .addCase(deleteCommunity.pending,
                    (state, action) => {
                        const args = action.meta.arg
                        if (state.data) {
                            const memberships = state.data.details.memberships
                            delete memberships[args.communityId]
                        }
                    })
                .addCase(leaveCommunity.pending,
                    (state, action) => {
                        const args = action.meta.arg
                        if (state.data) {
                            const memberships = state.data.details.memberships
                            delete memberships[args.communityId]
                        }
                    })
                .addCase(updateCommunity.pending,
                    (state, action) => {
                        const args = action.meta.arg
                        if (args.details.name && state.data) {
                            state.data.details.memberships[args.communityId].name = args.details.name
                        }
                    })
        }
    }
)

export const {
    setUserSecret
} = userSecretsSlice.actions

export const userSecretsReducer = userSecretsSlice.reducer
