import {useDispatch} from "react-redux";
import useMemoizedState from "../../../../../hooks/useMemoizedState";
import {useEffect} from "react";
import {firestoreManager} from "../../../../../firebase/queryManager";
import {getCommunityRef} from "../communityUtils";
import {removeMemberInsights, upsertMemberInsights} from "./memberInsightsSlice";
import {MemberInsights} from "./memberInsightsInterfaces";


export function getMemberInsightsRef(communityId: string) {
    return getCommunityRef(communityId).collection("members").doc("insights")
}

export function useMemberInsights(communityId: string) {
    const dispatch = useDispatch()
    const [isLoading, setIsLoading] = useMemoizedState(true)

    // fetch
    useEffect(() => {
        const memberInsightsRef = getMemberInsightsRef(communityId)
        const {isNew, cancel} = firestoreManager.queryDocumentSnapshots(memberInsightsRef, (doc) => {
            setIsLoading(false)
            if (doc.exists) {
                const rewardInsights = {
                    id: communityId,
                    ...doc.data()
                } as MemberInsights
                dispatch(upsertMemberInsights(rewardInsights))
            } else {
                dispatch(removeMemberInsights(communityId))
            }
        })
        setIsLoading(isNew)
        return cancel
    }, [communityId, dispatch, setIsLoading])
    return isLoading
}