import {createEntityAdapter, createSelector, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {InvitationLink} from "./invitationLinkInterfaces";
import {createInvitationLink, deleteInvitationLink} from "../../../../../firebase/discunaApi";
import {RootState} from "../../../../store";
import {arraySaveDelete, arraySavePush} from "../../../../../utils/arrayOperations";
import {memberRoles, createInvitationLinkFromArgs} from "shared";

const invitationLinkEntityAdapter = createEntityAdapter<InvitationLink>()

export function getInvitationLinkReduxId(communityId: string, role: memberRoles) {
    return `${communityId}_${role}`
}

const invitationLinkSlice = createSlice({
    name: "invitationLinks",
    initialState: invitationLinkEntityAdapter.getInitialState({
        notSynced: [] as string[]
    }),
    reducers: {
        addInvitationLink: invitationLinkEntityAdapter.addOne,
        upsertInvitationLink: invitationLinkEntityAdapter.upsertOne,
        updateInvitationLink: invitationLinkEntityAdapter.updateOne,
        removeInvitationLink: invitationLinkEntityAdapter.removeOne,
        removeAllInvitationLinks: invitationLinkEntityAdapter.removeAll,
        setAllInvitationLinks: invitationLinkEntityAdapter.setAll,
        setSynced: (state, action: PayloadAction<string>) => {
            state.notSynced = arraySaveDelete(state.notSynced, action.payload)
        },
        setNotSynced: (state, action: PayloadAction<string>) => {
            state.notSynced = arraySavePush(state.notSynced, action.payload)
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(deleteInvitationLink.fulfilled, (state, action) => {
                const args = action.meta.arg
                const invitationLinkReduxEntityId = state.ids.filter(id => state.entities[id]?.invitationId === args.invitationId)[0]
                const invitationLinkReduxId = state.entities[invitationLinkReduxEntityId]?.id as string
                invitationLinkEntityAdapter.removeOne(state, invitationLinkReduxId)
                state.notSynced = arraySaveDelete(state.notSynced, invitationLinkReduxId)
            })
            .addCase(deleteInvitationLink.rejected, (state, action) => {
                const args = action.meta.arg
                const invitationLinkReduxEntityId = state.ids.filter(id => state.entities[id]?.invitationId === args.invitationId)[0]
                const invitationLinkReduxId = state.entities[invitationLinkReduxEntityId]?.id as string
                state.notSynced = arraySaveDelete(state.notSynced, invitationLinkReduxId)
            })
            .addCase(deleteInvitationLink.pending, (state, action) => {
                const args = action.meta.arg
                const invitationLinkReduxId = state.ids.filter(id => state.entities[id]?.invitationId === args.invitationId)[0]
                const invitationLink = state.entities[invitationLinkReduxId]
                if (invitationLink) state.notSynced = arraySavePush(state.notSynced, invitationLink.id)
            })
    }
})

export const invitationLinkReducer = invitationLinkSlice.reducer

export const {
    addInvitationLink,
    removeInvitationLink,
    updateInvitationLink,
    upsertInvitationLink,
    removeAllInvitationLinks,
    setNotSynced: setInvitationLinkNotSynced,
    setSynced: setInvitationLinkSynced
} = invitationLinkSlice.actions

export const {
    selectAll: selectAllInvitationLinks,
    selectById: selectInvitationLinkById,
    selectIds: selectInvitationLinkIds,
} = invitationLinkEntityAdapter.getSelectors<RootState>(state => state.invitationLinks)

export const selectInvitationLinkByCommunityAndRole = () => createSelector(
    [
        (state: RootState) => selectAllInvitationLinks(state),
        (state: RootState, communityId: string, role: memberRoles) => ({
            communityId,
            role
        })
    ],
    (invitationLinks, queryParams) =>
        invitationLinks.filter(invitationLink =>
            invitationLink.communityId === queryParams.communityId &&
            invitationLink.role === queryParams.role
        )[0] as InvitationLink | undefined
)




