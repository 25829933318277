import {Channel, OldChannel} from "./channelInterfaces";
import {firestore} from "../../../../../firebase/firebase";
import { ChannelReferenceInfo } from "shared/communities/channels/channel";
import {useDispatch} from "react-redux";
import {useEffect} from "react";
import {firestoreManager} from "../../../../../firebase/queryManager";
import {removeChannel, upsertChannel} from "./channelSlice";
import useMemoizedState from "../../../../../hooks/useMemoizedState";

export function getChannelReferenceInfo(channel: Channel | OldChannel) {
    return {
        id: channel.id,
        communityId: channel.communityId,
    } as ChannelReferenceInfo
}

export function getGlobalChannelRef(globalChannelId: string) {
    return firestore
        .collection("globalChannels").doc(globalChannelId)
}

export function getFirestoreChannelRef(channelReferenceInfo: ChannelReferenceInfo) {

    return firestore
        .collection("communities").doc(channelReferenceInfo.communityId)
        .collection("channels").doc(channelReferenceInfo.id)
}

export function useChannel(
    communityId: string,
    channelId: string
) {
    const dispatch = useDispatch()
    const [isLoading, setIsLoading] = useMemoizedState(false)

    // fetch channel
    useEffect(() => {
        const channelDocRef = getFirestoreChannelRef({
            communityId,
            id: channelId
        })
        const {isNew, cancel} = firestoreManager.queryDocumentSnapshots(
            channelDocRef,
            (doc) => {
                setIsLoading(false)
                if(doc.exists) {
                    const channelData = {
                        id: doc.id,
                        communityId: communityId,
                        ...doc.data()
                    } as Channel
                    dispatch(upsertChannel(channelData))
                } else {
                    dispatch(removeChannel(channelId))
                }
            })
        setIsLoading(isNew)
        return cancel
    }, [dispatch, channelId, communityId, setIsLoading])
    return isLoading
}
