import {Center} from "../../../../../components/motion_mui";
import React from "react";
import {SxProps} from "@mui/material";


export function AppIcon(props: {
    sx?: SxProps,
    url: string
}) {
    return (
        <Center
            sx={{
                position: "relative",
                // width: 144,
                // minWidth: 144,
                minWidth: "100%",
                minHeight: "100%",

                // height: "40px",
                // minHeight: "40px",
                // width: "40px",
                // minWidth: "40px",
                boxSizing: "border-box",
                // bgcolor: "#E3F2FD",
                // borderRadius: 2,
                // p: 2
                ...props.sx
            }}
        >
            <img
                placeholder={"app icon"}
                src={props.url}
                style={{
                    maxWidth: "100%",
                    maxHeight: "100%"
                }}
                alt={"app icon"}
            />
        </Center>
    )
}
