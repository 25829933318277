import {ModalTitle, ModalWithPresence} from "../../../../../components/modal";
import {Box, Button, Typography} from "@mui/material";
import {Row} from "../../../../../components/motion_mui";
import {leaveCommunity} from "../../../../../firebase/discunaApi";
import React, {useCallback, useState} from "react";
import {useNavigate} from "react-router-dom";
import {CustomLoadingButton} from "../accessWrappers";
import {setSelectedCommunityId} from "../../../dashboardSlice";
import {useDispatch} from "react-redux";
import {useAppDispatch} from "../../../../store";
// import {refreshToken} from "../../../../auth/authUtils";


export function LeaveCommunityModal({show, communityId, communityName, onClose}: {
    communityId: string,
    communityName: string,
    onClose: () => void,
    show: boolean,
}) {
    const [pressedConfirm, setPressedConfirm] = useState(false)
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const onConfirm = useCallback(async () => {
        setPressedConfirm(true)
        dispatch(leaveCommunity({
            communityId
        }))
        // await refreshToken()
        dispatch(setSelectedCommunityId(null))
        onClose()
        navigate("../account")
    }, [dispatch, onClose, communityId, navigate])

    return (
        <ModalWithPresence onCancel={onClose}
                           show={show}>
            <ModalTitle title={`Leave "${communityName}"`} onCancel={onClose}/>
            {
                <Typography variant={"body1"}>
                    Are you sure you want to leave "{communityName}"? All your posts will be made anonymous and you
                    will loose all rewards from this community. This step cannot be undone.
                </Typography>
            }
            <Row sx={{mt: 4}} mainAxisAlignment={"end"} crossAxisAlignment={"center"}>
                <Button onClick={onClose} disabled={pressedConfirm}>
                    Cancel
                </Button>
                <Box width={12}/>
                <CustomLoadingButton
                    variant={"contained"}
                    disabled={pressedConfirm}
                    color={"primary"}
                    onClick={onConfirm}
                    isLoading={pressedConfirm}
                >
                    Confirm
                </CustomLoadingButton>
            </Row>

        </ModalWithPresence>
    )
}
