import {MainPaperCard} from "../../../components/mainPaperCard";
import {DiscunaLogoAndTextBlack} from "../../../components/discunaLogoAndTextBlack";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {Column, Row} from "components/motion_mui";
import {Alert, Button, Checkbox, CircularProgress, FormControlLabel, Link, TextField, Typography} from "@mui/material";
import {useDispatch} from "react-redux";
import {getAuth, updateProfile} from "firebase/auth";
import {completeSignUp, updateName} from "../../../firebase/discunaApi";
import {updateNameRedux} from "../authSlice";
import {refreshToken} from "../authUtils";
import {displayNameValidator, nameValidator} from "shared/auth/auth";
import {darkGrey} from "../../../constants/constants";
import {Navigate, useLocation, useNavigate} from "react-router-dom";


const termsOfService =
    <Link
        underline="hover"
        target="_blank"
        href={"https://discuna.com/terms"}
    >
        Terms of Service
    </Link>

const privacyPolicy =
    <Link
        target="_blank"
        underline="hover"
        href={"https://discuna.com/privacy"}
    >
        Privacy Policy
    </Link>


export function RegistrationForm() {

    const location = useLocation()
    const targetURL = useMemo(() => (location.state as { from?: string })?.from, [])

    const [givenName, setGivenName] = useState("")
    const [givenNameError, setGivenNameError] = useState<string | undefined>("zero length")

    const [familyName, setFamilyName] = useState("")
    const [familyNameError, setFamilyNameError] = useState<string | undefined>("zero length")

    // if useRealNameAsDisplayName is false => displayName = firstName + " " + lastName
    const [useRealNameAsDisplayName, setUseRealNameAsDisplayName] = useState(true)
    const [displayName, setDisplayName] = useState("")
    const [displayNameError, setDisplayNameError] = useState<string | undefined>("zero length")
    useEffect(() => {
        if (useRealNameAsDisplayName) {
            const displayName = `${givenName} ${familyName}`
            const value = displayNameValidator(displayName)
            if (!value.ok) setDisplayNameError(value.errors[0].message)
            else if (displayNameError) setDisplayNameError(undefined)
            setDisplayName(`${givenName} ${familyName}`)
        }
    }, [displayNameError, givenName, familyName, useRealNameAsDisplayName])

    const dispatch = useDispatch()

    const [progress, setProgress] = useState<null | "loading" | string>(null)

    const navigate = useNavigate()

    const onSubmit = useCallback(async () => {
        setProgress("loading")

        const auth = getAuth()
        if (!auth.currentUser) throw Error("not authenticated")

        try {

            // complete profile
            await completeSignUp({
                givenName,
                familyName,
                displayName
            })

            updateName({
                firstName: givenName,
                lastName: familyName
            }).catch((e) => console.error("could not update name", e))
            dispatch(updateNameRedux(`${givenName} ${familyName}`))

            await updateProfile(auth.currentUser, {
                displayName: `${givenName} ${familyName}`
            })
            // TODO why does token refresh not load the new user name??
            // TODO what happens if the user closes this window before this operation finishes?
            await refreshToken()

            // navigate to target URL
            let targetRelativePath = "/"
            if (targetURL) {
                try {
                    const targetURLObj = new URL(targetURL)
                    targetRelativePath = targetURLObj.pathname + targetURLObj.search
                } catch (e) {
                    console.error("target URL is invalid")
                }
            }
            navigate(targetRelativePath)
        } catch (e) {
            setProgress("unknown")
        }
    }, [dispatch, displayName, familyName, givenName, navigate, targetURL])

    const [consentGiven, setConsentGiven] = useState(false)

    const enableButton = consentGiven && !givenNameError && !familyNameError && !displayNameError

    const isSignedIn = useMemo(() => {
        const auth = getAuth()
        return !!auth.currentUser
    }, [])

    if (!isSignedIn) {
        return <Navigate to={"/"} replace={true}/>
    }

    return (
        <MainPaperCard>
            <Column
                mainAxisAlignment={"start"}
                crossAxisAlignment={"stretch"}
                sx={{
                    p: 3,
                    width: "100%",
                }}
            >
                <DiscunaLogoAndTextBlack/>
                {
                    progress !== null && progress !== "loading" &&
                    <Alert sx={{mt: 3}} severity="error">
                        Error: {progress ?? "unknown"}. Please try again or contact us at <Link href={"mailto:support@discuna.com"}>support@discuna.com</Link>.
                    </Alert>
                }
                {
                    progress === "loading" &&
                    <CircularProgress size={20} sx={{mt: 3, mx: "auto"}}/>
                }
                {
                    progress === null &&
                    <>
                        <Typography variant={"body1"} sx={{mt: 3, textAlign: "center"}}>
                            Welcome to Discuna! Please fill out the form below to complete the registration.
                        </Typography>

                        <TextField
                            sx={{mt: 3}}
                            error={givenName.length > 0 && givenNameError !== undefined}
                            helperText={givenName.length > 0 && givenNameError ? givenNameError : undefined}
                            variant={"filled"}
                            label={"First name"}
                            value={givenName}
                            onChange={(e) => {
                                const value = nameValidator(e.target.value)
                                if (!value.ok) setGivenNameError(value.errors[0].message)
                                else if (givenNameError) setGivenNameError(undefined)
                                setGivenName(e.target.value)
                            }}
                        />
                        <TextField
                            error={familyName.length > 0 && familyNameError !== undefined}
                            helperText={familyName.length > 0 && familyNameError ? familyNameError : undefined}
                            sx={{mt: 1}}
                            variant={"filled"}
                            label={"Last name"}
                            value={familyName}
                            onChange={(e) => {
                                const value = nameValidator(e.target.value)
                                if (!value.ok) setFamilyNameError(value.errors[0].message)
                                else if (familyNameError) setFamilyNameError(undefined)
                                setFamilyName(e.target.value)
                            }}
                        />

                        <FormControlLabel
                            sx={{mt: 3}}
                            control={
                                <Checkbox
                                    checked={useRealNameAsDisplayName}
                                    onChange={(e) => {
                                        setUseRealNameAsDisplayName(e.target.checked)
                                    }}
                                />
                            }
                            label={
                                <Typography sx={{ml: 1, color: darkGrey}} variant={"body2"}>
                                    Use real name as display name. You can hide your display name in posts.
                                </Typography>
                            }
                        />
                        <TextField
                            disabled={useRealNameAsDisplayName}
                            error={!useRealNameAsDisplayName && displayName.length > 0 && displayNameError !== undefined}
                            helperText={!useRealNameAsDisplayName && displayName.length > 0 && displayNameError ? displayNameError : "Name visible to the public"}
                            sx={{mt: 1}}
                            variant={"filled"}
                            label={"Display name"}
                            value={displayName}
                            onChange={(e) => {
                                if (!useRealNameAsDisplayName) {
                                    const value = displayNameValidator(e.target.value)
                                    if (!value.ok) setDisplayNameError(value.errors[0].message)
                                    else if (displayNameError) setDisplayNameError(undefined)
                                    setDisplayName(e.target.value)
                                }
                            }}
                        />


                        <Row
                            mainAxisAlignment={"start"}
                            crossAxisAlignment={"center"}
                            sx={{mt: 3}}
                        >
                            <Checkbox
                                edge={"start"}
                                //sx={{mt: "-8px"}}
                                sx={{mt: "-2px"}}
                                value={consentGiven}
                                onChange={(e) => setConsentGiven(e.target.checked)}
                            />
                            <Typography sx={{ml: 1, color: darkGrey}} variant={"body2"}>
                                I have read and agree to Discuna's {termsOfService} and {privacyPolicy}
                            </Typography>
                        </Row>

                        <Row sx={{mt: 6}} mainAxisAlignment={"spaceBetween"} crossAxisAlignment={"center"}>
                            <Button onClick={() => {
                                const auth = getAuth()
                                auth.signOut()
                            }}>
                                Cancel
                            </Button>
                            <Button
                                sx={{ml: 2}}
                                disabled={!enableButton}
                                variant={"contained"}
                                onClick={onSubmit}
                            >
                                Continue
                            </Button>
                        </Row>
                    </>
                }


            </Column>
        </MainPaperCard>
    )
}
