import {Button, TextField, Typography} from "@mui/material";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import debounce from "lodash.debounce";
import {emailValidator} from "shared/auth/auth";
import {getSignInMethod} from "../authUtils";
import {Navigate, useLocation, useNavigate} from "react-router-dom";
import {getAuth} from "firebase/auth";

export function AuthIdentity() {
    const [email, setEmail] = useState(window.localStorage.getItem("auth/email") ?? "")
    const [emailError, setEmailError] = useState<string | undefined>(undefined)
    const [isLoading, setIsLoading] = useState(false)

    const location = useLocation()
    const targetURL = useMemo(() => (location.state as {from?: string})?.from, [])
    useEffect(() => {
        if(targetURL) window.localStorage.setItem("auth/targetURL", targetURL)
    }, [targetURL])

    const navigate = useNavigate()

    const emailCallback = useMemo(() => debounce((email: string) => {
        const value = emailValidator(email)
        if (!value.ok) setEmailError(value.errors[0].message)
        else setEmailError(undefined)
    }, 300), [])

    const onContinue = useCallback(async (email: string) => {
        const value = emailValidator(email)
        if (!value.ok) return
        window.localStorage.setItem("auth/email", email)
        setIsLoading(true)
        try {
            const signInMethod = await getSignInMethod(email)
            navigate(`/auth/credentials?via=${signInMethod ?? "email"}`)
        } catch (e) {
            console.error("could not fetch sign in method", e)
            setIsLoading(false)
        }
    }, [])

    const isSignedIn = useMemo(() => {
        const auth = getAuth()
        return !!auth.currentUser
    }, [])

    if (isSignedIn) {
        return <Navigate to={"/"} replace={true}/>
    }

    const enableButton = !isLoading && !emailError && email.length > 0

    // ask for email
    return (
        <>
            <Typography variant={"body1"} sx={{mt: 3, textAlign: "center"}}>
                Log in or create a new account
            </Typography>
            <TextField
                disabled={isLoading}
                sx={{mt: 1}}
                // error={email.length > 0 && emailError !== undefined}
                // helperText={email.length > 0 && emailError ? emailError : ""}
                variant={"filled"}
                label={"Email"}
                value={email}
                onChange={(e) => {
                    emailCallback(e.target.value)
                    setEmail(e.target.value)
                }}
                onKeyDown={(e) => {
                    if (e.key === "Enter") {
                        onContinue(email)
                    }
                }}
            />
            <Button
                sx={{
                    mt: 6,
                    alignSelf: "end"
                }}
                variant={"contained"}
                disabled={!enableButton}
                onClick={() => {
                    onContinue(email)
                }}
            >
                Continue
            </Button>
        </>
    )
}
