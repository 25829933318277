import {AddressElement, Elements} from "@stripe/react-stripe-js";
import React, {useCallback} from "react";
import {ModalTitle, ModalWithPresence} from "../../../../components/modal";
import {Center, Column, Row} from "../../../../components/motion_mui";
import {Alert, Button, CircularProgress} from "@mui/material";
import {stripe} from "../../../stripe";
import {setBillingAddress} from "../../../../firebase/discunaApi";
import {Address} from "shared";
import {circularProgressSize} from "../../../../constants/constants";
import {sleep} from "../../../../utils/sleep";
import {AddressMode, StripeAddressElementOptions} from "@stripe/stripe-js";


export function AddressFormModal(props: {
    open: boolean,
    onClose: () => void,
    isUpdate: boolean,
}) {

    const options = {
        // Fully customizable with appearance API.
        appearance: {/*...*/},
    };

    return (
        <ModalWithPresence onCancel={props.onClose}
                           show={props.open}>
            <Elements stripe={stripe} options={options}>
                {
                    props.open && <AddressForm isUpdate={props.isUpdate} onClose={props.onClose}/>
                }
            </Elements>
        </ModalWithPresence>
    )
}


function AddressForm({isUpdate, onClose}: {
    isUpdate: boolean,
    onClose: () => void,
}) {

    const addressIsCompleteRef = React.useRef(false)

    const [addressIsComplete, setAddressIsComplete] = React.useState(false);

    const [name, setName] = React.useState("");
    const [address, setAddress] = React.useState<Address>({
        city: '',
        line1: '',
        line2: '',
        postal_code: '',
        state: '',
        country: ""
    })

    const [status, setStatus] = React.useState<null | "processing" | "success" | "error">(null);

    const onSubmit = useCallback((address: Address, name: string) => {
        setStatus("processing")
        setBillingAddress({
            name,
            address
        }).then(() => {
            setStatus("success")
        }).catch((e) => {
            console.error("failed to set address", e)
            setStatus(null)
        })
    }, [])

    if (status === "processing") {
        return (
            <>
                <ModalTitle title={`Billing Address`} onCancel={onClose}/>
                <Center>
                    <CircularProgress size={circularProgressSize}/>
                </Center>
            </>
        )
    }

    if (status === "success") {
        return (
            <>
                <ModalTitle title={`Billing Address`} onCancel={onClose}/>
                <Alert severity={"success"}>
                    Address saved successfully
                </Alert>
            </>
        )
    }

    const addressElementOptions = {
        mode: "billing",
        autocomplete: {
            mode: "google_maps_api",
            apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string,
        },
    } as StripeAddressElementOptions

    return (
        <>

            <ModalTitle title={`Billing Address`} onCancel={onClose}/>

            <form>
                <AddressElement options={addressElementOptions} onChange={(event) => {
                    setName(event.value.name ?? "")
                    if (event.complete) {
                        // Extract potentially complete address
                        const address = event.value.address;
                        console.log("address", address, event.value.name)
                        if (!addressIsCompleteRef.current) {
                            setAddressIsComplete(true)
                            setAddress(event.value.address as Address)
                        }

                    } else {
                        if (addressIsCompleteRef.current)
                            setAddressIsComplete(false)
                    }
                }}/>
            </form>

            <Row mainAxisAlignment={"spaceBetween"} crossAxisAlignment={"start"} sx={{
                mt: 2
            }}>
                <Button onClick={onClose}>
                    Cancel
                </Button>
                <Button variant={"contained"} disabled={!addressIsComplete || name.length === 0}
                        onClick={() => onSubmit(address, name)}>
                    {isUpdate ? "Update" : "Add"}
                </Button>
            </Row>

        </>

    )
}
