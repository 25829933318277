import {Column, Row} from "../../../components/motion_mui";
import {PasswordTextField} from "../../../components/PasswordTextField";
import {Alert, Button, Typography} from "@mui/material";
import React, {useCallback, useState} from "react";
import {getAuth, sendPasswordResetEmail, signInWithEmailAndPassword} from "firebase/auth";
import {Navigate} from "react-router-dom";

export function AuthCredentialsViaPassword({email, onGoBack, onAuthSuccess}: {
    email: string | null,
    onGoBack: () => void,
    onAuthSuccess: () => void,
}) {

    const [password, setPassword] = useState("")
    const [progress, setProgress] = useState<null | "processing" | "resetPassword" | "wrongPassword">(null)

    const signInViaPassword = useCallback(async (password: string) => {
        if(!email) throw Error("email must be defined")
        setProgress("processing")
        try {
            window.localStorage.removeItem('emailForSignIn')
            const auth = getAuth()
            await signInWithEmailAndPassword(auth, email, password)
            onAuthSuccess()
        } catch (e: any) {
            console.error("sign in failed", e)
            setProgress("wrongPassword")
        }
    }, [email, onAuthSuccess])

    const resetPassword = useCallback(async () => {
        if(!email) throw Error("email must be defined")
        setProgress("processing")
        const auth = getAuth()
        await sendPasswordResetEmail(auth, email)
        setProgress("resetPassword")
    }, [email])

    if(!email) {
        return <Navigate to={"/auth/identity"} replace={true}/>
    }

    return (
        <>
            <Typography variant={"body1"} sx={{mt: 3, textAlign: "center"}}>
                Password sign-in
            </Typography>
            <Column
                mainAxisAlignment={"start"}
                crossAxisAlignment={"stretch"}
                sx={{
                    mt: 1
                }}
            >
                <PasswordTextField
                    disabled={progress === "processing"}
                    password={password}
                    setPassword={(value) => {
                        if(progress === "wrongPassword" || progress === "resetPassword") setProgress(null)
                        setPassword(value)
                    }}
                    label={"Password"}
                    id={"password"}
                    onEnter={() => signInViaPassword(password)}
                    errorMessage={progress === "wrongPassword" ? "wrong password" : undefined}
                />
                {
                    progress !== "resetPassword" ?
                        <Typography variant={"body2"} sx={{color: "primary.light", cursor: "pointer", mt: 1}} onClick={resetPassword}>
                            Forgot password?
                        </Typography> :
                        <Alert sx={{mt: 1}} severity={"success"} onClose={() => setProgress(null)}>
                            We sent you an email containing the password-reset link.
                        </Alert>
                }
                <Row sx={{mt: 6}} mainAxisAlignment={"spaceBetween"} crossAxisAlignment={"center"}>
                    <Button onClick={onGoBack}>
                        Go back
                    </Button>
                    <Button disabled={password.length === 0 || progress !== null} variant={"contained"} onClick={() => signInViaPassword(password)}>
                        Continue
                    </Button>
                </Row>
            </Column>
        </>
    )
}
