import {useDispatch} from "react-redux";
import useMemoizedState from "../../../../../hooks/useMemoizedState";
import {useEffect} from "react";
import {firestore} from "../../../../../firebase/firebase";
import {firestoreManager} from "../../../../../firebase/queryManager";
import {AppStoreItemFirestoreData} from "shared";
import {DiscunaAppStoreItem} from "./discunaAppInterface";
import {removeDiscunaAppStoreItem, upsertDiscunaAppStoreItem} from "./discunaAppStoreItemsSlice";


export async function fetchAppStoreItem(appId: string) {
    return await firestore.collection("appStore").doc(appId).get()
}


export function useAppStoreItems() {
    const dispatch = useDispatch()
    const [isLoading, setIsLoading] = useMemoizedState(true)
    useEffect(() => {
        setIsLoading(true)
        const appStoreColRef = firestore
            .collection("appStore")
        const {isNew, cancel} = firestoreManager.queryCollectionSnapshots(appStoreColRef,
            (snap) => {
                setIsLoading(false)
                snap.docChanges().forEach((change) => {
                    const appId = change.doc.id
                    if (change.type === "removed") {
                        dispatch(removeDiscunaAppStoreItem(appId))
                    } else {
                        const discunaAppStoreItem = {
                            id: appId,
                            isSynced: true,
                            ...(change.doc.data() as AppStoreItemFirestoreData)
                        } as DiscunaAppStoreItem
                        dispatch(upsertDiscunaAppStoreItem(discunaAppStoreItem))
                    }
                })
            })
        setIsLoading(isNew)
        return cancel
    }, [dispatch, setIsLoading])

    return isLoading
}
