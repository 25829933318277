import {LibraryItem} from "shared";


export type LibraryItemWithLevel = {
    level: number,
} & LibraryItem

export function getOrderedCommunityLibraryList(
    parentItem: LibraryItemWithLevel | null,
    libraryItems: LibraryItem[],
    appIds: string[]
) {
    const parentId = parentItem?.id ?? null
    const directChildren = libraryItems.filter((item) => item.parentId === parentId)
    const orderedList: LibraryItemWithLevel[] = []
    if (parentItem !== null) orderedList.push(parentItem)
    const channelTypesOrdered = [...appIds, "folder"]
    for (const channelType of channelTypesOrdered) {
        const directChildrenWithType = directChildren
            .filter((child) => child.appId === channelType)
            .sort((a, b) => a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1)
        for (const child of directChildrenWithType) {
            orderedList.push(...getOrderedCommunityLibraryList({
                ...child,
                level: parentItem ? parentItem.level + 1 : 0
            }, libraryItems, appIds))
        }
    }
    return orderedList
}
