import {Route, Routes} from "react-router-dom";
import {Login} from "./login";
import {Consent, SkipConsent} from "./consent";

export function OAuthFlow() {
    return (
        <Routes>
            <Route path={"login"} element={<Login/>}/>
            <Route path={"consent"} element={<SkipConsent/>}/>
        </Routes>
    )
}
