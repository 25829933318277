import {useDispatch, useSelector} from "react-redux";
import {
    Box,
    CircularProgress,
    IconButton,
    List,
    ListItem, ListItemButton, ListItemIcon,
    ListItemSecondaryAction,
    ListItemText, Menu, MenuItem, Tooltip
} from "@mui/material";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import React, {useMemo, useState} from "react";
import {AddCommunityModal} from "./contextMenu/addCommunity";
import WorkspacesOutlinedIcon from '@mui/icons-material/WorkspacesOutlined';
import {RootState} from "../../../../store";
import {Center, Column} from "../../../../../components/motion_mui";
import {JoinCommunityModal} from "./contextMenu/joinCommunity";
import {RestrictToRegistered} from "../../communities/accessWrappers";
import {setSelectedCommunityId} from "../../../dashboardSlice";
import {useParams} from "react-router-dom";
import {useBadgeCountInboxCommunityList} from "./library/useBadgeCountInbox";
import {useBadgeCountBookmarkCommunityList} from "./library/useBadgeCountBookmarks";
import {useDiscunaBadgeList} from "./badgeList";
import {circularProgressSize} from "../../../../../constants/constants";


export function CommunityList() {
    const userSecrets = useSelector((state: RootState) => state.userSecrets)
    const userSecretsData = userSecrets.data
    const membershipsNotSynced = useMemo(() => new Set(userSecrets.membershipsNotSynced),
        [userSecrets.membershipsNotSynced])

    // context menus
    const [contextMenuAnchorEl, setContextMenuAnchorEl] = useState<null | EventTarget & HTMLElement>(null);
    const [showCreateForm, setShowCreateForm] = useState(false)
    const [showJoinForm, setShowJoinForm] = useState(false)

    const {communityId} = useParams<{ communityId: string }>()

    const memberships = useMemo(() => {
        if (userSecretsData) {
            let memberships = [] as { id: string, name: string }[]
            for (const communityId of Object.keys(userSecretsData.details.memberships)) {
                memberships.push({
                    id: communityId,
                    name: userSecretsData.details.memberships[communityId].name
                })
            }
            memberships.sort((a, b) => a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1)
            return memberships
        }
        return []
    }, [userSecretsData])


    // count unread messages and bookmarks
    const communityIds = useMemo(() => memberships.map(m => m.id), [memberships])
    const unreadMessageCount = useBadgeCountInboxCommunityList(communityIds)
    const bookmarkCount = useBadgeCountBookmarkCommunityList(communityIds)


    return (
        <Column
            mainAxisAlignment={"start"}
            crossAxisAlignment={"stretch"}
            sx={{overflow: "hidden", flexGrow: 1, height: "100%"}}
        >
            {/*TODO make a Space Title Component that can also be used in the Space component*/}
            <List component="nav" disablePadding>
                <ListItem
                    key={"community menu"}
                    selected={false}
                >
                    <ListItemIcon>
                        <WorkspacesOutlinedIcon/>
                    </ListItemIcon>
                    <ListItemText
                        primary={"Communities"}
                    />
                    <ListItemSecondaryAction>
                        <Tooltip title={"Join or create a community"}>
                            <IconButton size={"small"}
                                        edge={"end"}
                                        onClick={(event) => {
                                            setContextMenuAnchorEl(event.currentTarget)
                                        }}>
                                <AddOutlinedIcon/>
                            </IconButton>
                        </Tooltip>
                    </ListItemSecondaryAction>
                </ListItem>
            </List>

            <div style={{
                position: "relative",
                height: "100%",
                overflow: "auto"
            }}>

                <Box sx={{
                    width: "fit-content", // lib items are all same length
                    minWidth: "100%",
                    height: "100%"
                }}>
                    {
                        !userSecretsData &&
                        <Center>
                            <CircularProgress size={circularProgressSize}/>
                        </Center>
                    }
                    <List
                        component={"nav"}
                        dense={true}
                        disablePadding={true}
                    >
                        {
                            memberships.map((community) => {
                                return (
                                    <CommunityListItem
                                        key={community.id}
                                        isSynced={!membershipsNotSynced.has(community.id)}
                                        numUnreadChannelMessages={unreadMessageCount.normal.get(community.id)}
                                        numUnreadPersonalMessages={unreadMessageCount.high.get(community.id)}
                                        bookmarksCount={bookmarkCount[community.id] ?? {}}
                                        communityId={community.id}
                                        selectedCommunityId={communityId}
                                        communityName={community.name}
                                    />
                                )
                            })
                        }
                    </List>
                </Box>
            </div>

            <Menu
                id="qanda-community-list-context-menu"
                anchorEl={contextMenuAnchorEl}
                open={Boolean(contextMenuAnchorEl)}
                onClose={() => setContextMenuAnchorEl(null)}>
                <MenuItem onClick={() => {
                    setContextMenuAnchorEl(null)
                    setShowJoinForm(true)
                }}>
                    Join
                </MenuItem>
                <RestrictToRegistered>
                    <MenuItem onClick={() => {
                        setContextMenuAnchorEl(null)
                        setShowCreateForm(true)
                    }}>
                        Create
                    </MenuItem>
                </RestrictToRegistered>
            </Menu>

            <JoinCommunityModal show={showJoinForm} onClose={() => setShowJoinForm(false)}/>
            <AddCommunityModal show={showCreateForm} onClose={() => setShowCreateForm(false)}/>
        </Column>
    )
}


function CommunityListItem(
    {
        isSynced,
        numUnreadChannelMessages,
        numUnreadPersonalMessages,
        bookmarksCount,
        communityId,
        communityName,
        selectedCommunityId
    }: {
        isSynced: boolean,
        numUnreadChannelMessages?: number,
        numUnreadPersonalMessages?: number,
        communityId: string,
        communityName: string,
        selectedCommunityId?: string,
        bookmarksCount: Record<string, number>
    }
) {
    const dispatch = useDispatch()
    const [badgeList, badgeListPlaceHolder] = useDiscunaBadgeList(
        communityId,
        numUnreadChannelMessages??0,
        numUnreadPersonalMessages??0,
        bookmarksCount
    )

    return (
        <Box sx={{
            position: "relative"
        }}>
            {badgeList}
            <ListItemButton
                selected={selectedCommunityId === communityId}
                disabled={!isSynced}
                onClick={() => {
                    dispatch(setSelectedCommunityId(communityId))
                }}>
                {
                    !isSynced &&
                    <Center
                        // List item icon
                        style={{
                            width: 24,
                            height: 24,
                            marginRight: "6px"
                        }}
                    >
                        <CircularProgress size={16}/>
                    </Center>
                }
                {badgeListPlaceHolder}
                <ListItemText
                    sx={{
                        whiteSpace: "nowrap",
                    }}
                    primary={communityName}
                />
            </ListItemButton>
        </Box>
    )
}

// const niceColors = [
//     "#F44336",
//     "#E91E63",
//     "#9C27B0",
//     "#673AB7",
//     "#3F51B5",
//     "#2196F3",
//     "#03A9F4",
//     "#00BCD4",
//     "#009688",
//     "#4CAF50",
//     "#8BC34A",
//     "#CDDC39",
//     "#FFEB3B",
//     "#FFC107",
//     "#FF9800",
//     "#FF5722",
//     "#795548",
// ]
