import {createEntityAdapter, createSlice} from '@reduxjs/toolkit'
import {Channel} from "./channelInterfaces";
import {RootState} from "../../../../store";
import {removeArrayItemWithId, updateArrayItemWithId} from "../../../../../utils/arrayOperations";
import {
    createIssue,
    deletePost,
    getTargetIdAndCollectionFromRelPath,
    getUser,
    setAcceptedAnswer, updateChannel, updateCommunityLibrary,
    updatePost
} from "../../../../../firebase/discunaApi";
import {
    createIssueFromArgs,
    createIssueTeaserData,
    PdfIssueTeaser,
    WebpageIssueTeaser,
} from "shared";
import {ChannelFirestoreData} from "shared/communities/channels/channel";

const channelEntityAdapter = createEntityAdapter<Channel>()

const channelsSlice = createSlice({
    name: "channels",
    initialState: channelEntityAdapter.getInitialState({}),
    reducers: {
        addChannel: channelEntityAdapter.addOne,
        upsertChannel: channelEntityAdapter.upsertOne,
        updateOneChannel: channelEntityAdapter.updateOne,
        removeChannel: channelEntityAdapter.removeOne,
        removeAllChannels: channelEntityAdapter.removeAll,
        setAllChannels: channelEntityAdapter.setAll
    },
    extraReducers: builder => {
        builder
            // TODO shift code to issue insights slice
            // .addCase(createIssue.pending, (state, action) => {
            //     const args = action.meta.arg
            //     const channel = state.entities[args.channel.id]
            //     if (channel && (channel.details.type === "pdf" || channel.details.type === "webpage")) {
            //         const issue = createIssueFromArgs(getUser(), args)
            //         const issueTeaser = createIssueTeaserData(args.issueId, issue)
            //         // TODO improve typing such that we do need those if statements
            //         if (channel.details.type === "pdf")
            //             channel.details.issueTeasers.push(issueTeaser as PdfIssueTeaser)
            //         if (channel.details.type === "webpage")
            //             channel.details.issueTeasers.push(issueTeaser as WebpageIssueTeaser)
            //     }
            // })
            // .addCase(updatePost.pending, (state, action) => {
            //     const args = action.meta.arg
            //     const channel = state.entities[args.channel.id]
            //     if (channel) {
            //         if (args.postUpdate.collection === "issues") {
            //             const {id} = getTargetIdAndCollectionFromRelPath(args.relPath)
            //             if (channel.details.type === "webpage") {
            //                 channel.details.issueTeasers = updateArrayItemWithId(
            //                     channel.details.issueTeasers,
            //                     id,
            //                     {title: args.postUpdate.update.title}
            //                 )
            //             }
            //             if (channel.details.type === "pdf") {
            //                 channel.details.issueTeasers = updateArrayItemWithId(
            //                     channel.details.issueTeasers,
            //                     id,
            //                     {title: args.postUpdate.update.title}
            //                 )
            //             }
            //         }
            //     }
            // })
            // .addCase(deletePost.pending, (state, action) => {
            //     const args = action.meta.arg
            //     const channel = state.entities[args.channel.id]
            //     if (channel) {
            //         const {id, collection} = getTargetIdAndCollectionFromRelPath(args.relPath)
            //         if(collection === "issues") {
            //             if (channel.details.type === "webpage") {
            //                 channel.details.issueTeasers = removeArrayItemWithId(channel.details.issueTeasers, id)
            //             }
            //             if (channel.details.type === "pdf") {
            //                 channel.details.issueTeasers = removeArrayItemWithId(channel.details.issueTeasers, id)
            //             }
            //         }
            //     }
            // })
            // .addCase(setAcceptedAnswer.pending, (state, action) => {
            //     const args = action.meta.arg
            //     const channel = state.entities[args.channel.id]
            //     if (channel) {
            //         const hasAcceptedAnswer = args.answerId !== null
            //         if (channel.details.type === "webpage") {
            //             channel.details.issueTeasers = updateArrayItemWithId(
            //                 channel.details.issueTeasers, args.issueId, {hasAcceptedAnswer})
            //         }
            //         if (channel.details.type === "pdf") {
            //             channel.details.issueTeasers = updateArrayItemWithId(
            //                 channel.details.issueTeasers, args.issueId, {hasAcceptedAnswer})
            //         }
            //     }
            // })
            .addCase(updateCommunityLibrary.pending, (state, action) => {
                const args = action.meta.arg
                const libraryAction = args.actions[0]
                switch (libraryAction.actionType) {
                    case "createFolder":
                        const channelData = {
                            parentId: libraryAction.parentId,
                            details: {
                                name: libraryAction.name,
                                appId: "folder"
                            }
                        } as ChannelFirestoreData
                        channelEntityAdapter.upsertOne(state, {
                            id: libraryAction.channelId,
                            communityId: args.communityId,
                            ...channelData
                        } as Channel)
                        break
                    case "delete":
                        channelEntityAdapter.removeOne(state, libraryAction.channelId)
                        break
                    case "move":
                        const channel = state.entities[libraryAction.channelId]
                        if(channel) channel.parentId = libraryAction.newParentId
                        break
                }
            })
            .addCase(updateChannel.pending, (state, action) => {
                const args = action.meta.arg
                const channel = state.entities[args.channelId]
                if(channel) {
                    if(args.name) channel.details.name = args.name
                }
            })
    }
})

export const {
    addChannel,
    upsertChannel,
    updateOneChannel,
    removeChannel,
    removeAllChannels,
    setAllChannels
} = channelsSlice.actions

export const channelReducer = channelsSlice.reducer

export const {
    selectAll: selectAllChannels,
    selectById: selectChannelById,
    selectIds: selectChannelIds,
} = channelEntityAdapter.getSelectors<RootState>(state => state.channels)
