import {ModalTitle, ModalWithPresence} from "../../../../components/modal";
import {SetName} from "../../../auth/setName";
import React, {useCallback, useEffect, useState} from "react";
import {
    Alert,
    CircularProgress,
    FormControl,
    FormControlLabel,
    FormLabel,
    Paper,
    Radio,
    RadioGroup,
    Typography
} from "@mui/material";
import {Column, Row} from "../../../../components/motion_mui";
import { getAuth, fetchSignInMethodsForEmail, EmailAuthProvider} from "firebase/auth";
import {useSelector} from "react-redux";
import {RootState} from "../../../store";
import {SetSignInMethod} from "../../../auth/setSignInMethod";

export function UpdateAccountModal({show, onClose}: {
    show: boolean,
    onClose: () => void,
}) {

    return (
        <ModalWithPresence show={show} onCancel={onClose}>
            <ModalTitle title={"Edit account details"} onCancel={onClose}/>
            <Typography variant={"h5"}>
                User name
            </Typography>

            <Paper sx={{mt: 2}} variant={"outlined"}>
                <Column sx={{p: 2}} mainAxisAlignment={"start"} crossAxisAlignment={"stretch"}>
                    <SetName type={"updateName"} onSkip={() => {}} onDone={() => {}}/>
                </Column>
            </Paper>
            <Typography sx={{mt: 4}}  variant={"h5"}>
                Sign-in method
            </Typography>
            <Paper sx={{mt: 2, p: 2}}  variant={"outlined"}>
                <SetSignInMethod/>
            </Paper>

        </ModalWithPresence>
    )
}


