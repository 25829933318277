import {
    FirestoreCollectionRef,
    FirestoreDocumentRef,
    FirestoreQuery,
    FirestoreQuerySnapshot, FirestoreDocumentSnapshot, FunctionsHttpsCallable
} from "./firebase";

interface QueryData {
    cancel: () => void,
}

export type groupIdTypes = "docChannel_issue" | "docChannel_comments"

/**
 * The FirestoreManager allows us to maintain query listeners during multiple lifespans of a component.
 * This is especially helpful when navigating to a child page while still maintaining listeners in the parent page.
 * (E.g. navigating from the issue page to the issue comment page). The Firestore manager allows us to perform queries
 * within the components without lifting the queries to a common parent component. This is extremely helpful when the
 * parent page has many nested components that again spawn new listeners themselves (and should be maintained as well).
 *
 * However, we still need a common parent element to remove listeners after the parent page changes (e.g. the issue ID
 * changes.) This is where groups come into play. The group ID allows us to cancel all queries added to that group.
 * Note that removing listeners in the common parent element is much easier than moving all queries that are spawned
 * in nested components to the common parent element.
 *
 * NOTE Firebase does not cache queries by default:
 * https://stackoverflow.com/questions/56691406/does-firestore-cache-query-results
 *
 * NOTE One advantage of lifting the queries up into a common parent element is that all queries can be performed
 * in parallel rather than sequentially. However, this is an optimization which we can still do at a later stage.
 */
class FirestoreManager {
    queries: Map<string, QueryData> = new Map<string, QueryData>();
    groups: Map<groupIdTypes, Set<string>> = new Map<groupIdTypes, Set<string>>() // groupId containing queryIds
    liveTimestamps: Map<string, number> = new Map<string, number>()
    numReads: number = 0
    numCloudFunctionCalls: number = 0

    // --------------------------
    // group operations
    // --------------------------

    addToGroup(groupId: groupIdTypes, queryId: string) {
        console.log("add to group", groupId, queryId)
        const group = this.groups.get(groupId)
        if (group) group.add(queryId)
        else this.groups.set(groupId, new Set([queryId]))
    }

    deleteFromGroup(groupId: groupIdTypes, queryId: string) {
        this.groups.get(groupId)?.delete(queryId)
    }

    deleteGroup(groupId: groupIdTypes) {
        const group = this.groups.get(groupId)
        if (group) {
            group.forEach((queryId) => {
                const queryData = this.queries.get(queryId)
                if (queryData) {
                    queryData.cancel()
                }
                this.queries.delete(queryId)
            })
            this.groups.delete(groupId)
        }
    }

    // --------------------------
    // query operations
    // --------------------------


    async query(query: FirestoreQuery) {
        const res = await query.get()
        this.numReads += res.empty ? 1 : res.docs.length
        return res.docs
    }

    querySnapshots(
        queryId: string,
        query: FirestoreQuery,
        onQuerySnapshot: (snapshot: FirestoreQuerySnapshot) => void,
        groupId?: groupIdTypes,
    ) {
        const onQuerySnapshotWrapper = (snapshot: FirestoreQuerySnapshot) => {
            this.numReads += snapshot.empty ? 1 : snapshot.docChanges().length
            onQuerySnapshot(snapshot)
        }

        const queryData = this.queries.get(queryId)
        if (queryData === undefined) {
            const cancelSnapshots = query.onSnapshot(onQuerySnapshotWrapper)
            const cancel = () => {
                cancelSnapshots()
                if (groupId) this.deleteFromGroup(groupId, queryId)
                this.queries.delete(queryId)
            }
            this.queries.set(queryId, {cancel})
            if (groupId) this.addToGroup(groupId, queryId)
            return {
                isNew: true,
                cancel
            }
        } else {
            return {
                isNew: false,
                cancel: queryData.cancel
            }
        }
    }

    async queryCollection(colRef: FirestoreCollectionRef) {
        return this.query(colRef)
    }

    queryCollectionSnapshots(
        colRef: FirestoreCollectionRef,
        onQuerySnapshot: (snapshot: FirestoreQuerySnapshot) => void,
        groupId?: groupIdTypes,
    ) {
        const colParentDoc = colRef.parent
        // TODO there might be cases where queryId is the same for two different subCollections
        //  should mostly not be a problem since we cancel queries before this case happens
        const queryId = (colParentDoc ? `${colParentDoc.parent.id}_${colParentDoc.id}_` : "") + colRef.id
        return this.querySnapshots(queryId, colRef, onQuerySnapshot, groupId)
    }

    liveQueryCollection(
        colRef: FirestoreCollectionRef,
        onQuerySnapshot: (snapshot: FirestoreQuerySnapshot) => void,
        groupId?: groupIdTypes,
    ) {
        const liveTimestampField = "createdOn" as const

        const colParentDoc = colRef.parent
        let queryId = (colParentDoc ? `${colParentDoc.parent.id}_${colParentDoc.id}_` : "") + colRef.id
        const liveTimestamp = this.liveTimestamps.get(queryId)??new Date().getTime()
        queryId += `_${liveTimestamp}`
        const query = colRef.where(liveTimestampField, ">", liveTimestamp)

        const onQuerySnapshotWrapper = (snapshot: FirestoreQuerySnapshot) => {
            this.numReads += snapshot.empty ? 1 : snapshot.docChanges().length
            // update live timestamp
            snapshot.docChanges().forEach((docChange) => {
                if(docChange.type === "added") {
                    const createdOn = docChange.doc.data()[liveTimestampField] as number | undefined
                    if(createdOn) {
                        this.liveTimestamps.set(
                            queryId,
                            Math.max(this.liveTimestamps.get(queryId)??0, createdOn)
                        )
                    }
                }
            })
            onQuerySnapshot(snapshot)
        }

        const queryData = this.queries.get(queryId)
        if (queryData === undefined) {
            const cancelSnapshots = query.onSnapshot(onQuerySnapshotWrapper)
            const cancel = () => {
                cancelSnapshots()
                if (groupId) this.deleteFromGroup(groupId, queryId)
                this.queries.delete(queryId)
            }
            this.queries.set(queryId, {cancel})
            if (groupId) this.addToGroup(groupId, queryId)
            return {
                isNew: true,
                cancel
            }
        } else {
            return {
                isNew: false,
                cancel: queryData.cancel
            }
        }
    }

    async queryDocument(docRef: FirestoreDocumentRef) {
        this.numReads += 1
        return await docRef.get()
    }

    queryDocumentSnapshots(
        docRef: FirestoreDocumentRef,
        onDocSnapshot: (doc: FirestoreDocumentSnapshot) => void,
        groupId?: groupIdTypes,
    ) {
        // TODO do we really need the parentIdentifier?
        //  => Cancel should be called before we query a document with the same id and collection name.
        // NOTE docRef.parent.id == collection name
        // e.g.
        // /userSecrets/{userId} = userSecrets_userID
        // /communities/{comID}/members/userId => communities_comID_members_userID
        const parentDoc = docRef.parent.parent
        let parentIdentifier = ""
        if (parentDoc) parentIdentifier = parentDoc.parent.id + "_" + parentDoc.id + "_"
        const queryId = parentIdentifier + docRef.parent.id + "_" + docRef.id
        const onDocSnapshotWrapper = (doc: FirestoreDocumentSnapshot) => {
            this.numReads += 1
            // NOTE: all writes are done via cloud functions
            // hasPendingWrites should always be false
            if (doc.metadata.hasPendingWrites) console.warn("doc has pending writes", doc)
            onDocSnapshot(doc)
        }

        const queryData = this.queries.get(queryId)

        if (queryData === undefined) {
            const cancelSnapshots = docRef.onSnapshot(onDocSnapshotWrapper)
            const cancel = () => {
                cancelSnapshots()
                if (groupId) this.deleteFromGroup(groupId, queryId)
                this.queries.delete(queryId)
            }
            this.queries.set(queryId, {cancel})
            if (groupId) this.addToGroup(groupId, queryId)
            return {
                isNew: true,
                cancel
            }
        } else {
            return {
                isNew: false,
                cancel: queryData.cancel
            }
        }
    }

    // --------------------------
    // cloud functions
    // --------------------------
    async callCloudFunction(foo: FunctionsHttpsCallable, data: any) {
        this.numCloudFunctionCalls += 1
        return await foo(data)
    }
}

export const firestoreManager = new FirestoreManager()

// @ts-ignore
window.firestoreManager = firestoreManager
