import {ModalTitle, ModalWithPresence} from "../../../../../components/modal";
import {useSelector} from "react-redux";
import {RootState} from "../../../../store";
import {selectDiscunaAppById} from "./discunaAppSlice";
import {Button, Typography} from "@mui/material";
import {addAppToCommunity} from "../../../../../firebase/discunaApi";
import React, {useCallback, useState} from "react";
import {selectDiscunaAppStoreItemById} from "./discunaAppStoreItemsSlice";
import {Column, Row} from "../../../../../components/motion_mui";
import {AppIcon} from "./appIcon";
import {lightGrey, modalWidth} from "../../../../../constants/constants";
import {selectCommunityById} from "../communitySlice";
import {CustomLoadingButton} from "../accessWrappers";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import {AppStoreImage} from "./appStoreImage";


export function AppStoreItemPageModal({onClose, show, appId, communityId}: {
    show: boolean,
    onClose: () => void,
    appId: string | null,
    communityId: string,
}) {


    return (
        <ModalWithPresence
            show={show}
            onCancel={onClose}
        >
            {
                appId &&
                <AppStoreItemPage onClose={onClose} appId={appId} communityId={communityId}/>
            }
        </ModalWithPresence>
    )
}


export function AppStoreItemPage({onClose, appId, communityId}: {
    onClose: () => void,
    appId: string,
    communityId: string
}) {
    const app = useSelector((state: RootState) => selectDiscunaAppById(state, appId))
    const community = useSelector((state: RootState) => selectCommunityById(state, communityId))

    // all app store items are fetched when app store page is loaded
    const appStoreItem = useSelector((state: RootState) => selectDiscunaAppStoreItemById(state, appId))


    const [appState, setAppState] = useState<null | "installed" | "processing">(
        community && community.details.apps.includes(appId) ? "installed" : null
    )
    const onAddAppToCommunity = useCallback(async () => {
        setAppState("processing")
        addAppToCommunity({
            appId,
            communityId
        })
            .then(() => {
                setAppState("installed")
            })
            .catch((err) => {
                setAppState(null)
                console.error(err)
            })
    }, [appId, communityId])

    // TODO load app licenses


    if (!app || !appStoreItem) return null

    const appConfig = app.details.config

    return (
        <>
            <ModalTitle title={"App Store"} onCancel={onClose}/>
            <Row mainAxisAlignment={"start"} crossAxisAlignment={"center"} sx={{mb: 3}}>
                <AppIcon url={appStoreItem.details.iconUrl} sx={{
                    height: "56px",
                    minHeight: "56px",
                    width: "56px",
                    minWidth: "56px",
                }}/>
                <Column sx={{ml: 2}} mainAxisAlignment={"start"} crossAxisAlignment={"start"}>
                    <Typography variant={"h4"}>
                        {appStoreItem.details.title}
                    </Typography>
                    <Typography variant={"subtitle1"}>
                        {appStoreItem.details.subTitle}
                    </Typography>
                </Column>
            </Row>
            <Row mainAxisAlignment={"start"} crossAxisAlignment={"start"} sx={{
                mb: 2,
                mx: "-20px",
                maxWidth: modalWidth + "px",
                position: "relative",
                overflow: "auto"
            }}>
                <Row mainAxisAlignment={"start"} crossAxisAlignment={"start"} sx={{
                    mx: "20px",
                }}>
                    {
                        appStoreItem.details.imageIds.map((imageId, idx) => {

                            return <AppStoreImage key={imageId} appId={appId} imageId={imageId} sx={{
                                ml: idx === 0 ? 0 : 2,
                                bgcolor: lightGrey
                            }}/>
                        })
                    }
                </Row>
            </Row>

            <Typography variant={"body1"} sx={{mb: 6, mt: 1}}>
                {appStoreItem.details.description}
            </Typography>

            {
                appState === "installed" ?
                    <Button endIcon={<CheckOutlinedIcon/>} disabled={true} variant={"contained"} sx={{
                        alignSelf: "end"
                    }}>
                        Added
                    </Button> :
                    <CustomLoadingButton isLoading={appState === "processing"} onClick={onAddAppToCommunity} variant={"contained"} sx={{
                        alignSelf: "end"
                    }}>
                        Add App
                    </CustomLoadingButton>
            }
        </>
    )
}

