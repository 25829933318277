import {getAuth} from "firebase/auth";
import {Navigate, Outlet} from "react-router-dom";

export function WithConsent(props: { protectedComponent?: JSX.Element }) {

    const auth = getAuth()

    // TODO should be consent_given instead of displayName?
    if(auth.currentUser && !auth.currentUser.displayName) {
        return <Navigate replace to={"/welcome"} state={{from: window.location.href}}/>
    }

    return props.protectedComponent ?? <Outlet/>
}
