import React from "react";
import {MainPaperCard} from "../../../components/mainPaperCard";
import {DiscunaLogoAndTextBlack} from "../../../components/discunaLogoAndTextBlack";
import {Column} from "../../../components/motion_mui";
import {Navigate, Route, Routes} from "react-router-dom";
import {AuthIdentity} from "./authIdentity";
import {AuthCredentials} from "./authCredentials";


export function AuthFlow() {
    return (
        <MainPaperCard>
            <Column
                mainAxisAlignment={"start"}
                crossAxisAlignment={"stretch"}
                sx={{
                    p: 3,
                    width: "100%",
                }}
            >
                <DiscunaLogoAndTextBlack/>
                <Routes>
                    <Route path={""} element={<Navigate to={"identity"}/>}/>
                    <Route path={"identity"} element={<AuthIdentity/>}/>
                    <Route path={"credentials"} element={<AuthCredentials/>}/>
                </Routes>
            </Column>
        </MainPaperCard>
    )
}


