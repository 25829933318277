import {useSelector} from "react-redux";
import {RootState} from "../../../store";
import {useCallback, useEffect, useState} from "react";
import {isIOS} from "react-device-detect";
import Pushy from "pushy-sdk-web";
import {addDeviceToken} from "../../../../firebase/discunaApi";
import {CustomLoadingButton} from "../communities/accessWrappers";
import {SxProps} from "@mui/material";
import {getPushyDeviceToken} from "../../../pushyUtils";

export function EnablePushNotificationButton(props: {
    sx?: SxProps
}) {

    const userSecrets = useSelector((state: RootState) => state.userSecrets.data)


    // --------------------------
    // Notifications
    // --------------------------

    const [showEnableWebPushNotification, setShowEnableWebPushNotification] = useState(
        !isIOS && !window.discunaDesktopAPI && !window.qandaDesktopAPI && !Pushy.isRegistered() && Notification.permission !== "denied")

    const [isProcessing, setIsProcessing] = useState(false)

    const registerWebDeviceCallback = useCallback(async () => {
        if (!Pushy.isRegistered()) {
            // Register visitor's browser for push notifications
            const deviceToken = await Pushy.register({appId: '6148d9d685d9d7b816d44aee'})
            console.log("Pushy device token:", deviceToken)
            // NOTE device token is always different when newly registered
            // => we do not have to check whether device token already exists
            await addDeviceToken({deviceToken, platform: "browser"})
        }
    }, [])

    // register tokens
    useEffect(() => {
        if (userSecrets) {
            if (!window.discunaDesktopAPI && !window.qandaDesktopAPI) {
                if (!Pushy.isRegistered()) {
                    if (!isIOS && Notification.permission !== "denied") {
                        setShowEnableWebPushNotification(true)
                    }
                } else {
                    // If we log out, Pushy is still running
                    // When we log in with another account, we reuse the same device token
                    const deviceToken = getPushyDeviceToken()
                    console.log("Pushy device token:", deviceToken)
                    if (deviceToken && !userSecrets.details.browserDevices?.includes(deviceToken)) {
                        addDeviceToken({deviceToken, platform: "browser"})
                            .catch((e) => {
                                console.error("could not add device token", e)
                            })
                    }
                }
            } else {
                // TODO where do we start Pushy?
                const registrationTokenHandler = ((event: CustomEvent) => {
                    const data = event.detail as {
                        success: boolean,
                        registrationToken?: string,
                    }
                    if (data.success && data.registrationToken) {
                        console.log("Pushy device token:", data.registrationToken)
                        if (!userSecrets.details.desktopDevices?.includes(data.registrationToken)) {
                            addDeviceToken({deviceToken: data.registrationToken, platform: "desktop"})
                                .catch((e) => {
                                    console.error("could not add device token", e)
                                })
                        }
                    }
                }) as EventListener
                window.addEventListener('desktopRegistrationTokenResult', registrationTokenHandler)
                if (window.discunaDesktopAPI) {
                    window.discunaDesktopAPI.getRegistrationToken()
                }
                return () => {
                    window.removeEventListener('desktopRegistrationTokenResult', registrationTokenHandler)
                }
            }
        }
    }, [userSecrets])


    const onEnablePushNotification = useCallback(async () => {
        try {
            if(!isIOS) {
                setIsProcessing(true)
                const permission = await Notification.requestPermission()
                if (permission === "granted") {
                    await registerWebDeviceCallback()
                }
                if (permission === "granted" || permission === "denied") {
                    setShowEnableWebPushNotification(false)
                }
                setIsProcessing(false)
            }
        } catch (e) {
            console.error("failed to register device", e)
        }

    }, [registerWebDeviceCallback])


    if (!showEnableWebPushNotification) return null


    return (
        <CustomLoadingButton
            variant={"contained"}
            isLoading={isProcessing}
            onClick={onEnablePushNotification}
            sx={props.sx}
        >
            Enable Push Notification
        </CustomLoadingButton>
    )
}
