import React, {useState} from "react";
import {useDispatch} from "react-redux";
import {Modal, ModalTitle, ModalWithPresence} from "../../../../../../components/modal";
import {
    alpha,
    Box,
    Button,
    CardActionArea, CardContent, List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Radio,
    TextField
} from "@mui/material";

import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import {ColumnMotion, Row} from "../../../../../../components/motion_mui";
import {AnimatePresence, motion} from "framer-motion"
import useDimensions from "react-cool-dimensions";
import {useIsResizing} from "../../../../../../hooks/windowIsResizing";
import {createCommunity, generateId} from "../../../../../../firebase/discunaApi";
import {useAppDispatch} from "../../../../../store";

function HighlightOption(props: {
    option: string
}) {
    return <Typography variant={"inherit"} component={"span"}
                       sx={{color: "primary.main"}}><i>{props.option}</i></Typography>
}

export function AddCommunityModal({onClose, show}: {
    show: boolean,
    onClose: () => void,
}) {
    return (
        <ModalWithPresence
            show={show}
            onCancel={onClose}
            // style={{
            //     visibility: dims[0].height === 0 ? "hidden" : "visible"
            // }}
        >
            <AddCommunity onClose={onClose}/>
        </ModalWithPresence>
    )
}

export function AddCommunity({onClose}: {
    onClose: () => void,
}) {
    // const dispatch = useDispatch()

    const [plan, setPlan] = useState<null | "free" | "patronage">("free")
    const [communityAccessibility, setCommunityAccessibility] = useState<null | "closed" | "open">("open")
    const [name, setName] = useState("")
    const [shortDescription, setShortDescription] = useState("")

    const [currentStep, setCurrentStep] = useState(0)

    const dims = [
        useDimensions(),
        useDimensions(),
        // useDimensions()
    ]

    const rowDim = useDimensions()

    const titles = [
        "Create a community",
        // <>Create a <HighlightOption option={plan ?? ""}/> community</>,
        <>Create {communityAccessibility === "open" ? "an" : "a"} <HighlightOption option={communityAccessibility ?? ""}/> community</>,
    ]

    const dispatch = useAppDispatch()

    return (
        <>
            <ModalTitle title={titles[currentStep]} onCancel={onClose}/>
            <Row ref={rowDim.observe} mainAxisAlignment={"stretch"} crossAxisAlignment={"start"} sx={{
                position: "relative",
                mx: "-20px",
                mb: "-20px",
                paddingBottom: "20px",
                overflow: "hidden"
            }}>

                {
                    dims[0].height !== 0 &&
                    <motion.div
                        // initial={{
                        //     // ugly but works
                        //     height: dims[0].heightMath.max(dims[0].height, 248)
                        // }}
                        initial={{
                            // ugly but works
                            height: dims[currentStep].height
                        }}
                        animate={{
                            // ugly but works
                            height: dims[currentStep].height
                        }}
                    />
                }


                {/*<OptionSection*/}
                {/*    distance={rowDim.width}*/}
                {/*    ref={dims[0].observe}*/}
                {/*    title={"Community type"}*/}
                {/*    subTitle={"some description"}*/}
                {/*    step={0}*/}
                {/*    currentStep={currentStep}*/}
                {/*    options={[*/}
                {/*        <OptionCard*/}
                {/*            onClick={() => setPlan("free")}*/}
                {/*            selected={plan === "free"}*/}
                {/*            title={"Free plan"}*/}
                {/*            description={"Freemium plan applies to all members of your community."}*/}
                {/*        />,*/}
                {/*        <OptionCard*/}
                {/*            disabled*/}
                {/*            onClick={() => setPlan("patronage")}*/}
                {/*            selected={plan === "patronage"}*/}
                {/*            title={"Patronage plan"}*/}
                {/*            description={*/}
                {/*                <>*/}
                {/*                    Become a patron and grant premium features to all members of your community!<br/>*/}
                {/*                    <a href={"#"}>See pricing.</a>*/}
                {/*                </>*/}
                {/*            }*/}
                {/*        />*/}
                {/*    ]}*/}
                {/*    enableNext={plan !== null}*/}
                {/*    onNext={() => setCurrentStep(currentStep + 1)}*/}

                {/*/>*/}

                <OptionSection
                    distance={rowDim.width}
                    ref={dims[0].observe}
                    title={"Accessibility"}
                    subTitle={"some description"}
                    step={0}
                    currentStep={currentStep}
                    options={[
                        <OptionCard
                            onClick={() => setCommunityAccessibility("open")}
                            selected={communityAccessibility === "open"}
                            title={"Open community"}
                            description={"Open communities are accessible to everyone and can appear in search results."}
                        />,
                        <OptionCard
                            onClick={() => setCommunityAccessibility("closed")}
                            selected={communityAccessibility === "closed"}
                            title={"Closed community"}
                            description={"Closed communities do not appear in search results and you can restrict access."}
                        />,
                    ]}
                    enableNext={communityAccessibility !== null}
                    onNext={() => setCurrentStep(currentStep + 1)}
                    // onPrev={() => setCurrentStep(currentStep - 1)}
                />

                <OptionSection
                    distance={rowDim.width}
                    ref={dims[1].observe}
                    title={"Info"}
                    subTitle={"some description"}
                    step={1}
                    currentStep={currentStep}
                    options={[
                        <TextField
                            key={"communityName"}
                            sx={{my: 1}}
                            label={"Community name"}
                            value={name}
                            variant={"filled"}
                            onChange={(e) => {
                                setName(e.currentTarget.value)
                            }}/>,
                        <TextField
                            key={"communityDescription"}
                            minRows={1}
                            sx={{my: 1}}
                            multiline
                            variant={"filled"}
                            label={"Brief community description"}
                            value={shortDescription}
                            onChange={(e) => {
                                // disallow line breaks
                                setShortDescription(e.currentTarget.value.replace(/[\r\n\v]+/g, ''))
                            }}/>,
                    ]}
                    enableNext={name.length > 0 && shortDescription.length > 0}
                    onNext={() => {
                        if (plan && communityAccessibility)
                            dispatch(createCommunity({
                                communityId: generateId(),
                                plan,
                                accessibility: communityAccessibility === "closed" ? 0 : 1,
                                name,
                                shortDescription
                            }))
                        onClose()
                    }}
                    onPrev={() => setCurrentStep(currentStep - 1)}
                    finalText={"Create"}
                    // onPrev={() => setCurrentStep(currentStep - 1)}
                />
            </Row>
        </>
    )
}


interface OptionSectionProps {
    title: string,
    subTitle: string,
    step: number,
    currentStep: number,
    options: React.ReactNode[],
    enableNext: boolean,
    onNext: () => void,
    onPrev?: () => void,
    finalText?: string,
    distance?: number
}


export const OptionSection = React.forwardRef<HTMLDivElement, OptionSectionProps>(
    (props, ref) => {
        const isFirst = props.step === 0
        const {currentStep, step} = props
        const distance = props.distance && props.distance !== 0 ? props.distance + 48 : 1000
        const xInit = step === 0 ? 0 : distance
        const isOutside = currentStep !== step
        const x = isOutside ? (currentStep < step ? distance : -distance) : 0

        const windowIsResizing = useIsResizing()

        return (
            <ColumnMotion mainAxisAlignment={"start"} crossAxisAlignment={"stretch"}
                          sx={{
                              position: "absolute",
                              flexGrow: 1,
                              width: "100%",
                              boxSizing: "border-box",
                              padding: "0 20px 20px"
                          }}
                          transition={{
                              default: {duration: windowIsResizing ? 0 : 0.5}
                          }}
                          initial={{x: xInit}}
                          animate={{x: x}}
                          ref={ref}
                // exit={{x: exit}}
            >
                {/*<Typography variant={"h2"}>{props.title}</Typography>*/}
                {/*<Typography variant={"subtitle1"}>{props.subTitle}</Typography>*/}
                {/*<Box sx={{my: 1}}/>*/}
                {props.options}
                <Box sx={{my: 2}}/>
                <Row mainAxisAlignment={"end"} crossAxisAlignment={"center"}>
                    {
                        !isFirst &&
                        <Button sx={{mr: 2}} onClick={props.onPrev}>Back</Button>
                    }
                    <Button color={"primary"} variant={"contained"} disabled={!props.enableNext}
                            onClick={props.onNext}>
                        {props.finalText ?? "Next"}
                    </Button>
                </Row>
            </ColumnMotion>
        )
    })


export function OptionCard(props: {
    onClick: () => void,
    selected: boolean,
    title: string,
    description: React.ReactNode,
    disabled?: true
}) {
    return (
        <Box sx={{minWidth: 275, my: 1}}>
            <Card variant="outlined"
                  sx={{
                      ...(props.selected) && {
                          bgcolor: theme => alpha(theme.palette.primary.main, theme.palette.action.hoverOpacity),
                          borderColor: "primary.main"
                      },
                  }}
            >
                <CardActionArea disabled={props.disabled} onClick={() => {
                    props.onClick()
                }}>
                    {/*<CardContent>*/}
                        <List disablePadding={true}>
                            <ListItem key={"optionCard"} alignItems={"flex-start"} disabled={props.disabled}>
                                <ListItemIcon>
                                    <Radio
                                        color={"primary"}
                                        sx={{
                                            padding: 0,
                                            // mr: 2
                                        }}
                                        checked={props.selected}
                                        value="a"
                                        name="radio-buttons"
                                        inputProps={{'aria-label': 'A'}}
                                    />
                                </ListItemIcon>
                                <ListItemText primary={props.title} secondary={props.description}/>
                            </ListItem>
                        </List>
                    {/*</CardContent>*/}
                </CardActionArea>
            </Card>
        </Box>
    );
}
