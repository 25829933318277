import {useSelector} from "react-redux";
import {RootState} from "../../../../../../../store";
import {selectDiscunaAppById} from "../../../../../communities/apps/discunaAppSlice";
import {Center, Column, Row} from "../../../../../../../../components/motion_mui";
import {Alert, Button, CircularProgress} from "@mui/material";
import {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {discunaCoreFrontendApiHost} from "../../../../../../../discunaCoreFrontendCom/discunaCoreFrontendApiHost";
import {discunaAppFrontendApiClient} from "../../../../../../../discunaCoreFrontendCom/discunaAppFrontendApiClient";

export function AppPageCreateChannel({appId, parentId, communityId, onClose}: {
    appId: string,
    parentId: string | null,
    communityId: string,
    onClose: () => void,
}) {
    const auth = useSelector((state: RootState) => state.auth)
    if (!auth.isSignedIn) throw Error("Not signed in")
    const app = useSelector((state: RootState) => selectDiscunaAppById(state, appId))

    const [progress, setProgress] = useState<null | "loading" | string>(null)
    const [createPageIsReady, setCreatePageIsReady] = useState(false)
    const createPageIsReadyRef = useRef(false)
    const [addChannelDisabled, setAddChannelDisabled] = useState(true)

    const iframeRef = useRef<HTMLIFrameElement>(null)
    const iframeSrc = useMemo(() => {
        if (app && app.details.config.pages.origin)
            return `${app.details.config.pages.origin}/discuna/communities/${communityId}/channels/${parentId ?? "root"}/create`
        else return null
    }, [app, communityId, parentId])

    // add event handler for messages from iframe
    useEffect(() => {
        if (iframeSrc && iframeRef.current && iframeRef.current.contentWindow) {
            const cancel1 = discunaCoreFrontendApiHost.onSetDisableCreateChannelButton(async (value) => {
                setAddChannelDisabled(value)
            }, new URL(iframeSrc).origin, iframeRef.current.contentWindow)
            const cancel2 = discunaCoreFrontendApiHost.onPageIsReady(async () => {
                if (!createPageIsReadyRef.current) {
                    createPageIsReadyRef.current = true
                    setCreatePageIsReady(true)
                }
            }, new URL(iframeSrc).origin, iframeRef.current.contentWindow)
            const cancel3 = discunaCoreFrontendApiHost.onGetMemberId(() => {
                    return auth.uid
                },
                new URL(iframeSrc).origin,
                iframeRef.current?.contentWindow
            )
            return () => {
                cancel1()
                cancel2()
                cancel3()
            }
        }
    }, [auth.uid, iframeSrc])


    useEffect(() => {
        console.log("iframe", iframeRef.current !== null && iframeRef.current.contentWindow !== null, iframeRef.current?.contentWindow)
    }, [])

    const addChannelCallback = useCallback(async () => {
        if (!app || !iframeRef.current || !iframeRef.current.contentWindow) return
        setProgress("loading")
        try {
            console.log("create channel")
            // dispatch event to iframe
            await discunaAppFrontendApiClient.createChannelClick(
                app.details.config.pages.origin,
                iframeRef.current.contentWindow
            )
            onClose()
        } catch (e: any) {
            console.error(e)
            setProgress("Error: " + e.message ?? "unknown")
        }
    }, [app, onClose])


    if (!app) return null
    return (
        <Column mainAxisAlignment={"start"} crossAxisAlignment={"stretch"}>
            {
                (!createPageIsReady || progress === "loading") &&
                <Center sx={{height: 400}}>
                    <CircularProgress size={24}/>
                </Center>
            }
            {
                createPageIsReady && progress !== null && progress !== "loading" &&
                <Alert severity={"error"} variant={"filled"}>
                    {progress}
                </Alert>
            }
            {
                iframeSrc &&
                <iframe
                    ref={iframeRef}
                    title={"create page"}
                    src={iframeSrc}
                    height={400}
                    style={{
                        border: "none",
                        display: (createPageIsReady && progress === null) ? "inherit" : "none"
                    }}
                />
            }
            <Row sx={{mt: 2}} mainAxisAlignment={"spaceBetween"} crossAxisAlignment={"center"}>
                <Button onClick={onClose} disabled={progress === "loading"}>
                    Cancel
                </Button>
                <Button disabled={progress !== null || addChannelDisabled} onClick={addChannelCallback}
                        variant={"contained"}>
                    Add
                </Button>
            </Row>
        </Column>
    )
}
