import React from "react";
import {useDispatch} from "react-redux";
import {Box, Button, Typography} from "@mui/material";
import {Row} from "../../../../../../../components/motion_mui";
import {ModalTitle, ModalWithPresence} from "components/modal";
import {updateCommunityLibrary} from "../../../../../../../firebase/discunaApi";
import {LibraryDeleteChannelAction} from "shared";
import {LibraryItemWithLevel} from "../communityLibraryUtils";
import {useAppDispatch} from "../../../../../../store";

export function DeleteChannel({show, item, communityId, onClose}: {
    item: LibraryItemWithLevel,
    communityId: string,
    onClose: () => void,
    show: boolean,
}) {
    const dispatch = useAppDispatch()
    return (
        <ModalWithPresence onCancel={onClose}
                           show={show}>
            <ModalTitle title={"Confirm Deletion"} onCancel={onClose}/>
            {
                <Typography variant={"body1"}>
                    Are you sure you want to delete "{item.name}".
                </Typography>
            }
            <Box height={"12px"}/>
            <Row mainAxisAlignment={"end"} crossAxisAlignment={"center"}>
                <Button onClick={onClose}>
                    Cancel
                </Button>
                <Box width={12}/>
                <Button color={"primary"}
                        onClick={() => {
                            const deleteChannelAction = {
                                actionType: "delete",
                                channelId: item.id,
                            } as LibraryDeleteChannelAction
                            dispatch(updateCommunityLibrary({
                                communityId,
                                actions: [deleteChannelAction]
                            }))
                            onClose()
                        }}>
                    Delete
                </Button>
            </Row>

        </ModalWithPresence>
    )
}
