import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import {store} from "./app/store";
import {Provider} from 'react-redux';
import {App} from "./app/app";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';


// TODO remove this and delete all console.log statements
if (process.env.NODE_ENV && process.env.NODE_ENV !== "development") {
    // console.log("disable logging")
    console.log = console.warn = console.error = console.debug = () => {
    }
}

export const DiscunaContext = React.createContext({
    isIFrame: false,
    isDemo: false,
    isDesktop: false,
});

const isIFrame = window.parent !== window

const root = ReactDOM.createRoot(document.getElementById('root')!)
root.render(
    // <React.StrictMode>
    <Router>
        <Routes>
            {/*<Route path={"/mergeDemo"}>*/}
            {/*    <MergeDemo/>*/}
            {/*</Route>*/}
            {/*<Route path={"/markdownEditor"}>*/}
            {/*    <MarkdownEditorDemo/>*/}
            {/*</Route>*/}
            <Route path={"/*"} element={
                <Provider store={store}>
                    <DiscunaContext.Provider
                        value={{
                            isIFrame: isIFrame,
                            isDemo: false,
                            isDesktop: window.hasOwnProperty("qandaDesktopAPI")
                        }}>
                        <App/>
                    </DiscunaContext.Provider>
                </Provider>
            }/>
        </Routes>
    </Router>
    // </React.StrictMode>
);

// listens to on load event
serviceWorkerRegistration.register()


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
