import {createEntityAdapter, createSlice} from '@reduxjs/toolkit'
import {RootState} from "../../../../../store";
import {Reward} from "./rewardInterfaces";

const rewardEntityAdapter = createEntityAdapter<Reward>()

const rewardSlice = createSlice({
    name: "rewards",
    initialState: rewardEntityAdapter.getInitialState({}),
    reducers: {
        addReward: rewardEntityAdapter.addOne,
        upsertReward: rewardEntityAdapter.upsertOne,
        updateReward: rewardEntityAdapter.updateOne,
        removeReward: rewardEntityAdapter.removeOne,
        removeAllRewards: rewardEntityAdapter.removeAll,
        setAllRewards: rewardEntityAdapter.setAll
    },
    extraReducers: builder => {
    }
})

export const {
    addReward,
    upsertReward,
    updateReward,
    removeReward,
    removeAllRewards,
    setAllRewards
} = rewardSlice.actions

export const rewardReducer = rewardSlice.reducer

export const {
    selectAll: selectAllRewards,
    selectById: selectRewardById,
    selectIds: selectRewardIds,
} = rewardEntityAdapter.getSelectors<RootState>(state => state.rewards)
