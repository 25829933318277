import {Center, FullSizedWindow} from "../components/motion_mui";
import {CircularProgress, SxProps} from "@mui/material";

export function LoadingScreen({sx}: {
    sx?: SxProps
}) {
    return (
        <FullSizedWindow sx={sx} visible={true}>
            <Center>
                <CircularProgress size={24}/>
            </Center>
        </FullSizedWindow>
    )
}
