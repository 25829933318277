import {FilledInput, FormControl, FormHelperText, IconButton, InputAdornment, InputLabel, SxProps} from "@mui/material";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import React, {useState} from "react";

export function PasswordTextField(props: {
    sx?: SxProps
    showPassword?: boolean,
    setShowPassword?: (showPassword: boolean) => void,
    password: string,
    setPassword: (password: string) => void,
    label: string,
    id: string,
    onEnter?: () => void,
    errorMessage?: string,
    showPasswordHelperText?: boolean,
    disabled?: boolean
}) {

    const [showPassword, setShowPassword] = useState(false)
    return (
        <FormControl sx={props.sx} variant="filled">
            <InputLabel htmlFor={`filled-adornment-password-${props.id}`}>{props.label}</InputLabel>
            <FilledInput
                disabled={props.disabled}
                error={!!props.errorMessage}
                id={`filled-adornment-password-${props.id}`}
                type={(props.showPassword !== undefined ? props.showPassword : showPassword) ? 'text' : 'password'}
                value={props.password}
                onChange={(e) => {
                    props.setPassword(e.target.value)
                }}
                onKeyDown={(e) => {
                    if (e.key === "Enter") {
                        if (props.onEnter) {
                            e.preventDefault()
                            props.onEnter()
                        }
                    }
                }}
                endAdornment={
                    <InputAdornment position="end" sx={{ml: 0.5}}>
                        <IconButton
                            size={"small"}
                            aria-label="toggle password visibility"
                            onClick={() => {
                                if (props.setShowPassword) props.setShowPassword(!props.showPassword)
                                else setShowPassword(!showPassword)
                            }}
                            onMouseDown={(e) => e.preventDefault()}
                            edge="end"
                        >
                            {props.showPassword || showPassword ? <VisibilityOff/> : <Visibility/>}
                        </IconButton>
                    </InputAdornment>
                }
            />
            {
                props.errorMessage &&
                <FormHelperText error={!!props.errorMessage}>
                    {props.errorMessage}
                </FormHelperText>
            }
            {
                props.showPasswordHelperText && props.password.length > 0 && props.password.length < 6 &&
                <FormHelperText>
                    Password must have at least 6 characters
                </FormHelperText>
            }
        </FormControl>
    )
}
