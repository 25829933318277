import {createSlice, PayloadAction} from "@reduxjs/toolkit";


export interface FirebaseState{
    numReads: number,
    numWrites: number
}

const initialState = {
    numReads: 0,
    numWrites: 0,
} as FirebaseState

const firebaseSlice = createSlice({
    name: 'firebase',
    initialState: initialState,
    reducers: {
        incrementReadsByOne(state){
            state.numReads += 1
        },
        incrementReadsByAmount(state, action: PayloadAction<number>){
            state.numReads += action.payload
        },
        incrementWritesByOne(state){
            state.numWrites += 1
        },
        incrementWritesByAmount(state, action: PayloadAction<number>){
            state.numWrites += action.payload
        },
    }
})

export const { incrementReadsByOne, incrementReadsByAmount, incrementWritesByOne, incrementWritesByAmount } = firebaseSlice.actions
export const firebaseReducer = firebaseSlice.reducer