import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";
import {firestoreManager} from "../../../../../firebase/queryManager";
import {CommunityLibrary} from "./communityLibraryInterfaces";
import {removeCommunityLibrary, upsertCommunityLibrary} from "./communityLibrarySlice";
import useMemoizedState from "../../../../../hooks/useMemoizedState";
import {getCommunityRef} from "../communityUtils";
import {RootState} from "../../../../store";

function getCommunityLibraryRef(communityId: string) {
    return getCommunityRef(communityId).collection("channels").doc("library")
}

export function useCommunityLibrary(communityId: string | null | undefined) {
    const dispatch = useDispatch()
    const [isLoading, setIsLoading] = useMemoizedState(true)
    const auth = useSelector((state: RootState) => state.auth)

    // fetch community library
    useEffect(() => {
        if(auth.isSignedIn && communityId) {
            const communityLibraryRef = getCommunityLibraryRef(communityId)
            const {isNew, cancel} = firestoreManager.queryDocumentSnapshots(communityLibraryRef, (doc) => {
                setIsLoading(false)
                if(doc.exists) {
                    const communityExtra = {
                        id: communityId,
                        isSynced: true,
                        channelsNotSynced: [] as string[],
                        ...doc.data()
                    } as CommunityLibrary
                    dispatch(upsertCommunityLibrary(communityExtra))
                } else {
                    dispatch(removeCommunityLibrary(communityId))
                }
            })
            setIsLoading(isNew)
            return cancel
        }
    }, [communityId, dispatch, setIsLoading, auth])
    return isLoading
}
