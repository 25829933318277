import {ChannelOfflineData} from "./channels/channelInterfaces";
import {useEffect} from "react";
import {firestore} from "../../../../firebase/firebase";
import {firestoreManager} from "../../../../firebase/queryManager";
import {Community} from "./communityInterfaces";
import {removeCommunity, upsertCommunity} from "./communitySlice";
import {useDispatch, useSelector} from "react-redux";
import useMemoizedState from "../../../../hooks/useMemoizedState";
import {RootState} from "../../../store";
import {getMemberId, selectMemberById} from "./members/memberSlice";
import {LibraryItemWithLevel} from "../navbar/communities/library/communityLibraryUtils";


// export function getLibraryChannelById(libraryList: LibraryItem[], id: string) {
//     let i = 0;
//     for (const item of libraryList) {
//         if (item.id === id) return {i, item};
//         i++;
//     }
//     return undefined
// }

export function getCommunityRef(communityId: string) {
    return firestore.collection("communities").doc(communityId)
}

export function getVisibleLibraryItems(
    orderedLibraryItems: LibraryItemWithLevel[],
    channelOfflineData: Map<string, ChannelOfflineData>
) {
    let parentClosedFolderLevel: null | number = 0;
    let visibleLibraryItems = [] as LibraryItemWithLevel[]
    for (const item of orderedLibraryItems) {
        if (parentClosedFolderLevel === null) {
            visibleLibraryItems.push(item)
            if (!channelOfflineData.get(item.id)?.open) {
                parentClosedFolderLevel = item.level
            }
        } else {
            if (item.level <= parentClosedFolderLevel) {
                visibleLibraryItems.push(item)
                if (!channelOfflineData.get(item.id)?.open) {
                    parentClosedFolderLevel = item.level
                } else {
                    parentClosedFolderLevel = null
                }
            }
        }
    }
    return visibleLibraryItems
}

// --------------------------
// security
// --------------------------

export function useMemberSelector(communityId: string) {
    const auth = useSelector((state: RootState) => state.auth)
    const memberId = getMemberId(communityId, auth.isSignedIn ? auth.uid : "")
    const member = useSelector((state: RootState) => selectMemberById(state, memberId))
    if (auth.isSignedIn && member) {
        return member
    }
    return null
}


// --------------------------
// fetch
// --------------------------


export function useCommunitiesOnce(communityIds: string[]) {
    const dispatch = useDispatch()
    const [isLoading, setIsLoading] = useMemoizedState(true)

    useEffect(() => {
        setIsLoading(true)
        const promises = []
        for (const communityId of communityIds) {
            const communityRef = firestore.collection("communities").doc(communityId)
            promises.push(communityRef.get().then((doc) => {
                return {
                    id: doc.id,
                    isSynced: true,
                    ...doc.data()
                } as Community
            }))
        }
        Promise.allSettled(promises)
            .then((res) => {
                res.forEach((val) => {
                    if (val.status === "fulfilled") {
                        dispatch(upsertCommunity(val.value))
                    }
                })
            })
            .finally(() => {
                setIsLoading(false)
            })
    }, [dispatch, communityIds, setIsLoading])

    return isLoading
}

export function useCommunity(communityId: string) {
    const dispatch = useDispatch()
    const auth = useSelector((state: RootState) => state.auth)
    const [isLoading, setIsLoading] = useMemoizedState(true)

    // fetch community
    useEffect(() => {
        if (auth.isSignedIn) {
            const communityRef = firestore.collection("communities").doc(communityId)
            const {isNew, cancel} = firestoreManager.queryDocumentSnapshots(communityRef, (doc) => {
                setIsLoading(false)
                if (doc.exists) {
                    const community = {
                        id: doc.id,
                        isSynced: true,
                        ...doc.data()
                    } as Community
                    dispatch(upsertCommunity(community))
                } else {
                    dispatch(removeCommunity(communityId))
                }
            })
            setIsLoading(isNew)
            return cancel
        }
    }, [communityId, dispatch, setIsLoading, auth])
    return isLoading
}
