import React, {useState} from "react";
import {useDispatch} from "react-redux";
import {Box, Button, TextField} from "@mui/material";
import {ModalTitle, ModalWithPresence} from "../../../../../../../components/modal";
import {LibraryCreateFolderAction} from "shared";
import {generateId, updateCommunityLibrary} from "../../../../../../../firebase/discunaApi";
import {useAppDispatch} from "../../../../../../store";

export function AddFolderChannel(props: {
    communityId: string,
    parentId: string | null,
    onClose: () => void,
    show: boolean,
}) {
    const [folderName, setFolderName] = useState("")
    const dispatch = useAppDispatch()

    const {communityId, parentId, onClose} = props

    return (
        <ModalWithPresence onCancel={props.onClose} show={props.show}>
            <ModalTitle title={"Add folder"} onCancel={props.onClose}/>
            <TextField value={folderName}
                       label={"folder name"}
                       variant={"filled"}
                       onChange={(e) => {
                           setFolderName(e.currentTarget.value)
                       }}/>
            <Button
                variant={"contained"}
                sx={{mt: 2, alignSelf: "end"}}
                disabled={folderName.length === 0}
                onClick={() => {
                    const createFolderChannelAction = {
                        actionType: "createFolder",
                        parentId,
                        channelId: generateId(),
                        name: folderName,
                    } as LibraryCreateFolderAction
                    dispatch(updateCommunityLibrary({
                        communityId,
                        actions: [createFolderChannelAction]
                    }))
                    setFolderName("")
                    onClose()
                }}>
                Add
            </Button>
        </ModalWithPresence>
    )
}
