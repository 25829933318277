import React, {useCallback, useMemo, useState} from "react";
import {useSelector} from "react-redux";
import {selectDiscunaAppById} from "../communities/apps/discunaAppSlice";
import {RootState} from "../../../store";
import {ModalTitle, ModalWithPresence} from "../../../../components/modal";
import {Alert, Button, CircularProgress, TextField} from "@mui/material";
import {appConfigValidator} from "shared";
import {updateAppConfig} from "../../../../firebase/discunaApi";
import {Center} from "../../../../components/motion_mui";
import {circularProgressSize} from "../../../../constants/constants";


export function AppConfigUpdateModal(props: {
    show: boolean,
    appId: string | null,
    onClose: () => void
}) {
    return (
        <ModalWithPresence show={props.show} onCancel={props.onClose}>
            {
                props.show && props.appId &&
                <AppConfigUpdateForm appId={props.appId} onClose={props.onClose}/>
            }
        </ModalWithPresence>
    )
}

export function AppConfigUpdateForm({appId, onClose}: {
    appId: string,
    onClose: () => void
}) {

    const existingAppConfig = useSelector((state: RootState) => selectDiscunaAppById(state, appId))

    const existingAppConfigStr = useMemo(() => {
        if(existingAppConfig) {
            return JSON.stringify(existingAppConfig.details.config, null, 2)
        }
        return ""
    }, [existingAppConfig])
    const [appConfig, setAppConfig] = useState(existingAppConfigStr)

    const [appConfigError, setAppConfigError] = useState<string | null>(null)

    const [status, setStatus] = useState<null | "loading" | "success" | string>(null)


    const submitUpdate = useCallback((appConfig: string) => {
        setStatus("loading")
        updateAppConfig({
            appId,
            appConfig: JSON.parse(appConfig)
        }).then(() => {
            setStatus("success")
        }).catch((e) => {
            setStatus(e.message)
        })
    }, [appId])

    const modalTitle = "Update App Config"

    if (status === "loading") {
        return (
            <>
                <ModalTitle title={modalTitle} onCancel={onClose}/>
                <Center>
                    <CircularProgress size={circularProgressSize}/>
                </Center>
            </>
        )
    }
    if (status === "success") {
        return (
            <>
                <ModalTitle title={modalTitle} onCancel={onClose}/>
                <Alert severity={"success"}>
                    App config updated successfully!
                </Alert>
            </>
        )
    }

    if(status === null) {
        return (
            <>
                <ModalTitle title={modalTitle} onCancel={onClose}/>
                <TextField
                    key={"appConfig"}
                    // sx={{mt: 2}}
                    variant={"filled"}
                    label={"App configuration"}
                    value={appConfig}
                    multiline={true}
                    error={!!appConfigError}
                    helperText={appConfigError}
                    onKeyDown={(e) => {
                        if (e.key === "TAB") {
                            e.preventDefault()
                        }
                    }}
                    onChange={(e) => {
                        try {
                            const data = JSON.parse(e.target.value)
                            const validationResult = appConfigValidator(data)
                            if (!validationResult.ok) {
                                setAppConfigError(validationResult.explanation ?? "unknown error")
                            } else {
                                setAppConfigError(null)
                            }
                        } catch (e) {
                            setAppConfigError("Invalid JSON")
                        }
                        setAppConfig(e.target.value)
                    }}
                />

                <Button
                    variant={"contained"}
                    sx={{mt: 2, alignSelf: "end"}}
                    disabled={appConfig.length === 0 || !!appConfigError}
                    onClick={() => {
                        submitUpdate(appConfig)
                    }}
                >
                    Update
                </Button>
            </>
        )
    }

    return (
        <>
            <ModalTitle title={modalTitle} onCancel={onClose}/>
            <Alert severity={"error"}>
                Error: {status}
            </Alert>
        </>
    )


}
