import React, {useCallback, useMemo, useState} from "react";
import {
    Box,
    CircularProgress, IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemSecondaryAction,
    ListItemText,
    Menu, MenuItem, Skeleton, Tooltip
} from "@mui/material";
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import {useDispatch, useSelector} from "react-redux";
import {RootState, useAppDispatch} from "../../../../store";
import {selectCommunityById} from "../../communities/communitySlice";
import {AddFolderChannel} from "./library/contextMenu/addFolderChannel";
import {CommunityLibrary} from "./library/communityLibrary";
import {Center, Column} from "../../../../../components/motion_mui";
import {useCommunity} from "../../communities/communityUtils";
import {useCommunityLibrary} from "../../communities/channels/communityLibraryUtils";
import {useMember} from "../../communities/members/memberUtils";
import {useMemberSecrets} from "../../communities/members/memberSecretsUtils";
import {useDrop} from "react-dnd";
import {LibraryMoveChannelAction} from "shared";
import {updateCommunityLibrary} from "../../../../../firebase/discunaApi";
import {AddMemberModal} from "./library/contextMenu/addMember";
import {RestrictAccess} from "../../communities/accessWrappers";
import {useNavigate} from "react-router-dom";
import {setSelectedCommunityId} from "../../../dashboardSlice";
// import {refreshToken} from "../../../../auth/authUtils";
import {AddDiscunaApp} from "./library/contextMenu/addDiscunaApp/addDiscunaApp";
import {selectDiscunaAppsByIDs} from "../../communities/apps/discunaAppSlice";
import {circularProgressSize} from "../../../../../constants/constants";

// import {AddChatChannel} from "./library/contextMenu/addChatChannel";


export function CommunityContainer(
    {
        navTo,
        communityId,
        isMobile = false
    }: {
        navTo: (navId: string) => void,
        communityId: string,
        isMobile?: boolean
    }
) {
    const auth = useSelector((state: RootState) => state.auth)
    if (!auth.isSignedIn) throw Error("auth")

    const dispatch = useAppDispatch()

    type joinProgressStates = null | "loading" | "done" | "leaving" | string
    const [joinProgress, setJoinProgress] = useState<joinProgressStates>(null)
    const joinProgressCallback = useCallback((val: joinProgressStates) => setJoinProgress(val), [])
    const joinFailed = !!(joinProgress && joinProgress !== "loading" && joinProgress !== "done")

    // TODO when community is being deleted or member leaves
    //  => navigate back to account
    const community = useSelector((state: RootState) => selectCommunityById(state, communityId))

    // fetch
    const comIsLoading = useCommunity(communityId)
    const comLibraryIsLoading = useCommunityLibrary(communityId)
    const memberIsLoading = useMember({communityId, userId: auth.uid})
    const memSecretIsLoading = useMemberSecrets(communityId)
    const isLoading = comIsLoading || comLibraryIsLoading || memberIsLoading || memSecretIsLoading
    // console.log("isLoading", isLoading, comIsLoading, comLibraryIsLoading, memberIsLoading, memSecretIsLoading)

    // load all apps of community
    // NOTE we do not need to wait until all apps are loaded, dependent entities are updated once loaded
    // NOTE we already have a real-time listener on communities
    //  => apps are fetched again when list of community apps changes
    // const communityAppsAreLoading = useCommunityAppsOnce(community?.details?.apps ?? [])
    const selectDiscunaAppsByIDsMemo = useMemo(selectDiscunaAppsByIDs, [])
    const communityApps = useSelector((state: RootState) => selectDiscunaAppsByIDsMemo(state, community?.details?.apps ?? []))

    // context menus
    const [contextAddMenuAnchorEl, setContextAddMenuAnchorEl] = useState<null | EventTarget & HTMLElement>(null);
    const [showFolderForm, setShowFolderForm] = useState(false)
    const [showMemberForm, setShowMemberForm] = useState(false)

    const [appID, setAppID] = useState<null | string>(null)

    const [{canDrop, isOver}, dropRef] = useDrop(() => ({
        // The type (or types) to accept - strings or symbols
        accept: 'card',
        // Props to collect
        collect: (monitor) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop()
        })
    }))

    const [dragId, setDragId] = useState<null | string>(null)
    const navigate = useNavigate()


    if (isLoading || community) {
        return (
            <Column mainAxisAlignment={"start"} crossAxisAlignment={"stretch"} sx={{
                flexGrow: 1,
                height: "100%"
            }}>
                <List component="nav" disablePadding sx={{
                    ...(isMobile) && {
                        borderBottom: "1px solid #e0e0e0"
                    }
                }}>
                    <ListItem
                        // key={"community"}
                        selected={isOver}
                        ref={dropRef}
                        onDrop={() => {
                            const action = {
                                actionType: "move",
                                channelId: dragId,
                                newParentId: null
                            } as LibraryMoveChannelAction
                            dispatch(updateCommunityLibrary({
                                communityId,
                                actions: [action]
                            }))
                        }}
                    >
                        {
                            !isMobile ?
                                <ListItemIcon>
                                    <IconButton sx={{p: "4px"}} size={"small"} edge={"start"}
                                                onClick={() => dispatch(setSelectedCommunityId(null))}>
                                        <ArrowBackOutlinedIcon/>
                                    </IconButton>
                                </ListItemIcon> :
                                <Box sx={{height: "34px"}}/>
                        }
                        <ListItemText primary={community ? community.details.name : <Skeleton/>}/>
                        <ListItemSecondaryAction>
                            <RestrictAccess communityId={communityId} role={"moderator"}>
                                <Tooltip title={"Add channels to the community library"}>
                                    <IconButton size={"small"}
                                                edge={"end"}
                                                onClick={(event) => {
                                                    setContextAddMenuAnchorEl(event.currentTarget)
                                                }}>
                                        <AddOutlinedIcon/>
                                    </IconButton>
                                </Tooltip>
                            </RestrictAccess>
                        </ListItemSecondaryAction>
                    </ListItem>
                </List>

                {
                    (isLoading || !community) ?
                        <Center>
                            <CircularProgress size={circularProgressSize}/>
                        </Center> :
                        <CommunityLibrary
                            sx={{
                                ...(isMobile) && {
                                    mt: 2,
                                    ml: "-6px"
                                }
                            }}
                            dragId={dragId}
                            setDragId={setDragId}
                            navTo={navTo}
                            community={community}
                        />
                }

                <Menu
                    id="qanda-add-library-item-menu"
                    anchorEl={contextAddMenuAnchorEl}
                    open={Boolean(contextAddMenuAnchorEl)}
                    onClose={() => setContextAddMenuAnchorEl(null)}
                >
                    <RestrictAccess communityId={communityId} role={"administrator"}>
                        <MenuItem onClick={() => {
                            setContextAddMenuAnchorEl(null)
                            setShowMemberForm(true)
                        }}>
                            Member
                        </MenuItem>
                    </RestrictAccess>
                    <RestrictAccess communityId={communityId} role={"moderator"}>
                        <MenuItem onClick={() => {
                            setContextAddMenuAnchorEl(null)
                            setShowFolderForm(true)
                        }}>
                            Folder
                        </MenuItem>

                        {
                            communityApps.map((app) => (
                                    <MenuItem key={"menu-add-" + app.id} onClick={() => {
                                        setContextAddMenuAnchorEl(null)
                                        // setShowFolderForm(true)
                                        setAppID(app.id)
                                    }}>
                                        {app.details.config.name}
                                    </MenuItem>
                                )
                            )
                        }

                        {/*<MenuItem onClick={() => {*/}
                        {/*    setContextAddMenuAnchorEl(null)*/}
                        {/*    setShowURLForm(true)*/}
                        {/*}}>*/}
                        {/*    URL*/}
                        {/*</MenuItem>*/}

                    </RestrictAccess>
                </Menu>

                <AddMemberModal
                    show={showMemberForm}
                    communityId={communityId}
                    onClose={() => setShowMemberForm(false)}
                />
                <AddFolderChannel
                    show={showFolderForm}
                    parentId={null}
                    communityId={communityId}
                    onClose={() => setShowFolderForm(false)}
                />
                {/*{*/}
                {/*    showURLForm && <AddUrlModal onClose={() => setShowURLForm(false)}/>*/}
                {/*}*/}

                <AddDiscunaApp
                    communityId={communityId}
                    parentId={null}
                    onClose={() => setAppID(null)}
                    appId={appID}
                />
            </Column>
        )
    }
    return (
        <>
            <List component="nav" disablePadding>
                <ListItem key={"community"}
                          selected={false}>
                    <ListItemIcon>
                        <IconButton sx={{p: "4px"}} size={"small"} edge={"start"}
                                    onClick={() => dispatch(setSelectedCommunityId(null))}>
                            <ArrowBackOutlinedIcon/>
                        </IconButton>
                    </ListItemIcon>
                    <ListItemText
                        primary={"Something wen wrong"}/>
                </ListItem>
            </List>
            <div>
                <p>something went wrong :/</p>
                <p>Please consider reporting this bug to us in the feedback&support space</p>
            </div>
        </>

    )
}
