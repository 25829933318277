import {PropsWithChildren} from "react";
import useWindowDimensions from "../hooks/window";
import {isMobile} from "react-device-detect";
import {Alert, AlertTitle, Box, Paper, SxProps} from "@mui/material";
import {Center, Column} from "./motion_mui";
import {LoadingScreen} from "../utils/loadingScreen";
import {primaryDarkBackground} from "../constants/constants";

export function MainPaperCard(props: PropsWithChildren<{
    paperWidth?: number,
    sxMobile?: SxProps,
    sxPaper?: SxProps,
    isLoading?: boolean,
    errMsg?: {
        title: string,
        body: string
    }
}>) {
    const dims = useWindowDimensions()

    const showMobile = isMobile || dims.width < (props.paperWidth ?? 300 + 64)

    if (props.isLoading && !props.errMsg) {
        return <LoadingScreen sx={{
            bgcolor: showMobile ? "background.default" : "#001B3A",
        }}/>
    }

    const errComponent = props.errMsg ? <Alert severity={"error"} sx={{
        ...(!isMobile && {})

    }}>
        <AlertTitle>{props.errMsg.title}</AlertTitle>
        {props.errMsg.body}
    </Alert> : null

    if (showMobile) {
        return (
            <Box sx={{
                width: "100%",
                height: "100%",
                overflow: "auto",
                ...props.sxMobile
            }}>
                {errComponent ?? props.children}
            </Box>
        )
    }
    return (
        <Box sx={{
            width: "100%",
            height: "100%",
            overflow: "auto",
            bgcolor: primaryDarkBackground, // "#003673" // "black" //"#001B3A"
        }}>
            <Column
                mainAxisAlignment={"center"}
                crossAxisAlignment={"center"}
                sx={{
                    minHeight: "100%",
                    minWidth: "100%",
                    height: "fit-content",
                    py: 4,
                }}
            >
                {
                    errComponent ??
                    <Paper
                        sx={{
                            width: props.paperWidth ?? 300,
                            ...props.sxPaper
                        }}
                        // variant={"outlined"}
                    >
                        {props.children}
                    </Paper>
                }

            </Column>
        </Box>
    )
}
