import {Column} from "../../../../components/motion_mui";
import {AppFormModal} from "./appFormModal";
import React, {useMemo, useState} from "react";
import {Button, Grid, MenuItem, Typography} from "@mui/material";
import {selectDiscunaAppsByPublisherId} from "../communities/apps/discunaAppSlice";
import {useSelector} from "react-redux";
import {RootState} from "../../../store";
import {AppCard} from "../communities/apps/appCard";
import {createFirstPartyApps, deleteApp} from "../../../../firebase/discunaApi";
import {AppStoreItemFormModal} from "./appStoreItemFormModal";
import {AppLicensesModal} from "./appLicensesModal";
import {AppConfigUpdateModal} from "./appConfigUpdateModal";
import {DeleteAppModal} from "./deleteAppModal";


export function DevApps() {

    const auth = useSelector((state: RootState) => state.auth)
    if (!auth.isSignedIn) throw Error("unknown")

    const [showAppForm, setShowAppForm] = useState(false)
    const [showAppStorePageForm, setShowAppStorePageForm] = useState(false)
    const [showAppLicensesForm, setShowAppLicensesForm] = useState(false)
    const [showAppConfigUpdateForm, setShowAppConfigUpdateForm] = useState(false)
    const [showDeleteForm, setShowDeleteForm] = useState(false)

    const [menuAppId, setMenuAppId] = useState<string | null>(null)

    const selectDiscunaAppsByPublisherIdMemo = useMemo(selectDiscunaAppsByPublisherId, [])
    const devApps = useSelector((state: RootState) => selectDiscunaAppsByPublisherIdMemo(state, auth.uid))

    return (
        <Column mainAxisAlignment={"start"} crossAxisAlignment={"start"} sx={{
            width: "100%"
        }}>
            <Typography variant={"h4"} sx={{mb: 2, ml: 4}}>
                Your Apps
            </Typography>
            {
                (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') &&
                <Button sx={{
                    ml: 4,
                    mb: 1,
                }} variant={"outlined"} onClick={() => {
                    createFirstPartyApps({})
                }}>
                    create first party apps
                </Button>
            }

            <Button sx={{
                ml: 4,
                mb: 1,
            }} variant={"outlined"} onClick={() => {
                setShowAppForm(true)
            }}>
                add app
            </Button>
            <Grid container spacing={2} sx={{
                width: "100%",
                pl: 4,
                pr: 2,
                pt: 2,
            }}>
                {
                    devApps.map((app) => (
                        <Grid key={app.id} item xs={12} md={6} lg={4}>
                            <AppCard
                                name={app.details.config.name}
                                description={
                                    <>
                                        App ID: {app.id}<br/>
                                        Open Connect ID: {app.details.openIdClientId}<br/>
                                        Description: {app.details.config.description}
                                    </>

                                }
                                iconUrl={app.details.config.icons.app}
                                // onClick={() => {
                                //     // TODO
                                // }}
                                contextMenuItems={
                                    [
                                        <MenuItem key={"Info"} onClick={() => {
                                            setShowAppConfigUpdateForm(true)
                                            setMenuAppId(app.id)
                                        }}>
                                            Update Config
                                        </MenuItem>,
                                        <MenuItem key={"Publish"} onClick={() => {
                                            setShowAppStorePageForm(true)
                                            setMenuAppId(app.id)
                                        }}>
                                            App Store Page
                                        </MenuItem>,
                                        <MenuItem key={"Licenses"} onClick={() => {
                                            setShowAppLicensesForm(true)
                                            setMenuAppId(app.id)
                                        }}>
                                            Licenses
                                        </MenuItem>,
                                        <MenuItem key={"Delete"} onClick={() => {
                                            setShowDeleteForm(true)
                                            setMenuAppId(app.id)
                                        }}>
                                            Delete
                                        </MenuItem>
                                    ]
                                }
                            />
                        </Grid>
                    ))
                }
            </Grid>
            <AppFormModal onClose={() => setShowAppForm(false)} show={showAppForm}/>
            <AppConfigUpdateModal appId={menuAppId} show={showAppConfigUpdateForm}
                                  onClose={() => setShowAppConfigUpdateForm(false)}/>
            <DeleteAppModal appId={menuAppId} onClose={() => {
                setShowDeleteForm(false)
            }} show={showDeleteForm}/>
            <AppStoreItemFormModal
                key={"appStoreItem-" + menuAppId}
                onClose={() => {
                    setShowAppStorePageForm(false)
                }}
                show={showAppStorePageForm}
                appId={menuAppId ?? ""}
            />
            <AppLicensesModal
                key={"appLicences-" + menuAppId}
                onClose={() => {
                    setShowAppLicensesForm(false)
                }}
                show={showAppLicensesForm}
                appId={menuAppId ?? ""}
            />
        </Column>

    )
}
