import React, {useMemo, useState} from "react";
import {useSelector} from "react-redux";
import {RootState} from "../../../../store";
import {selectCommunityById} from "../communitySlice";
import {Grid, MenuItem, Typography} from "@mui/material";
import {selectDiscunaAppsByIDs} from "./discunaAppSlice";
import {AppCard} from "./appCard";
import {AssignLicensesModal} from "./assignLicensesModal";
import {RemoveAppFromCommunityModal} from "./removeAppFromCommunityModal";


export function AppsAdded({communityId}: {
    communityId: string
}) {

    const community = useSelector((state: RootState) => selectCommunityById(state, communityId))

    const [selectedAppId, setSelectedAppId] = useState<{
        appId: string,
        modal: "deletion" | "licenses"
    } | null>(null)

    // apps are loaded as soon as community is selected
    // => we only need to select apps
    const selectDiscunaAppsByIDsMemo = useMemo(selectDiscunaAppsByIDs, [])
    const apps = useSelector((state: RootState) => selectDiscunaAppsByIDsMemo(state, community?.details?.apps ?? []))

    // TODO sort apps

    return (
        <>
            <Typography variant={"h4"} sx={{ml: 4, mt: 4, mb: 1}}>
                Added Apps
            </Typography>
            {
                apps.length === 0 &&
                <Typography sx={{ml: 4}} variant={"body1"} color={"text.secondary"}>
                    No apps added
                </Typography>
            }
            <Grid container spacing={2} sx={{
                width: "100%",
                pl: 4,
                pr: 2,
                pt: 2,
            }}>
                {
                    apps.map((app) => (
                        <Grid key={app.id} item xs={12} md={6} lg={4}>
                            <AppCard
                                name={app.details.config.name}
                                description={app.details.config.description}
                                iconUrl={app.details.config.icons.app}
                                // onClick={() => {
                                //     setSelectedAppId(app.id)
                                // }}
                                contextMenuItems={[
                                    <MenuItem key={"Licenses"} onClick={() => {
                                        setSelectedAppId({appId: app.id, modal: "licenses"})
                                    }}>
                                        Assign Licenses
                                    </MenuItem>,
                                    // <MenuItem key={"Publish"} onClick={() => {
                                    // }}>
                                    //     Add-ons
                                    // </MenuItem>,
                                    // <MenuItem key={"Info"} onClick={() => {
                                    // }}>
                                    //     Usage
                                    // </MenuItem>,
                                    <MenuItem key={"Delete"} onClick={() => {
                                        setSelectedAppId({appId: app.id, modal: "deletion"})
                                    }}>
                                        Remove
                                    </MenuItem>
                                ]}
                            />
                        </Grid>
                    ))
                }
            </Grid>
            <AssignLicensesModal
                onClose={() => setSelectedAppId(null)}
                appId={selectedAppId?.appId ?? null}
                show={selectedAppId ? selectedAppId.modal === "licenses" : false}
                communityId={communityId}
            />
            <RemoveAppFromCommunityModal
                appId={selectedAppId?.appId ?? null}
                show={selectedAppId ? selectedAppId.modal === "deletion" : false}
                communityId={communityId}
                onClose={() => setSelectedAppId(null)}
            />
        </>
    )
}



