import {useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../../store";
import {getMemberId, selectMemberById, selectMembersByRole, upsertMember} from "../members/memberSlice";
import {
    Box,
    Card,
    CardContent,
    CardHeader, CircularProgress,
    Fab,
    Grid,
    IconButton,
    Menu,
    MenuItem,
    Skeleton,
    Typography
} from "@mui/material";
import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {Center, Column, Row} from "components/motion_mui";
import {selectCommunityById} from "../communitySlice";
import {darkGrey} from "../../../../../constants/constants";
import {useMemberInsights} from "../members/memberInsightsUtils";
import {loadMember, useMembersWithRole} from "../members/memberUtils";
import {Member} from "../members/memberInterfaces";
import {selectMemberInsightsById} from "../members/memberInsightsSlice";
import {MemberFirestoreData} from "shared";
import {useInView} from "react-intersection-observer";
import debounce from "lodash.debounce";
import EditIcon from '@mui/icons-material/Edit';
import {EditCommunityInfoModal} from "./editCommunityInfoModal";
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import {CustomLoadingButton} from "../accessWrappers";
import {memberRoles} from "shared/communities/members/member";
import {deleteMember, updateMemberRole} from "../../../../../firebase/discunaApi";
import {ModalTitle, ModalWithPresence} from "../../../../../components/modal";

export function CommunityInfo() {
    const {communityId} = useParams<{ communityId: string }>()
    if (!communityId) throw Error("communityId missing")
    // 1 activity / impact
    // 2 actions

    // Card
    // role: none | moderator | administrator
    // member: since
    // reward:

    // fetch
    const adminsAreLoading = useMembersWithRole(communityId, "administrator")
    const moderatorsAreLoading = useMembersWithRole(communityId, "moderator")

    // select
    const auth = useSelector((state: RootState) => state.auth)

    const community = useSelector((state: RootState) => selectCommunityById(state, communityId))
    const member = useSelector((state: RootState) => selectMemberById(state, getMemberId(communityId)))

    const selectMembersByRoleMemo = useMemo(selectMembersByRole, [])
    const administrators = useSelector((state: RootState) => selectMembersByRoleMemo(state, "administrator", communityId))
    const moderators = useSelector((state: RootState) => selectMembersByRoleMemo(state, "moderator", communityId))

    const membersWithRole = useMemo(() => {
        const membersWithRoleList = administrators.map((admin) => admin.userId)
            .concat(moderators.map((moderator) => moderator.userId))
        return new Set(membersWithRoleList)
    }, [administrators, moderators])


    const [showEditForm, setShowEditForm] = useState(false)

    const isAdmin = member?.role === "administrator"

    const memberInsightsAreLoading = useMemberInsights(communityId)

    const memberInsights = useSelector((state: RootState) => selectMemberInsightsById(state, communityId))

    const members = useMemo(() => {
        let members: { id: string, name: string | null, role: memberRoles }[] = []
        if (memberInsights) {
            const membersRecord = memberInsights.details.members
            const memberIds = Object.keys(membersRecord)
                .filter(id => !membersWithRole.has(id))
            memberIds.sort((a, b) => {
                const nameA = membersRecord[a]
                const nameB = membersRecord[b]
                if (!nameA) return 1
                if (!nameB) return -1
                return nameA < nameB ? -1 : 1
            })
            members = memberIds.map((id) => ({
                id,
                name: membersRecord[id],
                role: null
            }))
        }
        return members
    }, [membersWithRole, memberInsights])

    if (!community) return (
        <Column
            sx={{
                width: "100%",
                flex: 1,
                maxHeight: "100%",
                overflowY: "auto"
            }}
            mainAxisAlignment={"start"}
            crossAxisAlignment={"start"}
        >
        </Column>
    )

    const showMembers = (!community.details.hideMembers ||
        (member?.role === "administrator" || member?.role === "moderator"))

    return (
        <Column
            sx={{
                width: "100%",
                flex: 1,
                maxHeight: "100%",
                overflowY: "auto"
            }}
            mainAxisAlignment={"start"}
            crossAxisAlignment={"start"}
        >
            <Column
                mainAxisAlignment={"start"}
                crossAxisAlignment={"start"}
                sx={{
                    height: "fit-content",
                    width: "100%",
                    maxWidth: "100%",
                    mb: 4
                }}
            >

                <Column sx={{mx: 4, mt: 4}} mainAxisAlignment={"start"} crossAxisAlignment={"start"}>
                    <Typography variant={"h1"}>
                        {community.details.name}
                    </Typography>
                    <Typography variant={"body1"} sx={{mt: 1, color: darkGrey}}>
                        {community.details.shortDescription}
                    </Typography>
                </Column>

                <Column sx={{mx: 4, mt: 4}} mainAxisAlignment={"start"} crossAxisAlignment={"start"}>
                    <Typography variant={"h4"}>
                        Details
                    </Typography>
                    <Column sx={{mt: 1}} mainAxisAlignment={"start"} crossAxisAlignment={"start"}>
                        <Typography sx={{color: darkGrey}} variant={"body1"}>
                            Created on: {new Date(community.createdOn).toDateString()}
                        </Typography>
                        <Typography sx={{color: darkGrey}} variant={"body1"}>
                            Member statistics:
                            <ul style={{margin: 0}}>
                                <li>{roleWithCount("administrator", adminsAreLoading ? null : administrators.length)}</li>
                                <li>{roleWithCount("moderator", moderatorsAreLoading ? null : moderators.length)}</li>
                                {showMembers &&
                                    <li>{roleWithCount("member", memberInsightsAreLoading ? null : members.length)}</li>}
                            </ul>
                        </Typography>
                    </Column>
                </Column>

                <MemberList isAdmin={isAdmin} title={"Administrators"} communityId={communityId} members={
                    administrators.map((admin) => ({id: admin.userId, name: admin.details.name, role: "administrator"}))
                }/>
                <MemberList isAdmin={isAdmin} title={"Moderators"} communityId={communityId} members={
                    moderators.map((moderator) => ({
                        id: moderator.userId,
                        name: moderator.details.name,
                        role: "moderator"
                    }))
                }/>
                {
                    showMembers &&
                    <MemberList title={"Members"} communityId={communityId} members={members} isAdmin={isAdmin}/>

                }
            </Column>


            {
                member && member.role === "administrator" &&
                <Fab
                    color={"primary"}
                    sx={{
                        position: "fixed",
                        right: 32,
                        bottom: 32,
                        transform: `scale(${showEditForm ? 0 : 1})`,
                        opacity: showEditForm ? 0 : 1,
                        transition: "300ms"
                    }}
                    onClick={() => setShowEditForm(true)}
                >
                    <EditIcon/>
                </Fab>
            }


            <EditCommunityInfoModal
                communityId={communityId}
                onClose={() => {
                    setShowEditForm(false)
                }}
                show={showEditForm}
            />

        </Column>
    )
}


function roleWithCount(role: string, number: number | null) {
    let roleWithCount = `${number === null ? "(loading...)" : number} ${role}`
    if (number !== 1) roleWithCount += "s"
    return roleWithCount
}


// function MembersWithoutRoleList({communityId, membersWithRole, isAdmin}: {
//     communityId: string,
//     membersWithRole: Set<string>,
//     isAdmin?: boolean
// }) {
//     const memberInsightsAreLoading = useMemberInsights(communityId)
//
//     const memberInsights = useSelector((state: RootState) => selectMemberInsightsById(state, communityId))
//
//     const members = useMemo(() => {
//         let members: { id: string, name: string | null, role: memberRoles }[] = []
//         if (memberInsights) {
//             const membersRecord = memberInsights.details.members
//             const memberIds = Object.keys(membersRecord)
//                 .filter(id => !membersWithRole.has(id))
//             memberIds.sort((a, b) => {
//                 const nameA = membersRecord[a]
//                 const nameB = membersRecord[b]
//                 if (!nameA) return 1
//                 if (!nameB) return -1
//                 return nameA < nameB ? -1 : 1
//             })
//             members = memberIds.map((id) => ({
//                 id,
//                 name: membersRecord[id],
//                 role: null
//             }))
//         }
//         return members
//     }, [membersWithRole, memberInsights])
//
//     return (
//         <MemberList title={"Members"} communityId={communityId} members={members} isAdmin={isAdmin}/>
//     )
// }

const CARD_MARGIN = 1
const CARD_WIDTH = 300

function MemberList(props: {
    members: { id: string, name: string | null, role: memberRoles }[],
    communityId: string,
    title: string,
    isAdmin?: boolean,
}) {
    // ugly but only way I found
    // const margin = CARD_MARGIN * 8
    // const minWidth = Math.ceil(props.members.length / 3) * (CARD_WIDTH + 2 * margin) + 2 * margin

    return (
        <>
            <Typography sx={{mx: 4, mt: 4}} variant={"h4"}>
                {props.title}
            </Typography>
            <Row
                className={"qanda-hideScrollbar"}
                mainAxisAlignment={"start"}
                crossAxisAlignment={"start"}
                sx={{
                    minWidth: "100%",
                    maxWidth: "100%",
                    // overflowX: "auto"
                }}
            >
                {
                    (props.members.length === 0) &&
                    <Typography variant={"body2"} color="text.secondary"
                                sx={{mt: 1, mx: 4, textTransform: "lowercase", width: "100%"}}>
                        No {props.title}
                    </Typography>
                }
                <Grid container spacing={2} sx={{
                    width: "100%",
                    // bgcolor: "red",
                    mr: 4,
                    ml: 2,
                    mt: 0
                }}>
                    {
                        props.members.map((member, i) =>
                            <MemberCard
                                key={member.id}
                                idx={i}
                                communityId={props.communityId}
                                memberId={member.id}
                                name={member.name}
                                isAdmin={props.isAdmin}
                                role={member.role}
                            />
                        )
                    }
                </Grid>
            </Row>
        </>
    )
}


function MemberCard({communityId, memberId, name, idx, isAdmin, role}: {
    communityId: string,
    memberId: string,
    name: string | null,
    idx: number,
    isAdmin?: boolean,
    role: "administrator" | "moderator" | null
}) {
    // rewards
    const reduxMemberId = useMemo(() => `${communityId}_${memberId}`, [communityId, memberId])
    const member = useSelector((state: RootState) => selectMemberById(state, reduxMemberId))
    const dispatch = useDispatch()

    const [contextMenuProcessing, setContextMenuProcessing] = useState(false)
    const [contextMenuAnchorEl, setContextMenuAnchorEl] = useState<null | EventTarget & HTMLElement>(null);
    const [showRemoveMemberDialog, setShowRemoveMemberDialog] = useState(false)

    const updateMemberRoleCallback = useCallback(async (role: "moderator" | null) => {
        setContextMenuProcessing(true)
        await updateMemberRole({communityId, memberId, role})
        setContextMenuAnchorEl(null)
        setContextMenuProcessing(false)
    }, [communityId, memberId])

    const {ref, inView} = useInView({
        // triggerOnce: true
    });

    const fetched = useRef(false)
    // const [loaded, setIsLoaded] = useState(false)

    // fetch member
    const fetchMember = useCallback(async (inView: boolean) => {
        if (inView && !fetched.current) {
            fetched.current = true
            // setIsLoaded(false)
            const doc = await loadMember({communityId, userId: memberId})
            if (doc.exists) {
                const member = {
                    id: reduxMemberId,
                    communityId,
                    userId: memberId,
                    isSynced: true,
                    ...(doc.data() as MemberFirestoreData)
                } as Member
                dispatch(upsertMember(member))
            }
            // setIsLoaded(true)
        }
    }, [communityId, dispatch, reduxMemberId, memberId])

    const fetchMemberDebounced = useMemo((() => debounce(fetchMember, 300)), [fetchMember])

    useEffect(() => {
        fetchMemberDebounced(inView)
    }, [inView, fetchMemberDebounced])

    return (
        <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
            <Card
                // key={forwardKey}
                sx={{width: "100%", boxSizing: "border-box"}}
                variant={"outlined"}
                ref={ref}
            >
                <CardHeader
                    title={name ?? "Incognito"}
                    sx={{mb: 0, pb: 0}}
                    titleTypographyProps={{
                        color: "black"
                    }}
                    action={
                        isAdmin && role !== "administrator" &&
                        <IconButton size={"small"} onClick={(event) => {
                            setContextMenuAnchorEl(event.currentTarget)
                        }}>
                            <MoreVertOutlinedIcon/>
                        </IconButton>
                    }
                />
                {/*<Typography variant="h4" sx={{pt: 2, px: 2}}>*/}
                {/*    {name ?? "Incognito"}*/}
                {/*</Typography>*/}
                <CardContent>
                    {
                        !member && // (!loaded || !member)
                        <>
                            <Skeleton>
                                <Typography variant="body2" color="text.secondary">
                                    this is some sample text
                                </Typography>
                            </Skeleton>
                        </>
                    }
                    {
                        member && // && loaded
                        <>
                            <Typography variant="body2" color="text.secondary">
                                Member since: {new Date(member.joinedOn).toDateString()}
                            </Typography>
                            {/*<Typography variant="body2" color="text.secondary">*/}
                            {/*    Reward: {member.totalReward}*/}
                            {/*</Typography>*/}
                        </>
                    }
                </CardContent>
            </Card>
            <Menu
                id="member-context-menu"
                anchorEl={contextMenuAnchorEl}
                open={Boolean(contextMenuAnchorEl)}
                onClose={() => setContextMenuAnchorEl(null)}
            >

                <Box sx={{
                    position: "relative"
                }}>
                    {
                        contextMenuProcessing &&
                        <Box>
                            <Center sx={{position: "absolute", width: "100%", height: "100%"}}>
                                <CircularProgress size={24}/>
                            </Center>
                        </Box>
                    }
                    <MenuItem disabled={contextMenuProcessing} onClick={() => {
                        // TODO add proper error handling
                        updateMemberRoleCallback(role === null ? "moderator" : null).catch(console.error)
                    }}>
                        {
                            role === null ?
                                "Add moderator privileges" :
                                "Remove moderator privileges"
                        }
                    </MenuItem>
                    <MenuItem disabled={contextMenuProcessing} onClick={() => {
                        setContextMenuAnchorEl(null)
                        setShowRemoveMemberDialog(true)
                    }}>
                        Delete
                    </MenuItem>
                </Box>
            </Menu>
            <DeleteMemberConfirmationDialog
                communityId={communityId}
                memberId={memberId}
                show={showRemoveMemberDialog}
                onClose={() => setShowRemoveMemberDialog(false)}
                memberName={name ?? "Incognito"}
            />
        </Grid>
    )
}

function DeleteMemberConfirmationDialog({communityId, memberId, show, onClose, memberName}: {
    communityId: string,
    memberId: string,
    show: boolean,
    onClose: () => void,
    memberName: string
}) {
    const [progress, setProgress] = useState<null | "loading">(null)

    // TODO add error handling
    const deleteMemberCallback = useCallback(async () => {
        setProgress("loading")
        await deleteMember({communityId, memberId})
        onClose()
    }, [communityId, memberId, onClose])

    return (
        <ModalWithPresence show={show} onCancel={onClose}>
            <Column mainAxisAlignment={"start"} crossAxisAlignment={"stretch"}>
                <ModalTitle title={"Confirm member removal"} onCancel={onClose}/>
                <Typography variant={"body1"} sx={{mb: 2}}>
                    Are you sure you want to remove {memberName} from the community?
                </Typography>
                <CustomLoadingButton isLoading={progress === "loading"} variant={"contained"} sx={{
                    alignSelf: "end"
                }} onClick={deleteMemberCallback}>
                    Confirm
                </CustomLoadingButton>
            </Column>
        </ModalWithPresence>
    )
}


