import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../store";
import {useEffect} from "react";
import {firestore} from "../../firebase/firebase";
import {firestoreManager} from "../../firebase/queryManager";
import {setUserSecret} from "./userSecretsSlice";
import {UserSecretsFirestoreData} from "shared/user/userSecrets";
import useMemoizedState from "../../hooks/useMemoizedState";

export function useUserSecrets() {
    const auth = useSelector((state: RootState) => state.auth)
    const dispatch = useDispatch()
    const [isLoading, setIsLoading] = useMemoizedState(false)
    useEffect(() => {
        if (auth.isSignedIn) {
            // query
            const userSecretRef = firestore.collection("userSecrets").doc(auth.uid)
            const {isNew, cancel} = firestoreManager.queryDocumentSnapshots(
                userSecretRef,
                (doc) => {
                    setIsLoading(false)
                    if (doc.exists) {
                        const userSecretData = doc.data() as UserSecretsFirestoreData
                        dispatch(setUserSecret(userSecretData))
                    } else {
                        // TODO what to do? just wait?
                        //  should work with handleSignInLink
                    }
                })
            setIsLoading(isNew)
            return () => {
                cancel()
            }
        }
    }, [dispatch, setIsLoading, auth])
    return isLoading
}

// ==========================
// fetch user data
// ==========================


// export function useUserData() {
//     const userId = useSelector((state: RootState) => state.user.id)
//     const signedIn = useSelector((state: RootState) => state.user.signedIn)
//     const libraryList = useSelector((state: RootState) => state.user.libraryList)
//     const libraryListRef = useRef(libraryList)
//     libraryListRef.current = libraryList
//     const dispatch = useDispatch()
//
//     // TODO this should go to a place that works both for iframe and dashboard
//     // get user data
//     useEffect(() => {
//         if (signedIn) {
//             // query
//             const docRef = firestore.collection("users")
//                 .doc(userId)
//             const cancel = firestoreManager.queryDocument(docRef,
//                 doc => {
//                     let userData = doc.data() as UserData
//                     const libraryList = userData.libraryList
//                     libraryList.forEach((item) => {
//                         item.isSynced = true
//                         const res = getNavItemById(libraryListRef.current, item.id)
//                         if (res) {
//                             item.open = res.item.open
//                         }
//                     })
//                     userData = {
//                         ...userData,
//                         libraryList,
//                         isSynced: true
//                     }
//                     dispatch(setUserData(userData))
//                 },
//                 doc => {
//                     let userData = doc.data() as UserData
//                     console.log("userData", userData)
//                     const libraryList = userData.libraryList
//                     libraryList.forEach((item) => {
//                         item.isSynced = true
//                         const res = getNavItemById(libraryListRef.current, item.id)
//                         if (res) {
//                             item.open = res.item.open
//                         }
//                     })
//                     userData = {
//                         ...userData,
//                         libraryList,
//                         isSynced: true
//                     }
//                     dispatch(setUserData(userData))
//                 })
//         } else {
//             // cancel all subscriptions
//             firestoreManager.cancelAll()
//         }
//     }, [signedIn, userId])
//     // })
// }


// ==========================
// nav library
// ==========================

// export function getNavItemById(libraryList: NavLibItem[], id: string) {
//     let i = 0;
//     for (const item of libraryList) {
//         if (item.id === id) return {i, item};
//         i++;
//     }
//     return undefined
// }
//
// export function navLibItemToNavLibItemData(item: NavLibItem) {
//     return {
//         id: item.id,
//         name: item.name,
//         level: item.level,
//         type: item.type,
//         ...(item.fileId) && {fileId: item.fileId},
//         ...(item.filepath) && {filepath: item.filepath},
//         ...(item.url) && {url: item.url}
//     } as NavLibItemData
// }
//
//
// export function getDirectChildNavLibItemsFromIndex(
//     libraryList: NavLibItem[],
//     parentFolderIdx?: number
// ) {
//     let navLibItems = [] as NavLibItemWithIdx[]
//     let childLevel = 0
//     if (parentFolderIdx !== undefined) {
//         childLevel = libraryList[parentFolderIdx].level + 1
//     }
//     const childStartIndex = parentFolderIdx !== undefined ? parentFolderIdx + 1 : 0
//     for (let i = childStartIndex; i < libraryList.length; ++i) {
//         if (libraryList[i].level < childLevel) break
//         if (libraryList[i].level === childLevel) navLibItems.push({
//             ...libraryList[i],
//             idx: i,
//         })
//     }
//     return navLibItems
// }
//
//
// export function insertNavLibItemInPlaceAtIndex(
//     libraryList: NavLibItem[],
//     navLibItem: NavLibItem,
//     parentFolderIdx: number | undefined
// ) {
//     const childIndex = (parentFolderIdx !== undefined) ? parentFolderIdx + 1 : 0
//     libraryList.splice(childIndex, 0, navLibItem)
//     return childIndex
// }
//
// /**
//  * returns same index as parent if folder has no children
//  * if libraryList has zero length it returns -1
//  *
//  * @param libraryList
//  * @param parentFolderIdx
//  */
// function getIndexOfLastChild(
//     libraryList: NavLibItem[],
//     parentFolderIdx: number | undefined
// ) {
//     if (parentFolderIdx === undefined) return libraryList.length - 1
//     const parentLevel = libraryList[parentFolderIdx].level
//     let lastChildIndex = parentFolderIdx
//     while (lastChildIndex < libraryList.length - 1) {
//         if (libraryList[lastChildIndex + 1].level > parentLevel) lastChildIndex++
//         else break
//     }
//     return lastChildIndex
// }
//
// export function addChildToNavLibraryInPlace(
//     libraryList: NavLibItem[],
//     navLibItem: NavLibItem,
//     parentFolderIdx: number | undefined
// ) {
//     // const childIndex = (parentFolderIdx !== undefined) ? parentFolderIdx + 1 : 0
//     const lastChildIndex = getIndexOfLastChild(libraryList, parentFolderIdx)
//     const childIndex = lastChildIndex + 1
//     libraryList.splice(childIndex, 0, navLibItem)
//     return childIndex
// }
