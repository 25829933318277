import {ModalTitle, ModalWithPresence} from "../../../../components/modal";
import {useSelector} from "react-redux";
import {RootState} from "../../../store";
import {selectDiscunaAppById} from "../communities/apps/discunaAppSlice";
import {Alert, Button, CircularProgress} from "@mui/material";
import {Center, Row} from "../../../../components/motion_mui";
import {useCallback, useState} from "react";
import {deleteApp} from "../../../../firebase/discunaApi";
import {circularProgressSize} from "../../../../constants/constants";


export function DeleteAppModal(props: {
    appId: string | null,
    onClose: () => void,
    show: boolean
}) {
    return (
        <ModalWithPresence show={props.show} onCancel={props.onClose}>
            {
                props.show && props.appId &&
                <DeleteAppForm
                    appId={props.appId}
                    onClose={props.onClose}
                />
            }
        </ModalWithPresence>
    )
}


function DeleteAppForm(props: {
    appId: string,
    onClose: () => void
}) {

    const app = useSelector((state: RootState) => selectDiscunaAppById(state, props.appId))

    const [status, setStatus] = useState<null | "loading" | "success" | string>(null)

    const submitDelete = useCallback(() => {
        setStatus("loading")
        deleteApp({appId: props.appId}).then(() => {
            props.onClose()
        }).catch((e) => {
            setStatus(e.message)
        })
    }, [props.appId])

    if (status === "success") {
        <>
            <ModalTitle title={`Delete ${app?.details.config.name} App`} onCancel={props.onClose}/>
            <Alert severity={"success"}>
                <b>{app?.details.config.name}</b> app deleted successfully!
            </Alert>
        </>
    }

    if (status === "loading") {
        <>
            <ModalTitle title={`Delete ${app?.details.config.name} App`} onCancel={props.onClose}/>
            <Center>
                <CircularProgress size={circularProgressSize}/>
            </Center>
        </>
    }

    if (status !== null) {
        <>
            <ModalTitle title={`Delete ${app?.details.config.name} App`} onCancel={props.onClose}/>
            <Alert severity={"error"}>
                Error: {status}
            </Alert>
        </>
    }

    if (status === null) {
        return (
            <>
                <ModalTitle title={`Delete ${app?.details.config.name} App`} onCancel={props.onClose}/>
                <Alert severity={"warning"}>
                    Are you sure to delete your <b>{app?.details.config.name}</b> app?
                </Alert>
                <Row sx={{mt: 2}} mainAxisAlignment={"spaceBetween"} crossAxisAlignment={"start"}>
                    <Button onClick={props.onClose}>
                        Cancel
                    </Button>
                    <Button variant={"contained"} onClick={submitDelete}>
                        Delete
                    </Button>
                </Row>

            </>
        )
    }

    return null

}
