import {Center} from "../../../../../../components/motion_mui";
import {ListItemButton, ListItemText} from "@mui/material";
import React from "react";

export function PersistentItem(props: {
    disabled: boolean,
    icon: JSX.Element,
    name: string,
    id: string,
    onClick: () => void,
    isSelected: boolean,
}) {
    return (
        <ListItemButton
            id={"nli-" + props.id}
            key={"nli-" + props.id}
            itemID={"nli-" + props.id}
            sx={{
                // TODO why do we need width and minWidth props?
                width: "fit-content",
                minWidth: "100%",
                paddingLeft: "24px !important",
            }}
            selected={props.isSelected}
            disabled={props.disabled}
            onClick={props.onClick}
        >
            <Center
                // List item icon
                style={{
                    width: 24,
                    height: 24,
                    ...(props.disabled) && {
                        pointerEvents: "none"
                    }
                }}
            >
                {
                    props.icon
                }
            </Center>

            <ListItemText
                sx={{
                    ml: "6px",
                    ...(props.disabled) && {
                        pointerEvents: "none"
                    },
                    whiteSpace: "nowrap"
                }}
                primary={props.name}
            />
        </ListItemButton>
    )
}