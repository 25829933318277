import {ModalTitle, ModalWithPresence} from "../../../../components/modal";
import React, {useCallback, useMemo, useState} from "react";
import {Button, Card, CircularProgress, IconButton, SxProps, TextField, Tooltip, Typography} from "@mui/material";
import {Center, Column, Row} from "../../../../components/motion_mui";
import {createAppLicense, deleteAppLicense, generateId, updateAppLicense} from "../../../../firebase/discunaApi";
import {circularProgressSize} from "../../../../constants/constants";
import {useAppLicenses} from "../communities/apps/discunaAppLicenseUtils";
import {selectDiscunaAppLicenseById, selectDiscunaAppLicensesByAppId} from "../communities/apps/discunaAppLicenseSlice";
import {useSelector} from "react-redux";
import {RootState} from "../../../store";
import {AppLicense} from "../communities/apps/discunaAppInterface";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import {LicenseTypes} from "shared/apps/appLicense";
import {AppLicenseFirestoreData} from "shared";


export function AppLicensesModal({show, appId, onClose}: {
    show: boolean,
    onClose: () => void,
    appId: string
}) {
    return (
        <ModalWithPresence show={show} onCancel={onClose}>
            <AppLicenses appId={appId} onClose={onClose}/>
        </ModalWithPresence>
    )
}


function AppLicenses({appId, onClose}: {
    appId: string,
    onClose: () => void
}) {

    // load app licenses
    useAppLicenses(appId)
    const selectAppLicensesMemo = useMemo(selectDiscunaAppLicensesByAppId, [])
    const appLicenses = useSelector((state: RootState) => selectAppLicensesMemo(state, appId))
    const [licenses0, licenses1] = useMemo(() => {
        return [
            appLicenses.filter((appLicense) => appLicense.details.license.type === 0),
            appLicenses.filter((appLicense) => appLicense.details.license.type === 1)
        ]
    }, [appLicenses])

    const [status, setStatus] = useState<null | "add0">(null)


    return (
        <>
            <ModalTitle title={"App Licenses"} onCancel={onClose}/>
            <Typography variant={"h6"} sx={{mb: 1}}>Member Auto Renew Subscriptions</Typography>
            {
                licenses0.map((appLicense) => {
                    return (
                        <LicenseItem key={appLicense.id} appLicense={appLicense} sx={{mb: 2}}/>
                    )
                })
            }
            {
                status === "add0" ?
                    <LicenseForm appId={appId} type={0} onClose={() => {
                        setStatus(null)
                    }}/> :
                    <Button size={"small"} sx={{alignSelf: "start"}} variant={"outlined"} onClick={() => setStatus("add0")}>
                        Add
                    </Button>
            }

            {/*<Typography variant={"h6"} sx={{mb: 1, mt: 3}}>Workspace Non Consumables</Typography>*/}
            {/*{*/}
            {/*    licenses1.map((appLicense) => {*/}
            {/*        return (*/}
            {/*            <LicenseItem key={appLicense.id} appLicense={appLicense} sx={{mb: 2}}/>*/}
            {/*        )*/}
            {/*    })*/}
            {/*}*/}
            {/*<LicenseForm appId={appId} type={1}/>*/}
        </>
    )
}

function LicenseItem({appLicense, sx}: {
    appLicense: AppLicense
    sx?: SxProps
}) {

    const [status, setStatus] = useState<null | "loading" | "edit">(null)

    const onDelete = useCallback(() => {
        setStatus("loading")
        deleteAppLicense({
            appId: appLicense.appId,
            licenseId: appLicense.licenseId
        }).then(() => {
        }).catch((e) => {
            console.error("failed to delete app license", e)
        }).finally(() => {
            setStatus(null)
        })
    }, [appLicense.appId, appLicense.licenseId])


    if (status === "loading") {
        return (
            <Card variant={"outlined"} sx={{p: 2, ...sx}}>
                <Center>
                    <CircularProgress size={circularProgressSize}/>
                </Center>
            </Card>
        )
    }

    if (status === "edit") {
        return (
            <LicenseForm
                sx={sx}
                appId={appLicense.appId}
                type={LicenseTypes.MemberAutoRenewSubscription}
                appLicense={appLicense}
                onClose={() => {
                    setStatus(null)
                }}
            />
        )
    }

    return (
        <Card variant={"outlined"} sx={{p: 2, ...sx}}>
            <Column mainAxisAlignment={"start"} crossAxisAlignment={"start"} sx={{
                position: "relative"
            }}>
                <Row mainAxisAlignment={"start"} crossAxisAlignment={"start"} sx={{
                    position: "absolute",
                    top: 0,
                    right: 0
                }}>
                    <Tooltip title={"Edit License"}>
                        <IconButton size={"small"} sx={{ml: 1}} onClick={() => {
                            setStatus("edit")
                        }}>
                            <EditOutlinedIcon/>
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={"Delete License. Be careful!"}>
                        <IconButton size={"small"} onClick={onDelete}>
                            <DeleteOutlineOutlinedIcon color={"warning"}/>
                        </IconButton>
                    </Tooltip>
                </Row>
                <Typography variant={"h6"}>{appLicense.details.title}</Typography>
                <Typography variant={"subtitle2"}>{appLicense.details.subTitle}</Typography>
                <Typography variant={"body2"}>{appLicense.details.description}</Typography>
                <Typography variant={"body2"}>License ID: {appLicense.licenseId}</Typography>
                <Typography variant={"body2"}>Price: {appLicense.details.license.price}</Typography>
                {
                    appLicense.details.license.type === 0 &&
                    <Typography variant={"body2"}>Level: {appLicense.details.license.level}</Typography>
                }
                {
                    appLicense.details.license.type === 1 &&
                    <Typography variant={"body2"}>Max Quantity: {appLicense.details.license.maxQuantity}</Typography>
                }
            </Column>
        </Card>
    )
}

function LicenseForm({appId, type, appLicense, sx, onClose}: {
    appId: string,
    // type 0 => member auto-renew subscription, type 1 => workspace non-consumable
    type: 0 | 1,
    appLicense?: AppLicense
    onClose: () => void,
    sx?: SxProps
}) {

    const [title, setTitle] = useState(appLicense?.details.title ?? "")
    const [subTitle, setSubTitle] = useState(appLicense?.details.subTitle ?? "")
    const [description, setDescription] = useState(appLicense?.details.description ?? "")
    const [price, setPrice] = useState(
        (appLicense?.details.license.type === LicenseTypes.MemberAutoRenewSubscription ?
                `${appLicense?.details.license.price}` :
                ""
        ) ?? ""
    )
    // for now level and maxQuantity is the only difference between type 0 and 1
    const [level, setLevel] = useState(
        (appLicense?.details.license.type === LicenseTypes.MemberAutoRenewSubscription ?
                `${appLicense?.details.license.level}` :
                ""
        ) ?? ""
    )
    // TODO
    const [maxQuantity, setMaxQuantity] = useState("")

    const [loading, setLoading] = useState(false)

    const reset = useCallback(() => {
        setTitle("")
        setSubTitle("")
        setDescription("")
        setPrice("")
        setLevel("")
        setMaxQuantity("")
        setLoading(false)
    }, [])

    const onUpdate = useCallback(() => {
        if (!appLicense) return
        setLoading(true)
        updateAppLicense({
            appId,
            licenseId: appLicense.licenseId ?? "",
            title,
            subTitle,
            description,
            license: type === 0 ? {
                type: 0,
                price: parseFloat(price),
                level: parseInt(level),
            } : {
                type: 1,
                price: parseFloat(price),
                maxQuantity: parseInt(maxQuantity)
            }
        }).then(() => {
            onClose()
        }).catch((error) => {
            console.error(error.message)
            onClose()
        })
    }, [appId, description, level, maxQuantity, onClose, appLicense, price, subTitle, title, type])

    const onCreate = useCallback(() => {
        const licenseId = generateId()
        setLoading(true)
        createAppLicense({
            appId,
            licenseId,
            title,
            subTitle,
            description,
            license: type === 0 ? {
                type: 0,
                price: parseFloat(price),
                level: parseInt(level),
            } : {
                type: 1,
                price: parseFloat(price),
                maxQuantity: parseInt(maxQuantity)
            }
        }).then(() => {
            reset()
        }).catch((error) => {
            // TODO: show error
            reset()
            console.error(error.message)
        })
    }, [appId, title, subTitle, description, type, price, level, maxQuantity, reset])

    const enable = title.length > 0 && subTitle.length > 0 && description.length > 0 && (
        type === LicenseTypes.MemberAutoRenewSubscription ?
            (price.length > 0 && level.length > 0) :
            (maxQuantity.length > 0)
    )

    if (loading) {
        return (
            <Card variant={"outlined"} sx={{p: 2, ...sx}}>
                <Center>
                    <CircularProgress size={circularProgressSize}/>
                </Center>
            </Card>
        )
    }

    return (
        <Card variant={"outlined"} sx={sx}>
            <Column mainAxisAlignment={"start"} crossAxisAlignment={"stretch"} sx={{p: 2}}>
                <TextField
                    size={"small"}
                    label={"Title"}
                    variant={"filled"}
                    sx={{mb: 2}}
                    inputProps={{
                        maxLength: 30
                    }}
                    helperText={`${title.length} / 30`}
                    multiline={false}
                    value={title}
                    onChange={(e) => {
                        setTitle(e.target.value)
                    }}
                />
                <TextField
                    size={"small"}
                    label={"Subtitle"}
                    variant={"filled"}
                    sx={{mb: 2}}
                    inputProps={{
                        maxLength: 30
                    }}
                    helperText={`${subTitle.length} / 30`}
                    multiline={false}
                    value={subTitle}
                    onChange={(e) => {
                        setSubTitle(e.target.value)
                    }}
                />
                <TextField
                    size={"small"}
                    label={"Description"}
                    variant={"filled"}
                    sx={{mb: 2}}
                    inputProps={{
                        maxLength: 1000
                    }}
                    helperText={`${description.length} / 1000`}
                    multiline={true}
                    value={description}
                    onChange={(e) => {
                        setDescription(e.target.value)
                    }}
                />
                <TextField
                    // inputProps={{
                    //     inputMode: 'numeric',
                    //     pattern: '[0-9]*'
                    // }}
                    disabled={!!appLicense}
                    type={"number"}
                    size={"small"}
                    label={"Price"}
                    variant={"filled"}
                    sx={{mb: 2}}
                    value={price}
                    onChange={(e) => {
                        setPrice(e.target.value)
                    }}
                />
                {
                    type === 0 ? (
                        <TextField
                            type={"number"}
                            size={"small"}
                            label={"Level"}
                            variant={"filled"}
                            sx={{mb: 2}}
                            value={level}
                            onChange={(e) => {
                                setLevel(e.target.value)
                            }}
                        />
                    ) : (
                        <TextField
                            type={"number"}
                            size={"small"}
                            label={"Max Quantity"}
                            variant={"filled"}
                            sx={{mb: 2}}
                            value={maxQuantity}
                            onChange={(e) => {
                                setMaxQuantity(e.target.value)
                            }}
                        />
                    )
                }
                <Row mainAxisAlignment={"spaceBetween"} crossAxisAlignment={"start"}>
                    <Button onClick={onClose} size={"small"} sx={{alignSelf: "end"}}>
                        Cancel
                    </Button>
                    {
                        appLicense ?
                            <Button disabled={!enable} onClick={onUpdate} variant={"contained"} size={"small"}
                                    sx={{alignSelf: "end"}}>
                                Update
                            </Button> :
                            <Button disabled={!enable} onClick={onCreate} variant={"contained"} size={"small"}
                                    sx={{alignSelf: "end"}}>
                                Create
                            </Button>
                    }
                </Row>
            </Column>
        </Card>
    )
}
