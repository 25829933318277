import {compile, TypeOf, v} from "suretype";

// --------------------------
// getters
// --------------------------

const getChannelNameActionSchema = v.object({
    cmd: v.string().const("getChannelName").required()
})
export type GetChannelNameAction = TypeOf<typeof getChannelNameActionSchema>
export const isGetChannelNameAction = compile(getChannelNameActionSchema, {simple: true})

const getNotificationDataActionSchema = v.object({
    cmd: v.string().const("getNotificationData").required()
})
export type GetNotificationDataAction = TypeOf<typeof getNotificationDataActionSchema>
export const isGetNotificationDataAction = compile(getNotificationDataActionSchema, {simple: true})

const getMemberIdActionSchema = v.object({
    cmd: v.string().const("getMemberId").required()
})
export type GetMemberIdAction = TypeOf<typeof getMemberIdActionSchema>
export const isGetMemberIdAction = compile(getMemberIdActionSchema, {simple: true})


// --------------------------
// setters
// --------------------------

const setDisableCreateChannelButtonActionSchema = v.object({
    cmd: v.string().const("setDisableCreateChannelButton").required(),
    payload: v.object({
        disable: v.boolean().required()
    }).required()
})
export type SetDisableCreateChannelButtonAction = TypeOf<typeof setDisableCreateChannelButtonActionSchema>
export const isSetDisableCreateChannelButtonAction = compile(setDisableCreateChannelButtonActionSchema, {simple: true})


// --------------------------
// events
// --------------------------

const pageIsReadyActionSchema = v.object({
    cmd: v.string().const("pageIsReady").required()
})
export type PageIsReadyAction = TypeOf<typeof pageIsReadyActionSchema>
export const isPageIsReadyAction = compile(pageIsReadyActionSchema, {simple: true})
