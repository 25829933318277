import {ModalTitle, ModalWithPresence} from "../../../../../../../../components/modal";
import React, {useState} from "react";
import {AppPageCreateChannel} from "./appPageCreateChannel";
import {useSelector} from "react-redux";
import {RootState} from "../../../../../../../store";
import {selectDiscunaAppById} from "../../../../../communities/apps/discunaAppSlice";


export function AddDiscunaApp(props: {
    communityId: string,
    parentId: string | null,
    onClose: () => void,
    appId: string | null
}) {
    return (
        <ModalWithPresence show={!!props.appId} onCancel={props.onClose}>
            {
                props.appId &&
                <AddDiscunaAppContent {...props} appId={props.appId}/>
            }
        </ModalWithPresence>
    )
}

function AddDiscunaAppContent(
    {
        communityId,
        parentId,
        onClose,
        appId
    }: {
        communityId: string,
        parentId: string | null,
        onClose: () => void,
        appId: string,
    }
) {
    const app = useSelector((state: RootState) => selectDiscunaAppById(state, appId))
    if(!app) return null
    return (
        <>
            <ModalTitle title={`Add ${app.details.config.name} Channel`} onCancel={onClose}/>
            {
                appId &&
                <AppPageCreateChannel
                    communityId={communityId}
                    appId={appId}
                    parentId={parentId}
                    onClose={onClose}
                />
            }
        </>
    )
}





