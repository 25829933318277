import {MessageServer} from "./messageServer";
import {
    isPageIsReadyAction,
    isGetNotificationDataAction,
    isSetDisableCreateChannelButtonAction, isGetChannelNameAction, isGetMemberIdAction
} from "./discunaCoreFrontendApiArgs";


export class DiscunaCoreFrontendApiHost {

    messageServer: MessageServer

    constructor() {
        this.messageServer = new MessageServer()
        this.messageServer.start()
    }

    // --------------------------
    // getters
    // --------------------------

    // TODO define a type for the eventHandler return value
    onGetNotificationData(eventHandler: () => unknown, targetOrigin: string, targetWindow: Window) {
        return this.messageServer.setCmdHandler("getNotificationData", (event) => {
            const action = event.data.action
            if(isGetNotificationDataAction(action)) {
                return eventHandler()
            }
        }, targetOrigin, targetWindow)
    }

    onGetChannelName(eventHandler: () => string, targetOrigin: string, targetWindow: Window) {
        return this.messageServer.setCmdHandler("getChannelName", (event) => {
            const action = event.data.action
            if(isGetChannelNameAction(action)) {
                return eventHandler()
            }
        }, targetOrigin, targetWindow)
    }

    onGetMemberId(eventHandler: () => string, targetOrigin: string, targetWindow: Window) {
        return this.messageServer.setCmdHandler("getMemberId", (event) => {
            const action = event.data.action
            if(isGetMemberIdAction(action)) {
                return eventHandler()
            }
        }, targetOrigin, targetWindow)
    }

    // --------------------------
    // setters
    // --------------------------

    onSetDisableCreateChannelButton(eventHandler: (value: boolean) => void, targetOrigin: string, targetWindow: Window) {
        return this.messageServer.setCmdHandler("setDisableCreateChannelButton", (event) => {
            const action = event.data.action
            if(isSetDisableCreateChannelButtonAction(action)) {
                eventHandler(action.payload.disable)
            }
        }, targetOrigin, targetWindow)
    }

    // --------------------------
    // events
    // --------------------------

    onPageIsReady(eventHandler: () => void, targetOrigin: string, targetWindow: Window) {
        return this.messageServer.setCmdHandler("pageIsReady", (event) => {
            const action = event.data.action
            if(isPageIsReadyAction(action)) {
                eventHandler()
            }
        }, targetOrigin, targetWindow)
    }
}

export const discunaCoreFrontendApiHost = new DiscunaCoreFrontendApiHost()
