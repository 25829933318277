import {Navigate, Outlet} from "react-router-dom";
import {getAuth} from "firebase/auth";

export function WithAuth(props: { protectedComponent?: JSX.Element }) {
    const auth = getAuth()

    if (!auth.currentUser) {
        return <Navigate replace to={"/auth/identity"} state={{from: window.location.href}}/>
    }

    return props.protectedComponent ?? <Outlet/>
}
