import {auth, firestore, functions} from "./firebase";
import {firestoreManager} from "./queryManager";
import {createAsyncThunk} from "@reduxjs/toolkit";
import {RootState} from "../app/store";

import {
    // auth
    CompleteSignUpArgs,

    DeleteCommunityArgs,
    CreateCommunityArgs,
    CreateInvitationLinkArgs,
    DeleteInvitationLinkArgs,
    GetCommunityInvitationLinkArgs,
    GetInvitationLinkArgs,
    UpdateLibraryPayload,
    AddAppToCommunityArgs,

    SetBillingAddressArgs,

    DeletePostArgs,
    UpdatePostArgs,
    TogglePostReactionArgs,
    postCollectionTypes,

    CreateIssueArgs,
    SetAcceptedAnswerArgs,
    ToggleIssueSubscriptionArgs,

    CreateAnswerArgs,
    // CreateProposalArgs,
    // CreateCommentArgs,
    // CreateSubCommentArgs,
    //
    // // SendSignInEmailArgs,
    // SendVerificationEmailArgs,
    UpdateEmailArgs,
    UpdateNameArgs,

    MarkInboxMessageAsReadArgs,

    ToggleChannelMuteArgs,
    UpdateChannelArgs,

    AddDeviceTokenArgs,

    // chatChannel
    CreateChatCommentArgs,
    DeleteChatCommentArgs,
    ToggleChatCommentReactionArgs,
    UpdateChatCommentArgs,
    JoinCommunityArgs,

    // dev
    CreateCustomAppArgs,

    // oauth
    LoginArgs,
    LogoutArgs,

    GetConsentDataArgs,
    GrantConsentArgs,
    DenyConsentArgs,
    ChangeMemberNameArgs,
    DeleteMemberArgs,
    LeaveCommunityArgs,
    LeavePreviewArgs,
    RemoveAppFromCommunityArgs,
    UpdateCommunityArgs,
    UpdateMemberLicenseArgs,
    UpdateMemberRoleArgs,
    DeleteAppArgs,
    PublishAppArgs,
    UpdateAppConfigArgs,
    CreateAppLicenseArgs,
    DeleteAppLicenseArgs,
    UpdateAppLicenseArgs,
    DeleteAppStorePageArgs,
    UpdateAppStorePageArgs,
    AddMonthlyActiveUserArgs,
    MarkIssueAsViewedArgs,
    CreateSubscriptionArgs,
    DeleteSubscriptionArgs,
    UpdateSubscriptionArgs,
    DeleteDeviceTokenArgs
} from "shared"
import {refreshToken} from "../app/auth/authUtils";
// import {apiCommands} from "shared/index";


// --------------------------
// utils
// --------------------------

export function createCloudFunction<T>(cmd: string) {
    return async (args: T) => {
        const cloudFunction = functions.httpsCallable("api");
        return await firestoreManager.callCloudFunction(cloudFunction, {
            payload: args,
            cmd
        })
    }
}

export function createCustomAsyncThunk<T>(cmd: string) {
    return createAsyncThunk<unknown, T, { state: RootState }>(
        `api/${cmd}`,
        async (args, thunkApi) => {
            const cloudFunction = functions.httpsCallable("api");
            const res = await firestoreManager.callCloudFunction(cloudFunction, {
                payload: args,
                cmd
            })
            // TODO refresh no longer needed?
            if (cmd === "createCommunity") await refreshToken()
            return res.data
            // if(!result.success) {
            //     console.log(`cloud function ${group}-${name} failed with code ${result.errorCode}: ${result.errorMessage}`)
            //     throw Error(`cloud function ${group}-${name} failed with code ${result.errorCode}: ${result.errorMessage}`)
            // }
        },
        // {
        //     idGenerator: () => firestore.collection("someIrrelevantCollectionName").doc().id,
        // }
    )
}

export function getUser() {
    const user = auth.currentUser
    if (!user) throw Error("auth")
    return {
        id: user.uid,
        ...(user.displayName) && {name: user.displayName}
    }
}

export function getTargetIdAndCollectionFromRelPath(relPath: string) {
    const pathSplit = relPath.split("/")
    const id = pathSplit.pop() as string
    const collection = pathSplit.pop() as postCollectionTypes
    return {id, collection}
}

export function getTargetParentIdAndCollectionFromRelPath(relPath: string) {
    const pathSplit = relPath.split("/")
    if (pathSplit.length <= 2) throw  Error("invalid relPath - parent does not exist")
    const parentId = pathSplit.splice(pathSplit.length - 2, 2).pop() as string
    const parentCollection = pathSplit.pop() as postCollectionTypes
    return {parentId, parentCollection}
}

export function generateId() {
    return firestore.collection("someIrrelevantCollectionName").doc().id
}

// --------------------------
// cloud functions
// --------------------------

// auth
export const getJWTForUser = createCloudFunction<{}>("getJWTForUser")
export const completeSignUp = createCloudFunction<CompleteSignUpArgs>("completeSignUp")
// export const sendSignInEmail = createCloudFunction<SendSignInEmailArgs>("auth", "sendSignInEmail")
// export const sendVerificationEmail = createCloudFunction<SendVerificationEmailArgs>("sendVerificationEmail")
export const updateName = createCloudFunction<UpdateNameArgs>("updateName")
export const updateEmail = createCloudFunction<UpdateEmailArgs>("updateEmail")

// oauth
export const login = createCloudFunction<LoginArgs>("login")
export const logout = createCloudFunction<LogoutArgs>("logout")
export const getConsentData = createCloudFunction<GetConsentDataArgs>("getConsentData")
export const grantConsent = createCloudFunction<GrantConsentArgs>("grantConsent")
export const denyConsent = createCloudFunction<DenyConsentArgs>("denyConsent")


// userSecrets
export const addDeviceToken = createCloudFunction<AddDeviceTokenArgs>("addDeviceToken")
export const deleteDeviceToken = createCloudFunction<DeleteDeviceTokenArgs>("deleteDeviceToken")

export const toggleDevRole = createCloudFunction<{value: boolean}>("toggleDevRole")
export const setBillingAddress = createCloudFunction<SetBillingAddressArgs>("setBillingAddress")

// dev
export const createCustomApp = createCloudFunction<CreateCustomAppArgs>("createCustomApp")
export const createFirstPartyApps = createCloudFunction("createFirstPartyApps")
export const updateAppConfig = createCloudFunction<UpdateAppConfigArgs>("updateAppConfig")
export const deleteApp = createCloudFunction<DeleteAppArgs>("deleteApp")
export const getAppSignInToken = createCloudFunction<{}>("getAppSignInToken")

// subscription
export const createSubscription = createCloudFunction<CreateSubscriptionArgs>("createSubscription")
export const updateSubscription = createCloudFunction<UpdateSubscriptionArgs>("updateSubscription")
export const deleteSubscription = createCloudFunction<DeleteSubscriptionArgs>("deleteSubscription")

// app store
export const publishApp = createCloudFunction<PublishAppArgs>("publishApp")
export const updateAppStorePage = createCloudFunction<UpdateAppStorePageArgs>("updateAppStorePage")
export const deleteAppStorePage = createCloudFunction<DeleteAppStorePageArgs>("deleteAppStorePage")

// app license
export const createAppLicense = createCloudFunction<CreateAppLicenseArgs>("createAppLicense")
export const updateAppLicense = createCloudFunction<UpdateAppLicenseArgs>("updateAppLicense")
export const deleteAppLicense = createCloudFunction<DeleteAppLicenseArgs>("deleteAppLicense")


// inbox
export const markInboxMessageAsRead = createCustomAsyncThunk<MarkInboxMessageAsReadArgs>("markInboxMessageAsRead")

// invitation links
// thunks
export const createInvitationLink = createCloudFunction<CreateInvitationLinkArgs>("createInvitationLink")
export const deleteInvitationLink = createCustomAsyncThunk<DeleteInvitationLinkArgs>("deleteInvitationLink")
// cloud functions
export const getInvitationLink = createCloudFunction<GetInvitationLinkArgs>("getInvitationLink")
export const getCommunityInvitationLink = createCloudFunction<GetCommunityInvitationLinkArgs>("getCommunityInvitationLink")

// communities
// thunks
export const createCommunity = createCustomAsyncThunk<CreateCommunityArgs>("createCommunity")
export const updateCommunity = createCustomAsyncThunk<UpdateCommunityArgs>("updateCommunity")
export const updateCommunityLibrary = createCustomAsyncThunk<UpdateLibraryPayload>("updateLibrary")

// export const updateCommunityLibrary = createAsyncThunk<unknown, UpdateLibraryPayload & {pdfData?: Buffer}, { state: RootState }>(
//     `communityLibraries/updateLibrary`,
//     async (args, thunkApi) => {
//         const cloudFunction = functions.httpsCallable(`communityLibraries-updateLibrary`);
//         const {pdfData, ...payload} = args
//         const res = await firestoreManager.callCloudFunction(cloudFunction, payload)
//         const action = args.actions[0]
//         if (action.actionType === "create" &&
//             action.createData.type === "pdf" &&
//             action.createData.pdfFetchInfo.type === "cloud"
//         ) {
//             if(!pdfData) throw Error("pdfData not defined")
//             const storage = admin.storage();
//             await storage.bucket(CLOUD_STORAGE_BUCKET)
//                 .file(getFilePath({communityId, channelId: action.channelId}))
//                 .save(Buffer.from(pdfData, "base64"))
//         }
//         return res.data
//     },
// )
// cloud functions
// export const previewCommunity = createCloudFunction<PreviewCommunityArgs>("previewCommunity")
export const joinCommunity = createCloudFunction<JoinCommunityArgs>("joinCommunity")
export const changeMemberName = createCustomAsyncThunk<ChangeMemberNameArgs>("changeMemberName")
export const leavePreview = createCloudFunction<LeavePreviewArgs>("leavePreview")
export const leaveCommunity = createCustomAsyncThunk<LeaveCommunityArgs>("leaveCommunity")
export const deleteCommunity = createCustomAsyncThunk<DeleteCommunityArgs>("deleteCommunity")
export const deleteMember = createCloudFunction<DeleteMemberArgs>("deleteMember")
export const updateMemberRole = createCloudFunction<UpdateMemberRoleArgs>("updateMemberRole")

export const updateMemberLicense = createCloudFunction<UpdateMemberLicenseArgs>("updateMemberLicense")


// community insights
export const addMonthlyActiveUser = createCustomAsyncThunk<AddMonthlyActiveUserArgs>("addMonthlyActiveUser")

// apps
export const addAppToCommunity = createCloudFunction<AddAppToCommunityArgs>("addAppToCommunity")
export const removeAppFromCommunity = createCloudFunction<RemoveAppFromCommunityArgs>("removeAppFromCommunity")

// channels
// export const downloadPdfFromCloud = createCloudFunction<DownloadPdfFromCloudArgs>("channels", "downloadPdfFromCloud")
// export const uploadPdfToCloud = createCloudFunction<UploadPdfToCloudArgs>("channels", "uploadPdfToCloud")

// channel
export const toggleChannelMute = createCustomAsyncThunk<ToggleChannelMuteArgs>("toggleChannelMute")
export const updateChannel = createCustomAsyncThunk<UpdateChannelArgs>("updateChannel")


// chatChannel
export const createChatComment = createCustomAsyncThunk<CreateChatCommentArgs>("createChatComment")
export const deleteChatComment = createCustomAsyncThunk<DeleteChatCommentArgs>("deleteChatComment")
export const updateChatComment = createCustomAsyncThunk<UpdateChatCommentArgs>("updateChatComment")
export const toggleChatCommentReaction = createCustomAsyncThunk<ToggleChatCommentReactionArgs>("toggleChatCommentReaction")


// docChannel
export const deletePost = createCustomAsyncThunk<DeletePostArgs>("deletePost")
export const updatePost = createCustomAsyncThunk<UpdatePostArgs>("updatePost")
export const togglePostReaction = createCustomAsyncThunk<TogglePostReactionArgs>("togglePostReaction")
export const createIssue = createCustomAsyncThunk<CreateIssueArgs>("createIssue")
export const toggleIssueSubscription = createCustomAsyncThunk<ToggleIssueSubscriptionArgs>("toggleIssueSubscription")
export const markIssueAsViewed = createCustomAsyncThunk<MarkIssueAsViewedArgs>("markIssueAsViewed")
export const setAcceptedAnswer = createCustomAsyncThunk<SetAcceptedAnswerArgs>("setAcceptedAnswer")
export const createAnswer = createCustomAsyncThunk<CreateAnswerArgs>("createAnswer")
// export const createProposal = createCustomAsyncThunk<CreateProposalArgs>("createProposal")
// export const createComment = createCustomAsyncThunk<CreateCommentArgs>("createComment")
// export const createSubComment = createCustomAsyncThunk<CreateSubCommentArgs>("createSubComment")

// --------------------------
// old
// --------------------------

// --

// export type channelTypes = "folder" | "pdf" | "url" | "q&a" | "conversation"
//
// export interface LibraryCreateChannelAction {
//     actionType: "create",
//     parentId: string | null,
//     channelId: string,
//     index: number,
//     level: number,
//     channelType: channelTypes,
//     name: string,
//     fileId?: string,
//     url?: string
// }
//
// export interface LibraryDeleteChannelAction {
//     actionType: "delete",
//     channelId: string,
//     index: number,
// }
//
// export interface LibraryMoveChannelAction {
//     actionType: "move",
//     channelId: string,
//     oldIndex: number,
//     newIndex: number,
//     newLevel: number,
//     newParentId: string | null,
// }
//
// export interface LibraryRenameChannelAction {
//     actionType: "rename",
//     newName: string,
//     channelId: string,
//     index: number,
// }
//
// export type libraryAction = LibraryCreateChannelAction |
//     LibraryDeleteChannelAction |
//     LibraryMoveChannelAction |
//     LibraryRenameChannelAction
//
//
// export interface UpdateLibraryPayload {
//     communityId: string,
//     communityAccessibility: "open" | "closed",
//     actions: libraryAction[], // currently, only one action is allowed
// }
//
// export async function firebaseUpdateLibrary(payload: UpdateLibraryPayload){
//     const updateLibrary = functions.httpsCallable('communities-updateLibrary');
//     await firestoreManager.callCloudFunction(updateLibrary, payload)
// }
//
//
// // --
//
// export interface CreateCommunityPayload {
//     communityId: string,
//     name: string,
//     shortDescription: string,
//     plan: "free" | "patronage",
//     accessibility: "open" | "closed",
// }
//
// export async function firebaseCreateCommunity(payload: CreateCommunityPayload){
//     const createCommunity = functions.httpsCallable('communities-createCommunity');
//     await firestoreManager.callCloudFunction(createCommunity, payload)
// }
//
// // --
//
// export interface AddFolderPayload {
//     folderName: string,
//     folderId: string,
//     parentFolderId?: string
// }
//
// export async function firebaseAddFolder(payload: AddFolderPayload){
//     const addFolder = functions.httpsCallable('users-addFolder');
//     await firestoreManager.callCloudFunction(addFolder, payload)
// }
//
// // --
//
// export interface DeleteNavLibItemsPayload {
//     navLibItems: NavLibItem[]
// }
//
// export async function firebaseDeleteNavLibItems(payload: DeleteNavLibItemsPayload){
//     const deleteNavLibItems = functions.httpsCallable('users-deleteNavLibItems');
//     await firestoreManager.callCloudFunction(deleteNavLibItems, payload)
// }
//
// // --
//
// export interface RenameNavLibItemPayload {
//     navLibItemId: string,
//     newName: string,
// }
//
// export async function firebaseRenameNavLibItem(payload: RenameNavLibItemPayload){
//     const renameNavLibItem = functions.httpsCallable('users-renameNavLibItem');
//     await firestoreManager.callCloudFunction(renameNavLibItem, payload)
// }
//
// // --
//
// export interface AddPdfFilesPayload {
//     filePathList: string[],
//     pdfIdList: string[],
//     pageIdsList: string[][],
//     parentFolderId?: string
// }
//
// export async function firebaseAddPdfFiles(payload: AddPdfFilesPayload){
//     const addPdfFiles = functions.httpsCallable('users-addPdfFiles');
//     await firestoreManager.callCloudFunction(addPdfFiles, payload)
// }
//
// // --
//
// export interface AddUrlFilesPayload {
//     urlFileInfos: UrlFileInfo[],
//     generateFolders: boolean,
//     shortenUrl: boolean,
//     parentFolderId?: string,
// }
//
// export async function firebaseAddUrlFiles(payload: AddUrlFilesPayload){
//     const addUrlFiles = functions.httpsCallable('users-addUrlFiles');
//     await firestoreManager.callCloudFunction(addUrlFiles, payload)
// }
//
// // --
//
// interface AddNavLibItemPayload {
//     parentFolderId?: string,
//     navLibItemId: string,
//     navLibItemName: string,
//     navLibItemType: "url" | "pdf" | "folder",
//     fileId?: string,
//     pageIds?: string[],
//     url?: string,
//     filepath?: string,
// }
//
// export async function firebaseAddNavLibItem(payload: AddNavLibItemPayload){
//     const addNavLibItem = functions.httpsCallable('users-addNavLibItem');
//     await firestoreManager.callCloudFunction(addNavLibItem, payload)
// }
//
// interface MoveNavLibItemPayload {
//     sourceId: string,
//     destId: string,
//     level: number,
// }
//
// export async function firebaseMoveNavLibItem(payload: MoveNavLibItemPayload){
//     const addNavLibItem = functions.httpsCallable('users-moveNavLibItem');
//     await firestoreManager.callCloudFunction(addNavLibItem, payload)
// }
//
// // ==========================
// // File subscriptions
// // ==========================
// export interface SubscribeToPdfFilesPayload {
//     pdfInfos: ({
//         id: string,
//         pageIds: string[],
//         title: string | null
//         // subscribers are merged
//     })[]
// }
//
// export async function firebaseSubscribeToPdfFiles(payload: SubscribeToPdfFilesPayload) {
//     const subscribeToPdfFiles = functions.httpsCallable('users-subscribeToPdfFiles');
//     await firestoreManager.callCloudFunction(subscribeToPdfFiles, payload)
// }
//
// export interface UnsubscribeFromPdfFilesPayload {
//     pdfIds: string[]
// }
//
// export async function firebaseUnsubscribeFromPdfFiles(payload: UnsubscribeFromPdfFilesPayload) {
//     const unsubscribeFromPdfFiles = functions.httpsCallable('users-unsubscribeFromPdfFiles');
//     await firestoreManager.callCloudFunction(unsubscribeFromPdfFiles, payload)
// }
//
// export interface SubscribeToUrlFilesPayload {
//     urlInfos: ({
//         id: string,
//         url: string,
//         // subscribers are merged
//     })[]
// }
//
// export async function firebaseSubscribeToUrlFiles(payload: SubscribeToUrlFilesPayload){
//     const subscribeToUrlFiles = functions.httpsCallable('users-subscribeToUrlFiles');
//     await firestoreManager.callCloudFunction(subscribeToUrlFiles, payload)
//
// }
//
// export interface UnsubscribeFromUrlFilesPayload {
//     urlIds: string[]
// }
//
// export async function firebaseUnsubscribeFromUrlFiles(payload: UnsubscribeFromUrlFilesPayload) {
//     const unsubscribeFromUrlFiles = functions.httpsCallable('users-unsubscribeFromUrlFiles');
//     await firestoreManager.callCloudFunction(unsubscribeFromUrlFiles, payload)
// }
