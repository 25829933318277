import {useParams} from "react-router-dom";
import {Typography} from "@mui/material";
import React from "react";
import {Column} from "../../../../../components/motion_mui";
import {AppsAdded} from "./appsAdded";
import {SelfHostedAppsExplorer} from "./selfHostedAppsExplorer";
import {AppStore} from "./appStore";
import {useSelector} from "react-redux";
import {RootState} from "../../../../store";

/**
 * NOTE
 *  it makes sense to have an app store per community because app suggestions
 *  are based on community
 *
 * @constructor
 */
export function CommunityAppsPage() {

    const {communityId} = useParams<{ communityId: string }>()
    if (!communityId) throw Error("communityId missing")

    // const userSecrets = useSelector((state: RootState) => state.userSecrets.data)
    // if (!userSecrets) return null

    return (

        <Column
            sx={{
                width: "100%",
                height: "100%",
                overflow: "auto",
                pb: 4,
            }}
            mainAxisAlignment={"start"}
            crossAxisAlignment={"start"}
        >

            <Typography variant={"h1"} sx={{
                ml: 4,
                mt: 4,
                // px: 2,
                // bgcolor: primaryLightBackground,
                // borderLeft: 4,
                // borderColor: 'primary.main',
            }}>
                Apps
            </Typography>


            <Column sx={{ml: 4, mt: 4}} mainAxisAlignment={"start"} crossAxisAlignment={"start"}>
                <Typography variant={"h4"} sx={{mb: 1}}>
                    Billing
                </Typography>
                {
                    <Typography variant={"body1"} color={"text.secondary"}>
                        No bills
                    </Typography>
                }
            </Column>

            <AppsAdded communityId={communityId}/>

            <SelfHostedAppsExplorer communityId={communityId}/>

            <AppStore communityId={communityId}/>

        </Column>
    )
}
