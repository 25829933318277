import React, {useEffect, useState} from "react";
import {motion} from "framer-motion";
import {Center, RowMotion} from "../../../../../../components/motion_mui";
import {Box, Card, CircularProgress, ListItemButton, ListItemText} from "@mui/material";
import {DragPreviewImage, useDrag, useDrop} from "react-dnd"
import {getEmptyImage} from "react-dnd-html5-backend";
import debounce from "lodash.debounce";
import {updateCommunityLibrary} from "../../../../../../firebase/discunaApi";
import {LibraryMoveChannelAction} from "shared";
import {useAppDispatch} from "../../../../../store";
import {useDiscunaBadgeList} from "../badgeList";


// because list item has 16px paddingLeft and icon has width 24px
export const INDENT = 18;

export function NestedListItem({isDraggable = true, ...props}: {
    id: string,
    name: string,
    level: number,
    selected: boolean,
    isSynced: boolean,

    // muted
    muted: boolean,

    // community
    communityId: string,
    channelId?: string,
    allChildChannels?: string[],
    lowCount?: number, // low priority
    highCount?: number, // high priority
    bookmarksCount?: Record<string, number>,

    icon: React.ReactNode,
    // showSecondaryAction: boolean,
    // secondaryAction: {},

    onClick: () => void,
    onContextMenu: (event: React.MouseEvent) => void,

    // drag and drop
    dragId: string | null,
    setDragId: (id: string | null) => void,
    isDroppable: boolean,
    isDraggable?: boolean,
    onDragStart?: () => void,
    onLongDragOver?: () => void,
}) {

    const [{isDragging}, dragRef, preview] = useDrag(
        () => ({
            type: "card",
            // item: { text: "test" },
            collect: (monitor) => {
                return {
                    isDragging: monitor.isDragging(),
                    // TODO always pass ref but define canDrag?
                    // canDrag: monitor.canDrag() && isDraggable
                }
            }
        }),
        []
    )

    const [{canDrop, isOver}, dropRef] = useDrop(() => ({
        // The type (or types) to accept - strings or symbols
        accept: 'card',
        // Props to collect
        collect: (monitor) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop()
        })
    }))

    useEffect(() => {
        if (isOver && props.onLongDragOver) {
            const onDragOverDebounced = debounce(props.onLongDragOver, 500)
            onDragOverDebounced()
            return onDragOverDebounced.cancel
        }
    }, [isOver, props.onLongDragOver])

    const dispatch = useAppDispatch()

    const [badgeList, badgeListPlaceHolder] = useDiscunaBadgeList(
        props.communityId,
        props.lowCount ?? 0,
        props.highCount ?? 0,
        props.bookmarksCount ?? {},
        props.channelId,
        props.allChildChannels
    )

    return (
        <>
            {
                isDragging &&
                <DragPreview key={"dragPreview" + props.id} text={props.name}/>
            }
            <DragPreviewImage connect={preview} src={emptyImage.src}/>
            <div
                ref={isDraggable ? dragRef : null}
                key={props.id}
                style={{
                    position: "relative"
                }}
                draggable={isDraggable}
                onDrop={(e) => {
                    if (!isDraggable) return false
                    if (props.dragId) {
                        const action = {
                            actionType: "move",
                            channelId: props.dragId,
                            newParentId: props.id
                        } as LibraryMoveChannelAction
                        dispatch(updateCommunityLibrary({
                            communityId: props.communityId,
                            actions: [action]
                        }))
                    }
                }}
                onDragStart={() => {
                    if (!isDraggable) return false
                    if (props.onDragStart) props.onDragStart()
                    props.setDragId(props.id)
                }}
                onDragEnd={() => {
                    if (!isDraggable) return false
                    props.setDragId(null)
                }}
            >
                <RowMotion
                    mainAxisAlignment={"start"}
                    crossAxisAlignment={"start"}
                    initial={{
                        left: 24 + 6 + 8,
                    }}
                    animate={{
                        left: INDENT * props.level + 24 + 6 + 8,  // +8 because badge list is 16px margin
                    }}
                    sx={{
                        position: "absolute",
                        // top: 8,
                        zIndex: 2,
                    }}
                >
                    {badgeList}
                </RowMotion>

                <ListItemButton
                    id={"nli-" + props.id}
                    key={"nli-" + props.id}
                    itemID={"nli-" + props.id}
                    ref={props.isDroppable ? dropRef : null}
                    sx={{
                        width: "fit-content",
                        minWidth: "100%",
                        paddingLeft: "24px !important",
                        // ...(isOver) && {
                        //     backgroundColor: "red",
                        // },
                    }}
                    onContextMenu={props.onContextMenu}
                    disabled={!props.isSynced || isDragging}
                    selected={props.selected || isOver}
                    onClick={() => props.onClick()}
                >

                    <MotionBoxLevel
                        // Indent list item content
                        keyForward={props.id}
                        level={props.level}
                    />

                    <Center
                        // List item icon
                        style={{
                            width: 24,
                            height: 24,
                            ...(props.dragId) && {
                                pointerEvents: "none"
                            }
                        }}
                    >
                        {
                            props.isSynced ?
                                props.icon :
                                <CircularProgress size={16}/>
                        }
                    </Center>

                    {badgeListPlaceHolder}

                    <ListItemText
                        sx={{
                            ml: "6px",
                            ...(props.dragId) && {
                                pointerEvents: "none"
                            },
                            whiteSpace: "nowrap"
                        }}
                        primary={props.muted ? <i>{props.name}</i> : props.name}
                    />
                </ListItemButton>
            </div>
        </>
    )
}


function MotionBoxLevel(props: {
    keyForward: string,
    level: number
}) {
    return <motion.div key={props.keyForward} animate={{
        // left: 100,
        width: INDENT * props.level + "px",
    }}/>
}

// interface CustomListItemProps {
//     selected?: boolean,
//     disabled?: boolean,
//     sx?: SxProps<Theme>,
// }

// const CustomListItem = React.forwardRef<HTMLLIElement, React.PropsWithChildren<CustomListItemProps>>(
//     (props, ref) => {
//     return <ListItem ref={ref} button>
//         {props.children}
//     </ListItem>
// })

const emptyImage = getEmptyImage()

const PreviewPaper = React.memo((props: { text: string }) => {
    return (
        <Card sx={{
            p: 1,
            boxSizing: "border-box"
        }}>
            {props.text}
        </Card>
    )
})

function DragPreview(props: {
    text: string
}) {
    const [position, setPosition] = useState<{ x: number, y: number } | null>(null)

    // const setPositionDebounced = debounce(setPosition, 5)

    const mouseEventHandler = (e: MouseEvent) => {
        setPosition({x: e.x, y: e.y})
    }

    useEffect(() => {
        window.addEventListener("drag", mouseEventHandler)
        return () => {
            window.removeEventListener("drag", mouseEventHandler)
        }
    })
    return (position && position.x !== 0 && position.y !== 0) ?
        <Box sx={{
            position: "fixed",
            transform: `translate(${position.x + 20}px, ${position.y}px)`,
            top: 0,
            left: 0,
            zIndex: 2,
        }}>
            <PreviewPaper text={props.text}/>
        </Box>
        :
        null
}



