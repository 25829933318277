import {getStorage, ref, uploadBytesResumable} from "firebase/storage";
import React, {useEffect, useRef, useState} from "react";
import {getAuth} from "firebase/auth";
import {
    CircularProgress,
    IconButton,
    LinearProgress,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    SxProps
} from "@mui/material";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import {log} from "../../../../utils/log";
import {getTmpImgUrl} from "shared";
import {fileNameToNameAndExtension} from "../../../../utils/fileUtils";

export function UploadImageToCloud({data, filename, fileId, onSuccess, onDelete, sx}: {
    data: Uint8Array,
    filename: string,
    fileId: string,
    onSuccess: (fileId: string) => void,
    onDelete: (fileId: string) => void,
    sx?: SxProps
}) {

    const {name, extension} = fileNameToNameAndExtension(filename)

    const [progress, setProgress] = useState<number | "success" | string>(0)
    const initialized = useRef(false)

    // NOTE in StrictMode, useEffect is called twice
    useEffect(() => {
        if (initialized.current) return
        initialized.current = true

        const storagePath = getTmpImgUrl(fileId)  // `tmp/${fileId}.${extension}`

        const storage = getStorage()
        const storageRef = ref(storage, storagePath)
        const auth = getAuth()
        if (!auth.currentUser) return setProgress("unauthenticated")
        const metadata = {
            contentType: `image/${extension}`,
            customMetadata: {
                publisher: auth.currentUser.uid,
            }
        }

        const uploadTask = uploadBytesResumable(storageRef, data, metadata)

        uploadTask.on("state_changed",
            (snapshot) => {
                // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                setProgress(progress)
                log("upload progress state", snapshot.state)
                // snapshot.state: "paused" | "running"
            },
            (error) => {
                // A full list of error codes is available at
                // error.code
                // https://firebase.google.com/docs/storage/web/handle-errors
                console.error("Error uploading Image:", error)
                setProgress(error.code)
            },
            () => {
                // Upload completed successfully
                log("upload completed successfully")
                setProgress("success")
                onSuccess(fileId)
            }
        )

        return () => {
            if (uploadTask.snapshot.state !== "success" &&
                uploadTask.snapshot.state !== "error") {
                uploadTask.cancel()
            }
        }

    }, [fileId, onSuccess, data, extension])


    return (
        <List disablePadding={true} sx={{
            m: 0,
            borderRadius: 1,
            bgcolor: typeof progress === "number" ? "#F0F3F4" :
                progress === "success" ? "#D5F5E3" : "#FADBD8",
            overflow: "hidden",
            ...sx
            // backgroundColor: status === "loading" ? "#F0F3F4" : status === "success" ? "#D5F5E3" : "#FADBD8",
        }}>
            {
                typeof progress === "number" &&
                <LinearProgress sx={{
                    position: "absolute",
                    bottom: 0,
                    width: "100%"
                }} variant="determinate" value={progress}/>
            }
            <ListItem
                sx={{
                    position: "relative"
                }}
                secondaryAction={
                    <IconButton edge="end" aria-label="delete" onClick={() => {
                        onDelete(fileId)
                    }}>
                        <DeleteOutlineOutlinedIcon/>
                    </IconButton>
                }
            >
                <ListItemIcon>
                    {
                        typeof progress === "number" ?
                            <CircularProgress size={24}/> :
                            progress === "success" ?
                                <CheckOutlinedIcon/> :
                                <ErrorOutlineOutlinedIcon/>
                    }
                </ListItemIcon>

                <ListItemText primary={
                    typeof progress === "number" ?
                        `Uploading ${name}.${extension}` :
                        progress === "success" ?
                            `Uploaded ${name}.${extension}` :
                            `Error occurred: ${progress}`
                }/>

            </ListItem>
        </List>
    )
}
