import {createEntityAdapter, createSelector, createSlice} from "@reduxjs/toolkit";
import {DiscunaApp} from "./discunaAppInterface";
// import {createApp, getUser} from "../../../../firebase/discunaApi";
import {createCustomAppFromAppConfig} from "shared/apps/app";
import {getAuth} from "firebase/auth";
import {RootState} from "../../../../store";

const discunaAppEntityAdapter = createEntityAdapter<DiscunaApp>()

const discunaAppSlice = createSlice({
    name: "discunaApps",
    initialState: discunaAppEntityAdapter.getInitialState({}),
    reducers: {
        addDiscunaApp: discunaAppEntityAdapter.addOne,
        upsertDiscunaApp: discunaAppEntityAdapter.upsertOne,
        updateOneDiscunaApp: discunaAppEntityAdapter.updateOne,
        removeDiscunaApp: discunaAppEntityAdapter.removeOne,
        removeAllDiscunaApps: discunaAppEntityAdapter.removeAll,
        setAllDiscunaApps: discunaAppEntityAdapter.setAll,
    },
    // extraReducers: builder => {
    //     builder
    //         .addCase(createApp.pending,
    //             (state, action) => {
    //                 const args = action.meta.arg
    //                 const user = getUser()
    //                 if (!user.name) throw Error("Name must be defined")
    //                 const app = {
    //                     // redux
    //                     id: args.appId,
    //                     isSynced: false,
    //                     ...createAppFromAppConfig(
    //                         {id: user.id}, args.appConfig)
    //                 } as DiscunaApp
    //                 discunaAppEntityAdapter.upsertOne(state, app)
    //             })
    //         .addCase(createApp.fulfilled,
    //             (state, action) => {
    //                 const args = action.meta.arg
    //                 const app = state.entities[args.appId]
    //                 if(app) app.isSynced = true
    //             })
    //         .addCase(createApp.rejected,
    //             (state, action) => {
    //                 const args = action.meta.arg
    //                 discunaAppEntityAdapter.removeOne(state, args.appId)
    //             })
    // }
})

export const discunaAppReducer = discunaAppSlice.reducer

export const selectDiscunaAppsByIDs = () => createSelector(
    [(state: RootState) => selectAllDiscunaApps(state), (state: RootState, appIDs: string[]) => appIDs], //
    (apps, appIDs) => apps.filter(app => appIDs.includes(app.id))
)

export const selectDiscunaAppsByPublisherId = () => createSelector(
    [
        (state: RootState) => selectAllDiscunaApps(state),
        (state: RootState, publisherId: string) => publisherId,
        (state: RootState, publisherId: string, isPublished?: boolean) => isPublished
    ], //
    (apps, publisherId, isPublished) => apps.filter(app => app.publisherId === publisherId && (isPublished === undefined || app.published === isPublished))
)


export const {
    addDiscunaApp,
    upsertDiscunaApp,
    updateOneDiscunaApp,
    setAllDiscunaApps,
    removeDiscunaApp,
    removeAllDiscunaApps
} = discunaAppSlice.actions

// selectors
export const {
    selectById: selectDiscunaAppById,
    selectAll: selectAllDiscunaApps,
} = discunaAppEntityAdapter.getSelectors((state: RootState) => state.discunaApps)


