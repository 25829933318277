import {createEntityAdapter, createSelector, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "../../../store";
import {ChannelReferenceInfo} from "shared/communities/channels/channel";
import {Bookmark} from "./bookmarkInterfaces";
import {BookmarkType} from "shared/user/bookmark";

const bookmarksMessageAdapter = createEntityAdapter<Bookmark>()

const bookmarksSlice = createSlice({
    name: 'bookmarks',
    initialState: bookmarksMessageAdapter.getInitialState(),
    reducers: {
        addBookmark: bookmarksMessageAdapter.addOne,
        upsertBookmark: bookmarksMessageAdapter.upsertOne,
        updateBookmark: bookmarksMessageAdapter.updateOne,
        removeBookmark: bookmarksMessageAdapter.removeOne,
        setAllBookmarks: bookmarksMessageAdapter.setAll,
    },
})

export const {
    addBookmark,
    updateBookmark,
    upsertBookmark,
    removeBookmark,
    setAllBookmarks
} = bookmarksSlice.actions

export const bookmarksReducer = bookmarksSlice.reducer

export const {
    selectAll: selectAllBookmarks,
    selectById: selectBookmarkById,
    selectIds: selectBookmarkIds
    // Pass in a selector that returns the posts slice of state
} = bookmarksMessageAdapter.getSelectors<RootState>(state => state.bookmarks)


export const selectBookmarksForCommunity = () => createSelector(
    [
        (state: RootState) => selectAllBookmarks(state),
        (state: RootState, communityId: string) => communityId
    ],
    (bookmarks, communityId) => bookmarks
        .filter(bookmark =>
            bookmark.details.communityId === communityId
        )
)

export const selectBookmarksForChannel = () => createSelector(
    [
        (state: RootState) => selectAllBookmarks(state),
        (state: RootState, channel?: ChannelReferenceInfo) => channel
    ],
    (bookmarks, channel) => {
        if(!channel) return []
        return bookmarks
            .filter(bookmark =>
                bookmark.details.channelId === channel.id
            )
    }
)

export const selectBookmarks = () => createSelector(
    [
        (state: RootState) => selectAllBookmarks(state),
        (state: RootState, communityId: string) => communityId,
        (state: RootState, communityId: string, channelIds: string[] | null) => channelIds,
        (state: RootState, communityId: string, channelIds: string[] | null, type: string) => type,
        // (state: RootState, communityId: string, channelIds: string[] | null, hasRead: boolean) => hasRead
    ],
    (bookmarks, communityId, channelIds, type) => {

        return bookmarks
            .filter(bookmark => {
                    return (
                        bookmark.type === type &&
                        bookmark.details.communityId === communityId &&
                        (!channelIds || channelIds.includes(bookmark.details.channelId))
                    )
                }
            ).sort((a, b) => a.createdAt < b.createdAt ? 1 : -1)
    }
)



