import {AnyAction, combineReducers, configureStore} from '@reduxjs/toolkit'
import {dashboardReducer} from "./dashboard/dashboardSlice";
import {firebaseReducer} from "../firebase/firebaseSlice";
// import {notificationsReducer} from "./dashboard/features/notifications/notificationsSlice";
import {communityReducer} from "./dashboard/features/communities/communitySlice";
import {communityLibraryReducer} from "./dashboard/features/communities/channels/communityLibrarySlice";
import {userSecretsReducer} from "./userSecrets/userSecretsSlice";
import {memberSecretsReducer} from "./dashboard/features/communities/members/memberSecretsSlice";
import {channelReducer} from "./dashboard/features/communities/channels/channelSlice";
import {memberReducer} from "./dashboard/features/communities/members/memberSlice";
import {channelOfflineDataReducer} from "./dashboard/features/communities/channels/channelOfflineDataSlice";
import {authReducer} from "./auth/authSlice";
import {invitationLinkReducer} from "./dashboard/features/communities/invitationLinks/invitationLinkSlice";
import {inboxReducer} from "./dashboard/features/inbox/inboxSlice";
import {memberInsightsReducer} from "./dashboard/features/communities/members/memberInsightsSlice";
import {rewardInsightsReducer} from "./dashboard/features/communities/members/rewards/rewardInsightsSlice";
import {rewardReducer} from "./dashboard/features/communities/members/rewards/rewardSlice";
import {discunaAppReducer} from "./dashboard/features/communities/apps/discunaAppSlice";
import {discunaAppLicenseReducer} from "./dashboard/features/communities/apps/discunaAppLicenseSlice";
import {discunaAppStoreItemReducer} from "./dashboard/features/communities/apps/discunaAppStoreItemsSlice";
import {TypedUseSelectorHook, useDispatch, useSelector} from "react-redux";
import {bookmarksReducer} from "./dashboard/features/bookmarks/bookmarksSlice";
// import thunkMiddleware from 'redux-thunk';


const SIGNOUT_REQUEST = "SIGNOUT_REQUEST"

export const signOutFromStore = () => ({
    type: SIGNOUT_REQUEST
})

const combinedReducer = combineReducers({
    // dashboard
    dashboard: dashboardReducer,
    firebase: firebaseReducer,

    // apps
    discunaApps: discunaAppReducer,
    discunaAppLicenses: discunaAppLicenseReducer,
    discunaAppStoreItems: discunaAppStoreItemReducer,

    // user
    auth: authReducer,
    userSecrets: userSecretsReducer,
    inbox: inboxReducer,
    bookmarks: bookmarksReducer,

    // communities
    communities: communityReducer,
    communityLibraries: communityLibraryReducer,
    invitationLinks: invitationLinkReducer,

    // members
    memberSecrets: memberSecretsReducer,
    members: memberReducer, // redux id === <communityId>_<userId>
    memberInsights: memberInsightsReducer, // id === communityId
    rewardInsights: rewardInsightsReducer, // id === userId
    rewards: rewardReducer, // id === rewardId

    // channels
    channels: channelReducer,
    channelOfflineData: channelOfflineDataReducer,
})

// https://stackoverflow.com/questions/35622588/how-to-reset-the-state-of-a-redux-store/35641992#35641992
const rootReducer = (state: any, action: AnyAction) => {
    if (action.type === SIGNOUT_REQUEST) {
        // for all keys defined in your persistConfig(s)
        // for redux persist
        // storage.removeItem('persist:root')
        // storage.removeItem('persist:otherKey')

        return combinedReducer(undefined, action);
    }
    return combinedReducer(state, action);
};



export const store = configureStore({
    reducer: rootReducer,
    // middleware: [
    //     thunkMiddleware,
    //     // save()
    // ],
    // preloadedState: load()
})

// export const store2 = configureStore({
//     reducer: {
//         app: appReducer,
//         // qanda
//         qanda: qandaReducer,
//         questions: questionsReducer,
//         answers: answersReducer,
//         // user
//         user: userReducer,
//         // question overview
//         // questionOverview: questionOverviewReducer,
//         urlInfo: urlInfoReducer,
//         // proposals
//         proposals: proposalsReducer,
//         // dashboard
//         dashboard: dashboardReducer,
//         // postQuestion
//         postQuestion: postQuestionReducer,
//         // comments
//         comments: commentsReducer,
//         // subComments
//         subComments: subCommentsReducer,
//         // edits
//         // edits: editsReducer
//         firebase: firebaseReducer,
//     },
//     // preloadedState: JSON.parse(stateDemo.payload)[0]
// })

// const demoActions = JSON.parse(stateDemo.payload)
// console.log(demoActions)
// for(let i = 0; i < demoActions.length; i++){
//     store2.dispatch(demoActions[i])
// }

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof rootReducer>
// Inferred bodyType: {posts: PostsState, discussion: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

// you can also create some redux hooks using the above explicit types
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
