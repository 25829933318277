import React, {useEffect, useMemo, useState} from "react";
import {AnimatePresence} from "framer-motion";
import {Box, Popover, Typography, useTheme} from "@mui/material";
import {Row, RowMotion} from "../../../../components/motion_mui";
import {Inbox} from "./inbox";
import useWindowDimensions from "../../../../hooks/window";
import {sidebarWidth} from "../../../../constants/constants";
import {Bookmarks} from "../bookmarks/bookmarks";

export const InboxBadge = React.forwardRef<HTMLDivElement, {
    count?: number, // we include count here because it is more efficient to compute count for all lib items at once
    communityId: string,
    channelId?: string,
    allChildChannels?: string[],

    inboxPriority?: "high" | "normal",
    bookmarkType?: string,
    // badgeCategory: string,
    // isCommunityBookmark?: boolean,

    // badgeType: "inbox" | "bookmark" | "communityBookmark",
    badgeColor: string,
    // badgeTextColor: string,
}>(({inboxPriority, bookmarkType, badgeColor, count, communityId, channelId, allChildChannels}, ref) => {

    const [contextMenuAnchorEl, setContextMenuAnchorEl] = useState<null | HTMLDivElement>(null);
    const [popoverProps, setPopoverProps] = useState<null | {
        position: "before" | "after",
        arrowOffset: number,
        arrowYOffset: number,
        popoverML: number,
        maxHeight: number
    }>(null)

    const [rendered, setRendered] = useState(false)
    useEffect(() => {
        setRendered(true)
    }, [])

    const windowDims = useWindowDimensions()
    if (contextMenuAnchorEl) contextMenuAnchorEl.getBoundingClientRect()

    // const badgeColor = useMemo(() => {
    //     if(priority) {
    //         return priority === "normal" ? "primary.main" : "#F44336"
    //     } else if (bookmarkType) {
    //         return bookmarkType === "todo" ? "green" : "orange"
    //     }
    //     return "primary.main"
    // }, [priority, bookmarkType])
    // const badgeTextColor = useMemo(() => {
    //     if(priority) {
    //         return priority === "normal" ? "primary.contrastText" : "white"
    //     } else if (bookmarkType) {
    //         return "white"
    //     }
    //     return "white"
    // }, [priority, bookmarkType])
    const theme = useTheme()
    const badgeTextColor = theme.palette.getContrastText(badgeColor)
    const badgeRadius = useMemo(() => {
        if (inboxPriority !== undefined) {
            return "10px"
        }
        return "2px"
    }, [inboxPriority])

    return (
        <AnimatePresence>
            <Row mainAxisAlignment={"start"} crossAxisAlignment={"start"} sx={{position: "relative"}}
                 ref={ref}>

                {
                    ((count !== undefined && count > 0) || Boolean(contextMenuAnchorEl)) &&
                    <RowMotion
                        initial={{scale: rendered ? 0 : 1}}
                        animate={{scale: 1}}
                        exit={{scale: 0}}
                        onClick={(e) => {
                            // e.preventDefault()
                            // e.persist()
                            // e.nativeEvent.stopImmediatePropagation();
                            // e.stopPropagation()
                            setContextMenuAnchorEl(e.currentTarget)
                            const rect = e.currentTarget.getBoundingClientRect()
                            setPopoverProps({
                                position: rect.top > (windowDims.height / 2) ? "before" : "after",
                                arrowOffset: rect.width / 2,
                                maxHeight: Math.max(rect.top, windowDims.height - rect.bottom) - 16,
                                arrowYOffset: rect.top - 16 + 10,
                                popoverML: sidebarWidth - rect.right
                            })
                            // setContextMenuAnchorElPos({
                            //     y: e.currentTarget
                            // })
                        }}
                        whileHover={{scale: 1.1}}
                        whileTap={{scale: 0.95}}
                        sx={{
                            borderRadius: badgeRadius,
                            px: "6px",
                            bgcolor: badgeColor,
                            // transform: `rotate(${badgeRotDegree}deg)`,
                            minHeight: "20px",
                            position: "relative",
                            cursor: "pointer",
                            mr: 1
                        }}
                        mainAxisAlignment={"center"}
                        crossAxisAlignment={"center"}
                    >
                        <Typography sx={{
                            color: badgeTextColor,
                            fontWeight: 500,
                            fontSize: "12px",
                            minWidth: "8px"
                        }}>
                            {count ?? 0}
                        </Typography>
                    </RowMotion>
                }
                {/*<Box*/}
                {/*    // If element has zero width, resize observer is not attached*/}
                {/*    // sx={{width: "1px"}}*/}
                {/*/>*/}

                <Popover
                    id="discuna-inbox"
                    anchorEl={contextMenuAnchorEl}
                    open={Boolean(contextMenuAnchorEl)}
                    onClose={() => setContextMenuAnchorEl(null)}
                    anchorOrigin={{
                        vertical: popoverProps && popoverProps.position === "before" ? "top" : "bottom",
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: popoverProps && popoverProps.position === "before" ? "bottom" : "top",
                        horizontal: 'left',
                    }}
                    // anchorOrigin={{
                    //     vertical: 'center',
                    //     horizontal: 'right',
                    // }}
                    // transformOrigin={{
                    //     vertical: 'center',
                    //     horizontal: 'left',
                    // }}
                    sx={{
                        mt: popoverProps && popoverProps.position === "before" ? -1 : 1,
                        ml: -2,
                        // ml: -8 + (popoverProps?.popoverML??0) + "px",
                        // position: "relative",
                        // '&:before': {
                        //     content: '"halloe"',
                        //     display: 'block',
                        //     position: 'absolute',
                        //     top: 0,
                        //     right: 14,
                        //     width: 100,
                        //     height: 100,
                        //     // bgcolor: 'background.paper',
                        //     transform: 'translateY(-50%) rotate(45deg)',
                        //     zIndex: 0,
                        //     bgcolor: "red"
                        // },
                    }}
                    PaperProps={{
                        sx: {
                            overflow: "visible"
                        }
                    }}
                >
                    <Box
                        sx={{
                            position: "absolute",
                            width: 14,
                            height: 5,
                            // zIndex: -1,
                            // transform: `translateY(-50%)`,
                            // top: popoverProps?.arrowYOffset??0,
                            // left: -5,

                            transform: 'translateX(-50%)',
                            // bgcolor: 'background.paper',
                            ...(popoverProps && popoverProps.position === "after") && {
                                top: -5,
                            },
                            ...(popoverProps && popoverProps.position === "before") && {
                                bottom: -5,
                            },
                            left: popoverProps && popoverProps.arrowOffset + 16
                        }}
                    >
                        <Triangle w={14} h={5}
                                  direction={popoverProps && popoverProps.position === "before" ? "bottom" : "top"}/>
                        {/*<Triangle w={5} h={14} direction={"left"}/>*/}
                    </Box>
                    {
                        inboxPriority &&
                        <Inbox
                            onClose={() => {
                                setContextMenuAnchorEl(null)
                            }}
                            maxHeight={popoverProps?.maxHeight ?? 0}
                            communityId={communityId}
                            channelId={channelId}
                            allChildChannels={allChildChannels}
                            priority={inboxPriority}
                        />
                    }
                    {
                        bookmarkType &&
                        <Bookmarks
                            communityId={communityId}
                            channelId={channelId}
                            allChildChannels={allChildChannels}
                            maxHeight={popoverProps?.maxHeight ?? 0}
                            onClose={() => {
                                setContextMenuAnchorEl(null)
                            }}
                            type={bookmarkType}
                        />
                    }
                </Popover>
            </Row>
        </AnimatePresence>
    )
})

const Triangle = ({w, h, direction, color = 'white'}: {
    w: number
    h: number,
    direction: "top" | "right" | "bottom" | "left",
    color?: string
}) => {
    const points = {
        top: [`${w / 2},0`, `0,${h}`, `${w},${h}`],
        right: [`0,0`, `0,${h}`, `${w},${h / 2}`],
        bottom: [`0,0`, `${w},0`, `${w / 2},${h}`],
        left: [`${w},0`, `${w},${h}`, `0,${h / 2}`],
    }

    return (
        <svg style={{position: "absolute"}} width={w} height={h}>
            <polygon points={points[direction].join(' ')} fill={color}/>
            Sorry, your browser does not support inline SVG.
        </svg>
    )
}
