import {useDispatch} from "react-redux";
import useMemoizedState from "../../../../hooks/useMemoizedState";
import {useEffect} from "react";
import {firestoreManager} from "../../../../firebase/queryManager";
import {BookmarkFirestoreData} from "shared";
import {Bookmark} from "./bookmarkInterfaces";
import {removeBookmark, upsertBookmark} from "./bookmarksSlice";
import {getCommunityRef} from "../communities/communityUtils";


export function useCommunityBookmarks(communityId: string) {
    const dispatch = useDispatch()
    const [isLoading, setIsLoading] = useMemoizedState(true)
    useEffect(() => {
        const bookmarksColRef = getCommunityRef(communityId).collection("bookmarks")
        const {isNew, cancel} = firestoreManager.queryCollectionSnapshots(bookmarksColRef, (snapshot) => {
            setIsLoading(false)
            snapshot.docChanges().forEach((change) => {
                if (change.type === "added" || change.type === "modified") {
                    const bookmark = {
                        id: change.doc.id,
                        isCommunityBookmark: true,
                        ...(change.doc.data() as BookmarkFirestoreData)
                    } as Bookmark
                    dispatch(upsertBookmark(bookmark))
                } else if (change.type === "removed") {
                    dispatch(removeBookmark(change.doc.id))
                }
            })
        })
        setIsLoading(isNew)
        return cancel
    }, [dispatch, communityId, setIsLoading])
    return isLoading
}


