import {RootState} from "../../../../../store";
import {useMemo} from "react";
import {selectAllInboxMessages, selectUnreadMessagesForCommunity} from "../../../inbox/inboxSlice";
import {useSelector} from "react-redux";
import {LibraryItemWithLevel} from "./communityLibraryUtils";

export function useBadgeCountInboxCommunityList(communityIds: string[]) {

    const allInboxMessages = useSelector((state: RootState) => selectAllInboxMessages(state))

    const unreadMessageCount = useMemo(() => {
        const unreadMessageCount = {
            "normal": new Map<string, number>(),
            "high": new Map<string, number>(),
        }
        communityIds.forEach((communityId) => {
            unreadMessageCount.normal.set(communityId, 0)
            unreadMessageCount.high.set(communityId, 0)
        })
        allInboxMessages.forEach((inboxMessage) => {
            if (!inboxMessage.hasRead) {
                const priority = inboxMessage.priority
                unreadMessageCount[priority].set(
                    inboxMessage.details.communityId,
                    (unreadMessageCount[priority].get(inboxMessage.details.communityId) ?? 0) + 1
                )
            }
        })
        return unreadMessageCount
    }, [allInboxMessages, communityIds])

    return unreadMessageCount
}

export function useBadgeCountInboxCommunityLibrary(
    communityId: string,
    libraryList: LibraryItemWithLevel[],
    allChildChannelsMap: Map<string, string[]>
) {
    // compute num unread channel messages
    const selectUnreadMessagesForCommunityMemo = useMemo(selectUnreadMessagesForCommunity, [])
    const unreadMessages = useSelector(
        (state: RootState) => selectUnreadMessagesForCommunityMemo(state, communityId)
    )
    const channelUnreadMessageCountMap = useMemo(() => {
        const unreadMessageCount = {
            "normal": new Map<string, number>(),
            "high": new Map<string, number>()
        } as const

        unreadMessages.forEach((inboxMessage) => {
            const channelId = inboxMessage.details.channelId
            unreadMessageCount[inboxMessage.priority].set(
                channelId,
                (unreadMessageCount[inboxMessage.priority].get(channelId) ?? 0) + 1
            )
        })
        return unreadMessageCount
    }, [unreadMessages])

    const unreadMessageCountMap = useMemo(() => {
            const unreadMessageCountMap = {
                "normal": new Map(channelUnreadMessageCountMap["normal"]),
                "high": new Map(channelUnreadMessageCountMap["high"])
            }
            allChildChannelsMap.forEach((childChannels, folderId) => {
                let lowCount = 0
                let highCount = 0
                childChannels.forEach(id => {
                    lowCount += unreadMessageCountMap["normal"].get(id) ?? 0
                    highCount += unreadMessageCountMap["high"].get(id) ?? 0
                })
                unreadMessageCountMap["normal"].set(folderId, lowCount)
                unreadMessageCountMap["high"].set(folderId, highCount)
            })
            return unreadMessageCountMap
        },
        [allChildChannelsMap, channelUnreadMessageCountMap]
    )

    return unreadMessageCountMap
}
