import {firestore} from "../../../../firebase/firebase";
import {firestoreManager} from "../../../../firebase/queryManager";
import {useDispatch, useSelector} from "react-redux";
import {InboxMessageFirestoreData, inboxMessageProtocols} from "shared/user/inbox";
import {InboxMessage} from "./inboxInterfaces";
import {useEffect} from "react";
import {upsertInboxMessage} from "./inboxSlice";
import {RootState} from "../../../store";
// import {
//     loadIssueFromInboxMessageFrom,
// } from "../communities/channels/docChannel/features/issues/issueUtils";

// --------------------------
// parsers
// --------------------------

export function parseWildcard<T extends Record<string, string>>(template: string, path: string) {
    const templateSplit = template.split("/")

    const pathSplit = path.split("/")
    const params = {} as Record<string, string>
    for (let i = 0; i < templateSplit.length && i < pathSplit.length; i++) {
        const curr = templateSplit[i]
        if (curr === "*") {
            params["tail"] = pathSplit.slice(i, pathSplit.length).join("/")
        }
        if (curr[0] === "{") {
            const key = curr.slice(1, curr.length - 1)
            params[key] = pathSplit[i]
        }
    }
    return params as T
}

export function parseFrom(from: string) {
    const url = new URL(from)
    // for some reason new URL(protocol://path).pathname === //path
    // => remove "/" from pathname

    // NOTE url.pathname behaves differently in Safari and Chrome
    // if url = docchannel://communities/1/channels/2
    // => in Chrome: url.pathname = /communities/1/channels/2
    // => in Safari: 1/channels/2
    // const path = url.pathname.slice(1)

    const path = "/" + url.href.split("://")[1]

    // for some reason new URL(protocol://path).protocol === protocol:
    // => remove ":" from protocol
    const protocol = url.protocol.slice(0, url.protocol.length - 1) as inboxMessageProtocols

    // if (protocol !== "updates") {
    const parsed = parseWildcard<{
        communityId: string,
        channelId: string,
        tail: string,
    }>(
        "/communities/{communityId}/channels/{channelId}/*",
        path
    )
    return {
        protocol,
        params: parsed
    }
    // } else {
    //     const parsed = parseWildcard<{
    //         tail: string,
    //     }>(
    //         "/*",
    //         path
    //     )
    //     return {
    //         protocol,
    //         params: parsed
    //     }
    // }
}

// --------------------------
// loaders
// --------------------------

export async function loadMoreInboxMessages(
    userId: string,
    startAfterDate: string,
    limit: number
) {
    const inboxQuery = firestore.collection("userSecrets").doc(userId)
        .collection("inbox").orderBy("sentAt", "desc")
        .startAfter(startAfterDate).limit(limit)
    const docs = await firestoreManager.query(inboxQuery)
    return [docs.map((doc) => ({
        id: doc.id,
        ...(doc.data() as InboxMessageFirestoreData)
    } as InboxMessage)), limit] as [InboxMessage[], number]
}


export function useUnreadInboxMessages() {
    const auth = useSelector((state: RootState) => state.auth)
    const dispatch = useDispatch()
    useEffect(() => {
        if (auth.isSignedIn) {
            const inboxQuery = firestore.collection("userSecrets").doc(auth.uid)
                .collection("inbox")
                .orderBy("sentAt", "desc")
                .where("hasRead", "==", false)
            const res = firestoreManager.querySnapshots("inbox", inboxQuery, (snapshot) => {
                snapshot.docChanges().forEach((change) => {
                    if (change.type === "added" || change.type === "modified") {
                        const inboxMessage = {
                            id: change.doc.id,
                            ...(change.doc.data() as InboxMessageFirestoreData)
                        } as InboxMessage
                        dispatch(upsertInboxMessage(inboxMessage))
                    }
                })
            })
            return res.cancel
        }
    }, [dispatch, auth])
}
