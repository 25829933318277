import algoliaSearch from "algoliasearch/lite";

export const isInDevMode = window.location.hostname.includes("localhost")
export const algoliaSearchClient = algoliaSearch("SLP38K62MQ", "1231683a49d5018b3d1ccddad5be71a2")


export const algoliaCustomSearchClient = {
    ...algoliaSearchClient,
    search(requests: any) {
        if (requests[0].params.query === "") {
            return (async () => ({
                results: [{
                    exhaustiveNbHits: true,
                    exhaustiveTypo: true,
                    hits: [],
                    hitsPerPage: 20,
                    index: "devOpenCommunities",
                    nbHits: 0,
                    nbPages: 0,
                    page: 0,
                    params: "",
                    processingTimeMS: 1,
                    query: "",
                    renderingContent: {}
                }]
            }))()
        }
        return algoliaSearchClient.search(requests).then(res => {
            console.log("res", res)
            return res
        })
        // return algoliaSearchClient.search(requests);
    },

} as typeof algoliaSearchClient;
