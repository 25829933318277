import {createEntityAdapter, createSelector, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "../../../store";
import {InboxMessage} from "./inboxInterfaces"
import {ChannelReferenceInfo} from "shared/communities/channels/channel";
import {markInboxMessageAsRead} from "../../../../firebase/discunaApi";

const inboxMessageAdapter = createEntityAdapter<InboxMessage>()

const inboxSlice = createSlice({
    name: 'inbox',
    initialState: inboxMessageAdapter.getInitialState({
        noMoreLoad: false,
        // fromId: null as string | null,
        latestInboxMessageFetchedSentOn: null as string | null,
        allInboxMessagesFetched: false,
        queuedNotification: null as InboxMessage | null
    }),
    reducers: {
        addInboxMessage: inboxMessageAdapter.addOne,
        upsertInboxMessage: inboxMessageAdapter.upsertOne,
        updateInboxMessage: inboxMessageAdapter.updateOne,
        removeInboxMessage: inboxMessageAdapter.removeOne,
        setAllInboxMessages: inboxMessageAdapter.setAll,
        setQueuedNotification(state, action: PayloadAction<InboxMessage | null>) {
            state.queuedNotification = action.payload
        },
        setNoMoreLoad(state, action: PayloadAction<boolean>) {
            state.noMoreLoad = action.payload
        },
        // setFromId(state, action: PayloadAction<null | string>) {
        //     state.fromId = action.payload
        // },
        setLatestInboxMessageFetchedSentOn(state, action: PayloadAction<null | string>) {
            state.latestInboxMessageFetchedSentOn = action.payload
        },
        setAllInboxMessagesFetched(state, action: PayloadAction<boolean>) {
            state.allInboxMessagesFetched = action.payload
        }
    },
    extraReducers: builder => {
        builder.addCase(markInboxMessageAsRead.pending, (state, action) => {
            for(const inboxMessageId of action.meta.arg.inboxMessageIds) {
                const inboxMessage = state.entities[inboxMessageId]
                if (inboxMessage) inboxMessage.hasRead = action.meta.arg.value
            }
        })
    }
})

export const {
    setQueuedNotification,
    addInboxMessage,
    upsertInboxMessage,
    updateInboxMessage,
    removeInboxMessage,
    setAllInboxMessages,
    setNoMoreLoad,
    // setFromId,
    setLatestInboxMessageFetchedSentOn,
    setAllInboxMessagesFetched
} = inboxSlice.actions

export const inboxReducer = inboxSlice.reducer

export const {
    selectAll: selectAllInboxMessages,
    selectById: selectInboxMessageById,
    selectIds: selectInboxMessageIds
    // Pass in a selector that returns the posts slice of state
} = inboxMessageAdapter.getSelectors<RootState>(state => state.inbox)


export const selectUnreadMessagesForCommunity = () => createSelector(
    [
        (state: RootState) => selectAllInboxMessages(state),
        (state: RootState, communityId: string) => communityId
    ],
    (inboxMessages, communityId) => inboxMessages
        .filter(inboxMessage =>
            !inboxMessage.hasRead &&
            inboxMessage.details.communityId === communityId
        )
)

export const selectUnreadMessagesForChannel = () => createSelector(
    [
        (state: RootState) => selectAllInboxMessages(state),
        (state: RootState, channel?: ChannelReferenceInfo) => channel
    ],
    (inboxMessages, channel) => {
        if(!channel) return []
        return inboxMessages
            .filter(inboxMessage =>
                !inboxMessage.hasRead &&
                inboxMessage.details.channelId === channel.id
            )
    }
)
//
// export const selectUnreadMessageMatchingFrom = () => createSelector(
//     [
//         (state: RootState) => selectAllInboxMessages(state),
//         (state: RootState, from: string) => from
//     ],
//     (inboxMessages, from) => inboxMessages
//         .filter(inboxMessage => inboxMessage.details.from === from)[0]
// )


export const selectMessages = () => createSelector(
    [
        (state: RootState) => selectAllInboxMessages(state),
        (state: RootState, communityId: string) => communityId,
        (state: RootState, communityId: string, channelIds: string[] | null) => channelIds,
        (state: RootState, communityId: string, channelIds: string[] | null, priority: string) => priority,
        // (state: RootState, communityId: string, channelIds: string[] | null, hasRead: boolean) => hasRead
    ],
    (inboxMessages, communityId, channelIds, priority) => {

        return inboxMessages
            .filter(inboxMessage => {
                    return (
                        inboxMessage.priority === priority &&
                        // inboxMessage.hasRead === hasRead &&
                        inboxMessage.details.communityId === communityId &&
                        (!channelIds || channelIds.includes(inboxMessage.details.channelId))
                    )
                }
            ).sort((a, b) => a.sentAt < b.sentAt ? 1 : -1)
    }
)



