import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../store";
import {useEffect} from "react";
import {firestore} from "../../../../firebase/firebase";
import {firestoreManager} from "../../../../firebase/queryManager";
import {BookmarkFirestoreData} from "shared";
import {Bookmark} from "./bookmarkInterfaces";
import {removeBookmark, upsertBookmark} from "./bookmarksSlice";
import useMemoizedState from "../../../../hooks/useMemoizedState";


export function useBookmarks() {
    const auth = useSelector((state: RootState) => state.auth)
    const dispatch = useDispatch()
    const [isLoading, setIsLoading] = useMemoizedState(true)
    useEffect(() => {
        if (auth.isSignedIn) {
            const bookmarksColRef = firestore.collection("userSecrets").doc(auth.uid)
                .collection("bookmarks")
            const {isNew, cancel} = firestoreManager.queryCollectionSnapshots(bookmarksColRef, (snapshot) => {
                setIsLoading(false)
                snapshot.docChanges().forEach((change) => {
                    if (change.type === "added" || change.type === "modified") {
                        const bookmark = {
                            id: change.doc.id,
                            ...(change.doc.data() as BookmarkFirestoreData)
                        } as Bookmark
                        dispatch(upsertBookmark(bookmark))
                    } else if (change.type === "removed") {
                        dispatch(removeBookmark(change.doc.id))
                    }
                })
            })
            setIsLoading(isNew)
            return cancel
        }
    }, [dispatch, auth, setIsLoading])
    return isLoading
}
