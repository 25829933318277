// Firebase App (the core Firebase SDK) is always required and must be listed first
import firebase from "firebase/compat/app";
import "firebase/compat/storage"
// If you are using v7 or any earlier version of the JS SDK, you should import firebase using namespace import
// import * as firebase from "firebase/app"

// If you enabled Analytics in your project, add the Firebase SDK for Analytics
import "firebase/compat/analytics";

// Add the Firebase products that you want to use
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/functions";
import "firebase/compat/app-check"
// import storage = firebase.storage;
// import * as firebaseui from "firebaseui";

// import "firebase/messaging"

// TODO: Replace the following with your app's Firebase project configuration
// For Firebase JavaScript SDK v7.20.0 and later, `measurementId` is an optional field
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyCLekW0jxJB7YZV6Q-18hP_U9nO_W4_qVU",
    authDomain: "scripto-7f81e.firebaseapp.com",
    projectId: "scripto-7f81e",
    storageBucket: "scripto-7f81e.appspot.com", // not gs://scripto-7f81e.appspot.com ?
    messagingSenderId: "727385565667",
    appId: "1:727385565667:web:edd8c711e1e0368b5d18d6",
    measurementId: "G-8ZDJNPPJ3D",
};

firebase.initializeApp(firebaseConfig);

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    // dev code
} else {
    // production code
    const analytics = firebase.analytics()
    const appCheck = firebase.appCheck()
    // Pass your reCAPTCHA v3 site key (public key) to activate(). Make sure this
    // key is the counterpart to the secret key you set in the Firebase console.
    appCheck.activate(
        '6Ld8lY4eAAAAABnOV-mhRYQFKTDUllUYIH29uK_r',

        // Optional argument. If true, the SDK automatically refreshes App Check
        // tokens as needed.
        true);
}


export let GoogleAuthProvider = firebase.auth.GoogleAuthProvider
// export let AnonymousAuthProvider = firebaseui.auth.AnonymousAuthProvider
export let EmailAuthProvider = firebase.auth.EmailAuthProvider
export let FieldValue = firebase.firestore.FieldValue;
export let FieldPath = firebase.firestore.FieldPath;
// export let FirestoreError = firebase.firestore.FirestoreError;
export let firestore = firebase.firestore();
export let auth = firebase.auth();
export let functions = firebase.app().functions("europe-west3")
// export let messaging = firebase.messaging()

export type FirestoreDocumentRef = firebase.firestore.DocumentReference<firebase.firestore.DocumentData>
export type FirestoreCollectionRef = firebase.firestore.CollectionReference<firebase.firestore.DocumentData>
export type FirestoreQuery = firebase.firestore.Query<firebase.firestore.DocumentData>
export type FirestoreDocumentChange = firebase.firestore.DocumentChange<firebase.firestore.DocumentData>
export type FirestoreQueryDocumentSnapshot = firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>[]
export type FirestoreQuerySnapshot = firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>
export type FirestoreDocumentSnapshot = firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>
export type FunctionsHttpsCallable = firebase.functions.HttpsCallable

// auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL).then(() => {
//     // Existing and future Auth states are now persisted in the current
//     // session only. Closing the window would clear any existing state even
//     // if a user forgets to sign out.
//     // ...
//     // New sign-in will be persisted with session persistence.
//     return firebase.auth().signInWithEmailAndPassword(email, password);
// })
//     .catch((error) => {
//         // Handle Errors here.
//         var errorCode = error.code;
//         var errorMessage = error.message;
//     });
// ;

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    // dev code
    firestore.useEmulator("localhost", 8080);
    firebase.storage().useEmulator("localhost", 8090)
    auth.useEmulator("http://localhost:9099");
    functions.useEmulator("localhost", 5001)
} else {
    // production code
}


// Initialize the FirebaseUI Widget using Firebase.
// var ui = new firebaseui.auth.AuthUI(firebase.auth());

// ui.start('#firebaseui-auth-container', {
//     signInOptions: [
//         firebase.auth.EmailAuthProvider.PROVIDER_ID
//     ],
//     // Other config options...
// });
