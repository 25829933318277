import {selectInboxMessageById, selectMessages, setQueuedNotification} from "./inboxSlice";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState, useAppDispatch} from "../../../store";
import {
    IconButton, List,
    ListItem, ListItemButton,
    ListItemIcon,
    ListItemText, Tooltip, Typography
} from "@mui/material";
import {Column, Row} from "../../../../components/motion_mui";
import {Icon} from "@iconify/react";
import {InboxMessage} from "./inboxInterfaces";
import UnfoldMoreOutlinedIcon from '@mui/icons-material/UnfoldMoreOutlined';
import {primaryLightBackground} from "../../../../constants/constants";
import {NotificationContextModal} from "./notificationContextModal";
import {markInboxMessageAsRead} from "../../../../firebase/discunaApi";
import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import {selectDiscunaAppById} from "../communities/apps/discunaAppSlice";
import {useNavigate} from "react-router-dom";
import {discunaAppFrontendApiClient} from "../../../discunaCoreFrontendCom/discunaAppFrontendApiClient";
import {setSelectedCommunityId} from "../../dashboardSlice";


export function Inbox({priority, communityId, channelId, allChildChannels, maxHeight, onClose}: {
    communityId: string,
    channelId?: string,
    allChildChannels?: string[],
    maxHeight: number,
    onClose: () => void,
    priority: string
}) {

    // NOTE we do not need to load unread messages
    // because all unread messages are loaded as soon as the user logs in

    // TODO Load more callback
    //  we do not need a load more callback anymore
    const [selectedNotification, setSelectedNotification] = useState<null | InboxMessage>(null)

    // const navigate = useNavigate()
    const dispatch = useAppDispatch()

    return (
        <Column
            mainAxisAlignment={"start"}
            crossAxisAlignment={"stretch"}
            sx={{
                width: 540,
                maxWidth: "80vw",
                maxHeight,
                // maxHeight: "calc(100vh - 32px)",
                // minHeight: "calc(100vh - 32px)",
                overflow: "auto",
                borderRadius: 1,
                position: "relative"
            }}
        >
            <InboxMessageList
                priority={priority}
                onClose={onClose}
                communityId={communityId}
                channelId={channelId}
                allChildChannels={allChildChannels}
                setSelectedNotification={(inboxMessage) => {
                    setSelectedNotification(inboxMessage)
                    if (!inboxMessage.hasRead) {
                        dispatch(markInboxMessageAsRead({
                            inboxMessageIds: [inboxMessage.id],
                            value: true
                        }))
                    }

                    // dispatch(setFromId(parsed.params.tail.split("/").pop() ?? null))
                }}
            />

            <NotificationContextModal
                show={Boolean(selectedNotification)}
                onClose={() => setSelectedNotification(null)}
                notification={selectedNotification}
            />
        </Column>
    )
}

function InboxMessageList({communityId, channelId, allChildChannels, setSelectedNotification, onClose, priority}: {
    communityId: string,
    channelId?: string,
    allChildChannels?: string[],
    setSelectedNotification: (inboxMessage: InboxMessage) => void,
    onClose: () => void,
    priority: string
}) {
    const selectMessagesMemo = useMemo(selectMessages, [])
    const messages = useSelector((state: RootState) => selectMessagesMemo(
        state,
        communityId,
        channelId ? (allChildChannels ? allChildChannels : [channelId]) : null,
        priority
    ))

    const unreadMessageIds = useMemo(() => {
        return messages.filter((message) => !message.hasRead).map(message => message.id)
    }, [messages])

    const dispatch = useAppDispatch()

    return (
        <>
            <Typography
                variant={"body2"}
                sx={{
                    alignSelf: "start",
                    mx: 2,
                    mt: 1,
                    // color: "primary.main",
                    "&:hover": {
                        textDecoration: "underline"
                    },
                    color: "darkgrey",
                    cursor: "pointer"
                }}
                onClick={() => {
                    dispatch(markInboxMessageAsRead({
                        inboxMessageIds: unreadMessageIds,
                        value: true
                    }))
                }}
            >
                Mark all notifications as read
            </Typography>
            <List dense sx={{
                minWidth: 340
            }}>
                {
                    messages.map((message) => (
                        <InboxItem
                            onPreviewNotification={() => setSelectedNotification(message)}
                            key={message.id}
                            inboxMessageId={message.id}
                            onClose={onClose}
                        />
                    ))
                }
            </List>
        </>
    )
}


function InboxItem({inboxMessageId, onPreviewNotification, onClose}: {
    inboxMessageId: string,
    onPreviewNotification: () => void,
    onClose: () => void
}) {

    const inboxMessage = useSelector((state: RootState) => selectInboxMessageById(state, inboxMessageId))
    if(!inboxMessage) throw Error("inbox message not found")
    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    // const [isHovered, setIsHovered] = useState(false)
    // const selectedCommunityId = useSelector((state: RootState) => state.dashboard.selectedCommunityId)

    const app = useSelector((state: RootState) => selectDiscunaAppById(state, inboxMessage.details.appId))

    const notificationClickCallback = useCallback(async () => {
        if (app) {
            dispatch(markInboxMessageAsRead({
                inboxMessageIds: [inboxMessage.id],
                value: true,
            }))
            const {communityId, channelId} = inboxMessage.details
            const iframe = document.getElementById(`iframe-${channelId}`) as HTMLIFrameElement | null
            if (iframe?.contentWindow) {
                discunaAppFrontendApiClient.notificationClick(
                    inboxMessage.id,
                    inboxMessage.details.data,
                    app.details.config.pages.origin,
                    iframe.contentWindow
                ).catch((err) => {console.error(err)})
            } else {
                dispatch(setQueuedNotification(inboxMessage))
                navigate(`communities/${communityId}/channels/${channelId}`)
            }
            // navigate to community
            dispatch(setSelectedCommunityId(communityId))
            onClose()
        }
    }, [onClose, app, dispatch, inboxMessage, navigate])

    return (
        <ListItem
            disablePadding
            secondaryAction={(

                <Row mainAxisAlignment={"start"} crossAxisAlignment={"start"}>
                    <Tooltip title={`Message ${inboxMessage.hasRead ? "was read" : "is unread"}`}>
                        <IconButton
                            size={"small"}
                            // sx={{ml: 1}}
                            onClick={() => {
                                dispatch(markInboxMessageAsRead({
                                    inboxMessageIds: [inboxMessage.id],
                                    value: !inboxMessage.hasRead
                                }))
                                // const parsed = parseFrom(inboxMessage.details.from)
                                // if (parsed.protocol !== "updates") {
                                //     const {communityId, channelId} = parsed.params
                                //     dispatch(setSelectedCommunityId(communityId))
                                //     navigate(`/dashboard/communities/${communityId}/channels/${channelId}`)
                                // }
                            }}
                        >
                            {
                                inboxMessage.hasRead ?
                                    <CheckBoxOutlinedIcon/> :
                                    <CheckBoxOutlineBlankOutlinedIcon/>

                            }
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={"Unfold context"}>
                        <IconButton
                            size={"small"}
                            sx={{ml: 0.5}}
                            edge={"end"}
                            onClick={() => {
                                // const parsed = parseFrom(inboxMessage.details.from)
                                // if (parsed.protocol !== "updates") {
                                //     const {communityId, channelId} = parsed.params
                                //     dispatch(setSelectedCommunityId(communityId))
                                //     navigate(`/dashboard/communities/${communityId}/channels/${channelId}`)
                                // }
                                onPreviewNotification()
                            }}
                        >
                            <UnfoldMoreOutlinedIcon/>
                        </IconButton>
                    </Tooltip>
                </Row>
            )}
            sx={{
                position: "relative",
                // "&:hover": {
                //     bgcolor: "lightgray"
                // }
            }}

        >
            <ListItemButton
                dense
                sx={{
                    pr: "92px !important",
                    bgcolor: !inboxMessage.hasRead ? primaryLightBackground : "initial",
                }}
                onClick={() => {
                    // NOTE
                    // dispatch(setSelectedInboxMessagePath(inboxMessage.details.from))
                    // if (!selectedCommunityId) onClose()

                    notificationClickCallback()
                    // dispatch(setSelectedInboxMessagePath(inboxMessage.details.from))
                    // navigateToInboxMessageOrigin(inboxMessage.details.from)
                }}
            >

                <ListItemIcon sx={{
                    // pl: 2,
                    // pt: 1
                }}>
                    {
                        inboxMessage.details.icon.split(":")[0] === "ic" &&
                        <Icon height={24} width={24} icon={inboxMessage.details.icon}/>
                    }
                </ListItemIcon>
                <ListItemText
                    primary={inboxMessage.details.title}
                    secondary={inboxMessage.details.body}
                />

            </ListItemButton>
        </ListItem>
    )
}
