

import {createEntityAdapter, createSlice} from "@reduxjs/toolkit";
import {DiscunaAppStoreItem} from "./discunaAppInterface";
import {RootState} from "../../../../store";

const discunaAppStoreItemEntityAdapter = createEntityAdapter<DiscunaAppStoreItem>()


const discunaAppStoreItemSlice = createSlice({
    name: "discunaAppStoreItems",
    initialState: discunaAppStoreItemEntityAdapter.getInitialState({}),
    reducers: {
        addDiscunaAppStoreItem: discunaAppStoreItemEntityAdapter.addOne,
        upsertDiscunaAppStoreItem: discunaAppStoreItemEntityAdapter.upsertOne,
        updateOneDiscunaAppStoreItem: discunaAppStoreItemEntityAdapter.updateOne,
        removeDiscunaAppStoreItem: discunaAppStoreItemEntityAdapter.removeOne,
        removeAllDiscunaAppStoreItems: discunaAppStoreItemEntityAdapter.removeAll,
        setAllDiscunaAppStoreItems: discunaAppStoreItemEntityAdapter.setAll,
    },
})

export const discunaAppStoreItemReducer = discunaAppStoreItemSlice.reducer

export const {
    addDiscunaAppStoreItem,
    upsertDiscunaAppStoreItem,
    updateOneDiscunaAppStoreItem,
    setAllDiscunaAppStoreItems,
    removeDiscunaAppStoreItem,
    removeAllDiscunaAppStoreItems
} = discunaAppStoreItemSlice.actions

// selectors
export const {
    selectById: selectDiscunaAppStoreItemById,
    selectAll: selectAllDiscunaAppStoreItems,
} = discunaAppStoreItemEntityAdapter.getSelectors((state: RootState) => state.discunaAppStoreItems)
