import {createEntityAdapter, createSelector, createSlice, PayloadAction} from '@reduxjs/toolkit'
import {RootState} from "../../../../store";
import {auth} from '../../../../../firebase/firebase';
import {Member} from "./memberInterfaces";
import {createCommunity, getUser} from "../../../../../firebase/discunaApi";
import {createMemberFromArgs, memberRoles} from "shared/communities/members/member";

const memberEntityAdapter = createEntityAdapter<Member>()

export function getMemberId(communityId: string, userId?: string) {
    let uid = userId
    if(!uid) {
        if (!auth.currentUser) throw Error("auth")
        uid = auth.currentUser.uid
    }
    return communityId + "_" + uid
}

const memberSlice = createSlice({
    name: "members",
    initialState: memberEntityAdapter.getInitialState({}),
    reducers: {
        addMember: memberEntityAdapter.addOne,
        upsertMember: memberEntityAdapter.upsertOne,
        updateMember: memberEntityAdapter.updateOne,
        removeMember: memberEntityAdapter.removeOne,
        removeAllMembers: memberEntityAdapter.removeAll,
        setAllMembers: memberEntityAdapter.setAll
    },
    extraReducers: builder => {
        builder.addCase(createCommunity.fulfilled,
            (state, action) => {
                const args = action.meta.arg
                const member = state.entities[getMemberId(args.communityId)]
                if (member) member.isSynced = true
            })
            .addCase(createCommunity.pending,
                (state, action) => {
                if(auth.currentUser) {
                    const args = action.meta.arg
                    const member = {
                        // redux
                        id: getMemberId(args.communityId),
                        isSynced: false,
                        communityId: args.communityId,
                        // firestore
                        ...createMemberFromArgs(getUser(), "administrator")
                    } as Member
                    memberEntityAdapter.upsertOne(state, member)
                }
                })
    }
})

export const {
    addMember,
    upsertMember,
    updateMember,
    removeMember,
    removeAllMembers,
    setAllMembers
} = memberSlice.actions

export const memberReducer = memberSlice.reducer

export const {
    selectAll: selectAllMembers,
    selectById: selectMemberById,
    selectIds: selectMemberIds,
} = memberEntityAdapter.getSelectors<RootState>(state => state.members)

export const selectMembersByRole = () => createSelector(
    [
        (state: RootState) => selectAllMembers(state),
        (state: RootState, role: memberRoles) => role,
        (state: RootState, role: memberRoles, communityId: string) => communityId
    ],
    (members, role, communityId) => {
        const membersWithRole = members.filter((member) => member.role === role && member.communityId === communityId)
        membersWithRole.sort((ma, mb) => {
            if(!ma.details.name) return 1
            if(!mb.details.name) return -1
            return ma.details.name < mb.details.name ? -1 : 1
        })
        return membersWithRole
    }
)

export const selectMembersByCommunityId = () => createSelector(
    [
        (state: RootState) => selectAllMembers(state),
        (state: RootState, communityId: string) => communityId
    ],
    (members, communityId) => {
        const membersWithRole = members.filter((member) => member.communityId === communityId && member.userId !== "insights")
        membersWithRole.sort((ma, mb) => {
            if(!ma.details.name) return 1
            if(!mb.details.name) return -1
            return ma.details.name < mb.details.name ? -1 : 1
        })
        return membersWithRole
    }
)


export const selectMemberships = () => createSelector(
    [
        (state: RootState) => selectAllMembers(state),
        (state: RootState, userId: string | null) => userId,
        (state: RootState, userId: string | null, communityIds: string[]) => communityIds,
    ],
    (members, userId, communityIds) => {
        const membershipMap = new Map<string, Member>()
        members.forEach((member) => {
            if(member.userId === userId && communityIds.includes(member.communityId)) {
                membershipMap.set(member.communityId, member)
            }
        })
        return membershipMap
    }
)
