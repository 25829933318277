import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {auth} from "../../firebase/firebase";
import {signIn, signOut} from "./authSlice";
import {RootState} from "../store";
import {getIdToken, getAuth, fetchSignInMethodsForEmail, EmailAuthProvider} from "firebase/auth";
import {deleteDeviceToken} from "../../firebase/discunaApi";
import {getPushyDeviceToken} from "../pushyUtils";

export type signInMethods = "password" | "email"

export async function getSignInMethod(email: string) {
    const auth = getAuth();
    const signInMethods = await fetchSignInMethodsForEmail(auth, email)
    if (signInMethods.indexOf(EmailAuthProvider.EMAIL_PASSWORD_SIGN_IN_METHOD) !== -1) {
        // User can sign in with email/password.
        return "password" as const
    }
    if (signInMethods.indexOf(EmailAuthProvider.EMAIL_LINK_SIGN_IN_METHOD) !== -1) {
        // User can sign in with email/link.
        return "email" as const
    }
    return null
}

export function useIsAuthor<T extends {authorId: string | null}>(data: T) {
    const auth = useSelector((state: RootState) => state.auth)
    if(auth.isSignedIn) {
        return auth.uid === data.authorId
    }
    return false
}

export async function refreshToken(){
    const currentUser = getAuth().currentUser
    if(currentUser) await getIdToken(currentUser, true)
}

export function logout() {
    const deviceToken = getPushyDeviceToken()
    if(deviceToken) {
        deleteDeviceToken({
            deviceToken,
            platform: "browser"
        }).catch(console.error)
    }
    getAuth().signOut().catch(console.error)
}

export function useAuth() {
    const dispatch = useDispatch()
    useEffect(() => {
        // check if user has upgraded
        const auth = getAuth()
        const cancel1 = auth.onIdTokenChanged(async (user) => {
            if(user) {
                dispatch(signIn({
                    uid: user.uid,
                    providerId: user.providerId,
                    data: user.email ? {
                        type: "email",
                        ...(user.displayName) && {
                            name: user.displayName
                        },
                        email: user.email,
                        emailVerified: user.emailVerified
                    } : {
                        type: "guest"
                    }
                }))
            } else {
                dispatch({ type: 'RESET_STATE' });
                dispatch(signOut());
            }
        })
        //
        // const cancel2 = auth.onAuthStateChanged(user => {
        //     console.log("auth changed", user)
        //     if (user) {
        //         dispatch(signIn({
        //             uid: user.uid,
        //             providerId: user.providerId,
        //             data: user.email ? {
        //                 type: "email",
        //                 ...(user.displayName) && {
        //                     name: user.displayName
        //                 },
        //                 email: user.email,
        //                 emailVerified: user.emailVerified
        //             } : {
        //                 type: "guest"
        //             }
        //         }))
        //     } else {
        //         dispatch(signOut());
        //     }
        // })
        return () => {
            cancel1()
            // cancel2()
        }
    }, [dispatch]);
}
