import React, {useCallback, useEffect, useState} from "react";
import {Center, Column, Row} from "../../components/motion_mui";
import {
    Alert,
    Button,
    CircularProgress,
    FilledInput, FormControl, FormHelperText,
    IconButton,
    InputAdornment, InputLabel,
    Radio,
    TextField,
    Typography
} from "@mui/material";
import {
    EmailAuthProvider,
    fetchSignInMethodsForEmail,
    getAuth,
    linkWithCredential,
    reauthenticateWithCredential,
    updatePassword,
    unlink,
    signInWithEmailLink,
    sendSignInLinkToEmail
} from "firebase/auth";
import {useSelector} from "react-redux";
import {RootState} from "../store";
import {PasswordTextField} from "../../components/PasswordTextField";
import {getSignInMethod, signInMethods} from "./authUtils";


export function SetSignInMethod() {

    const [signInMethod, setSignInMethod] = useState<signInMethods | null>(null)
    const [newSignInMethod, setNewSignInMethod] = useState<signInMethods | null>(null)

    const [oldPassword, setOldPassword] = useState<string>("")
    const [newPassword, setNewPassword] = useState<string>("")
    const [newPasswordConfirm, setNewPasswordConfirm] = useState<string>("")
    const [changePassword, setChangePassword] = useState(false)

    const [updateProgress, setUpdateProgress] = useState<null | "processing" | "done" | "error">(null)
    const [alertMessage, setAlertMessage] = useState("")

    const setError = useCallback((msg: string) => {
        setUpdateProgress("error")
        setAlertMessage(`Error occurred: ${msg}`)
    }, [])

    const setDone = useCallback((msg: string) => {
        setUpdateProgress("done")
        setAlertMessage(msg)
    }, [])

    const handleChange = useCallback(async (event: React.ChangeEvent<HTMLInputElement>) => {
        setNewSignInMethod(event.target.value as signInMethods)
    }, [])

    const auth = useSelector((state: RootState) => state.auth)
    useEffect(() => {
        if (auth.isSignedIn && auth.data.type === "email") {
            getSignInMethod(auth.data.email).then((res) => {
                if(!res) setError("no sign in method found")
                else {
                    setSignInMethod(res)
                    setNewSignInMethod(res)
                }
            }).catch((e) => setError(e.message ?? "unknown"))
        }
    }, [auth, setError])

    const signInMethodIsValid = signInMethod === "password" || signInMethod === "email"

    const cancel = useCallback(() => {
        setNewSignInMethod(signInMethod)

        // password
        setOldPassword("")
        setNewPassword("")
        setNewPasswordConfirm("")
        setChangePassword(false)

        // error / success message
        setUpdateProgress(null)
        setAlertMessage("")
    }, [signInMethod])

    const onCloseAlert = useCallback(() => {
        cancel()
    }, [cancel])

    const update = useCallback(async (updateDate: {
        updateAction: "newSignInMethod",
        signInData: {
            type: "email"
        } | {
            type: "password",
            newPassword: string,
        }
    } | {
        updateAction: "changePassword",
        signInData: {
            oldPassword: string,
            newPassword: string
        }
    }) => {
        const currentUser = getAuth().currentUser
        const isDevMode = window.location.origin.includes("localhost")
        if (!currentUser || !currentUser.email || (!isDevMode && !currentUser.emailVerified))
            return setError("auth required")
        try {
            setUpdateProgress("processing")
            if (updateDate.updateAction === "newSignInMethod") {
                // change sign in method
                if (updateDate.signInData.type === "password") {
                    // change sign in method to password authentication

                    // NOTE we use update password instead of unlink because this will leave the email verified
                    // As a result, fetchSignInMethodsForEmail will return ["emailLink", "password"]
                    try {
                        await updatePassword(currentUser, updateDate.signInData.newPassword)
                        return setDone("Successfully changed sign-in method to password authentication.")
                    } catch (e) {
                        // update password failed, most likely because user has to re-authenticate
                        console.error("update password failed", e)
                    }

                    // user has to re-authenticate
                    const url = `${window.origin}/auth/reauthenticate?app=${window.discunaDesktopAPI ? "desktopApp" : "desktopBrowser"}`
                    await sendSignInLinkToEmail(getAuth(), currentUser.email, {
                        url,
                        handleCodeInApp: true
                    })
                    setError("Re-authentication required. Please open the sign-in link in the email we just sent you and try again.")
                } else {
                    // change sign in method to email link authentication
                    // send email with sign in link to user
                    // once the user clicks on the sign-in link, they will be redirected to handleUpdateSignInMethod
                    // component that updates the sign-in method to email link authentication
                    const url = `${window.origin}/auth/completeUpdateSignInMethod?app=${window.discunaDesktopAPI ? "desktopApp" : "desktopBrowser"}`
                    await sendSignInLinkToEmail(getAuth(), currentUser.email, {
                        url,
                        handleCodeInApp: true
                    })
                    setDone("Action required. Please open the sign-in link in the email we just sent you to complete the requested change.")
                }
            } else {
                // change password
                // https://firebase.google.com/docs/auth/web/manage-users#set_a_users_password
                // NOTE: "To set a user's password, the user must have signed in recently"
                const credential = EmailAuthProvider.credential(
                    currentUser.email, updateDate.signInData.oldPassword)
                const userCredential = await reauthenticateWithCredential(currentUser, credential)
                await updatePassword(userCredential.user, updateDate.signInData.newPassword)
                setDone("Successfully changed password.")
            }
        } catch (e: any) {
            console.error("update", e)
            setError(e.code ?? "unknown")
        }
    }, [setDone, setError])

    const passwordIsValid = newPassword.length >= 6 && newPassword === newPasswordConfirm

    const enableUpdate = signInMethodIsValid && updateProgress === null && (
        (newSignInMethod !== signInMethod && (newSignInMethod === "password" ? passwordIsValid : true)) ||
        (newSignInMethod === "password" && changePassword && passwordIsValid && oldPassword.length > 0)
    )

    const [showPassword, setShowPassword] = useState(false)

    return (
        <Column mainAxisAlignment={"start"} crossAxisAlignment={"stretch"}>
            {
                (signInMethod === null || updateProgress === "processing") &&
                <Center style={{flexGrow: 1}}>
                    <CircularProgress/>
                </Center>
            }
            {
                signInMethodIsValid && updateProgress === null &&
                <Column mainAxisAlignment={"start"} crossAxisAlignment={"stretch"}>
                    <Row sx={{mt: "-9px"}} mainAxisAlignment={"start"} crossAxisAlignment={"start"}>
                        <Radio
                            edge={"start"}
                            checked={newSignInMethod === 'email'}
                            onChange={handleChange}
                            value="email"
                            name="radio-buttons"
                            inputProps={{'aria-label': 'Email-Link'}}
                        />
                        <Column sx={{mt: "9px"}} mainAxisAlignment={"start"} crossAxisAlignment={"start"}>
                            <Typography variant={"body1"} sx={{cursor: "pointer"}} onClick={() => setNewSignInMethod("email")}>
                                Email link authentication
                            </Typography>
                        </Column>
                    </Row>
                    <Row mainAxisAlignment={"stretch"} crossAxisAlignment={"start"}>
                        <Radio
                            edge={"start"}
                            checked={newSignInMethod === 'password'}
                            onChange={handleChange}
                            value="password"
                            name="radio-buttons"
                            inputProps={{'aria-label': 'Password'}}
                        />
                        <Column sx={{mt: "9px", flexGrow: 1}} mainAxisAlignment={"start"}
                                crossAxisAlignment={"stretch"}>
                            <Typography variant={"body1"} sx={{cursor: "pointer"}} onClick={() => setNewSignInMethod("password")}>
                                Password authentication
                            </Typography>
                            {
                                newSignInMethod === "password" && signInMethod !== "password" &&
                                <Column mainAxisAlignment={"start"} crossAxisAlignment={"stretch"}>
                                    <PasswordTextField
                                        password={newPassword}
                                        setPassword={setNewPassword}
                                        showPassword={showPassword}
                                        setShowPassword={setShowPassword}
                                        sx={{mt: 1.5}}
                                        label={"Password"}
                                        id={"new-password"}
                                    />
                                    <PasswordTextField
                                        password={newPasswordConfirm}
                                        setPassword={setNewPasswordConfirm}
                                        showPassword={showPassword}
                                        setShowPassword={setShowPassword}
                                        sx={{mt: 1.5}}
                                        label={"Confirm password"}
                                        id={"new-password-confirm"}
                                    />
                                </Column>
                            }
                            {
                                newSignInMethod === "password" && signInMethod === "password" &&
                                (
                                    changePassword ?
                                        <Column mainAxisAlignment={"start"} crossAxisAlignment={"stretch"}>
                                            <PasswordTextField
                                                password={oldPassword}
                                                setPassword={setOldPassword}
                                                showPassword={showPassword}
                                                setShowPassword={setShowPassword}
                                                sx={{mt: 1.5}}
                                                label={"Current password"}
                                                id={"current-password"}
                                            />
                                            <PasswordTextField
                                                password={newPassword}
                                                setPassword={setNewPassword}
                                                showPassword={showPassword}
                                                setShowPassword={setShowPassword}
                                                sx={{mt: 1.5}}
                                                label={"New password"}
                                                id={"new-password"}
                                            />
                                            <PasswordTextField
                                                password={newPasswordConfirm}
                                                setPassword={setNewPasswordConfirm}
                                                showPassword={showPassword}
                                                setShowPassword={setShowPassword}
                                                sx={{mt: 1.5}}
                                                label={"Confirm new password"}
                                                id={"new-password-confirm"}
                                            />
                                        </Column>
                                        :
                                        <Button
                                            size={"small"}
                                            // variant={"outlined"}
                                            onClick={() => setChangePassword(true)}
                                            sx={{
                                                ml: "-5px",
                                                mt: 0.5,
                                                alignSelf: "start"
                                            }}
                                        >
                                            Change password
                                        </Button>
                                )
                            }
                        </Column>
                    </Row>

                    <Row
                        mainAxisAlignment={"start"}
                        crossAxisAlignment={"center"}
                        sx={{mt: 4, alignSelf: "end"}}
                    >
                        <Button disabled={!enableUpdate && !changePassword} onClick={cancel}>
                            Cancel
                        </Button>
                        <Button
                            variant={"contained"}
                            sx={{ml: 2}}
                            disabled={!enableUpdate}
                            onClick={() => {
                                if (newSignInMethod === "email") {
                                    update({
                                        updateAction: "newSignInMethod",
                                        signInData: {type: "email"}
                                    })
                                } else if (newSignInMethod === "password") {
                                    if (changePassword) {
                                        update({
                                            updateAction: "changePassword",
                                            signInData: {
                                                oldPassword,
                                                newPassword
                                            }
                                        })
                                    } else {
                                        update({
                                            updateAction: "newSignInMethod",
                                            signInData: {
                                                type: "password",
                                                newPassword
                                            }
                                        })
                                    }
                                }
                            }}
                        >
                            Update
                        </Button>
                    </Row>
                </Column>
            }
            {
                updateProgress === "error" &&
                <Alert onClose={onCloseAlert} severity={"error"}>
                    {alertMessage}
                </Alert>
            }
            {
                updateProgress === "done" &&
                <Alert onClose={onCloseAlert} severity={"success"}>
                    {alertMessage}
                </Alert>
            }
        </Column>
    )
}
