import {InboxMessage} from "./inboxInterfaces";
import React, {useEffect, useMemo, useRef, useState} from "react";
import {useSelector} from "react-redux";
import {RootState} from "../../../store";
import {Center, Column} from "../../../../components/motion_mui";
import {CircularProgress} from "@mui/material";
import {ModalTitle, ModalWithPresence} from "../../../../components/modal";
import {useCommunityAppsOnce} from "../communities/apps/discunaAppUtils";
import {selectDiscunaAppById} from "../communities/apps/discunaAppSlice";
import {discunaCoreFrontendApiHost} from "../../../discunaCoreFrontendCom/discunaCoreFrontendApiHost";


export function NotificationContextModal({show, onClose, notification}: {
    show: boolean,
    onClose: () => void,
    notification: null | InboxMessage
}){

    return (
        <ModalWithPresence
            show={show}
            onCancel={onClose}
            paperSx={{
                px: 0,
                pb: 0
            }}
        >
            {
                notification &&
                <NotificationContext
                    notification={notification}
                    onClose={onClose}
                />
            }
        </ModalWithPresence>
    )
}

function NotificationContext({notification, onClose}: {
    notification: InboxMessage,
    onClose: () => void,
}) {
    // fetch app
    // useCommunityAppsOnce([notification.details.appId])
    const app = useSelector((state: RootState) => selectDiscunaAppById(state, notification.details.appId))

    const auth = useSelector((state: RootState) => state.auth)
    if(!auth.isSignedIn) throw Error("Not signed in")

    // iframe is loading
    const [iframeIsLoading, setIframeIsLoading] = useState<boolean>(true)
    const iframeRef = useRef<HTMLIFrameElement>(null)
    const iframeSrc = useMemo(() => {
        if (app && app.details.config.pages.origin)
            return `${app.details.config.pages.origin}/discuna/communities/${notification.details.communityId}/channels/${notification.details.channelId}/notifications/${notification.id}/context`
        else return null
    }, [app, notification.details.channelId, notification.details.communityId, notification.id])

    useEffect(() => {
        if(iframeSrc && iframeRef.current && iframeRef.current.contentWindow) {
            return discunaCoreFrontendApiHost.onPageIsReady(async () => {
                setIframeIsLoading(false)
            }, new URL(iframeSrc).origin, iframeRef.current.contentWindow)
        }
    }, [iframeSrc])

    useEffect(() => {
        if(iframeSrc && iframeRef.current && iframeRef.current.contentWindow) {
            return discunaCoreFrontendApiHost.onGetNotificationData(async () => {
                return notification.details.data
            }, new URL(iframeSrc).origin, iframeRef.current.contentWindow)
        }
    }, [iframeSrc, notification.details.data])

    // member ID handler
    useEffect(() => {
        if (app && iframeRef.current?.contentWindow) {
            return discunaCoreFrontendApiHost.onGetMemberId(() => {
                    return auth.uid
                },
                app.details.config.pages.origin,
                iframeRef.current?.contentWindow
            )
        }
    }, [app, auth])

    return (
        <>
            <ModalTitle title={notification.details.title} onCancel={onClose} sx={{mx: 2.5, mb: 0}}/>
            <Column mainAxisAlignment={"start"} crossAxisAlignment={"stretch"}>
                {
                    (iframeIsLoading || !app) &&
                    <Center sx={{height: 400}}>
                        <CircularProgress size={24}/>
                    </Center>
                }
                {
                    iframeSrc &&
                    <iframe
                        ref={iframeRef}
                        title={"notification context"}
                        src={iframeSrc}
                        height={400}
                        style={{border: "none", display: iframeIsLoading ? "none" : "inherit"}}
                    />
                }
            </Column>
        </>
    )
}
