import {Typography} from "@mui/material";
import {DiscunaText} from "../app/auth/discunaText";
import React from "react";


export function DiscunaLogoAndTextBlack() {
    return (
        <>
            <img
                placeholder={"discuna logo"}
                src={"/assets/logos/discuna-icon.svg"}
                height={40}
                alt={"Discuna logo"}
            />
            <Typography variant={"h1"} sx={{mt: 1, fontSize: "2rem !important", textAlign: "center"}}>
                <DiscunaText/>
            </Typography>
        </>
    )
}
