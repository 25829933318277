import {Center, Row} from "../../components/motion_mui";
import {Alert, Box, Button, CircularProgress, TextField} from "@mui/material";
import {useCallback, useEffect, useState} from "react";
import {updateName} from "../../firebase/discunaApi";
import {nameValidator} from "shared/auth/auth";
import { getAuth, updateProfile, getIdToken } from "firebase/auth";
import {useDispatch, useSelector} from "react-redux";
import {updateNameRedux} from "./authSlice";
import {refreshToken} from "./authUtils";
import {RootState} from "../store";
import {getFirstAndLastName} from "../../components/userAvatar";

export function SetName({onSkip, onDone, type="newUser"}: {
    onSkip?: () => void,
    onDone?: () => void,
    type?: "newUser" | "updateName"
}) {

    const authRedux = useSelector((state: RootState) => state.auth)

    const res = authRedux.isSignedIn && authRedux.data.type === "email" && authRedux.data.name ?
        getFirstAndLastName(authRedux.data.name) : null
    const initialFirstName = res ? res.firstName : ""
    const initialLastName = res ? res.lastName : ""
    const [firstName, setFirstName] = useState(initialFirstName)
    const [lastName, setLastName] = useState(initialLastName)

    const [progress, setProgress] = useState<null | "processing" | "done" | "error">(null)
    const [alertMessage, setAlertMessage] = useState("")

    const setError = useCallback((msg: string) => {
        setProgress("error")
        setAlertMessage(`Error occurred: ${msg}`)
    }, [])

    const setDone = useCallback((msg: string) => {
        setProgress("done")
        setAlertMessage(msg)
    }, [])

    useEffect(() => {
        if(authRedux.isSignedIn && authRedux.data.type === "email" && authRedux.data.name) {
            const res = getFirstAndLastName(authRedux.data.name)
            setFirstName(res.firstName)
            setLastName(res.lastName)
        }
    }, [authRedux])

    const [firstNameError, setFirstNameError] = useState<string | undefined>(undefined)
    const [lastNameError, setLastNameError] = useState<string | undefined>(undefined)

    useEffect(() => {
        const value = nameValidator(firstName)
        if (!value.ok) setFirstNameError(value.errors[0].message)
        else setFirstNameError(undefined)
    }, [firstName])

    useEffect(() => {
        const value = nameValidator(lastName)
        if (!value.ok) setLastNameError(value.errors[0].message)
        else setLastNameError(undefined)
    }, [lastName])

    const auth = getAuth()
    const user = auth.currentUser

    const dispatch = useDispatch()
    const submitCallback = useCallback(async () => {
        setProgress("processing")
        try {
            if(!auth.currentUser) return setError("auth required")
            updateName({firstName, lastName}).catch((e) => console.error("could not update name", e))
            dispatch(updateNameRedux(`${firstName} ${lastName}`))
            await updateProfile(auth.currentUser, {
                displayName: `${firstName} ${lastName}`
            })
            // TODO why does token refresh not load the new user name??
            // TODO what happens if the user closes this window before this operation finishes?
            await refreshToken()
            if(type === "updateName") {
                setDone("Successfully updated name")
            } else {
                if(onDone) onDone()
            }
            // refresh token to get new display name
            // await currUser.getIdToken(true)
            // const res = await currUser.getIdTokenResult(true)
            // if(token) await auth.signInWithCustomToken(token)
        } catch(error: any) {
            console.error(error)
            setError(error.code)
        }
    }, [auth.currentUser, dispatch, firstName, lastName, onDone, setDone, setError, type])

    const cancel = useCallback(() => {
        setProgress(null)
        setFirstName(initialFirstName)
        setLastName(initialLastName)
        setAlertMessage("")
    }, [initialFirstName, initialLastName])

    // component must be called authenticated
    if(!user) return null

    const enableButton = !firstNameError && !lastNameError &&
        (firstName !== initialFirstName || lastName !== initialLastName)

    return (
        <>
            {
                progress === "processing" &&
                <Center style={{flexGrow: 1}}>
                    <CircularProgress/>
                </Center>
            }
            {
                progress === null &&
                <>
                    <TextField
                        error={firstName.length > 0 && firstNameError !== undefined}
                        helperText={firstName.length > 0 && firstNameError ? firstNameError : undefined}
                        variant={"filled"}
                        label={"First name"}
                        value={firstName}
                        onChange={(e) => {
                            setFirstName(e.target.value)
                        }}
                    />
                    <TextField
                        error={lastName.length > 0 && lastNameError !== undefined}
                        helperText={lastName.length > 0 && lastNameError ? lastNameError : undefined}
                        sx={{mt: 2}}
                        variant={"filled"}
                        label={"Last name"}
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                    />
                    <Alert sx={{mt: 2}} severity="info">You can hide your name in posts</Alert>
                </>
            }
            {
                progress === "done" &&
                <Alert onClose={cancel} severity="success">{alertMessage}</Alert>
            }
            {
                progress === "error" &&
                <Alert onClose={cancel} severity="error">{alertMessage}</Alert>
            }
            {
                type === "newUser" && progress === null &&
                    <Row sx={{mt: 4}} mainAxisAlignment={"end"} crossAxisAlignment={"center"}>
                        <Button
                            onClick={onSkip}
                        >
                            Skip
                        </Button>
                        <Button
                            sx={{ml: 2}}
                            disabled={!enableButton}
                            variant={"contained"}
                            onClick={submitCallback}
                        >
                            Submit
                        </Button>
                    </Row>
            }
            {
                type === "updateName" && progress === null &&
                <Row sx={{mt: 4}} mainAxisAlignment={"end"} crossAxisAlignment={"center"}>
                    <Button
                        disabled={!enableButton}
                        onClick={() => {
                            setFirstName(initialFirstName)
                            setLastName(initialLastName)
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        sx={{ml: 2}}
                        disabled={!enableButton}
                        variant={"contained"}
                        onClick={submitCallback}
                    >
                        Update
                    </Button>
                </Row>
            }
        </>
    )
}
