import React, {useEffect, useMemo, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {ModalTitle, ModalWithPresence} from "../../../../../../../components/modal";
import {Button, CircularProgress, TextField} from "@mui/material";
import {updateChannel} from "../../../../../../../firebase/discunaApi";
import {MarkdownEditor} from "../../../../../../../markdownEditor/markdownEditor";
import {useChannel} from "../../../../communities/channels/channelUtils";
import {RootState, useAppDispatch} from "../../../../../../store";
import {selectChannelById} from "../../../../communities/channels/channelSlice";
import {Center} from "../../../../../../../components/motion_mui";
import {LibraryItemWithLevel} from "../communityLibraryUtils";


export function EditChannelModal({show, communityId, item, onClose}: {
    item: LibraryItemWithLevel,
    communityId: string,
    onClose: () => void,
    show: boolean
}) {
    return (
        <ModalWithPresence onCancel={onClose}
                           show={show}>
            <EditChannel item={item} communityId={communityId} onClose={onClose}/>
        </ModalWithPresence>
    )
}


function EditChannel({communityId, item, onClose}: {
    item: LibraryItemWithLevel,
    communityId: string,
    onClose: () => void,
}) {

    const [channelName, setChannelName] = useState(item.name)
    const [channelDescription, setChannelDescription] = useState("")

    const isLoading = useChannel(communityId, item.id)
    const channel = useSelector((state: RootState) => selectChannelById(state, item.id))
    const oldName = useMemo(() => item.name, [item.name])

    const appId = channel?.details.appId
    // const channelHasDescription = Boolean(channel && (
    //     appId === "chat" || appId === "announcement" ||
    //     ((appId === "pdf" || appId === "pdfGlobal") && channel.details.extraData.pdfFetchInfo.type === "fs")
    // ))
    // const oldDescription = useMemo(() => !channel ? null :
    //     (appId === "chat" || appId === "announcement") ? channel.details.description :
    //             ((appId === "pdf" || appId === "pdfGlobal") && channel.details.extraData.pdfFetchInfo.type === "fs") ?
    //                 channel.details.description : null,
    //     [channel, appId])

    // useEffect(() => {
    //     if(oldDescription) setChannelDescription(oldDescription)
    // }, [oldDescription])

    const disabled = !channel || channelName.length === 0 || (channelName === oldName)
    // && (channelHasDescription ? (oldDescription === channelDescription) : true))

    const dispatch = useAppDispatch()

    return (
        <>
            <ModalTitle title={"Edit channel"} onCancel={onClose}/>
            {
                (isLoading || !channel) ?
                    <Center>
                        <CircularProgress/>
                    </Center> :
                    <>
                        <TextField value={channelName}
                                   label={"Channel name"}
                                   variant={"filled"}
                                   onChange={(e) => {
                                       setChannelName(e.currentTarget.value)
                                   }}/>
                        {/*{*/}
                        {/*    channelHasDescription &&*/}
                        {/*    <MarkdownEditor placeholder={"Channel description"}*/}
                        {/*                    sx={{mt: 2}}*/}
                        {/*                    markdown={channelDescription}*/}
                        {/*                    onChange={(value) => setChannelDescription(value.slice(0, 2000))}/>*/}
                        {/*}*/}
                        <Button
                            variant={"contained"}
                            sx={{mt: 2, alignSelf: "end"}}
                            disabled={disabled}
                            onClick={() => {
                                dispatch(updateChannel({
                                    communityId: communityId,
                                    channelId: item.id,
                                    ...(channelName !== oldName) && {
                                        name: channelName,
                                    },
                                    // ...(channelDescription !== oldDescription) && {
                                    //     description: channelDescription
                                    // }
                                }))
                                onClose()
                            }}>
                            Update
                        </Button>
                    </>
            }
        </>
    )
}
