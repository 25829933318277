import {CommunityContainer} from "../features/navbar/communities/communityContainer";
import {Outlet, useLocation, useNavigate, useParams} from "react-router-dom";
import {HTML5Backend} from "react-dnd-html5-backend";
import {DndProvider} from "react-dnd";
import React, {useEffect, useMemo, useRef, useState} from "react";

import {
    setupIonicReact,
    IonApp,
    IonNav,
    IonNavLink,
    IonButton,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonContent,
    IonButtons,
    IonPage,
    IonBackButton,
    useIonViewDidEnter,
    useIonViewDidLeave,
    useIonViewWillEnter,
    useIonViewWillLeave
} from '@ionic/react';
import {Column, MotionBox, Row} from "../../../components/motion_mui";
import {
    IconButton, List,
    ListItem,
    ListItemIcon,
    ListItemSecondaryAction,
    ListItemText,
    Skeleton,
    Tooltip
} from "@mui/material";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import {AnimatePresence} from "framer-motion";

export function ExplorerMobile() {


    const {communityId} = useParams<{ communityId: string }>()

    if (!communityId) throw Error("url malformed")


    const navigate = useNavigate()

    const navTo = (navId: string) => {
        navigate(`/mobile/dashboard/${navId}`)
    }

    const location = useLocation()

    const showChannelModal = useMemo(() => {
        let pathSplits = location.pathname.split("/")
        if (pathSplits.at(-1) === "") pathSplits = pathSplits.slice(0, -1)
        return pathSplits.at(-2) !== "communities"
    }, [location])

    return (
        <DndProvider backend={HTML5Backend}>
            <AnimatePresence>
                {
                    showChannelModal &&
                    <FullPage key={"fullpage-mobile"} title={"Channel"} onBack={() => {
                        navTo(`communities/${communityId}`)
                    }}>
                        <Outlet/>
                    </FullPage>
                }
            </AnimatePresence>
            <CommunityContainer isMobile={true} navTo={navTo} communityId={communityId}/>
        </DndProvider>
    )
}


interface FullPageProps {
    title: string,
    onBack: () => void,
}

function FullPage(props: React.PropsWithChildren<FullPageProps>) {
    return (
        <MotionBox
            sx={{
                position: "fixed",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                bgcolor: "white",
                zIndex: 1000
            }}
            initial={{
                y: 100,
                opacity: 0,
                scale: 0.8,
            }}
            transition={{ease: "easeInOut", duration: 0.1}}
            exit={{
                opacity: 0,
                scale: 0.8,
                y: 100
            }}
            animate={{
                y: 0,
                scale: 1,
                opacity: 1
            }}
        >
            <Column mainAxisAlignment={"start"} crossAxisAlignment={"stretch"} sx={{
                height: "100%",
                width: "100%",
            }}>
                <List component="nav" disablePadding sx={{
                    borderBottom: "1px solid #e0e0e0"
                }}>
                    <ListItem>
                        <ListItemIcon>
                            <IconButton edge={"start"} size={"small"} onClick={props.onBack}>
                                <ArrowBackOutlinedIcon/>
                            </IconButton>
                        </ListItemIcon>
                        <ListItemText primary={props.title}/>
                    </ListItem>
                </List>
                <Column mainAxisAlignment={"start"} crossAxisAlignment={"stretch"} sx={{
                    flex: 1,
                    width: "100%",
                    overflow: "auto"
                }}>
                    {props.children}
                </Column>
            </Column>

        </MotionBox>
    );
}
