import {createEntityAdapter, createSelector, createSlice, PayloadAction} from '@reduxjs/toolkit'
import {RootState} from "../../../../store";
import {MemberSecrets} from "./memberSecretsInterfaces";
import {createCommunity, toggleChannelMute} from "../../../../../firebase/discunaApi";
import {getMemberId} from "./memberSlice";
import {createDefaultMemberSecrets} from "shared/communities/members/memberSecrets";
import {arraySaveDelete, arraySavePush} from "../../../../../utils/arrayOperations";

const memberSecretsEntityAdapter = createEntityAdapter<MemberSecrets>()

const memberSecretsSlice = createSlice({
    name: "memberSecrets",
    initialState: memberSecretsEntityAdapter.getInitialState({}),
    reducers: {
        addMemberSecrets: memberSecretsEntityAdapter.addOne,
        upsertMemberSecrets: memberSecretsEntityAdapter.upsertOne,
        updateMemberSecrets: memberSecretsEntityAdapter.updateOne,
        removeMemberSecrets: memberSecretsEntityAdapter.removeOne,
        removeAllMemberSecrets: memberSecretsEntityAdapter.removeAll,
        setAllMemberSecrets: memberSecretsEntityAdapter.setAll
    },
    extraReducers: builder => {
        builder.addCase(createCommunity.fulfilled,
            (state, action) => {
                const memberSecrets = state.entities[action.meta.arg.communityId]
                if (memberSecrets) memberSecrets.isSynced = true
            })
            .addCase(createCommunity.pending,
                (state, action) => {
                    const args = action.meta.arg
                    const memberSecrets = {
                        // redux
                        id: args.communityId,
                        isSynced: false,

                        // firestore
                        ...createDefaultMemberSecrets()
                    } as MemberSecrets

                    memberSecretsEntityAdapter.upsertOne(state, memberSecrets)
                })
            .addCase(toggleChannelMute.pending, (state, action) => {
                const args = action.meta.arg
                const memberSecrets = state.entities[args.communityId]
                if(memberSecrets) memberSecrets.details.mutedChannels = args.value ?
                    arraySavePush(memberSecrets.details.mutedChannels, args.channelId) :
                    arraySaveDelete(memberSecrets.details.mutedChannels, args.channelId)
            })
    }
})

export const {
    addMemberSecrets,
    upsertMemberSecrets,
    updateMemberSecrets,
    removeMemberSecrets,
    removeAllMemberSecrets,
    setAllMemberSecrets
} = memberSecretsSlice.actions

export const memberSecretsReducer = memberSecretsSlice.reducer

export const {
    selectAll: selectAllMemberSecrets,
    selectById: selectMemberSecretById,
    selectIds: selectMemberSecretIds,
} = memberSecretsEntityAdapter.getSelectors<RootState>(state => state.memberSecrets)
