import {getVisibleLibraryItems} from "../../../communities/communityUtils";
import React, {useMemo} from "react";
import {Box, List, SxProps} from "@mui/material";
import {FolderItem} from "./folderItem";
import {FileItem} from "./fileItem";
import {useSelector} from "react-redux";
import {RootState} from "../../../../../store";
import {selectChannelOfflineDataByCommunityId} from "../../../communities/channels/channelOfflineDataSlice";
import {ChannelOfflineData} from "../../../communities/channels/channelInterfaces";
import {selectCommunityLibraryById} from "../../../communities/channels/communityLibrarySlice";
import {selectUnreadMessagesForCommunity} from "../../../inbox/inboxSlice";
import {parseFrom} from "../../../inbox/inboxUtils";
import {selectMemberSecretById} from "../../../communities/members/memberSecretsSlice";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CardMembershipOutlinedIcon from '@mui/icons-material/CardMembershipOutlined';
import {PersistentItem} from "./persistentItem";
import {useLocation, useParams} from "react-router-dom";
import {getOrderedCommunityLibraryList, LibraryItemWithLevel} from "./communityLibraryUtils";
import {RestrictAccess, useHasAccess} from "../../../communities/accessWrappers";
import AppsOutlinedIcon from '@mui/icons-material/AppsOutlined';
import {Community} from "../../../communities/communityInterfaces";
import {useBadgeCountInboxCommunityLibrary} from "./useBadgeCountInbox";
import {useBadgeCountBookmarksCommunityLibrary} from "./useBadgeCountBookmarks";

export function CommunityLibrary(props: {
    community: Community,
    navTo: (navId: string) => void,
    dragId: string | null,
    setDragId: (id: string | null) => void,
    sx?: SxProps
}) {
    const communityLibrary = useSelector((state: RootState) => selectCommunityLibraryById(state, props.community.id))
    const {communityId: urlCommunityId} = useParams<{ communityId: string | undefined }>()
    const communityId = props.community.id

    // TODO instead of computing maps it would make much more sense to create a virtual tree

    // order library items and compute visible items
    const libraryItems = communityLibrary?.details.libraryItems
    const libraryList = useMemo(() => getOrderedCommunityLibraryList(
        null,
        libraryItems ?? [],
        props.community.details.apps
    ), [libraryItems, props.community.details.apps])
    const selectChannelOfflineDataByCommunityIdMemo = useMemo(selectChannelOfflineDataByCommunityId, [])
    const channelOfflineData = useSelector((state: RootState) => selectChannelOfflineDataByCommunityIdMemo(state, communityId))
    const channelOfflineDataMap = useMemo(() => {
        const channelOfflineDataMap = new Map<string, ChannelOfflineData>()
        for (const data of channelOfflineData) {
            channelOfflineDataMap.set(data.id, data)
        }
        return channelOfflineDataMap
    }, [channelOfflineData])
    const visibleLibItems = useMemo(() =>
            getVisibleLibraryItems(libraryList, channelOfflineDataMap),
        [libraryList, channelOfflineDataMap]
    )

    // create map
    const allChildChannelsMap = useMemo(() => {
        const allChildChannelsMap = new Map<string, string[]>()
        setAllChildChannelsMap(null, libraryList, allChildChannelsMap)
        return allChildChannelsMap
    }, [libraryList])

    // count unread messages and bookmarks
    const unreadMessageCountMap = useBadgeCountInboxCommunityLibrary(communityId, libraryList, allChildChannelsMap)
    const bookmarkCountMap = useBadgeCountBookmarksCommunityLibrary(communityId, libraryList, allChildChannelsMap)

    // compute muted channels
    const memberSecrets = useSelector((state: RootState) => selectMemberSecretById(state, communityId))
    const mutedChannels = useMemo(() => {
        const muted = new Set(memberSecrets?.details.mutedChannels)
        let muteLevel = null as number | null
        for (let i = 0; i < libraryList.length; i++) {
            const curr = libraryList[i]
            if (muteLevel !== null && curr.level > muteLevel) {
                muted.add(curr.id)
            } else {
                if (muted.has(curr.id)) muteLevel = curr.level
                else muteLevel = null
            }
        }
        return muted
    }, [memberSecrets, libraryList])

    // isMember == not in preview
    const isMember = !!memberSecrets

    const location = useLocation()

    const infoSelected = useMemo(
        () => location.pathname.includes("info") && communityId === urlCommunityId,
        [location, urlCommunityId, communityId]
    )
    const membershipSelected = useMemo(
        () => location.pathname.includes("membership") && communityId === urlCommunityId,
        [location, urlCommunityId, communityId]
    )

    const appsSelected = useMemo(
        () => location.pathname.includes("apps") && communityId === urlCommunityId,
        [location, urlCommunityId, communityId]
    )

    const {channelId} = useParams<{ channelId: string | undefined }>()
    const libraryMap = useMemo(() => {
        const libraryMap = new Map<string, LibraryItemWithLevel>()
        for (const item of libraryList) {
            libraryMap.set(item.id, item)
        }
        return libraryMap
    }, [libraryList])

    const selectedFolderId = useMemo(() => {
        if (!channelId) return null
        const visibleLibItemsIdsSet = new Set(visibleLibItems.map(item => item.id))
        if (visibleLibItemsIdsSet.has(channelId)) return null
        let parentId = libraryMap.get(channelId)?.parentId
        while (parentId) {
            if (visibleLibItemsIdsSet.has(parentId)) return parentId
            parentId = libraryMap.get(parentId)?.parentId
        }
        return null
    }, [channelId, visibleLibItems, libraryMap])

    const hasModeratorPrivileges = useHasAccess(
        communityId,
        "moderator"
    )

    if (!communityLibrary) return null

    return (
        <Box sx={{
            maxWidth: "100%",
            overflow: "auto", // scroll in x or y direction in library
            flexGrow: 1,
            ...props.sx
        }}>
            <List
                dense
                disablePadding
                sx={{
                    width: "fit-content", // lib items are all same length
                    minWidth: "100%",
                    height: "100%"
                }}
            >
                {
                    isMember &&
                    <PersistentItem
                        id={"membership"}
                        name={"Your Membership"}
                        icon={
                            <CardMembershipOutlinedIcon sx={{
                                maxWidth: "19.2px", // == 16/20 * 24
                                maxHeight: "19.2px"
                            }}/>
                        }
                        onClick={() => {
                            props.navTo(`communities/${communityId}/membership`)
                        }}
                        isSelected={membershipSelected}
                        disabled={!!props.dragId}
                    />
                }
                <RestrictAccess communityId={communityId} role={"administrator"}>
                    <PersistentItem
                        id={"apps"}
                        name={"Apps"}
                        icon={
                            <AppsOutlinedIcon sx={{
                                maxWidth: "19.2px", // == 16/20 * 24
                                maxHeight: "19.2px"
                            }}/>
                        }
                        onClick={() => {
                            props.navTo(`communities/${communityId}/apps`)
                        }}
                        isSelected={appsSelected}
                        disabled={!!props.dragId}
                    />
                </RestrictAccess>
                <PersistentItem
                    id={"info"}
                    name={"Community Info"}
                    icon={
                        <InfoOutlinedIcon sx={{
                            maxWidth: "19.2px", // == 16/20 * 24
                            maxHeight: "19.2px"
                        }}/>
                    }
                    onClick={() => {
                        props.navTo(`communities/${communityId}/info`)
                    }}
                    isSelected={infoSelected}
                    disabled={!!props.dragId}
                />
                {visibleLibItems.map(
                    (libItem, idx) => {
                        if (libItem.appId === "folder") return (
                            <FolderItem
                                allChildChannels={allChildChannelsMap.get(libItem.id) ?? []}
                                selected={selectedFolderId === libItem.id}
                                isSynced={!communityLibrary.channelsNotSynced.includes(libItem.id)}
                                key={libItem.id}
                                communityId={communityId}
                                item={libItem}
                                numUnreadChannelMessages={unreadMessageCountMap["normal"].get(libItem.id) ?? 0}
                                numUnreadPersonalMessages={unreadMessageCountMap["high"].get(libItem.id) ?? 0}
                                bookmarksCount={bookmarkCountMap[libItem.id] ?? {}}
                                dragId={props.dragId}
                                setDragId={props.setDragId}
                                muted={mutedChannels.has(libItem.id)}
                                enableMuteMenu={!mutedChannels.has(libItem.parentId ?? "root")}
                                isDraggable={hasModeratorPrivileges}
                            />
                        )
                        else return (
                            <FileItem
                                key={libItem.id}
                                isSynced={!communityLibrary.channelsNotSynced.includes(libItem.id)}
                                communityId={communityId}
                                item={libItem}
                                numUnreadChannelMessages={unreadMessageCountMap["normal"].get(libItem.id) ?? 0}
                                numUnreadPersonalMessages={unreadMessageCountMap["high"].get(libItem.id) ?? 0}
                                bookmarksCount={bookmarkCountMap[libItem.id] ?? {}}
                                navTo={(channelId: string) => {
                                    props.navTo(`communities/${communityId}/channels/${channelId}`)
                                }}
                                dragId={props.dragId}
                                setDragId={props.setDragId}
                                muted={mutedChannels.has(libItem.id)}
                                enableMuteMenu={!mutedChannels.has(libItem.parentId ?? "root")}
                                isDraggable={hasModeratorPrivileges}
                            />
                        )
                    }
                )}
            </List>
        </Box>
    )
}


/**
 * Compute map m such that m.get(parentId) returns all file item ids that are contained in parentId and its sub-folders
 * @param parentId
 * @param libraryItems
 * @param allChildChannelsMap
 */
function setAllChildChannelsMap(
    parentId: string | null,
    libraryItems: LibraryItemWithLevel[],
    allChildChannelsMap: Map<string, string[]>,
) {
    const allChildChannels = [] as string[]
    const children = libraryItems.filter((item) => item.parentId === parentId)
    for (const child of children) {
        if (child.appId !== "folder")
            allChildChannels.push(child.id)
        else
            allChildChannels.push(...setAllChildChannelsMap(child.id, libraryItems, allChildChannelsMap))
    }
    if (parentId) allChildChannelsMap.set(parentId, allChildChannels)
    return allChildChannels
}

