import {Button, IconButton, Tooltip, Typography} from "@mui/material";
import {Column, Row} from "../../../../components/motion_mui";
import {darkGrey} from "../../../../constants/constants";
import React, {useState} from "react";
import {useSelector} from "react-redux";
import {RootState} from "../../../store";
import {getAuth} from "firebase/auth";
import {UpdateAccountModal} from "./updateAccountModal";
import {DeleteCommunityModal} from "./deleteAccountModal";
import {EditOutlined, LogoutOutlined} from "@mui/icons-material";
import {useAuth0} from "@auth0/auth0-react";
import {deleteDeviceToken, getJWTForUser} from "../../../../firebase/discunaApi";
import {Billing} from "./billing";
import {DangerZone} from "./dangerZone";
import {AccountDetails} from "./accountDetails";
import {DevSettings} from "./devSettings";
import {getPushyDeviceToken} from "../../../pushyUtils";
import {logout} from "../../../auth/authUtils";

export function Account() {

    const auth = useSelector((state: RootState) => state.auth)

    if (!auth.isSignedIn) return null

    return (
        <Column
            sx={{
                width: "100%",
                // TODO height 100% in flex seems to have same effect as flex: 1
                height: "100%",
                overflow: "auto",
                pb: 4
            }}
            mainAxisAlignment={"start"}
            crossAxisAlignment={"start"}
        >

            <Row sx={{
                pt: 4,
                px: 4,
                width: "100%"
            }} mainAxisAlignment={"spaceBetween"} crossAxisAlignment={"center"}>
                <Typography variant={"h1"} sx={{
                }}>
                    Your Account
                </Typography>

                <Tooltip title={"Logout"}>
                    <IconButton sx={{ml: 2, color: "primary.main"}} onClick={logout}>
                        <LogoutOutlined/>
                    </IconButton>
                </Tooltip>

            </Row>

            <AccountDetails/>
            <Billing/>
            <DevSettings/>
            <DangerZone/>


        </Column>
    )
}

