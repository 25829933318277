import {Button, Typography} from "@mui/material";
import React, {useState} from "react";
import {Column, Row} from "../../../../components/motion_mui";
import {getAuth} from "firebase/auth";
import {useSelector} from "react-redux";
import {RootState} from "../../../store";
import {DeleteCommunityModal} from "./deleteAccountModal";


export function DangerZone() {

    const [showDeleteAccount, setShowDeleteAccount] = useState(false)
    const auth = useSelector((state: RootState) => state.auth)
    if (!auth.isSignedIn) return null

    return (
        <Column sx={{ml: 4, mt: 4}} mainAxisAlignment={"start"} crossAxisAlignment={"start"}>
            <Typography variant={"h4"} sx={{mb: 2}}>
                Danger Zone
            </Typography>
            <Row mainAxisAlignment={"start"} crossAxisAlignment={"start"}>

                {/*<Button variant={"contained"} sx={{mr: 2}} onClick={async () => {*/}
                {/*    await getAuth().signOut()*/}
                {/*}}>*/}
                {/*    Auth0 Logout*/}
                {/*</Button>*/}

                {/*<Button onClick={() => {*/}
                {/*    getJWTForUser({}).then((res) => {*/}
                {/*        console.log("jwt for user", res)*/}
                {/*    })*/}
                {/*}}>*/}
                {/*    get token*/}
                {/*</Button>*/}

                <Button variant={"outlined"} color={"error"} onClick={() => setShowDeleteAccount(true)}>
                    Delete Account
                </Button>
            </Row>

            <DeleteCommunityModal show={showDeleteAccount} onClose={() => setShowDeleteAccount(false)}/>

        </Column>
    )
}
