import {createEntityAdapter, createSelector, createSlice} from "@reduxjs/toolkit";
import {AppLicense} from "./discunaAppInterface";
import {RootState} from "../../../../store";

const discunaAppLicenseEntityAdapter = createEntityAdapter<AppLicense>()


const discunaAppLicenseSlice = createSlice({
    name: "discunaAppLicenses",
    initialState: discunaAppLicenseEntityAdapter.getInitialState({}),
    reducers: {
        addDiscunaAppLicense: discunaAppLicenseEntityAdapter.addOne,
        upsertDiscunaAppLicense: discunaAppLicenseEntityAdapter.upsertOne,
        updateOneDiscunaAppLicense: discunaAppLicenseEntityAdapter.updateOne,
        removeDiscunaAppLicense: discunaAppLicenseEntityAdapter.removeOne,
        removeAllDiscunaAppLicenses: discunaAppLicenseEntityAdapter.removeAll,
        setAllDiscunaAppLicenses: discunaAppLicenseEntityAdapter.setAll,
    },
})

export const discunaAppLicenseReducer = discunaAppLicenseSlice.reducer

export const {
    addDiscunaAppLicense,
    upsertDiscunaAppLicense,
    updateOneDiscunaAppLicense,
    setAllDiscunaAppLicenses,
    removeDiscunaAppLicense,
    removeAllDiscunaAppLicenses
} = discunaAppLicenseSlice.actions

// selectors
export const {
    selectById: selectDiscunaAppLicenseById,
    selectAll: selectAllDiscunaAppLicenses,
} = discunaAppLicenseEntityAdapter.getSelectors((state: RootState) => state.discunaAppLicenses)

export const selectDiscunaAppLicensesByAppId = () => createSelector(
    [
        (state: RootState) => selectAllDiscunaAppLicenses(state),
        (state: RootState, appId: string) => appId,
    ], //
    (appLicenses, appId) => appLicenses.filter(
        appLicense => appLicense.appId === appId
    )
)

