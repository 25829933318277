import {ModalTitle, ModalWithPresence} from "../../../../components/modal";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {Alert, Button, CircularProgress, Snackbar, TextField, Typography} from "@mui/material";
import {UploadImage} from "./uploadImage";
import {UploadImageToCloud} from "./uploadImageToCloud";
import {deleteAppStorePage, generateId, publishApp, updateAppStorePage} from "../../../../firebase/discunaApi";
import {log} from "../../../../utils/log";
import {deleteObject, getStorage, ref} from "firebase/storage";
import {circularProgressSize} from "../../../../constants/constants";
import {getTmpImgUrl} from "shared";
import {ExistingAppStoreItemImage} from "./existingAppStoreItemImage";
import {arraySaveDelete, arraySavePush} from "../../../../utils/arrayOperations";
import {useAppsOnce} from "../communities/apps/useAppsOnce";
import {fetchAppStoreItem} from "../communities/apps/discunaAppStoreItemUtils";
import {AppStoreItemFirestoreData} from "shared";
import {Row} from "../../../../components/motion_mui";


interface imgData {
    id: string,
    filename: string,
    data: Uint8Array,
    uploadedToCloud: boolean
}

type progressStates = null | "loading" | string | "successPublish" | "fetching" | "successUpdate" | "successDelete"


export function AppStoreItemFormModal({onClose, show, appId}: {
    onClose: () => void,
    show: boolean,
    appId: string
}) {

    const [imgs, setImgs] = useState<imgData[]>([])

    const [progress, setProgress] = useState<progressStates>("fetching")

    const deleteImageCallback = useCallback((img: {
        id: string,
        filename: string,
        data: Uint8Array
    }) => {
        log("delete image...")
        const storagePath = getTmpImgUrl(img.id)  // `tmp/${img.id}.${extension}`
        const storage = getStorage();
        const imgRef = ref(storage, storagePath);
        deleteObject(imgRef).then(() => {
            log("Image deletion success")
        }).catch((error) => {
            log("Image deletion error", error.message)
        });
    }, [])

    const resetStates = useCallback(() => {
        setProgress("fetching")
        setImgs([])
    }, [])

    useEffect(() => {
        if(show) {
            resetStates()
        }
    }, [resetStates, show])

    const cancelCallback = useCallback(() => {
        // do not delete image while operation is in progress or if operation was successful
        if (progress !== "loading" && progress !== "successPublish") {
            for (const img of imgs) {
                deleteImageCallback(img)
            }
            resetStates()
        }
        onClose()
    }, [deleteImageCallback, imgs, onClose, progress, resetStates])

    const publishCallback = useCallback((title: string, subTitle: string, description: string, imgs: imgData[]) => {
        setProgress("loading")
        publishApp({
            appId,
            title,
            subTitle,
            description,
            imageIds: imgs.map((img) => img.id)
        }).then(() => {
            setProgress("successPublish")
        }).catch((error) => {
            setProgress(error.message)
            console.error(error)
        })
    }, [appId])

    const updateCallback = useCallback((
        title: string,
        subTitle: string,
        description: string,
        imageIdsAdd: string [],
        imageIdsDelete: string[]
    ) => {
        setProgress("loading")
        updateAppStorePage({
            appId,
            title,
            subTitle,
            description,
            imageIdsAdd,
            imageIdsDelete
        }).then(() => {
            setProgress("successUpdate")
        }).catch((error) => {
            setProgress(error.message)
            console.error(error)
        })
    }, [appId])

    const removeCallback = useCallback(() => {
        setProgress("loading")
        deleteAppStorePage({
            appId,
        }).then(() => {
            setProgress("successDelete")
        }).catch((error) => {
            setProgress(error.message)
            console.error(error)
        })
    }, [appId])

    return (
        <ModalWithPresence
            show={show}
            onCancel={cancelCallback}
        >
            {
                show &&
                <AppStoreItemForm
                    appId={appId}
                    onClose={onClose}
                    onCancel={cancelCallback}
                    imgs={imgs}
                    setImgs={setImgs}
                    deleteImageCallback={deleteImageCallback}
                    progress={progress}
                    setProgress={setProgress}
                    publishCallback={publishCallback}
                    updateCallback={updateCallback}
                    removeCallback={removeCallback}
                />
            }
        </ModalWithPresence>
    )
}


export function AppStoreItemForm(
    {
        appId,
        onClose,
        imgs,
        setImgs,
        deleteImageCallback,
        onCancel,
        progress,
        setProgress,
        publishCallback,
        updateCallback,
        removeCallback,
    }: {
        appId: string,
        onClose: () => void,
        imgs: imgData[],
        setImgs: React.Dispatch<React.SetStateAction<imgData[]>>;
        deleteImageCallback: (img: imgData) => void,
        onCancel: () => void,
        progress: progressStates,
        setProgress: React.Dispatch<React.SetStateAction<progressStates>>,
        publishCallback: (title: string, subTitle: string, description: string, imgs: imgData[]) => void
        updateCallback: (title: string, subTitle: string, description: string, imageIdsAdd: string[], imageIdsDelete: string[]) => void
        removeCallback: () => void,
    }) {

    const [title, setTitle] = useState("")
    const [subTitle, setSubTitle] = useState("")
    const [description, setDescription] = useState("")

    const [existingAppStoreItem, setExistingAppStoreItem] = useState<null | AppStoreItemFirestoreData>(null)
    const [existingImgIds, setExistingImgIds] = useState<string[]>([])
    const [imageIdsDelete, setImageIdsDelete] = useState<string[]>([])

    useAppsOnce()

    // load prefilled data
    useEffect(() => {
        setProgress("fetching")
        fetchAppStoreItem(appId).then((doc) => {
            if (doc.exists) {
                const appStoreItem = doc.data() as AppStoreItemFirestoreData
                setExistingAppStoreItem(appStoreItem)
                setTitle(appStoreItem.details.title)
                setSubTitle(appStoreItem.details.subTitle)
                setDescription(appStoreItem.details.description)
                setExistingImgIds(appStoreItem.details.imageIds)
            }
            setProgress(null)
        }).catch((e) => {
            console.error("failed to fetch app store item", appId, e)
        })
    }, [appId, setProgress])


    const MAX_IMGS = 3

    const enableButton = useMemo(() => {
        const allImagesUploaded = imgs.every((img) => img.uploadedToCloud)
        return title.length > 0 && subTitle.length > 0 && description.length > 0 && (imgs.length + existingImgIds.length) > 0 && allImagesUploaded
    }, [description.length, imgs, subTitle.length, title.length, existingImgIds])


    const [formError, setFormError] = useState<string | null>(null)

    const modalTitle = "App Store Page"

    // loading page
    if (progress === "loading" || progress === "fetching") {
        return (
            <>
                <ModalTitle title={modalTitle} onCancel={onClose}/>
                <CircularProgress size={circularProgressSize} sx={{
                    alignSelf: "center",
                    my: 4
                }}/>
            </>
        )
    }

    if (progress?.includes("success")) {
        return (
            <>
                <ModalTitle title={modalTitle} onCancel={onClose}/>
                <Alert severity={"success"}>
                    {
                        progress === "successPublish" ?
                            "App published successfully!" :
                            progress === "successUpdate" ?
                                "App store page updated successfully!" :
                                    "App store page deleted successfully!"
                    }
                </Alert>
            </>
        )
    }

    // error page
    if (progress) {
        return (
            <>
                <ModalTitle title={modalTitle} onCancel={onCancel}/>
                <Alert severity={"error"}>Error occurred: {progress}</Alert>
            </>
        )
    }

    // form page
    return (
        <>
            <ModalTitle title={modalTitle} onCancel={onCancel}/>
            <TextField
                sx={{
                    mb: 2,
                }}
                key={"title"}
                // sx={{mt: 2}}
                variant={"filled"}
                label={"Title"}
                value={title}
                multiline={false}
                // error={!!appConfigError}
                // helperText={appConfigError}
                onChange={(e) => {
                    setTitle(e.target.value)
                }}
                inputProps={{
                    maxLength: 30
                }}
                helperText={`${title.length} / 30`}
            />
            <TextField
                sx={{
                    mb: 2,
                }}
                key={"sub-title"}
                variant={"filled"}
                label={"Subtitle"}
                value={subTitle}
                multiline={false}
                // error={!!appConfigError}
                // helperText={appConfigError}
                onChange={(e) => {
                    setSubTitle(e.target.value)
                }}
                inputProps={{
                    maxLength: 30
                }}
                helperText={`${subTitle.length} / 30`}
            />
            <TextField
                sx={{
                    mb: 2,
                }}
                key={"description"}
                variant={"filled"}
                label={"Description"}
                value={description}
                multiline={true}
                // error={!!appConfigError}
                // helperText={appConfigError}
                onChange={(e) => {
                    setDescription(e.target.value)
                }}
                inputProps={{
                    maxLength: 1000
                }}
                // InputProps={{
                //     endAdornment: <InputAdornment position="end">{description.length} / 1000</InputAdornment>
                // }}
                helperText={`${description.length} / 1000`}
            />
            <Typography variant={"h6"}>Images</Typography>
            {
                (existingImgIds.filter((id) => !imageIdsDelete.includes(id))).map((imgId) => {
                    return (
                        <ExistingAppStoreItemImage sx={{mt: 2}} key={imgId} appId={appId} imageId={imgId} onRemove={
                            () => {
                                setImageIdsDelete(arraySavePush(imageIdsDelete, imgId))
                            }
                        }/>
                    )
                })
            }
            {
                imgs.map((img) => {
                    return (
                        <UploadImageToCloud
                            sx={{
                                my: 0,
                                mt: 2,
                                mb: 0
                            }}
                            key={img.id}
                            data={img.data}
                            filename={img.filename}
                            fileId={img.id}
                            onSuccess={() => {
                                setImgs(imgs.map(i => {
                                    if (i.id === img.id) {
                                        return {
                                            ...i,
                                            uploadedToCloud: true
                                        }
                                    }
                                    return i
                                }))
                            }}
                            onDelete={() => {
                                deleteImageCallback(img)
                                setImgs(imgs.filter(i => i.id !== img.id))
                            }}
                        />
                    )
                })
            }
            {
                (imgs.length + (existingImgIds.length - imageIdsDelete.length)) < MAX_IMGS && (
                    <UploadImage
                        onComplete={(imgData) => {
                            setImgs([...imgs, {
                                id: generateId(),
                                filename: imgData.filename,
                                data: imgData.data,
                                uploadedToCloud: false
                            }])
                            log("success", imgData)
                        }}
                        onError={(err) => setFormError(err)}
                        sx={{
                            mt: 2
                        }}
                    />
                )
            }

            {
                existingAppStoreItem ?
                    <Row mainAxisAlignment={"spaceBetween"} crossAxisAlignment={"start"} sx={{
                        mt: 2.5,
                    }}>
                        <Button color={"warning"} onClick={removeCallback}>
                            Delete
                        </Button>
                        <Button disabled={!enableButton} variant={"contained"} onClick={() => {
                            updateCallback(
                                title,
                                subTitle,
                                description,
                                imgs.map((d) => d.id),
                                imageIdsDelete
                            )
                        }}>
                            Update
                        </Button>
                    </Row> :
                    <Button
                        disabled={!enableButton}
                        sx={{
                            mt: 2.5,
                            alignSelf: "end"
                        }}
                        variant={"contained"}
                        onClick={() => {
                            publishCallback(title, subTitle, description, imgs)
                        }}
                    >
                        Publish
                    </Button>
            }


            <Snackbar anchorOrigin={{vertical: "top", horizontal: "center"}} open={formError != null}
                      autoHideDuration={6000} onClose={() => setFormError(null)}>
                <Alert onClose={() => setFormError(null)} severity="error" sx={{width: '100%'}}>
                    {formError}
                </Alert>
            </Snackbar>
        </>
    )
}
