import {Column, Row} from "../../../../../components/motion_mui";
import {Box, Button, IconButton, Link, Typography} from "@mui/material";
import React, {useState} from "react";
import {motion} from "framer-motion";
import {darkGrey} from "../../../../../constants/constants";
import {useSelector} from "react-redux";
import {RootState} from "../../../../store";
import {getMemberId, selectMemberById} from "../members/memberSlice";
import {useParams} from "react-router-dom";
import {LeaveCommunityModal} from "./leaveCommunityModal";
import {selectCommunityById} from "../communitySlice";
import {DeleteCommunityModal} from "./deleteCommunityModal";
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import {ChangeMemberNameModal} from "./changeMemberNameModal";


export function Membership() {

    const {communityId} = useParams<{ communityId: string }>()
    if (!communityId) throw Error("communityId missing")
    // 1 activity / impact
    // 2 actions


    // Card
    // role: none | moderator | administrator
    // member: since
    // reward:

    const [showChangeName, setShowChangeName] = useState(false)
    const [showLeaveCommunity, setShowLeaveCommunity] = useState(false)
    const [showDeleteCommunity, setShowDeleteCommunity] = useState(false)

    const member = useSelector((state: RootState) => selectMemberById(state, getMemberId(communityId)))
    const community = useSelector((state: RootState) => selectCommunityById(state, communityId))

    if (!member || !community) return null

    return (
        <Column
            sx={{
                width: "100%",
                height: "100%",
                overflow: "auto"
            }}
            mainAxisAlignment={"start"}
            crossAxisAlignment={"start"}
        >

            <Typography variant={"h1"} sx={{
                ml: 4,
                mt: 4,
                // px: 2,
                // bgcolor:
                // borderLeft: 4,
                // borderColor: 'primary.main',
            }}>
                Your Membership
            </Typography>


            <Column sx={{ml: 4, mt: 4}} mainAxisAlignment={"start"} crossAxisAlignment={"start"}>
                <Typography variant={"h4"} sx={{mb: 1}}>
                    Details
                </Typography>
                <Row mainAxisAlignment={"start"} crossAxisAlignment={"start"} sx={{
                    alignSelf: "start",
                    position: "relative"
                }}>
                    <Typography sx={{color: darkGrey}} variant={"body1"}>
                        Display name in this community: <b>{member.details.name}</b>
                        <Box onClick={() => setShowChangeName(true)} component={"span"} sx={{
                            ml: 1.5,
                            cursor: "pointer",
                            color: "primary.main",
                            "&:hover": {
                                textDecoration: "underline"
                            },
                        }}>
                            edit
                        </Box>
                    </Typography>

                    {/*<IconButton size={"small"} sx={{*/}
                    {/*    position: "absolute",*/}
                    {/*    right: -42,*/}
                    {/*    top: -8 // height 34, height text 24, icon is 18x18*/}
                    {/*}}>*/}
                    {/*    <ModeEditOutlineOutlinedIcon sx={{*/}
                    {/*        // maxHeight: "16px",*/}
                    {/*        // maxWidth: "16px"*/}
                    {/*    }}/>*/}
                    {/*</IconButton>*/}
                </Row>
                <Typography sx={{color: darkGrey}} variant={"body1"}>
                    Member since: {new Date(member.joinedOn).toDateString()}
                </Typography>
                <Typography sx={{textTransform: "capitalize", color: darkGrey}} variant={"body1"}>
                    Role: {member.role ?? "none"}
                </Typography>
                <Typography sx={{textTransform: "capitalize", color: darkGrey}} variant={"body1"}>
                    Reward: {member.totalReward}
                </Typography>
            </Column>


            {/*<Column sx={{px: 4, mt: 4, width: "100%", boxSizing: "border-box", position: "relative"}} mainAxisAlignment={"start"} crossAxisAlignment={"start"}>*/}
            {/*    <Typography variant={"h4"} sx={{mb: 1}}>*/}
            {/*        Rewards*/}
            {/*    </Typography>*/}

            {/*    <PlotDateValue sx={{mt: 2, minHeight: 480, maxHeight: 480, minWidth: "100%"}}/>*/}
            {/*</Column>*/}


            <Row mainAxisAlignment={"start"} crossAxisAlignment={"start"} sx={{ml: 4, mt: 4}}>

                <Button variant={"contained"} onClick={() => setShowLeaveCommunity(true)}>
                    Leave Community
                </Button>
                {
                    member.role === "administrator" &&
                    <Button color={"error"} sx={{ml: 2}} onClick={() => setShowDeleteCommunity(true)}>
                        Delete Community
                    </Button>
                }
            </Row>

            {
                showLeaveCommunity &&
                <LeaveCommunityModal
                    communityId={communityId}
                    communityName={community.details.name}
                    onClose={() => setShowLeaveCommunity(false)}
                    show={showLeaveCommunity}
                />
            }
            {
                showDeleteCommunity &&
                <DeleteCommunityModal
                    communityId={communityId}
                    communityName={community.details.name}
                    onClose={() => setShowDeleteCommunity(false)}
                    show={showDeleteCommunity}
                />
            }
            <ChangeMemberNameModal
                member={member}
                communityId={communityId}
                setShowChangeMemberNameModal={setShowChangeName}
                showChangeMemberNameModal={showChangeName}
            />
        </Column>
    )
}


const Circle = React.forwardRef<SVGCircleElement, React.SVGProps<SVGCircleElement>>(
    (props, ref) =>
        <circle ref={ref} {...props}/>
)
export const CircleMotion = motion(Circle)
