import {FormControlLabel, Switch, Typography} from "@mui/material";
import React, {useCallback, useState} from "react";
import {Column} from "../../../../components/motion_mui";
import {RootState} from "../../../store";
import {useSelector} from "react-redux";
import {toggleDevRole} from "../../../../firebase/discunaApi";


export function DevSettings() {

    const userSecrets = useSelector((state: RootState) => state.userSecrets.data)

    const [status, setStatus] = useState<null | "processing">(null)

    const onToggleDevRole = useCallback((value: boolean) => {
        setStatus("processing")
        toggleDevRole({
            value
        }).catch(console.error).finally(() => {
            setStatus(null)
        })
    }, [])

    if (!userSecrets) return null

    const isDev = (userSecrets.roles??[]).includes("dev")

    return (
        <Column sx={{ml: 4, mt: 4}} mainAxisAlignment={"start"} crossAxisAlignment={"start"}>
            <Typography variant={"h4"} sx={{mb: 2}}>
                Developer
            </Typography>

            <Typography variant={"body1"}>
                Start developing an app for Discuna or integrate your existing app with Discuna!
            </Typography>

            <FormControlLabel
                disabled={status === "processing"}
                sx={{mt: 1}}
                label={isDev ? "Disable Developer Mode" : "Enable Developer Mode"}
                control={
                    <Switch checked={isDev} onChange={() => {
                        onToggleDevRole(!isDev)
                    }}/>
                }
            />

        </Column>
    )
}
