import {useSelector} from "react-redux";
import {RootState} from "../../../../store";
import {selectCommunityById} from "../communitySlice";
import {useDiscunaAppsFromPublisher} from "./discunaAppUtils";
import React, {useMemo, useState} from "react";
import {Grid, Typography} from "@mui/material";
import {AppCard} from "./appCard";
import {useAppStoreItems} from "./discunaAppStoreItemUtils";
import {selectAllDiscunaAppStoreItems} from "./discunaAppStoreItemsSlice";
import {AppStoreItemPageModal} from "./appStoreItemPage";


export function AppStore({communityId}: {
    communityId: string
}) {

    // load all app store items
    const appStoreIsLoading = useAppStoreItems()
    const appStoreItems = useSelector((state:RootState) => selectAllDiscunaAppStoreItems(state))

    const community = useSelector(
        (state: RootState) => selectCommunityById(state, communityId)
    )

    // fetch all published apps
    const auth = useSelector((state: RootState) => state.auth)
    if (!auth.isSignedIn) throw Error("unknown")
    const appsAreLoading = useDiscunaAppsFromPublisher(auth.uid)

    //
    // const selectDiscunaAppsByPublisherIdMemo = useMemo(selectDiscunaAppsByPublisherId, [])
    // const unpublishedApps = useSelector((state: RootState) => selectDiscunaAppsByPublisherIdMemo(state, auth.uid, false))
    // const unpublishedAppsNotAdded = useMemo(() => {
    //     return unpublishedApps.filter((app) => !community || !community.details.apps?.includes(app.id))
    // }, [unpublishedApps, community])

    const [appInfoId, setAppInfoId] = useState<null | string>(null)

    return (
        <>
            <Typography variant={"h4"} sx={{ml: 4, mt: 4, mb: 1}}>
                App Store
            </Typography>
            <Grid container spacing={2} sx={{
                width: "100%",
                pl: 4,
                pr: 2,
                pt: 2,
            }}>
                {
                    appStoreItems.map((appStoreItem) => (
                        <Grid key={appStoreItem.id} item xs={12} md={6} lg={4}>
                            <AppCard
                                name={appStoreItem.details.title}
                                description={appStoreItem.details.subTitle}
                                iconUrl={appStoreItem.details.iconUrl}
                                onClick={() => {
                                    setAppInfoId(appStoreItem.id)
                                }}
                            />
                        </Grid>
                    ))
                }
            </Grid>

            <AppStoreItemPageModal
                show={!!appInfoId}
                onClose={() => {
                    setAppInfoId(null)
                }}
                appId={appInfoId}
                communityId={communityId}
            />

        </>
    )
}
