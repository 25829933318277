import {useDispatch, useSelector} from "react-redux";
import {RootState, useAppDispatch} from "../../../../store";
import {selectCommunityById} from "../communitySlice";
import {ModalTitle, ModalWithPresence} from "../../../../../components/modal";
import React, {useState} from "react";
import {Button, Checkbox, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, TextField} from "@mui/material";
import {updateCommunity} from "../../../../../firebase/discunaApi";
import {AccessibilityOptions} from "shared/index";


export function EditCommunityInfoModal({communityId, onClose, show}: {
    communityId: string,
    onClose: () => void,
    show: boolean
}) {
    return (
        <ModalWithPresence onCancel={onClose}
                           show={show}>
            <EditCommunityInfo communityId={communityId} onClose={onClose}/>
        </ModalWithPresence>
    )
}

function EditCommunityInfo({communityId, onClose}: {
    communityId: string,
    onClose: () => void,
}) {

    const community = useSelector((state: RootState) => selectCommunityById(state, communityId))

    const oldName = community?.details.name
    const oldDescription = community?.details.shortDescription
    const oldHideMembers = community?.details.hideMembers
    const oldAccessibility = community?.accessibility

    const [name, setName] = useState(oldName ?? "")
    const [shortDescription, setShortDescription] = useState(oldDescription ?? "")
    const [hideMembers, setHideMembers] = useState(oldHideMembers)
    const [accessibility, setAccessibility] = useState(oldAccessibility)

    const disabled = !community || name.length === 0 || name.length > 60 ||
        (
            oldName === name &&
            oldDescription === shortDescription &&
            hideMembers === oldHideMembers &&
            oldAccessibility === accessibility
        )

    const dispatch = useAppDispatch()

    return (
        <>
            <ModalTitle title={"Edit community"} onCancel={onClose}/>

            <TextField
                key={"communityName"}
                label={"Community name"}
                value={name}
                variant={"filled"}
                onChange={(e) => {
                    setName(e.currentTarget.value)
                }}/>

            <TextField
                key={"communityDescription"}
                sx={{mt: 2}}
                variant={"filled"}
                label={"Brief community description"}
                value={shortDescription}
                onChange={(e) => {
                    setShortDescription(e.currentTarget.value)
                }}/>

            <FormControl sx={{mt: 2}}>
                <FormLabel id="community-accessibility-radio-buttons-group-label">Community Accessibility</FormLabel>
                <RadioGroup
                    aria-labelledby="community-accessibility-radio-buttons-group-label"
                    defaultValue="female"
                    name="radio-buttons-group"
                    value={accessibility}
                    onChange={(e) => {
                        // console.log(e.target.value)
                        setAccessibility(Number(e.target.value))
                    }}
                >
                    <FormControlLabel
                        value={0}
                        control={<Radio/>}
                        label="Closed"
                    />
                    <FormControlLabel
                        value={1}
                        control={<Radio/>}
                        label="Open"
                    />
                </RadioGroup>
            </FormControl>

            <FormControlLabel
                sx={{mt: 2}}
                control={
                    <Checkbox
                        checked={hideMembers}
                        onChange={(e) => {
                            setHideMembers(e.currentTarget.checked)
                        }}
                    />}
                label="Make members only visible to admins and moderators"
            />

            <Button
                variant={"contained"}
                sx={{mt: 2, alignSelf: "end"}}
                disabled={disabled}
                onClick={() => {
                    dispatch(updateCommunity({
                        communityId,
                        details: {
                            ...(name !== oldName) && {
                                name,
                            },
                            shortDescription,
                            hideMembers
                        },
                        accessibility
                    }))
                    onClose()
                }}
            >
                Update
            </Button>

        </>
    )
}
