import {useCallback, useEffect, useMemo, useState} from "react";
import {getAuth} from "firebase/auth";
import {Column} from "../../components/motion_mui";
import {useNavigate, useParams} from "react-router-dom";
import {InvitationLinkFirestore} from "shared/communities/invitationLink";
import {firestore} from "../../firebase/firebase";
import {firestoreManager} from "../../firebase/queryManager";
import {Alert, AlertTitle, Button, TextField, Typography} from "@mui/material";
import {joinCommunity} from "../../firebase/discunaApi";
import {CustomLoadingButton} from "../dashboard/features/communities/accessWrappers";
import {MainPaperCard} from "../../components/mainPaperCard";
import {darkGrey} from "../../constants/constants";
import {LoadingScreen} from "../../utils/loadingScreen";


export function Invitation() {

    const user = useMemo(() => {
        const auth = getAuth()
        return auth.currentUser
    }, [])

    const {invitationId} = useParams<{ invitationId: string }>()
    const [memberDisplayName, setMemberDisplayName] = useState(user?.displayName ?? "")

    // return page not found
    if (!invitationId) throw Error("no invitation id provided")

    const [progress, setProgress] = useState<null | "loading">(null)
    const [error, setError] = useState<null | string>(null)
    const navigate = useNavigate()


    // fetch invitation
    const [invitation, setInvitation] = useState<null | InvitationLinkFirestore>(null)
    useEffect(() => {
        const invitationLinkRef = firestore.collection("invitationLinks").doc(invitationId)
        firestoreManager.queryDocument(invitationLinkRef)
            .then((res) => {
                if (res.exists) {
                    const now = new Date().getTime()
                    const invitation = res.data() as InvitationLinkFirestore
                    if (invitation.expiresOn && invitation.expiresOn < Date.now()) {
                        setError("Invitation has expired")
                    } else {
                        setInvitation(invitation)
                    }
                } else {
                    setError("Invitation ID does not exist")
                }
            })
            .catch((e) => {
                console.error("could not fetch invitation", e)
            })
    }, [invitationId])


    const onJoin = useCallback(async () => {
        if (user && invitation) {
            setProgress("loading")
            await joinCommunity({
                via: "invitation",
                id: invitationId,
                name: memberDisplayName,
            })
            // navigate to dashboard
            navigate(`/dashboard/account`)
        }
    }, [user, invitation, invitationId, memberDisplayName, navigate])


    return (
        <MainPaperCard
            paperWidth={500}
            sxMobile={{
                p: 3
            }}
            sxPaper={{
                p: 6
            }}
            isLoading={!invitation && !error}
        >
            {
                error &&
                <Column
                    mainAxisAlignment={"start"}
                    crossAxisAlignment={"stretch"}
                    sx={{
                        width: "100%",
                    }}
                >
                    <Alert severity={"error"}><AlertTitle>Invalid invitation link</AlertTitle>{error}</Alert>
                    <Button variant={"outlined"} sx={{mt: 3}} onClick={() => {
                        navigate("/dashboard/account")
                    }}>
                        Go to dashboard
                    </Button>
                </Column>
            }
            {
                invitation && !error &&
                <Column
                    mainAxisAlignment={"start"}
                    crossAxisAlignment={"stretch"}
                    sx={{
                        width: "100%",
                    }}
                >
                    <Typography variant={"body1"} sx={{
                        textAlign: "center"
                    }}>
                        Invitation to join
                    </Typography>
                    <Typography variant={"h2"} sx={{
                        textAlign: "center",
                        color: "black",
                        mt: 3,
                        wordBreak: "break-word",
                        maxWidth: "100%",
                        overflow: "hidden"
                    }}>
                        <b>{invitation.details.name}</b>
                    </Typography>
                    <Typography variant={"body1"} sx={{
                        textAlign: "center",
                        mt: 1,
                        color: darkGrey
                    }}>
                        {invitation.details.shortDescription}
                    </Typography>
                    {
                        user &&
                        <TextField
                            disabled={progress === "loading"}
                            label={"Your display name in this community"}
                            sx={{mt: 6}}
                            variant={"filled"}
                            value={memberDisplayName}
                            onChange={(e) => setMemberDisplayName(e.target.value)}
                        />
                    }
                    {
                        user ?
                            <CustomLoadingButton disabled={memberDisplayName.length === 0} variant={"contained"} sx={{
                                mt: 3,
                                // alignSelf: "end"
                            }} onClick={onJoin} isLoading={progress === "loading"}>
                                Join
                            </CustomLoadingButton> :
                            <Button sx={{mt: 6}} variant={"contained"} onClick={() => {
                                navigate("/auth/identity", {
                                    state: {
                                        from: window.location.href
                                    }
                                })
                            }}>
                                Register / Login to join
                            </Button>
                    }
                </Column>
            }
        </MainPaperCard>
    )
}
