import {v4 as uuidv4} from "uuid";

export class MessageServer {
    messageHandler: (event: MessageEvent) => void
    api: Map<string, Record<string, { cmdHandler: (event: MessageEvent) => unknown, targetOrigin: string, targetWindow: Window }>>

    constructor() {
        this.api = new Map()
        this.messageHandler = async (event: MessageEvent) => {
            const data = event.data
            if (
                typeof data === "object" &&
                "nonce" in data &&
                "action" in data &&
                typeof data.nonce === "string" &&
                typeof data.action.cmd === "string" &&
                event.source && "location" in event.source
            ) {
                const cmdHandlerRecord = this.api.get(data.action.cmd)??{}
                const cmdHandler = Object.values(cmdHandlerRecord).find(cmdHandler =>
                    (cmdHandler.targetOrigin === event.origin) &&
                    (cmdHandler.targetWindow === event.source)
                )
                if (cmdHandler) {
                    const response = await cmdHandler.cmdHandler(event)
                    event.source.postMessage({
                        nonce: data.nonce,
                        response
                    }, {
                        targetOrigin: event.origin
                    })
                }
            }
        }
    }

    setCmdHandler(
        cmd: string,
        cmdHandler: (event: MessageEvent) => unknown,
        targetOrigin: string,
        targetWindow: Window,
    ) {
        const cmdHandlerRecord = this.api.get(cmd)??{}

        // remove old handlers that match targetOrigin and targetWindow
        for(const key of Object.keys(cmdHandlerRecord)) {
            const value = cmdHandlerRecord[key]
            if (value.targetOrigin === targetOrigin && value.targetWindow === targetWindow) {
                delete cmdHandlerRecord[key]
            }
        }

        // add new handler
        const cmdHandlerId = uuidv4()
        cmdHandlerRecord[cmdHandlerId] = {cmdHandler, targetOrigin, targetWindow}
        this.api.set(cmd, cmdHandlerRecord)
        return () => {
            const cmdHandlerRecord = this.api.get(cmd)??{}
            if(cmdHandlerId in cmdHandlerRecord) delete cmdHandlerRecord[cmdHandlerId]
        }
    }

    start() {
        window.addEventListener("message", this.messageHandler)
    }

    stop() {
        window.removeEventListener("message", this.messageHandler)
    }
}
