import React, {useState} from "react";
import {useSelector} from "react-redux";
import {RootState} from "../../../store";
import {getMemberId, selectMemberById} from "./members/memberSlice";
import {memberRoles} from "shared/communities/members/member";
import {Box, Button, CircularProgress, IconButton, IconButtonProps} from "@mui/material";
import {ButtonProps} from "@mui/material/Button/Button";
import {ModalTitle, ModalWithPresence} from "../../../../components/modal";
import {SignInFlow} from "../../../auth/signInFlow";
import {BackgroundShadow, Center} from "../../../../components/motion_mui";


export function useHasAccess(communityId: string, role: memberRoles) {
    const auth = useSelector((state: RootState) => state.auth)
    let memberId = ""
    if (auth.isSignedIn) memberId = getMemberId(communityId)
    const member = useSelector((state: RootState) => selectMemberById(state, memberId))
    if (!member) return false
    if (role === "administrator" && member.role !== "administrator")
        return false
    return !(role === "moderator" && !member.role);
}


export function RestrictAccess({communityId, role, children}: React.PropsWithChildren<{
    communityId: string,
    role: memberRoles
}>) {
    const auth = useSelector((state: RootState) => state.auth)
    let memberId = ""
    if (auth.isSignedIn) memberId = getMemberId(communityId)
    const member = useSelector((state: RootState) => selectMemberById(state, memberId))
    if (!member) return null
    if (role === "administrator" && member.role !== "administrator")
        return null
    if (role === "moderator" && !member.role)
        return null
    return <>{
        children
    }</>
}

export function RestrictToRegistered({children}: React.PropsWithChildren<{}>) {
    const auth = useSelector((state: RootState) => state.auth)
    let memberId = ""
    if (!auth.isSignedIn) return null
    if (auth.data.type === "guest") return null
    return <>{
        children
    }</>
}

export function CustomLoadingButton({children, isLoading, disabled, sx, ...props}: { isLoading?: boolean } & ButtonProps) {
    return (
        <Button
            {...props}
            disabled={isLoading || disabled}
            sx={{...sx, position: "relative", overflow: "hidden"}}
        >
            {
                isLoading &&
                <Center style={{position: "absolute"}}>
                    <BackgroundShadow color={"rgba(0, 0, 0, 0.25)"}/>
                    <CircularProgress size={24}/>
                </Center>
            }
            {children}
        </Button>
    )
}

export function OnlyQandaMemberButton({children, isLoading, sx, ...props}: { isLoading?: boolean } & ButtonProps) {
    const auth = useSelector((state: RootState) => state.auth)
    const [showSignIn, setShowSignIn] = useState(false)
    return (
        <>
            <CustomLoadingButton
                isLoading={isLoading}
                sx={sx}
                {...props}
                onClick={(e) => {
                    if (!auth.isSignedIn || auth.data.type === "guest")
                        setShowSignIn(true)
                    else if (props.onClick) props.onClick(e)
                }}
            >
                {children}
            </CustomLoadingButton>
            <ModalWithPresence show={showSignIn} onCancel={() => setShowSignIn(false)}>
                <ModalTitle title={"Sign in"} onCancel={() => setShowSignIn(false)}/>
                <SignInFlow doNotShowTitle={true} onComplete={() => setShowSignIn(false)}/>
            </ModalWithPresence>
        </>
    )
}

export function OnlyCommunityMemberButton({communityId, children, ...props}: {
    communityId: string
} & ButtonProps) {
    const auth = useSelector((state: RootState) => state.auth)
    let memberId = ""
    if (auth.isSignedIn) memberId = getMemberId(communityId)
    const member = useSelector((state: RootState) => selectMemberById(state, memberId))
    const [showJoinCommunity, setShowJoinCommunity] = useState(false)
    if (!auth.isSignedIn || auth.data.type === "guest") return (
        <OnlyQandaMemberButton {...props}>
            {children}
        </OnlyQandaMemberButton>
    )
    return (
        <>
            <Button {...props} onClick={(e) => {
                if (!member) setShowJoinCommunity(true)
                else if (props.onClick) props.onClick(e)
            }}>
                {children}
            </Button>
            <ModalWithPresence show={showJoinCommunity} onCancel={() => setShowJoinCommunity(false)}>
                <ModalTitle title={"Join"} onCancel={() => setShowJoinCommunity(false)}/>
                <Button>
                    Join
                </Button>
            </ModalWithPresence>
        </>
    )
}

export function useIsCommunityMember(communityId?: string) {
    const auth = useSelector((state: RootState) => state.auth)
    let memberId = ""
    if (auth.isSignedIn && communityId) memberId = getMemberId(communityId)
    const member = useSelector((state: RootState) => selectMemberById(state, memberId))
    if (!communityId) return false
    const isCommunityMember = auth.isSignedIn && auth.data.type !== "guest" && member
    return isCommunityMember
}

export function OnlyCommunityMemberButtonDisable({communityId, children, disabled, ...props}: {
    communityId?: string
} & ButtonProps) {
    const isCommunityMember = useIsCommunityMember(communityId)
    const disable = !isCommunityMember
    return (
        <Button {...props} disabled={disable || disabled}>
            {children}
        </Button>
    )
}

export function OnlyCommunityMemberIconButtonDisable({communityId, children, disabled, ...props}: {
    communityId?: string
} & IconButtonProps) {
    const auth = useSelector((state: RootState) => state.auth)
    let memberId = ""
    if (auth.isSignedIn && communityId) memberId = getMemberId(communityId)
    const member = useSelector((state: RootState) => selectMemberById(state, memberId))
    const [showJoinCommunity, setShowJoinCommunity] = useState(false)
    const disable = !communityId || !auth.isSignedIn || auth.data.type === "guest" || !member
    return (
        <IconButton {...props} disabled={disable || disabled}>
            {children}
        </IconButton>
    )
}
