import {createEntityAdapter, createSelector, createSlice} from '@reduxjs/toolkit'
import {ChannelOfflineData} from "./channelInterfaces";
import {RootState} from "../../../../store";


const channelOfflineDataEntityAdapter = createEntityAdapter<ChannelOfflineData>()

export type channelOfflineDataSliceStateType = ReturnType<typeof channelOfflineDataEntityAdapter.getInitialState>

const initialState = (() => {

    if (window.discunaDesktopAPI) {
        const {channelOfflineData} = window.discunaDesktopAPI.readChannelOfflineDataSync()

        console.log("readChannelOfflineDataSync", channelOfflineData)
        if(channelOfflineData) return channelOfflineData
    }
    return channelOfflineDataEntityAdapter.getInitialState({})
})()

const channelOfflineDataSlice = createSlice({
    name: "channelOfflineData",
    initialState,
    reducers: {
        addChannelOfflineData: channelOfflineDataEntityAdapter.addOne,
        upsertChannelOfflineData: channelOfflineDataEntityAdapter.upsertOne,
        updateChannelOfflineData: channelOfflineDataEntityAdapter.updateOne,
        removeChannelOfflineData: channelOfflineDataEntityAdapter.removeOne,
        removeAllChannelOfflineData: channelOfflineDataEntityAdapter.removeAll,
        setAllChannelOfflineData: channelOfflineDataEntityAdapter.setAll
    },
    extraReducers: builder => {
    }
})

export const {
    addChannelOfflineData,
    upsertChannelOfflineData,
    updateChannelOfflineData,
    removeChannelOfflineData,
    removeAllChannelOfflineData,
    setAllChannelOfflineData
} = channelOfflineDataSlice.actions

export const channelOfflineDataReducer = channelOfflineDataSlice.reducer

export const {
    selectAll: selectAllChannelOfflineData,
    selectById: selectChannelOfflineDataById,
    selectIds: selectChannelOfflineDataIds,
} = channelOfflineDataEntityAdapter.getSelectors<RootState>(state => state.channelOfflineData)

export const selectChannelOfflineDataByCommunityId = () => createSelector(
    [selectAllChannelOfflineData, (state: RootState, communityId: string) => communityId],
    (allChannelOfflineData, communityId) => allChannelOfflineData
        .filter(channelOfflineData => channelOfflineData.communityId === communityId)
)
