import {ModalTitle, ModalWithPresence} from "../../../../../components/modal";
import {Alert, Button, TextField} from "@mui/material";
import {useDispatch} from "react-redux";
import {Member} from "../members/memberInterfaces";
import {useState} from "react";
import {changeMemberName} from "../../../../../firebase/discunaApi";
import {useAppDispatch} from "../../../../store";

export function ChangeMemberNameModal({communityId, member, showChangeMemberNameModal, setShowChangeMemberNameModal}: {
    communityId: string,
    member: Member,
    showChangeMemberNameModal: boolean,
    setShowChangeMemberNameModal: (show: boolean) => void,
}) {
    return (
        <ModalWithPresence show={showChangeMemberNameModal} onCancel={() => setShowChangeMemberNameModal(false)}>
            {
                showChangeMemberNameModal &&
                <ChangeMemberName
                    communityId={communityId}
                    member={member}
                    showChangeMemberNameModal={showChangeMemberNameModal}
                    setShowChangeMemberNameModal={setShowChangeMemberNameModal}
                />
            }
        </ModalWithPresence>
    )
}

function ChangeMemberName({communityId, member, showChangeMemberNameModal, setShowChangeMemberNameModal}: {
    communityId: string,
    member: Member,
    showChangeMemberNameModal: boolean,
    setShowChangeMemberNameModal: (show: boolean) => void,
}) {
    const dispatch = useAppDispatch()
    const [memberName, setMemberName] = useState(member.details.name??"")

    const nameChangeCooldown = 1000 * 60 * 60 * 24 * 7
    // const nameChangeCooldown = 10000

    const nameCanChange = !member.details.nameLastChangeAt || new Date().getTime() - member.details.nameLastChangeAt > nameChangeCooldown

    const ableToChangeNameOn = member.details.nameLastChangeAt ? new Date(member.details.nameLastChangeAt + nameChangeCooldown) : new Date()

    return (
        <>
            <ModalTitle title={"Change name"} onCancel={() => setShowChangeMemberNameModal(false)}/>
            {
                nameCanChange ?
                    <>
                        <Alert severity={"info"}>
                            You can change your name once every week.
                        </Alert>
                        <TextField

                            variant={"filled"}
                            sx={{mt: 2}}
                            label={"Name"}
                            value={memberName}
                            onChange={(e) => setMemberName(e.target.value)}
                        />
                        <Button
                            sx={{mt: 2, alignSelf: "end"}}
                            variant={"contained"}
                            disabled={memberName === member.details.name || memberName.length === 0}
                            onClick={() => {
                                dispatch(changeMemberName({communityId, name: memberName}))
                                setShowChangeMemberNameModal(false)
                            }}
                        >
                            Change
                        </Button>
                    </> :
                    <Alert severity={"info"}>
                        You can change your name again on {ableToChangeNameOn.toLocaleDateString()} at {ableToChangeNameOn.toLocaleTimeString()}.
                    </Alert>
            }
        </>
    )
}
