import {useSelector} from "react-redux";
import {RootState} from "../../../store";
import {Button, Typography} from "@mui/material";
import React from "react";
import {Column} from "../../../../components/motion_mui";


export function AdminPanel() {
    const auth = useSelector((state: RootState) => state.auth)

    if(!auth.isSignedIn) throw Error("unknown")
    const userData = useSelector((state: RootState) => state.userSecrets.data)

    if (!userData || !(userData.roles??[]).includes("admin")) {
        return null
    }

    return (
        <Column
            sx={{
                width: "100%",
                height: "100%",
                overflow: "auto"
            }}
            mainAxisAlignment={"start"}
            crossAxisAlignment={"start"}
        >
            <Typography variant={"h1"} sx={{
                ml: 4,
                mt: 4,
                mb: 4,
            }}>
                Admin Panel
            </Typography>

            <Button>
                Create Bills
            </Button>
        </Column>
    )
}
