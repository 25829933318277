
export function fileNameToNameAndExtension(fileName: string) {
    const fileSplit = fileName.split(".")
    if (fileSplit.length === 1) {
        return {
            name: fileSplit[0],
            extension: null
        }
    }
    return {
        name: fileSplit.slice(0, fileSplit.length - 1).join("."),
        extension: fileSplit[fileSplit.length - 1]
    }
}

function filePathToFileName(path: string) {
    // https://stackoverflow.com/questions/423376/how-to-get-the-file-name-from-a-full-path-using-javascript
    return path.replace(/^.*[\\\/]/, '')
}

export function filePathToNameAndExtension(path: string) {
    return fileNameToNameAndExtension(filePathToFileName(path))
}

