import {useDispatch} from "react-redux";
import useMemoizedState from "../../../../../hooks/useMemoizedState";
import {useEffect} from "react";
import {FieldPath, firestore} from "../../../../../firebase/firebase";
import {firestoreManager} from "../../../../../firebase/queryManager";
import {removeDiscunaApp, upsertDiscunaApp} from "./discunaAppSlice";
import {DiscunaApp} from "./discunaAppInterface";
import {AppFirestoreData} from "shared";


export async function fetchApp(appId: string, dispatch: ReturnType<typeof useDispatch>) {
    firestore.collection("apps").doc(appId).get().then((doc) => {
        if (doc.exists) {
            const discunaApp = {
                id: doc.id,
                isSynced: true,
                ...(doc.data() as AppFirestoreData)
            } as DiscunaApp
            dispatch(upsertDiscunaApp(discunaApp))
        }
    }).catch((error) => {
        console.log("Error getting document:", error);
    })
}


export function useCommunityAppsOnce(communityAppIDs: string[]) {
    const dispatch = useDispatch()
    const [isLoading, setIsLoading] = useMemoizedState(true)


    useEffect(() => {
        setIsLoading(true)
        const fetches = []
        // fetch apps in batches
        for (let i = 0; i < communityAppIDs.length; i += 10) {
            fetches.push(firestoreManager.query(
                firestore.collection("apps").where(FieldPath.documentId(), "in", communityAppIDs.slice(i, i + 10))
            ))
        }
        // process results of fetches
        Promise.allSettled(fetches).then((res) => {
            res.forEach((subRes) => {
                if(subRes.status === "fulfilled") {
                    const snaps = subRes.value
                    snaps.forEach((doc) => {
                        const discunaApp = {
                            id: doc.id,
                            isSynced: true,
                            ...(doc.data() as AppFirestoreData)
                        } as DiscunaApp
                        dispatch(upsertDiscunaApp(discunaApp))
                    })
                }
            })
            setIsLoading(false)
        })
    }, [communityAppIDs, dispatch, setIsLoading])

    return isLoading
}

export function useDiscunaAppsFromPublisher(publisherId: string) {
    const dispatch = useDispatch()
    const [isLoading, setIsLoading] = useMemoizedState(true)
    useEffect(() => {
        setIsLoading(true)
        const appQuery = firestore.collection("apps").where("publisherId", "==", publisherId)
        const {isNew, cancel} = firestoreManager.querySnapshots(`apps/${publisherId}`, appQuery,
            (snap) => {
            setIsLoading(false)
            snap.docChanges().forEach((change) => {
                if (change.type === "removed") {
                    dispatch(removeDiscunaApp(change.doc.id))
                } else {
                    const discunaApp = {
                        id: change.doc.id,
                        isSynced: true,
                        ...(change.doc.data() as AppFirestoreData)
                    } as DiscunaApp
                    dispatch(upsertDiscunaApp(discunaApp))
                }
            })
        })
        setIsLoading(isNew)
        return cancel
    }, [dispatch, publisherId, setIsLoading])

    return isLoading
}
//
// export function useDiscunaApps() {
//     const dispatch = useDispatch()
//     const [isLoading, setIsLoading] = useMemoizedState(true)
//
//     useEffect(() => {
//         setIsLoading(true)
//         const appsRef = firestore.collection("apps")
//         const {isNew, cancel} = firestoreManager.queryCollectionSnapshots(appsRef, (snap) => {
//             setIsLoading(false)
//             snap.docChanges().forEach((change) => {
//                 if (change.type === "removed") {
//                     dispatch(removeDiscunaApp(change.doc.id))
//                 } else {
//                     const discunaApp = {
//                         id: change.doc.id,
//                         isSynced: true,
//                         ...(change.doc.data() as AppFirestoreData)
//                     } as DiscunaApp
//                     dispatch(upsertDiscunaApp(discunaApp))
//                 }
//             })
//         })
//         setIsLoading(isNew)
//         return cancel
//     }, [dispatch, setIsLoading])
//
//     return isLoading
// }
