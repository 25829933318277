import {ModalTitle, ModalWithPresence} from "../../../../../components/modal";
import {useMembers} from "../members/memberUtils";
import {useAppLicenses} from "./discunaAppLicenseUtils";
import {selectDiscunaAppLicensesByAppId} from "./discunaAppLicenseSlice";
import {RootState} from "../../../../store";
import {useSelector} from "react-redux";
import {useCallback, useMemo, useState} from "react";
import {
    Alert, AlertTitle,
    Button,
    Radio,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    Typography
} from "@mui/material";
import {selectMembersByCommunityId} from "../members/memberSlice";
import {Member} from "../members/memberInterfaces";
import {AppLicense} from "./discunaAppInterface";
import {updateMemberLicense} from "../../../../../firebase/discunaApi";
import {selectDiscunaAppById} from "./discunaAppSlice";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {Column, Row} from "../../../../../components/motion_mui";
import {getNextLicenseRenewalDate} from "shared/apps/appLicense";
import {log} from "../../../../../utils/log";
import {CustomLoadingButton} from "../accessWrappers";

export function AssignLicensesModal({show, onClose, appId, communityId}: {
    show: boolean,
    onClose: () => void,
    appId: string | null,
    communityId: string,
}) {
    return (
        <ModalWithPresence show={show} onCancel={onClose}>
            {
                appId &&
                <AssignLicenses appId={appId} communityId={communityId} onClose={onClose}/>
            }
        </ModalWithPresence>
    )
}


export function AssignLicenses({communityId, appId, onClose}: {
    appId: string,
    communityId: string,
    onClose: () => void,
}) {
    const app = useSelector((state: RootState) => selectDiscunaAppById(state, appId))

    // get user secrets to check for bill address
    const userSecrets = useSelector((state: RootState) => state.userSecrets.data)

    // Fetch licenses
    useAppLicenses(appId)
    const selectDiscunaAppLicensesByAppIdMemo = useMemo(selectDiscunaAppLicensesByAppId, [])
    const appLicenses = useSelector((state: RootState) => selectDiscunaAppLicensesByAppIdMemo(state, appId))
    const appLicensesSortedByLevel = useMemo(() => {
        return appLicenses.sort((a: any, b: any) => {
            return a.details.license.level - b.details.license.level
        })
    }, [appLicenses])

    // Fetch all members => licenses included
    useMembers(communityId)
    const selectMembersByCommunityIdMemo = useMemo(selectMembersByCommunityId, [])
    const members = useSelector((state: RootState) => selectMembersByCommunityIdMemo(state, communityId))

    if(!userSecrets) return null

    if(!userSecrets.details.profile.billing) return (
        <>
            <ModalTitle title={`${app?.details?.config?.name} Member Licenses`} onCancel={onClose}/>
            <Alert severity={"info"}>
                Before assigning licenses, please add a billing address to your profile under "Account".
            </Alert>
        </>
    )

    return (
        <>
            <ModalTitle title={`${app?.details?.config?.name} Member Licenses`} onCancel={onClose}/>
            <TableContainer>
                <Table size={"small"}>
                    <TableHead>
                        <TableRow>
                            <TableCell align="left">Member Name</TableCell>
                            <TableCell align="center">No License</TableCell>
                            {
                                appLicensesSortedByLevel.map((appLicense) => {
                                    return (
                                        <TableCell align="center">
                                            <Row mainAxisAlignment={"center"} crossAxisAlignment={"center"}>
                                                {appLicense.details.title}
                                                <Tooltip title={appLicense.details.description}>
                                                    <InfoOutlinedIcon sx={{
                                                        fontSize: 16,
                                                        ml: 1,
                                                        cursor: "pointer"
                                                    }}/>
                                                </Tooltip>
                                            </Row>
                                        </TableCell>
                                    )
                                })
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            members.map((member) => {
                                return (
                                    <MemberLicenseRow
                                        key={member.userId}
                                        member={member}
                                        licenses={appLicensesSortedByLevel}
                                        appId={appId}
                                    />
                                )
                            })
                        }
                    </TableBody>
                </Table>

            </TableContainer>
        </>
    )
}


function MemberLicenseRow({member, licenses, appId}: {
    member: Member,
    licenses: AppLicense[],
    appId: string
}) {

    const [isProcessing, setIsProcessing] = useState(false)

    const [newLicenseId, setNewLicenseId] = useState<string | null | undefined>(undefined)

    const onChangeLicense = useCallback((licenseId: string | null) => {
        setIsProcessing(true)
        updateMemberLicense({
            memberId: member.userId,
            licenseId: licenseId,
            appId: appId,
            communityId: member.communityId
        }).then(() => {
        }).catch((e) => {
            console.error(e)
        }).finally(() => {
            setIsProcessing(false)
            setNewLicenseId(undefined)
        })
    }, [appId, member.communityId, member.userId])

    const [currLicense, nextLicense] = useMemo(() => {
        let appLicenseHistory = [...member.details.licenses[appId] ?? []]
        // log("appLicenseHistory", appLicenseHistory)
        // sort by timestamp t in descending order
        appLicenseHistory = appLicenseHistory.sort((a, b) => {
            return b.t - a.t
        })
        let currLicense = null, nextLicense = null
        if (appLicenseHistory.length > 0) {
            if (appLicenseHistory.length === 1) {
                currLicense = appLicenseHistory[0]
            } else {
                currLicense = appLicenseHistory[0]
                const currTimestamp = (new Date()).getTime()
                if (currLicense.t > currTimestamp) {
                    // license is in the future, so we use the previous one
                    nextLicense = currLicense
                    currLicense = appLicenseHistory[1]
                }
            }
        }
        return [currLicense, nextLicense]
    }, [appId, member.details.licenses])

    const currAppLicense = useMemo(() => {
        if (currLicense) {
            return licenses.find((license) => {
                return license.licenseId === currLicense.id
            })
        }
    }, [currLicense, licenses])

    const selectedAppLicense = useMemo(() => {
        if (newLicenseId) {
            return licenses.find((license) => {
                return license.licenseId === newLicenseId
            })
        }
    }, [newLicenseId, licenses])

    const onSelectNewLicense = useCallback((licenseId: string | null) => {
        if ((licenseId !== (currLicense?.id ?? null) || nextLicense) && (!nextLicense || licenseId !== nextLicense.id)) {
            setNewLicenseId(licenseId)
        }
    }, [currLicense?.id, nextLicense])

    return (
        <>
            {
                newLicenseId === undefined &&
                <TableRow>
                    <TableCell align="left">{member.details.name}</TableCell>
                    <TableCell align="center">
                        <LicenseRadioButton
                            currLicense={currLicense}
                            nextLicense={nextLicense}
                            licenseId={null}
                            onClick={onSelectNewLicense}
                            isProcessing={isProcessing}
                        />
                    </TableCell>
                    {
                        licenses.map((license) => {
                            return (
                                <TableCell align="center">
                                    <LicenseRadioButton
                                        currLicense={currLicense}
                                        nextLicense={nextLicense}
                                        licenseId={license.licenseId}
                                        onClick={onSelectNewLicense}
                                        isProcessing={isProcessing}
                                    />
                                </TableCell>
                            )
                        })
                    }
                </TableRow>
            }
            {
                newLicenseId !== undefined &&
                <TableRow>
                    <TableCell align="left">{member.details.name}</TableCell>
                    <TableCell align="left" colSpan={licenses.length + 1}>
                        <Column mainAxisAlignment={"start"} crossAxisAlignment={"stretch"} sx={{
                            my: 1
                        }}>
                            <Alert severity="info">
                                {/*<AlertTitle>*/}
                                {/*    {*/}
                                {/*        `"${selectedAppLicense ? selectedAppLicense.details.title : "Default"}" license selected`*/}
                                {/*    }*/}
                                {/*</AlertTitle>*/}
                                {
                                    newLicenseId === null && (currAppLicense && currLicense) &&
                                    cancelSubscriptionText(currAppLicense, currLicense.t)
                                }
                                {
                                    (newLicenseId && selectedAppLicense) && (currAppLicense && currLicense) && newLicenseId !== currLicense.id &&
                                    changeSubscriptionText(currAppLicense, currLicense.t, selectedAppLicense)
                                }
                                {
                                    (newLicenseId && selectedAppLicense) && (currAppLicense && currLicense) && newLicenseId === currLicense.id &&
                                    renewSubscription(currAppLicense, currLicense.t)
                                }
                                {
                                    (newLicenseId && selectedAppLicense) && !currAppLicense &&
                                    startSubscriptionText(selectedAppLicense)
                                }

                            </Alert>
                            <Row mainAxisAlignment={"start"} crossAxisAlignment={"start"} sx={{
                                alignSelf: "end",
                                mt: 1
                            }}>
                                <Button disabled={isProcessing} size={"small"} onClick={() => {
                                    setNewLicenseId(undefined)
                                }}>
                                    Cancel
                                </Button>
                                <CustomLoadingButton
                                    variant={"contained"}
                                    size={"small"}
                                    onClick={() => {
                                        onChangeLicense(newLicenseId)
                                    }}
                                    isLoading={isProcessing}
                                    sx={{
                                        ml: 1
                                    }}
                                >
                                    Confirm
                                </CustomLoadingButton>
                            </Row>
                        </Column>
                    </TableCell>
                </TableRow>
            }


        </>
    )
}


function LicenseRadioButton({licenseId, currLicense, nextLicense, onClick, isProcessing}: {
    currLicense: {
        id: string | null,
        t: number
    } | null,
    nextLicense: {
        id: string | null,
        t: number
    } | null,
    licenseId: string | null,
    onClick: (id: string | null) => void,
    isProcessing: boolean
}) {

    const isCurrLicense = useMemo(() => {
        return (
            (currLicense === null && licenseId === null) ||
            (currLicense?.id === licenseId)
        )
    }, [currLicense, licenseId])

    const isNextLicense = useMemo(() => {
        return nextLicense?.id === licenseId
    }, [nextLicense?.id, licenseId])

    const willExpire = isCurrLicense && nextLicense !== null
    const renewalDate = useMemo(() => {
        if (currLicense && isCurrLicense) {
            return new Date(getNextLicenseRenewalDate(currLicense.t, (new Date()).getTime()))
        }
        return null
    }, [currLicense, isCurrLicense])


    const tooltipTitle = useMemo(() => {
        if (isCurrLicense && (currLicense?.id ?? null) !== null) {
            return (
                <>
                    <Typography variant={"inherit"}>
                        Active since {new Date(currLicense?.t ?? 0).toLocaleString()}
                    </Typography>
                    {
                        willExpire ?
                            <>
                                <Typography variant={"inherit"} sx={{
                                    color: "warning.main",
                                    fontWeight: "bold"
                                }}>
                                    Expires on {renewalDate?.toLocaleString()}.
                                </Typography>
                                <Typography variant={"inherit"}>
                                    Click to renew.
                                </Typography>
                            </> :
                            <Typography variant={"inherit"}>
                                Renews on {renewalDate?.toLocaleString()}
                            </Typography>
                    }
                </>
            )
        }
        if (isNextLicense) {
            return (
                <Typography variant={"inherit"}>
                    Starts on {new Date(nextLicense?.t ?? 0).toLocaleString()}
                </Typography>
            )
        }
        if (isCurrLicense && (currLicense?.id ?? null) === null) {
            return "No license assigned."
        }
        if (licenseId === null) {
            return "Terminate license."
        }
        return <>
            <Typography variant={"inherit"}>
                Click to change license.
            </Typography>
        </>
    }, [currLicense?.id, currLicense?.t, isCurrLicense, isNextLicense, licenseId, nextLicense?.t, renewalDate, willExpire])

    return (
        <Column mainAxisAlignment={"start"} crossAxisAlignment={"center"}>
            <Tooltip title={tooltipTitle}>
                <Radio
                    checked={isCurrLicense || isNextLicense}
                    disabled={isProcessing}
                    onClick={() => onClick(licenseId)}
                    color={willExpire ? "warning" : "primary"}
                />
            </Tooltip>
        </Column>
    )

}


function cancelSubscriptionText(appLicense: AppLicense, licenseTimestamp: number) {
    if (appLicense.details.license.type !== 0) throw Error("Invalid license type")
    return (
        `Upon termination, the "${appLicense?.details.title}" license of this member will 
        expire on ${new Date(getNextLicenseRenewalDate(licenseTimestamp, Date.now())).toLocaleString()},
        when the current billing period ends.`
    )
}

function startSubscriptionText(appLicense: AppLicense) {
    if (appLicense.details.license.type !== 0) throw Error("Invalid license type")
    return (
        `After confirmation, the member will have a "${appLicense.details.title}" license immediately.
        You pay ${appLicense.details.license.price} USD per month as long as this license is active.
         You can terminate the license at any time.`
    )
}


function renewSubscription(
    appLicense: AppLicense,
    appLicenseTimestamp: number
) {
    return (
        `After confirmation, the "${appLicense.details.title}" license of this member will be
        renewed on ${new Date(getNextLicenseRenewalDate(appLicenseTimestamp, Date.now())).toLocaleString()}.
        You pay ${appLicense.details.license.price} USD per month as long as this license is active.
        You can terminate the license at any time.`
    )
}

function changeSubscriptionText(
    currAppLicense: AppLicense,
    currLicenseTimestamp: number,
    nextAppLicense: AppLicense
) {

    if (nextAppLicense.details.license.type !== 0) throw Error("Invalid license type")
    if (currAppLicense.details.license.type !== 0) throw Error("Invalid license type")
    if (nextAppLicense.details.license.level > currAppLicense.details.license.level) return (
        `After confirmation, the "${currAppLicense.details.title}" license of this member will be upgraded to "${nextAppLicense.details.title}" immediately.
        You pay ${nextAppLicense.details.license.price} USD per month as long as the new license is active.
        The charge of the previous license will be prorated for the time it has been used since the last billing period.
        You can terminate the license at any time.`
    )
    return (
        `After confirmation, the "${currAppLicense.details.title}" license of this member 
        will be downgraded to "${nextAppLicense.details.title}" on ${new Date(getNextLicenseRenewalDate(currLicenseTimestamp, Date.now())).toLocaleString()}, 
        when the current billing period ends.
        You will pay ${nextAppLicense.details.license.price} USD per month from the date the new license becomes active.
        You can terminate the license at any time.`
    )
}

