import {AppStoreImage} from "../communities/apps/appStoreImage";
import {Card, IconButton, SxProps} from "@mui/material";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import {lightGrey} from "../../../../constants/constants";
import {Center} from "../../../../components/motion_mui";


export function ExistingAppStoreItemImage(props: {
    appId: string,
    imageId: string,
    onRemove: () => void
    sx?: SxProps
}) {


    return (
        <Card variant={"outlined"} sx={{
            position: "relative",
            ...props.sx
        }}>
            <Center>
                <AppStoreImage imageId={props.imageId} appId={props.appId} sx={{
                    ml: 0,
                    // bgcolor: lightGrey
                }}/>
            </Center>
            <IconButton
                sx={{
                    position: "absolute",
                    top: 4,
                    right: 4,
                }}
                onClick={() => props.onRemove()}
            >
                <DeleteOutlineOutlinedIcon/>
            </IconButton>
        </Card>
    )
}
