import {ModalTitle, ModalWithPresence} from "components/modal";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {RootState, useAppDispatch} from "../../../../../../store";
import {
    Alert,
    Box,
    Button, Card, CircularProgress,
    Collapse,
    FormControl,
    FormControlLabel, IconButton,
    InputLabel, ListItem, ListItemButton, ListItemIcon, ListItemText,
    MenuItem,
    Select, Snackbar,
    Switch,
    TextField, Tooltip,
    Typography
} from "@mui/material";
import {Column, Row} from "../../../../../../../components/motion_mui";
import {memberRoles} from "shared/communities/members/member";
import {
    createInvitationLink,
    deleteInvitationLink,
    generateId,
    getCommunityInvitationLink,
    getInvitationLink
} from "../../../../../../../firebase/discunaApi";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import debounce from "lodash.debounce";
import {
    getInvitationLinkReduxId, removeInvitationLink,
    selectInvitationLinkById, setInvitationLinkNotSynced, setInvitationLinkSynced, upsertInvitationLink
} from "../../../../communities/invitationLinks/invitationLinkSlice";
import {
    InvitationLink,
    InvitationLinkWithInvitationId
} from "../../../../communities/invitationLinks/invitationLinkInterfaces";
import {idValidator} from "shared/common/schemas";
import {darkGrey} from "../../../../../../../constants/constants";
import {selectCommunityById} from "../../../../communities/communitySlice";
import {CreateInvitationLinkArgs, createInvitationLinkFromArgs} from "shared";

// function getTimeDiff(d1: Date, d2: Date) {
//     const diff = d2.getHours() - d2.getHours()
//     const dayDiff = Math.floor(diff / 24)
//     const weeksDiff = Math.floor(diff / (24*7))
//     const monthsDiff = Math.floor(diff / (24*30))
//     if(diff <= 1) {
//         return "less than an hour"
//     } else if(diff < 24) {
//         return `${diff} hours`
//     } else if (diff < 7*24) {
//         return `${Math.floor(diff / 24)} days`
//     } else if (diff < 7*24) {
//         return `${Math.floor(diff / 24)} days`
//     } else if (diff < )
// }

export function AddMemberModal({show, communityId, onClose}: {
    communityId: string,
    onClose: () => void,
    show: boolean,
}) {
    return (
        <ModalWithPresence show={show} onCancel={onClose}>
            <AddMember
                communityId={communityId}
                onClose={onClose}
            />
        </ModalWithPresence>
    )
}

function AddMember({communityId, onClose}: {
    communityId: string,
    onClose: () => void,
}) {
    return (
        <>
            <ModalTitle title={"Add members"} onCancel={onClose}/>
            <Typography variant={"h5"} sx={{mb: 2}}>
                Member invitation link
            </Typography>
            <InvitationLinkCard
                key={"member"}
                communityId={communityId}
                role={null}
                allowCustomInvitationId={true}/>

            <Box sx={{my: 2}}/>

            <Typography variant={"h5"}>
                Moderator invitation link
            </Typography>
            <Typography variant={"body2"} sx={{mb: 2, color: darkGrey}}>
                Moderators can update the community library.
            </Typography>
            <InvitationLinkCard
                key={"moderator"}
                communityId={communityId}
                role={"moderator"}
                allowCustomInvitationId={false}/>
        </>
    )
}


function InvitationLinkCard({communityId, role, allowCustomInvitationId}: {
    communityId: string,
    role: memberRoles,
    allowCustomInvitationId: boolean
}) {
    const community = useSelector((state: RootState) => selectCommunityById(state, communityId))
    if (!community) throw Error("community does not exist")

    // invitation link
    const invitationLinkReduxId = getInvitationLinkReduxId(communityId, role)
    const invitationLink = useSelector((state: RootState) => selectInvitationLinkById(state, invitationLinkReduxId))

    // custom invitation id
    // 1 day = 1440 minutes, 7 days = 10080 minutes, never = -1
    const [invitationLinkExpiresIn, setInvitationLinkExpiresIn] = useState(7 * 24 * 60)
    const [useCustomInvitationId, setUseCustomInvitationId] = useState(false)
    const [customInvitationId, setCustomInvitationId] = useState("")
    const [customInvitationIdError, setCustomInvitationIdError] = useState<string | null>(null)
    const checkInvitationId = async (invitationId: string) => {
        const res = idValidator(invitationId)
        if (!res.ok) return setCustomInvitationIdError(res.errors[0].message ?? "")
        try {
            const result = await getInvitationLink({invitationId})
            if (result.data === null) setCustomInvitationIdError(null)
            else setCustomInvitationIdError("ID already exists")
        } catch (e) {
            console.log("error", e)
            setCustomInvitationIdError("ID already exists")
        }
    }
    const checkInvitationIdDebounced = useMemo((() => debounce(checkInvitationId, 300)), [])

    // copy
    const [copyFailed, setCopyFailed] = useState<boolean>(false)
    const [copySuccess, setCopySuccess] = useState<boolean>(false)

    // fetch invitation link
    // we call a cloud function to access links to prevent users from querying all invitation links
    // NOTE everyone can fetch an invitation link based on an invitation ID
    // but only the administrator can query invitation links based on community ID etc
    const dispatch = useAppDispatch()
    const [fetched, setFetched] = useState(!!invitationLink)
    const fetchCallback = useCallback((val: boolean) => setFetched(val), [])
    // useEffect(() => {
    //     if (invitationLink && invitationLink.expiresOn && invitationLink.expiresOn < (new Date()).getTime()) {
    //         dispatch(removeInvitationLink(invitationLink.id))
    //         setFetched(false)
    //     }
    // }, [dispatch, invitationLink])

    // fetch invitation link
    useEffect(() => {
        if (!fetched) {
            console.log("fetching invitation link")
            getCommunityInvitationLink({communityId, role})
                .then((result) => {
                    const invitationLinkWithInvitationId = result.data as InvitationLinkWithInvitationId | null
                    console.log("fetched invitation link: ", invitationLinkWithInvitationId)

                    if (invitationLinkWithInvitationId) {
                        if(!invitationLinkWithInvitationId.expiresOn ||
                            invitationLinkWithInvitationId.expiresOn > (new Date()).getTime()) {
                            dispatch(upsertInvitationLink({
                                ...invitationLinkWithInvitationId,
                                id: getInvitationLinkReduxId(
                                    invitationLinkWithInvitationId.communityId,
                                    invitationLinkWithInvitationId.role
                                )
                            } as InvitationLink))
                        }
                    }
                    // fetchCallback(true)
                })
                .finally(() => fetchCallback(true))
        }

    }, [communityId, dispatch, fetchCallback, fetched, role])

    const notSyncedInvitationLinks = useSelector((state: RootState) => state.invitationLinks.notSynced)
    const invitationLinkNotSynced = notSyncedInvitationLinks.includes(invitationLinkReduxId)

    const createInvitationLinkCallback = useCallback(async () => {
        const invitationLinkReduxId = getInvitationLinkReduxId(community.id, role)
        dispatch(setInvitationLinkNotSynced(invitationLinkReduxId))
        const args = {
            invitationId: useCustomInvitationId ? customInvitationId : generateId(),
            role,
            communityId: community.id,
            expiresAfterMinutes: invitationLinkExpiresIn === -1 ? null : invitationLinkExpiresIn
        } as CreateInvitationLinkArgs
        await createInvitationLink(args)
        dispatch(upsertInvitationLink({
            id: invitationLinkReduxId,
            invitationId: args.invitationId,
            ...createInvitationLinkFromArgs(args, community)
        }))
        dispatch(setInvitationLinkSynced(invitationLinkReduxId))
    }, [community, customInvitationId, dispatch, invitationLinkExpiresIn, role, useCustomInvitationId])

    return (
        <>
            {
                invitationLink && !invitationLinkNotSynced ?
                    <ListItem
                        disablePadding
                        secondaryAction={
                            <IconButton edge="end" aria-label="delete" onClick={() => {
                                setUseCustomInvitationId(false)
                                setCustomInvitationId("")
                                // special case
                                if (invitationLink.expiresOn && invitationLink.expiresOn < (new Date()).getTime())
                                    dispatch(removeInvitationLink(invitationLink.id))
                                else dispatch(deleteInvitationLink({invitationId: invitationLink.invitationId as string}))
                            }}>
                                <DeleteOutlineOutlinedIcon/>
                            </IconButton>
                        }
                    >
                        <ListItemButton
                            sx={{
                                borderRadius: 1,
                            }}
                            onClick={() => {
                                navigator.clipboard.writeText(window.location.origin + "/invite/" + invitationLink.invitationId)
                                    .then(function () {
                                        setCopySuccess(true)
                                    }, function (err) {
                                        setCopyFailed(true)
                                    });
                            }}
                        >
                            <ListItemIcon>
                                <ContentCopyOutlinedIcon/>
                            </ListItemIcon>
                            <ListItemText
                                primary={window.location.origin + "/invite/" + invitationLink.invitationId}
                                secondary={
                                    invitationLink.expiresOn ?
                                        "Expires on " + new Date(invitationLink.expiresOn).toDateString() + " at " + new Date(invitationLink.expiresOn).toLocaleTimeString()
                                        : "Does not expire"
                                }
                            />
                        </ListItemButton>
                        <Snackbar
                            anchorOrigin={{vertical: "top", horizontal: "center"}}
                            open={copyFailed}
                            autoHideDuration={3000}
                            onClose={() => setCopyFailed(false)}
                        >
                            <Alert
                                onClose={() => setCopyFailed(false)}
                                severity={"error"}
                                sx={{width: '100%'}}
                            >
                                Could not copy link to clipboard
                            </Alert>
                        </Snackbar>
                        <Snackbar
                            anchorOrigin={{vertical: "top", horizontal: "center"}}
                            open={copySuccess}
                            autoHideDuration={3000}
                            onClose={() => setCopySuccess(false)}
                        >
                            <Alert
                                onClose={() => setCopySuccess(false)}
                                severity={"success"}
                                sx={{width: '100%'}}
                            >
                                Copied link to clipboard
                            </Alert>
                        </Snackbar>
                    </ListItem>
                    :
                    <Card variant={"outlined"} sx={{p: 2}}>
                        {
                            (!fetched || invitationLinkNotSynced) ?
                                <CircularProgress
                                    size={24}
                                /> :
                                <Column mainAxisAlignment={"start"} crossAxisAlignment={"start"}>
                                    <FormControl variant="filled" sx={{mb: 1, minWidth: "200px"}}>
                                        <InputLabel id="member-link-expires-in">Expires in</InputLabel>
                                        <Select
                                            labelId="member-link-select-expires-in-label"
                                            id="member-link-select-expires-in"
                                            value={invitationLinkExpiresIn}
                                            label="Expires in"
                                            onChange={(event) => {
                                                if (typeof event.target.value === "number")
                                                    setInvitationLinkExpiresIn(event.target.value)
                                                // if(val) setExpiresIn(va)
                                            }}
                                        >
                                            <MenuItem value={60}>1 hour</MenuItem>
                                            <MenuItem value={24 * 60}>1 day</MenuItem>
                                            <MenuItem value={24 * 60 * 3}>3 days</MenuItem>
                                            <MenuItem value={24 * 60 * 7}>1 week</MenuItem>
                                            <MenuItem value={24 * 60 * 14}>2 weeks</MenuItem>
                                            <MenuItem value={24 * 60 * 30}>1 month</MenuItem>
                                            <MenuItem value={24 * 60 * 30 * 3}>3 months</MenuItem>
                                            <MenuItem value={24 * 60 * 30 * 6}>6 months</MenuItem>
                                            <MenuItem value={24 * 60 * 30 * 12}>1 year</MenuItem>
                                            <MenuItem value={-1}>Never</MenuItem>
                                        </Select>
                                    </FormControl>
                                    {
                                        allowCustomInvitationId &&
                                        <>
                                            <FormControlLabel
                                                sx={{my: 1}}
                                                control={
                                                    <Switch
                                                        value={useCustomInvitationId}
                                                        onChange={(e) => setUseCustomInvitationId(e.target.checked)}
                                                    />
                                                }
                                                label="Use custom invitation ID"
                                            />
                                            <Collapse in={useCustomInvitationId}>
                                                <TextField
                                                    sx={{my: 1, minWidth: "200px"}}
                                                    label="Invitation ID"
                                                    variant="filled"
                                                    error={!!customInvitationIdError && customInvitationId.length > 0}
                                                    helperText={!!customInvitationIdError && customInvitationId.length > 0 ? customInvitationIdError : null}
                                                    value={customInvitationId}
                                                    onChange={(e) => {
                                                        checkInvitationIdDebounced(e.target.value)
                                                        setCustomInvitationId(e.target.value)
                                                    }}
                                                    // color="warning"
                                                    // focused
                                                />
                                            </Collapse>
                                        </>
                                    }
                                    <Button
                                        disabled={useCustomInvitationId && !!customInvitationIdError}
                                        variant={"contained"}
                                        sx={{mt: 1, alignSelf: "end"}}
                                        onClick={createInvitationLinkCallback}
                                    >
                                        Create
                                    </Button>
                                </Column>
                        }
                    </Card>
            }
        </>
    )
}

