import {loadStripe} from "@stripe/stripe-js";


let strip = null
try {
    strip = loadStripe(process.env.REACT_APP_STRIPE_API_KEY as string)
} catch (e) {
    console.error(e)
}
export const stripe = strip // loadStripe(process.env.REACT_APP_STRIPE_API_KEY as string)
