import React, {useCallback, useEffect, useMemo, useState} from "react";
import {getAuth, sendSignInLinkToEmail} from "firebase/auth";
import {Alert, Button, CircularProgress, Typography} from "@mui/material";
import {Navigate, useSearchParams} from "react-router-dom";
import {isSignInWithEmailLink, signInWithEmailLink} from "firebase/auth"
import {discunaDesktopAppProtocol} from "../../discunaDesktopApi";

export function AuthCredentialsViaEmail({email, onAuthSuccess, onGoBack}: {
    email: string | null,
    onGoBack: () => void,
    onAuthSuccess: () => void
}) {
    const [progress, setProgress] = useState<"processing" | "emailSignInLinkSent" | string>("processing")

    const [params] = useSearchParams()
    const app = useMemo(() => params.get("app"), [params])

    const sendEmailLink = useCallback(() => {
        if (!email) throw Error("email must be defined")
        const url = `${window.origin}/auth/credentials?via=email&app=${window.discunaDesktopAPI ? "desktopApp" : "desktopBrowser"}`
        setProgress("processing")
        sendSignInLinkToEmail(getAuth(), email, {
            url,
            handleCodeInApp: true
        }).then(() => {
            setProgress("emailSignInLinkSent")
        }).catch((e) => {
            setProgress(e.message ?? "unknown error")
        })
    }, [email])

    useEffect(() => {
        if (app === "desktopApp") {
            const url = new URL(window.location.href)
            url.searchParams.delete("app")
            window.location.assign(`${discunaDesktopAppProtocol}://auth/credentials?via=email${url.search}`)
        } else if (email) {
            const auth = getAuth()
            if (isSignInWithEmailLink(auth, window.location.href)) {
                // sign in user
                signInWithEmailLink(auth, email, window.location.href)
                    .then(onAuthSuccess)
                    .catch((reason) => {
                        setProgress(reason.message ?? "unknown")
                    })
            } else {
                // send sign in email link
                sendEmailLink()
            }
        }
    }, [email, sendEmailLink, app, onAuthSuccess])

    if (!email && app !== "desktopBrowser") {
        return <Navigate to={"/auth/identity"} replace={true}/>
    }

    return (
        <>
            <Typography variant={"body1"} sx={{mt: 3, textAlign: "center"}}>
                {
                    app === "desktopApp" ?
                        "Launching in desktop app..." :
                        "Email link authentication"
                }
            </Typography>

            {
                app !== "desktopApp" && progress === "processing" &&
                <CircularProgress size={20} sx={{
                    mt: 3,
                    alignSelf: "center"
                }}/>
            }

            {
                progress === "emailSignInLinkSent" &&
                <Alert sx={{mt: 1}} severity="success">
                    Sign-in link sent to your inbox.
                </Alert>
            }

            {
                progress !== "processing" && progress !== "emailSignInLinkSent" &&
                <Alert sx={{mt: 1}} severity="error">
                    Error: {progress ?? "unknown"}. Please try again or use a different email.
                </Alert>
            }

            {
                app !== "desktopApp" &&
                <Button
                    sx={{
                        mt: 6,
                        alignSelf: "start"
                    }}
                    onClick={onGoBack}
                >
                    Go back
                </Button>
            }
        </>
    )
}
