import {ModalTitle, ModalWithPresence} from "../../../../../components/modal";
import {Box, Button, Typography} from "@mui/material";
import {Row} from "../../../../../components/motion_mui";
import {deleteCommunity} from "../../../../../firebase/discunaApi";
import React, {useCallback, useState} from "react";
import {CustomLoadingButton} from "../accessWrappers";
import {setSelectedCommunityId} from "../../../dashboardSlice";
import {useDispatch} from "react-redux";
import {useAppDispatch} from "../../../../store";


export function DeleteCommunityModal({show, communityId, communityName, onClose}: {
    communityId: string,
    communityName: string,
    onClose: () => void,
    show: boolean,
}) {
    const [pressedConfirm, setPressedConfirm] = useState(false)
    const dispatch = useAppDispatch()
    const onConfirm = useCallback(async () => {
        setPressedConfirm(true)
        dispatch(deleteCommunity({
            communityId
        }))
        dispatch(setSelectedCommunityId(null))
        onClose()
    }, [dispatch, onClose, communityId])

    return (
        <ModalWithPresence onCancel={onClose}
                           show={show}>
            <ModalTitle title={`Delete "${communityName}"`} onCancel={onClose}/>
            {
                <Typography variant={"body1"}>
                    Are you sure you want to delete "{communityName}"? This step cannot be undone.
                </Typography>
            }
            <Row sx={{mt: 4}} mainAxisAlignment={"end"} crossAxisAlignment={"center"}>
                <Button onClick={onClose} disabled={pressedConfirm}>
                    Cancel
                </Button>
                <Box width={12}/>
                <CustomLoadingButton
                    variant={"contained"}
                    disabled={pressedConfirm}
                    color={"primary"}
                    onClick={onConfirm}
                    isLoading={pressedConfirm}
                >
                    Confirm
                </CustomLoadingButton>
            </Row>

        </ModalWithPresence>
    )
}
