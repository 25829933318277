import {Button, Card, CardActions, CardContent, Link, Typography} from "@mui/material";
import React, {useState} from "react";
import {Column} from "../../../../components/motion_mui";
import {AddressFormModal} from "./addressFormModal";
import {useSelector} from "react-redux";
import {RootState} from "../../../store";


export function Billing() {


    const [showAddressForm, setShowAddressForm] = useState(false)

    // TODO get address from user secrets
    const userSecretsData = useSelector((state: RootState) => state.userSecrets.data)

    if (!userSecretsData) return null

    const billingInfo = userSecretsData.details.profile.billing

    return (
        <Column sx={{ml: 4, mt: 4}} mainAxisAlignment={"start"} crossAxisAlignment={"start"}>
            <Typography variant={"h4"} sx={{mb: 2}}>
                Billing
            </Typography>

            {
                billingInfo ?
                    (
                        <Card variant={"outlined"}>
                            <CardContent>
                                <Typography sx={{fontSize: 14}} color="text.secondary" gutterBottom>
                                    Billing Address
                                </Typography>
                                {/*<Typography sx={{ mb: 1.5 }} color="text.secondary">*/}
                                {/*    adjective*/}
                                {/*</Typography>*/}
                                <Typography variant="body2">
                                    {billingInfo.name} <br/>
                                    {billingInfo.address.line1} <br/>
                                    {
                                        billingInfo.address.line2 &&
                                        <>
                                            {billingInfo.address.line2} <br/>
                                        </>
                                    }
                                    {billingInfo.address.city} <br/>
                                    {billingInfo.address.country} - {billingInfo.address.postal_code} {billingInfo.address.state}
                                </Typography>
                            </CardContent>
                            <CardActions>
                                <Button size="small" onClick={() => setShowAddressForm(true)}>Update</Button>
                            </CardActions>
                        </Card>
                    ) :
                    <Button variant={"outlined"} onClick={() => setShowAddressForm(true)}>
                        Add billing address
                    </Button>
            }


            <Link sx={{mt: 2}} underline={"hover"}
                  href={process.env.REACT_APP_STRIPE_CUSTOMER_PORTAL_URL}>
                Manage invoices
            </Link>

            <AddressFormModal open={showAddressForm} onClose={() => {
                setShowAddressForm(false)
            }} isUpdate={!!billingInfo}/>

        </Column>
    )
}
