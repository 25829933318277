import {useLocation, useNavigate} from "react-router-dom";
import {InvitationLinkWithInvitationId} from "../../communities/invitationLinks/invitationLinkInterfaces";
import useMemoizedState from "../../../../../hooks/useMemoizedState";
import usePrevious from "../../../../../hooks/previous";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {Divider} from "@mui/material";
import {Column} from "../../../../../components/motion_mui";
import {AnimatePresence, motion} from "framer-motion";
import {sidebarWidth} from "../../../../../constants/constants";
import {DndProvider} from "react-dnd";
import {HTML5Backend} from "react-dnd-html5-backend";
import {CommunityContainer} from "./communityContainer";
import {CommunityList} from "./communityList";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../../store";
import {setSelectedCommunityId} from "../../../dashboardSlice";


interface CommunityProps {
    communityId: string,
    isPreview: boolean,
    invitationId?: string,
    communityName?: string,
}

export function CommunitySection(props: {
    navTo: (navId: string) => void
}) {
    return (
        <>
            <Divider style={{
                margin: "8px 16px"
            }}/>
            <AnimatedCommunityContainer
                navTo={props.navTo}
            />
        </>
    )
}

const exitRight = sidebarWidth + 16
const exitLeft = -exitRight

function AnimatedCommunityContainer(props: {
    navTo: (navId: string) => void,
}) {

    const selectedCommunityId = useSelector((state: RootState) => state.dashboard.selectedCommunityId)
    const prevSelectedCommunity = usePrevious(selectedCommunityId)
    const initial1 = useMemo(() =>
        prevSelectedCommunity === undefined && selectedCommunityId ? 0 : exitRight,
        [prevSelectedCommunity, selectedCommunityId])
    const initial2 = useMemo(() => prevSelectedCommunity ? exitLeft : 0, [prevSelectedCommunity])

    return (
        <Column mainAxisAlignment={"start"} crossAxisAlignment={"stretch"}
                sx={{position: "relative", width: "100%", overflow: "hidden", flexGrow: 1}}>

            <AnimatePresence>
                {
                    selectedCommunityId !== null &&
                    <motion.div
                        style={{position: "absolute", width: "100%", height: "100%"}}
                        initial={{x: initial1}}
                        animate={{x: 0}}
                        transition={{
                            default: {duration: 0.5},
                            // ease: "linear"
                        }}
                        exit={{x: exitRight}}
                    >
                        <DndProvider backend={HTML5Backend}>
                            <CommunityContainer
                                navTo={props.navTo}
                                communityId={selectedCommunityId}
                            />
                        </DndProvider>
                    </motion.div>
                }
            </AnimatePresence>
            <AnimatePresence>
                {
                    selectedCommunityId === null &&
                    <motion.div
                        style={{position: "absolute", width: "100%", height: "100%", overflow: "hidden"}}
                        initial={{x: initial2}}
                        animate={{x: 0}}
                        transition={{
                            default: {duration: 0.5},
                            // ease: "linear"
                        }}
                        exit={{x: exitLeft}}
                    >
                        <CommunityList/>
                    </motion.div>
                }
            </AnimatePresence>
        </Column>
    )
}

