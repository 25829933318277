import {createSlice, PayloadAction} from "@reduxjs/toolkit";

interface AuthSignedOut {
    isSignedIn: false,
}

type AuthData = {
    type: "guest"
} | {
    type: "email",
    name?: string,
    email: string,
    emailVerified: boolean,
}

interface AuthSignedIn {
    isSignedIn: true,
    uid: string,
    providerId: string,
    data: AuthData
}

export type Auth = {hasLoaded: boolean, signedOutByAction: boolean} & (AuthSignedOut | AuthSignedIn)

const authSlice = createSlice({
    name: 'auth',
    initialState: {
        hasLoaded: false,
        isSignedIn: false,
        // NOTE we use sign in with redirect => this variable is always false when new sign in
        signedOutByAction: false
    } as Auth,
    reducers: {
        updateNameRedux(state, action: PayloadAction<string>) {
            if(state.isSignedIn && state.data.type === "email") {
                state.data.name = action.payload
            }
        },
        signIn(state, action: PayloadAction<{
            uid: string,
            providerId: string,
            data: AuthData
        }>) {
            state.hasLoaded = true
            state.isSignedIn = true

            if(state.isSignedIn) {
                state.providerId = action.payload.providerId
                state.uid = action.payload.uid
                state.data = action.payload.data
            }
        },
        signOut(state) {
            // only set to true if auth has loaded
            state.signedOutByAction = state.hasLoaded
            state.hasLoaded = true
            state.isSignedIn = false
        }
    }
})

export const {
    signIn,
    signOut,
    updateNameRedux
} = authSlice.actions

export const authReducer = authSlice.reducer
