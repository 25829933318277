import {useDispatch} from "react-redux";
import useMemoizedState from "../../../../../hooks/useMemoizedState";
import {useEffect} from "react";
import {firestoreManager} from "../../../../../firebase/queryManager";
import {FieldPath, firestore} from "../../../../../firebase/firebase";
import {AppFirestoreData} from "shared";
import {DiscunaApp} from "./discunaAppInterface";
import {upsertDiscunaApp} from "./discunaAppSlice";


export function useAppsOnce() {
    const dispatch = useDispatch()
    const [isLoading, setIsLoading] = useMemoizedState(true)

    useEffect(() => {
        setIsLoading(true)
        const appsCollection = firestore.collection("apps")
        firestoreManager.queryCollection(appsCollection).then((docs) => {
            docs.forEach((doc) => {
                const discunaApp = {
                    id: doc.id,
                    isSynced: true,
                    ...(doc.data() as AppFirestoreData)
                } as DiscunaApp
                dispatch(upsertDiscunaApp(discunaApp))
            })
            setIsLoading(false)
        })
    }, [dispatch, setIsLoading])

    return isLoading
}
