import {Column, Row} from "../../../../components/motion_mui";
import {Button, Typography} from "@mui/material";
import {darkGrey} from "../../../../constants/constants";
import React, {useState} from "react";
import {useSelector} from "react-redux";
import {RootState} from "../../../store";
import {UpdateAccountModal} from "./updateAccountModal";


export function AccountDetails() {

    const [showChangeName, setShowChangeName] = useState(false)
    const auth = useSelector((state: RootState) => state.auth)

    if (!auth.isSignedIn ||  auth.data.type !== "email") return null

    return (
        <Column sx={{ml: 4, mt: 4}} mainAxisAlignment={"start"} crossAxisAlignment={"start"}>
            <Row mainAxisAlignment={"start"} crossAxisAlignment={"baseline"}>
                <Typography variant={"h4"} sx={{mb: 1}}>
                    Details
                </Typography>
                <Button sx={{ml: 1}} variant={"text"} size={"small"} onClick={() => setShowChangeName(true)}>
                    edit
                </Button>
            </Row>

            <Typography sx={{color: darkGrey}} variant={"body1"}>
                Name: {auth.data.name}
            </Typography>


            <Typography sx={{color: darkGrey}} variant={"body1"}>
                Email: {auth.data.email}
            </Typography>
            <Typography sx={{color: darkGrey}} variant={"body1"}>
                Version: {window.version}
            </Typography>

            <UpdateAccountModal show={showChangeName} onClose={() => setShowChangeName(false)}/>

        </Column>
    )
}
