import {ModalTitle, ModalWithPresence} from "../../../../components/modal";
import React, {useCallback, useState} from "react";
import {
    Alert,
    Button,
    Card,
    CardActionArea, CardContent, CardHeader,
    CircularProgress, List,
    ListItemButton,
    ListItemIcon, ListItemText,
    TextField,
    Typography,
    SxProps
} from "@mui/material";
import {appConfigValidator} from "shared"
import {createCustomApp, generateId, updateCommunity} from "../../../../firebase/discunaApi";
import {useDispatch} from "react-redux";
import {Center} from "../../../../components/motion_mui";
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import {circularProgressSize} from "../../../../constants/constants";

export function AppFormModal({onClose, show}: {
    onClose: () => void,
    show: boolean
}) {

    return (
        <ModalWithPresence onCancel={onClose}
                           show={show}>
            <AppForm onClose={onClose}/>
        </ModalWithPresence>
    )
}

function AppForm({onClose}: {
    onClose: () => void,
}) {
    const [appConfig, setAppConfig] = useState("")

    const [appConfigError, setAppConfigError] = useState<string | null>(null)

    const dispatch = useDispatch()

    const [progress, setProgress] = useState<{
        code: "pre-submission" | "loading"
    } | {
        code: "success",
        data: {
            openIdClientId: string,
            discunaCoreApiClientId: string,
            discunaCoreApiClientSecret: string
        }
    }>({code: "pre-submission"})

    const onSubmit = useCallback(async (appConfig: string) => {
        setProgress({code: "loading"})
        const data = JSON.parse(appConfig)
        const res = await createCustomApp({
            appConfig: data,
        })
        // TODO error handling
        setProgress({
            code: "success",
            data: res.data
        })
    }, [])

    if (progress.code === "pre-submission") {
        return (
            <>
                <ModalTitle title={"Add App"} onCancel={onClose}/>

                <TextField
                    key={"appConfig"}
                    // sx={{mt: 2}}
                    variant={"filled"}
                    label={"App configuration"}
                    value={appConfig}
                    multiline={true}
                    error={!!appConfigError}
                    helperText={appConfigError}
                    onKeyDown={(e) => {
                        if (e.key === "TAB") {
                            e.preventDefault()
                        }
                    }}
                    onChange={(e) => {
                        try {
                            const data = JSON.parse(e.target.value)
                            const validationResult = appConfigValidator(data)
                            if (!validationResult.ok) {
                                setAppConfigError(validationResult.explanation ?? "unknown error")
                            } else {
                                setAppConfigError(null)
                            }
                        } catch (e) {
                            setAppConfigError("Invalid JSON")
                        }
                        setAppConfig(e.target.value)
                    }}
                />

                <Button
                    variant={"contained"}
                    sx={{mt: 2, alignSelf: "end"}}
                    disabled={appConfig.length === 0 || !!appConfigError}
                    onClick={() => {
                        onSubmit(appConfig)
                    }}
                >
                    Submit
                </Button>
            </>
        )
    }
    if (progress.code === "loading") {
        return (
            <>
                <ModalTitle title={"Add App"} onCancel={onClose}/>
                <Center>
                    <CircularProgress size={circularProgressSize}/>
                </Center>
            </>
        )
    }
    if (progress.code === "success") {
        return (
            <>
                <ModalTitle title={"Add App"} onCancel={onClose}/>
                <Alert severity={"info"}>
                    Please copy your secrete key and store it securely.
                    You cannot access your secrete key after you close this window.
                </Alert>

                <CopyCard
                    sx={{mt: 2}}
                    description={"Discuna Core Api Client Secret"}
                    copyText={progress.data.discunaCoreApiClientSecret}
                />

                <CopyCard
                    sx={{mt: 2}}
                    description={"Discuna Core Api Client ID"}
                    copyText={progress.data.discunaCoreApiClientId}
                />

                <CopyCard
                    sx={{mt: 2}}
                    description={"OpenID Client ID"}
                    copyText={progress.data.openIdClientId}
                />
            </>
        )
    }
    return null
}


function CopyCard(props: {
    description: string,
    copyText: string,
    sx?: SxProps
}) {

    const [copyProgress, setCopyProgress] = useState<"not-copied" | "copied" | "error">("not-copied")

    const onCopy = useCallback(async () => {
        try {
            await navigator.clipboard.writeText(props.copyText)
            setCopyProgress("copied")
        } catch (e) {
            setCopyProgress("error")
        } finally {
            setTimeout(() => {
                setCopyProgress("not-copied")
            }, 1000)
        }
    }, [props.copyText])

    return (
        <Card variant={"outlined"} sx={props.sx}>
            <ListItemButton dense onClick={onCopy}>
                <ListItemIcon>
                    {
                        copyProgress === "not-copied" ?
                            <ContentCopyOutlinedIcon/> :
                            copyProgress === "error" ?
                                <ErrorOutlineOutlinedIcon/> :
                                <CheckOutlinedIcon/>
                    }

                </ListItemIcon>
                <ListItemText primary={props.copyText} secondary={props.description}/>
            </ListItemButton>
        </Card>
    )
}
