import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../../store";
import {useEffect} from "react";
import {firestore} from "../../../../../firebase/firebase";
import {firestoreManager} from "../../../../../firebase/queryManager";
import {removeMemberSecrets, upsertMemberSecrets} from "./memberSecretsSlice";
import {MemberSecrets} from "./memberSecretsInterfaces";
import useMemoizedState from "../../../../../hooks/useMemoizedState";


export function useMemberSecrets(communityId: string) {
    const auth = useSelector((state: RootState) => state.auth)
    const dispatch = useDispatch()
    const [isLoading, setIsLoading] = useMemoizedState(true)

    // fetch community and community member data
    useEffect(() => {
        if (auth.isSignedIn) {
            const userId = auth.uid
            const communityRef = firestore.collection("communities").doc(communityId)
            const memberSecretsRef = communityRef.collection("memberSecrets").doc(userId)
            const {isNew, cancel} = firestoreManager.queryDocumentSnapshots(memberSecretsRef, (doc) => {
                if(doc.exists) {
                    const memberSecrets = {
                        // We choose community ID here because user ID does not
                        // uniquely identify member secrets from different communities
                        id: communityId,
                        isSynced: true,
                        ...doc.data()
                    } as MemberSecrets
                    dispatch(upsertMemberSecrets(memberSecrets))
                } else {
                    dispatch(removeMemberSecrets(communityId))
                }
                setIsLoading(false)
            })
            setIsLoading(isNew)
            return cancel
        }
    }, [auth, communityId, dispatch, setIsLoading])
    return isLoading
}
