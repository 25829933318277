import {Button, CircularProgress, Grid, Icon, Typography} from "@mui/material";
import React, {useCallback, useMemo, useState} from "react";
import {useSelector} from "react-redux";
import {RootState} from "../../../../store";
import {selectCommunityById} from "../communitySlice";
import {selectDiscunaAppsByPublisherId} from "./discunaAppSlice";
import {AppCard} from "./appCard";
import {addAppToCommunity} from "../../../../../firebase/discunaApi";
import {log} from "../../../../../utils/log";
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import {circularProgressSize} from "../../../../../constants/constants";
import {CustomLoadingButton} from "../accessWrappers";

export function SelfHostedAppsExplorer({communityId}: {
    communityId: string
}) {

    const community = useSelector((state: RootState) => selectCommunityById(state, communityId))

    // fetch discuna apps developed by user
    const auth = useSelector((state: RootState) => state.auth)
    if (!auth.isSignedIn) throw Error("unknown")


    const selectDiscunaAppsByPublisherIdMemo = useMemo(selectDiscunaAppsByPublisherId, [])
    const selfHostedApps = useSelector((state: RootState) => selectDiscunaAppsByPublisherIdMemo(state, auth.uid))

    const [appBeingAdded, setAppBeingAdded] = useState<null | string>(null)
    const onAddAppToCommunity = useCallback(async (appId: string) => {
        setAppBeingAdded(appId)
        addAppToCommunity({
            appId,
            communityId
        }).then(log).catch(console.error).finally(() => setAppBeingAdded(null))
    }, [communityId])


    const appStates = useMemo(() => {
        const appStates: Record<string, "loading" | "added" | null> = {}
        for (const app of selfHostedApps) {
            if (!community || !community.details.apps?.includes(app.id)) {
                appStates[app.id] = null
            } else {
                appStates[app.id] = "added"
            }
        }
        return appStates
    }, [community, selfHostedApps])

    return (
        <>
            <Typography variant={"h4"} sx={{ml: 4, mt: 4, mb: 1}}>
                Self-hosted Apps
            </Typography>
            {
                selfHostedApps.length === 0 &&
                <Typography sx={{ml: 4}} variant={"body1"} color={"text.secondary"}>
                    No self-hosted apps
                </Typography>
            }
            <Grid container spacing={2} sx={{
                width: "100%",
                pl: 4,
                pr: 2,
                pt: 2,
            }}>
                {
                    selfHostedApps.map((app) => (
                        <Grid key={app.id} item xs={12} md={6} lg={4}>
                            <AppCard
                                name={app.details.config.name}
                                description={app.details.config.description}
                                iconUrl={app.details.config.icons.app}
                                secondaryAction={
                                    appStates[app.id] === "added" ?
                                        <Icon>
                                            <CheckOutlinedIcon/>
                                        </Icon> :
                                        <CustomLoadingButton isLoading={appBeingAdded === app.id}
                                                             disabled={appBeingAdded !== null} onClick={() => {
                                            onAddAppToCommunity(app.id).catch(console.error)
                                        }}>
                                            Add
                                        </CustomLoadingButton>
                                }
                            />
                        </Grid>
                    ))
                }
            </Grid>
        </>
    )
}
