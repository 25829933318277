import {createEntityAdapter, createSelector, createSlice, PayloadAction} from '@reduxjs/toolkit'
import {CommunityLibrary} from "./communityLibraryInterfaces";
import {RootState} from "../../../../store";
import {createCommunity, updateChannel, updateCommunityLibrary} from "../../../../../firebase/discunaApi";
import {createDefaultCommunityLibrary, getChildren} from 'shared';
import {
    arraySaveDelete,
    arraySavePush,
    removeArrayItemWithId,
    updateArrayItemWithId
} from "../../../../../utils/arrayOperations";

const communityLibraryEntityAdapter = createEntityAdapter<CommunityLibrary>()

const communityLibrarySlice = createSlice({
    name: "communityLibraries",
    initialState: communityLibraryEntityAdapter.getInitialState({}),
    reducers: {
        addCommunityLibrary: communityLibraryEntityAdapter.addOne,
        upsertCommunityLibrary: communityLibraryEntityAdapter.upsertOne,
        updateCommunityLibraryRedux: communityLibraryEntityAdapter.updateOne,
        removeCommunityLibrary: communityLibraryEntityAdapter.removeOne,
        removeAllCommunityLibraries: communityLibraryEntityAdapter.removeAll,
        setAllCommunityLibraries: communityLibraryEntityAdapter.setAll
    },
    extraReducers: builder => {
        builder.addCase(createCommunity.fulfilled,
            (state, action) => {
                const communityLibrary = state.entities[action.meta.arg.communityId]
                if (communityLibrary) communityLibrary.isSynced = true
            })
            .addCase(createCommunity.pending,
                (state, action) => {
                    const args = action.meta.arg
                    const communityLibrary = {
                        // redux
                        id: args.communityId,
                        isSynced: false,

                        // firestore
                        ...createDefaultCommunityLibrary()
                    } as CommunityLibrary

                    communityLibraryEntityAdapter.upsertOne(state, communityLibrary)
                })
            .addCase(updateCommunityLibrary.fulfilled,
                (state, action) => {
                    const args = action.meta.arg
                    const communityLibrary = state.entities[args.communityId]
                    if(communityLibrary) {
                        const libraryAction = args.actions[0]
                        if(libraryAction.actionType === "createFolder" || libraryAction.actionType === "rename") {
                            communityLibrary.channelsNotSynced = arraySaveDelete(
                                communityLibrary.channelsNotSynced, libraryAction.channelId
                            )
                        }
                    }
                })
            .addCase(updateCommunityLibrary.pending,
                (state, action) => {
                    const args = action.meta.arg
                    const communityLibrary = state.entities[args.communityId]
                    if(!communityLibrary) throw Error("invalid args")
                    const libraryAction = args.actions[0]
                    const libraryItems = communityLibrary.details.libraryItems
                    switch (libraryAction.actionType) {
                        case "createFolder":
                            libraryItems.push({
                                id: libraryAction.channelId,
                                name: libraryAction.name,
                                parentId: libraryAction.parentId,
                                appId: "folder",
                                createdOn: (new Date()).getTime()
                            })
                            break;
                        case "rename":
                            communityLibrary.details.libraryItems = updateArrayItemWithId(
                                libraryItems,
                                libraryAction.channelId,
                                {
                                    name: libraryAction.newName
                                }
                            )
                            break;
                        case "delete":
                            const ids = [libraryAction.channelId]
                            const children = getChildren(libraryAction.channelId, libraryItems)
                            ids.push(...(children.map((val) => val.id)))
                            const idsSet = new Set(ids)
                            communityLibrary.details.libraryItems = communityLibrary.details.libraryItems
                                .filter(val => !idsSet.has(val.id))
                            break;
                        case "move":
                            communityLibrary.details.libraryItems = updateArrayItemWithId(
                                libraryItems,
                                libraryAction.channelId,
                                {
                                    parentId: libraryAction.newParentId
                                }
                            )
                            break;
                    }
                    if(libraryAction.actionType === "createFolder" || libraryAction.actionType === "rename") {
                        communityLibrary.channelsNotSynced = arraySavePush(
                            communityLibrary.channelsNotSynced, libraryAction.channelId
                        )
                    }
                })

            .addCase(updateChannel.pending, (state, action) => {
                const args = action.meta.arg
                const communityLibrary = state.entities[args.communityId]
                if(communityLibrary && args.name) {
                    communityLibrary.details.libraryItems = updateArrayItemWithId(
                        communityLibrary.details.libraryItems,
                        args.channelId,
                        {
                            name: args.name
                        }
                    )
                }
            })

    }
})

export const {
    addCommunityLibrary,
    upsertCommunityLibrary,
    updateCommunityLibraryRedux,
    removeCommunityLibrary,
    removeAllCommunityLibraries,
    setAllCommunityLibraries
} = communityLibrarySlice.actions

export const communityLibraryReducer = communityLibrarySlice.reducer

export const {
    selectAll: selectAllCommunityLibraries,
    selectById: selectCommunityLibraryById,
    selectIds: selectCommunityLibraryIds,
} = communityLibraryEntityAdapter.getSelectors<RootState>(state => state.communityLibraries)
