import React from "react";
import {MotionBox, Row} from "../../../../../components/motion_mui";
import useDimensions from "react-cool-dimensions";
import {Box} from "@mui/material";
import {InboxBadge} from "../../inbox/inboxBadge";


export function useDiscunaBadgeList(
    communityId: string,
    nInboxNormal: number,
    nInboxHigh: number,
    bookmarksCount: Record<string, number>,
    channelId?: string,
    allChildChannels?: string[],
) {

    const badges = [
        <InboxBadge
            // priority={"normal"}
            badgeColor={"#0063dc"}
            // badgeType={"inbox"}
            inboxPriority={"normal"}
            count={nInboxNormal}
            communityId={communityId}
            key={"normal" + communityId + channelId}
            channelId={channelId}
            allChildChannels={allChildChannels}
        />,
        <InboxBadge
            badgeColor={"#F44336"}
            inboxPriority={"high"}
            count={nInboxHigh}
            communityId={communityId}
            key={"high" + communityId + channelId}
            channelId={channelId}
            allChildChannels={allChildChannels}
        />,
        <InboxBadge
            badgeColor={"#FFCDD2"}
            bookmarkType={"unsolved"}
            count={bookmarksCount["unsolved"]}
            communityId={communityId}
            key={"unsolved" + communityId + channelId}
            channelId={channelId}
            allChildChannels={allChildChannels}
        />,
        <InboxBadge
            badgeColor={"#B2DFDB"}
            bookmarkType={"solved"}
            count={bookmarksCount["solved"]}
            communityId={communityId}
            key={"solved" + communityId + channelId}
            channelId={channelId}
            allChildChannels={allChildChannels}
        />,
        <InboxBadge
            badgeColor={"#008000"}
            bookmarkType={"todo"}
            count={bookmarksCount["todo"]}
            communityId={communityId}
            key={"todo" + communityId + channelId}
            channelId={channelId}
            allChildChannels={allChildChannels}
        />,
        <InboxBadge
            badgeColor={"#FFA500"}
            bookmarkType={"interesting"}
            count={bookmarksCount["interesting"]}
            communityId={communityId}
            key={"interesting" + communityId + channelId}
            channelId={channelId}
            allChildChannels={allChildChannels}
        />
    ]

    const [badgeList, badgeListPlaceholder] = useBadgeList(badges)
    return [badgeList, badgeListPlaceholder] as const
}


export function useBadgeList(badges: React.ReactElement[]) {
    const badgeListDims = useDimensions()

    const badgeList = (
        <Row
            sx={{
                position: "absolute",
                zIndex: 2,
                top: 8,
                left: "16px",
            }}
            ref={badgeListDims.observe}
            mainAxisAlignment={"start"}
            crossAxisAlignment={"start"}
        >
            {badges.map((b) => {
                return (
                    <BadgeContainer badge={b} key={b.key}/>
                )
            })}
        </Row>
    )

    const badgeListPlaceholder = (
        // <MotionBox
        //     // sx={{width: width, height: height}}
        //     animate={{width: badgeListDims.width, height: badgeListDims.height}}
        //     initial={{width: badgeListDims.width, height: badgeListDims.height}}
        //     // dragTransition={{bounceDamping: 0}}
        //     transition={{
        //         ease: "linear",
        //         duration: 0.0,
        //         // x: { duration: 0.1 }
        //     }}
        // />
        <Box sx={{width: badgeListDims.width}}/>
    )

    return [badgeList, badgeListPlaceholder] as const
}


export function BadgeContainer(props: {
    badge: React.ReactNode,
}) {
    const badgeDims = useDimensions()

    return (
        <Box>
            <Box ref={badgeDims.observe} sx={{
                position: "absolute"
            }}>
                {props.badge}
            </Box>
            {
                // placeholder for badge
                // badgeDims.entry &&
                // <MotionBox
                //     transition={{
                //         ease: "linear",
                //         duration: 0.0,
                //     }}
                //     initial={{width: badgeDims.width, height: badgeDims.height}}
                //     animate={{width: badgeDims.width, height: badgeDims.height}}
                // />
                <Box sx={{width: badgeDims.width}}/>
            }
        </Box>
    )
}


export function BadgeList(props: {
    badges: React.ReactElement[],
}) {

    const badgeListDims = useDimensions()

    return (
        <Box>
            <Row
                sx={{position: "absolute", zIndex: 100, bgcolor: "green"}}
                onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    e.nativeEvent.stopImmediatePropagation();
                }}
                ref={badgeListDims.observe}
                mainAxisAlignment={"start"}
                crossAxisAlignment={"start"}
            >
                {props.badges}
            </Row>
            <Box sx={{width: badgeListDims.width, height: badgeListDims.height}}/>
        </Box>

    )
}

export function BadgeListPlaceholder(props: {}) {
    return (
        <Row mainAxisAlignment={"start"} crossAxisAlignment={"start"}>

        </Row>
    )
}
