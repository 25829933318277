import {useSelector} from "react-redux";
import React, {useMemo, useState} from "react";
import {NestedListItem} from "./nestedListItem";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import {Menu, MenuItem} from "@mui/material";
import {AddFolderChannel} from "./contextMenu/addFolderChannel";
import {
    selectChannelOfflineDataById,
    upsertChannelOfflineData
} from "../../../communities/channels/channelOfflineDataSlice";
import {RootState, store, useAppDispatch} from "../../../../../store";
import {EditChannelModal} from "./contextMenu/editChannelModal";
import {DeleteChannel} from "./contextMenu/deleteChannel";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import NotificationsOffOutlinedIcon from "@mui/icons-material/NotificationsOffOutlined";
import {toggleChannelMute} from "../../../../../../firebase/discunaApi";
import {RestrictAccess} from "../../../communities/accessWrappers";
import {LibraryItemWithLevel} from "./communityLibraryUtils";
import {AddDiscunaApp} from "./contextMenu/addDiscunaApp/addDiscunaApp";
import {selectCommunityById} from "../../../communities/communitySlice";
import {selectDiscunaAppsByIDs} from "../../../communities/apps/discunaAppSlice";


export function FolderItem({isDraggable = true, ...props}: {
    // community info
    communityId: string,
    allChildChannels: string[],

    isSynced: boolean,
    // item props
    item: LibraryItemWithLevel,

    numUnreadChannelMessages: number,
    numUnreadPersonalMessages: number,
    bookmarksCount: Record<string, number>,

    selected: boolean,

    // mute
    muted: boolean,
    enableMuteMenu: boolean,

    // drag and drop
    dragId: string | null,
    setDragId: (id: string | null) => void,
    isDraggable?: boolean
}) {
    const {communityId, allChildChannels, item, numUnreadChannelMessages, numUnreadPersonalMessages, isSynced} = props
    const community = useSelector((state: RootState) => selectCommunityById(state, communityId))
    const selectDiscunaAppsByIDsMemo = useMemo(selectDiscunaAppsByIDs, [])
    const communityApps = useSelector((state: RootState) => selectDiscunaAppsByIDsMemo(state, community?.details?.apps ?? []))
    const [appID, setAppID] = useState<null | string>(null)


    const channelOfflineData = useSelector((state: RootState) => selectChannelOfflineDataById(state, item.id))
    const folderIsOpen = channelOfflineData?.open

    const dispatch = useAppDispatch()

    const [showFolderForm, setShowFolderForm] = useState(false)
    // const [showURLForm, setShowURLForm] = useState(false)
    const [showPDFForm, setShowPDFForm] = useState(false)
    const [showEditForm, setShowEditForm] = useState(false)
    const [showDeleteForm, setShowDeleteForm] = useState(false)
    const [showChatForm, setShowChatForm] = useState(false)
    const [showAnnouncementsForm, setShowAnnouncementsForm] = useState(false)

    const [contextMenu, setContextMenu] = useState<{
        mouseX: number;
        mouseY: number;
    } | null>(null);

    const handleContextMenu = (event: React.MouseEvent) => {
        event.preventDefault();
        setContextMenu(
            contextMenu === null
                ? {
                    mouseX: event.clientX - 2,
                    mouseY: event.clientY - 4,
                }
                : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
                  // Other native context menus might behave different.
                  // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
                null,
        );
    };

    const handleClose = () => {
        setContextMenu(null);
    };

    // TODO isSynced

    return (
        <>
            <NestedListItem
                id={item.id}
                communityId={communityId}
                channelId={item.id}
                allChildChannels={allChildChannels}
                lowCount={numUnreadChannelMessages}
                highCount={numUnreadPersonalMessages}
                bookmarksCount={props.bookmarksCount}
                name={item.name}
                muted={props.muted}
                level={item.level}
                isSynced={isSynced}
                selected={contextMenu !== null || props.selected}
                dragId={props.dragId}
                setDragId={props.setDragId}
                isDroppable={isDraggable}
                isDraggable={isDraggable}
                icon={
                    <ExpandMoreOutlinedIcon sx={{
                        transform: `rotate(${folderIsOpen ? 0 : -90}deg)`,
                        transition: `all 0.1s`
                    }}/>
                }
                onContextMenu={handleContextMenu}
                onLongDragOver={() => {
                    dispatch(upsertChannelOfflineData({
                        id: item.id,
                        communityId: communityId,
                        open: true,
                    }))
                }}
                onDragStart={() => {
                    dispatch(upsertChannelOfflineData({
                        id: item.id,
                        communityId: communityId,
                        open: false,
                    }))
                }}
                onClick={() => {
                    dispatch(upsertChannelOfflineData({
                        id: item.id,
                        communityId: communityId,
                        open: !folderIsOpen,
                    }))
                    if (window.discunaDesktopAPI) {
                        window.discunaDesktopAPI.setChannelOfflineData(store.getState().channelOfflineData)
                    }
                }}
            />
            <Menu
                id="qanda-more-library-item-menu"
                anchorReference="anchorPosition"
                anchorPosition={
                    contextMenu !== null
                        ? {top: contextMenu.mouseY, left: contextMenu.mouseX}
                        : undefined
                }
                open={contextMenu !== null}
                onClose={handleClose}
            >
                <MenuItem
                    disabled={!props.enableMuteMenu}
                    onClick={() => {
                        dispatch(toggleChannelMute({
                            value: !props.muted,
                            communityId: props.communityId,
                            channelId: props.item.id
                        }))
                        handleClose()
                    }}>
                    {
                        props.muted ?
                            <NotificationsActiveIcon sx={{mr: 1}}/> :
                            <NotificationsOffOutlinedIcon sx={{mr: 1}}/>
                    }
                    {
                        props.muted ?
                            "Unmute" :
                            "Mute"
                    }
                </MenuItem>
                <RestrictAccess communityId={communityId} role={"moderator"}>
                    <MenuItem onClick={() => {
                        handleClose()
                        setShowEditForm(true)
                    }}>
                        Edit
                    </MenuItem>
                    <MenuItem onClick={() => {
                        handleClose()
                        setShowDeleteForm(true)
                    }}>
                        Delete
                    </MenuItem>

                    <MenuItem onClick={() => {
                        handleClose()
                        setShowFolderForm(true)
                    }}>
                        + Folder
                    </MenuItem>

                    {
                        communityApps.map((app) => (
                                <MenuItem key={"menu-add-" + app.id} onClick={() => {
                                    handleClose()
                                    setAppID(app.id)
                                }}>
                                    +{" " + app.details.config.name}
                                </MenuItem>
                            )
                        )
                    }
                </RestrictAccess>
            </Menu>

            <AddDiscunaApp
                communityId={communityId}
                parentId={item.id}
                onClose={() => setAppID(null)}
                appId={appID}
            />

            <AddFolderChannel
                show={showFolderForm}
                communityId={communityId}
                parentId={item.id}
                onClose={() => setShowFolderForm(false)}
            />

            <EditChannelModal
                show={showEditForm}
                communityId={communityId}
                item={item}
                onClose={() => setShowEditForm(false)}
            />
            <DeleteChannel
                show={showDeleteForm}
                communityId={communityId}
                item={item}
                onClose={() => setShowDeleteForm(false)}
            />
        </>
    )
}
